import React from "react";
import { useSelector } from "react-redux";
import { getFacilityAction } from "../../../features/actions/facilityAction";
import { Box, Stack } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { getFacilityBySearchTermAction } from "../../../features/actions/facilityAction";
import CustomPreview from "./../../../layout/CustomPreview";
import { facilityCols } from "../../../utils/gridCols";

const Facility = () => {
  const navigate = useNavigate();
  const { getFacilityData, loading } = useSelector((state) => state.facility);
 console.log(getFacilityData, "facilityData")
  // rows
  const rows =
    getFacilityData?.result && getFacilityData.result?.facility?.length > 0
      ? getFacilityData.result?.facility?.map((el) => ({
          id: el.facilityId,
          facilityName: el.facilityName,
          phoneNumber: el.phoneNumber,
          sequenceNo: el.sequenceNo,
          taxonomySpecialty: el.taxonomySpecialty,
        }))
      : [];

  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={facilityCols}
        loading={loading}
        handleGetAll={getFacilityAction}
        paginationDetail={getFacilityData}
        handleBySearch={getFacilityBySearchTermAction}
        moduleHeading={'Facility'}
        handleCell={(params) => navigate(`/facility/update/${params?.id}`)}
        searchLabel="Search by First Name, Phone ,or Sequence Number"
      >
        <Stack direction="row" alignItems="center">
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => navigate("/facility/new")}
          >
            Add Facility
          </CustomButton>
        </Stack>
      </CustomPreview>
    </Box>
  );
};

export default Facility;
