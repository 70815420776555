// import { Box } from "@mui/material";
// import React, { useEffect } from "react";
// import { useFormik } from "formik";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getProviderAction,
//   getProviderByIdAction,
//   updateProviderAction,
// } from "../../../features/actions/providerAction";
// import MainHeading from "../../../components/MainHeading";
// import ProviderInfo from "./ProviderInfo";
// import { providerInitValues } from "../../../utils/formikInitValues";
// import CustomButton from "../../../components/CustomButton";
// import { useNavigate, useParams } from "react-router-dom";
// import { providerSchema } from "../../../schemas/patientSchema";
// import Loading from "../../../components/Loading";

// const UpdateProvider = () => {
//   const dispatch = useDispatch();
//   const { providerSequenceNo } = useParams();
//   useEffect(() => {
//     dispatch(getProviderByIdAction(providerSequenceNo));
//   }, [providerSequenceNo, dispatch]);

//   const { providerById, singleProviderLoading } = useSelector(
//     (state) => state.provider
//   );
//   const handleCancel = () => {
//     console.log("object");
//     const conform = window.confirm("Are you sure you want to cancel?");
//     if (conform) {
//       formik.resetForm();

//       navigate("/provider");
//     }
//   };
//   const navigate = useNavigate();

//   const providerToEdit = providerById?.result;

//   console.log(providerById?.result, "providerToEdit");
//   const formik = useFormik({
//     initialValues: providerById?.result,
//     validationSchema: providerSchema,
//     enableReinitialize: true,
//     onSubmit: (values, action) => {
//       console.log(formik.values.claimBillingId, "checkIdBox");
//       console.log(values, "provider Values");
//       try {
//         dispatch(
//           updateProviderAction({
//             providerSequenceNo: providerToEdit?.providerSequenceNo,
//             ...values,
//           })
//         );
//         action.resetForm();
//         navigate("/provider");
//       } catch (error) {
//         throw new Error(error);
//       }
//     },
//   });

//   return (
//     <>
//       <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
//         <MainHeading mainheading={"Update Provider"} />

//         <Box
//           display="grid"
//           gap="30px"
//           margin={"20px 0"}
//           sx={{
//             gridTemplateColumns: {
//               xs: "repeat(1, minmax(0, 150px))",
//               sm: "repeat(1, minmax(0, 150px))",
//               md: "repeat(2, minmax(0, 150px))",
//             },
//           }}
//         >
//           <CustomButton
//             formId="provider-form"
//             isEdit={true}
//             isBlue={true}
//             type="submit"
//           >
//             Update
//           </CustomButton>
//           <CustomButton isCancel={true} handleClick={handleCancel}>
//             Cancel
//           </CustomButton>
//         </Box>

//         <form id="provider-form" onSubmit={formik.handleSubmit}>
//           {singleProviderLoading ? (
//             <Loading />
//           ) : (
//             <ProviderInfo formik={formik} />
//           )}
//         </form>
//       </Box>
//     </>
//   );
// };

// export default UpdateProvider;




import { Box,Typography} from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getProviderByIdAction,
  updateProviderAction,
} from "../../../features/actions/providerAction";
import MainHeading from "../../../components/MainHeading";
import ProviderInfo from "./ProviderInfo";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { providerSchema } from "../../../schemas/patientSchema";
import Loading from "../../../components/Loading";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";

const UpdateProvider = () => {
  const dispatch = useDispatch();
  const { providerSequenceNo } = useParams();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  useEffect(() => {
    dispatch(getProviderByIdAction(providerSequenceNo));
  }, [providerSequenceNo, dispatch]);

  const { providerById, singleProviderLoading } = useSelector(
    (state) => state.provider
  );

  // const handleCancel = () => {
  //   const confirm = window.confirm("Are you sure you want to cancel?");
  //   if (confirm) {
  //     formik.resetForm();
  //     navigate("/provider");
  //   }
  // };
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/provider");
  };

  const navigate = useNavigate();

  const providerToEdit = providerById?.result;

  const formik = useFormik({
    initialValues: providerById?.result,
    validationSchema: providerSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      try {
        dispatch(
          updateProviderAction({
            providerSequenceNo: providerToEdit?.providerSequenceNo,
            ...values,
          })
        );
        action.resetForm();
        navigate("/provider");
      } catch (error) {
        throw new Error(error);
      }
    },
  });

  return (
    <>
      <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
        <MainHeading mainheading={"Update Provider"} providerSequenceNo={providerSequenceNo} />

        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >
          <CustomButton
            formId="provider-form"
            isEdit={true}
            isBlue={true}
            type="submit"
          >
            Update
          </CustomButton>
        <CustomButton   type="submit" isCancel={true} handleClick={handleCancel}>
         Cancel
       </CustomButton>
        </Box>

        <form id="provider-form" onSubmit={formik.handleSubmit}>
          {singleProviderLoading ? (
            <Loading />
          ) : (
            <ProviderInfo formik={formik} />
          )}
        </form>
      </Box>
      <CustomDialog
     open={openCancelDialog}
     isWarning={true}
     handleClose={() => setOpenCancelDialog(false)}
     handleConfirm={handleConfirmCancel}
     handleCancel={() => setOpenCancelDialog(false)}
     confirmButtonTitle="OK"
     cancelButtonTitle="Cancel"
    >
     <Typography component="h3">Are you sure you want to cancel?</Typography>
    </CustomDialog>
    </>
  );
};

export default UpdateProvider;

