
import React, { useEffect, useState } from "react";
import {
  Box,  
  Typography,
  AccordionDetails,
  FormControlLabel,
  FormGroup,
  TextField,
  FormControl,
} from "@mui/material";
import CustomField from '../../../components/CustomField';
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomAccordion from "../../../components/CustomAccordion";
import CheckBox from "../../../components/CheckBox";
import TextArea from "../../../components/TextArea";





const ReferringProviderInfo=({ formik })=> {
  const [referringType, setreferringType] = useState([]);
  const [taxIdType, settaxIdType] = useState([]);
 
  return (
    <>
       <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(4, minmax(0, 1fr))",
            },
          }}
        >
          {/* for id */}
           <CustomField
            type="number"
            label="Id"
            value={formik.values.renderingProviderId}
            name="renderingProviderId"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            
          />
          <CustomField
            type="text"
            label="First Name"
            value={formik.values.renderingProviderFirstName}
            name="renderingProviderFirstName"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            
          />
           <CustomField
            type="text"
            label="Last Name"
            value={formik.values.renderingProviderLastName}
            name="renderingProviderLastName"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
           <CustomField
            type="text"
            label="MI"
            value={formik.values.providerMI}
            name="providerMI"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
         
          />
           <CustomField
            type="number"
            label="Credentials"
            value={formik.values.providerCredential}
            name="providerCredential"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
         
          />
        </Box>
        <Box
          display="grid"
          gap="10px"
          margin={"0px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(2, minmax(0, 1fr))",
            },
          }}
        >
          <Typography variant="h5" fontWeight="600">
                This referrer is an:   
                </Typography>
  
          
        </Box>
        <Box
          display="grid"
          gap="10px"
          margin={"5px 15px"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(3, minmax(0, 1fr))",
            },
          }}
        >
          
        <FormGroup>
     
            <FormControlLabel
            
               label={
                <>
                <Typography variant="h5" fontWeight="600">
                  Individual
                </Typography>
           
               </>
              }
              control={<CheckBox />}
             
            />
          
          </FormGroup>
          <FormGroup>
            <FormControlLabel
               label={
                <Typography variant="h5" fontWeight="600">
                 Organization
                </Typography>
              }
              control={<CheckBox />}
            />
          
          </FormGroup>
        </Box>
        
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(2, minmax(0, 1fr))",
            },
          }}
        >
         <CustomSelectBox2
          label='Referring Provider'
          dropdownOptions={referringType.map((opt) => ({
       
          }))}
          />
<div style={{marginTop:'20px'}}>
           <FormGroup>
            <FormControlLabel
               label={
                <Typography variant="h5" fontWeight="600" >
                  Do not send referrer on claims
                </Typography>
              }
              control={<CheckBox />}
           
            />
          
          </FormGroup>
          </div>
        </Box>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(1, minmax(0, 1fr))",
            },
          }}
        >
         <CustomSearchField
          label='NPI'
          />
        </Box>

         
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(1, minmax(0, 1fr))",
            },
          }}
        >
         <CustomSearchField
                label="Taxonomy Specialty"
                fieldVal={formik.values.taxonomySpecialty}
                name="taxonomySpecialty"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                // handleModalOpen={() => setOpenPosModal(true)}
       
          />
        </Box> 

        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(2, minmax(0, 1fr))",
            },
          }}
        >
          
            <CustomField 
              type='text'
              disable={true}
              label='Sequence #'
              value={formik.values. providerMI}
              name=" providerMI"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              />

             <CustomField
            label="Reference #"
            type='number'
            value={formik.values.referenceNo}
            name="referenceNo"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Box> 


        <CustomAccordion heading="Contact Information" maxHeight="300px">
          <AccordionDetails>
            <Box
              display="grid"
              gap="30px"
              margin={"20px 0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(1, minmax(0, 1fr))",
                },
              }}
            >
              <CustomField
                type="text"
                label="Address"
                value={formik.values.address}
                name="address"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
          
              />
                <TextArea
              placeholder="Address"
              minRows={4}
              style={{ resize: "none", width: "100%", fontSize: "1.5rem" }}
            />
            </Box>

            <Box
              display="grid"
              gap="30px"
              margin={"20px 0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(3, minmax(0, 1fr))",
                },
              }}
            >
             
              
              <CustomField
                type="text"
                label="City"
                value={formik.values.city}
                name="city"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
       
              />
                <CustomField
                type="text"
                label="State"
                value={formik.values.state}
                name="state"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
       
              />
                <CustomField
                type="number"
                label="ZIP Code"
                value={formik.values.zipCode}
                name="zipCode"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
       
              />
            </Box>

            <Box
              display="grid"
              gap="30px"
              margin={"20px 0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 1fr))",
                },
              }}
            >
               <CustomField
                type="number"
                label="Home Phone"
                value={formik.values. homePhone}
                name=" homePhone"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
       
              />
                <CustomField
                type="number"
                label="Cell Phone"
                value={formik.values.cellPhone}
                name="cellPhone"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
       
              />
            
            </Box>
            <Box
              display="grid"
              gap="30px"
              margin={"20px 0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(1, minmax(0, 1fr))",
                },
              }}
            >
               <CustomField
                type="email"
                label="Email"
                value={formik.values.email}
                name="email"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
       
              />
            
            
            </Box>

           

            
         
            <div>
              <Typography
                variant="h2"
                component="h3"
                sx={{
                  bgcolor: "#DBE8FC",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              >
                ID Numbers
              </Typography>
              <Box
                display="grid"
                gap="30px"
                margin={"20px 0"}
                sx={{
                  gridTemplateColumns: {
                    xs: "repeat(1, minmax(0, 1fr))",
                    sm: "repeat(1, minmax(0, 1fr))",
                    md: "repeat(2, minmax(0, 1fr))",
                  },
                }}
              >
                <CustomField
                  type="number"
                  label="Tax ID"
                  value={formik.values.taxId}
                  name="taxId"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}                 
             
                />
                <CustomSelectBox2
                  label='Tax ID Type'
                  dropdownOptions={taxIdType.map((opt) => ({
                    // value: opt.cityName,
                    // id: opt.cityId,
                  }))}
                  
          
                />

                <CustomField
                  type="text"
                  label="UPIN"
                  value={formik.values.upinNo}
                  name="upinNo"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
               
                />
                {/* * */}
                <CustomField
                  type="text"
                  label="BCBS ID"
                  value={formik.values.bcbsId}
                  name="bcbsId"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
            
                />
                 <CustomField
                  type="text"
                  label="Medicare ID"
                  value={formik.values.medicareId}
                  name="medicareId"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
           
                />
                <CustomField
                  type="text"
                  label="Medicaid ID"
                  value={formik.values.medicaidId}
                  name="medicaidId"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
             
                />
                {/* * */}
                   <CustomField
                  type="text"
                  label="Champus ID"
                  value={formik.values.champusId}
                  name="champusId"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
             
                />
                <CustomField
                  type="text"
                  label="Specialty License #"
                  value={formik.values.specialtyLicenseNo}
                  name="specialtyLicenseNo"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                
                />
                 <CustomField
                  type="text"
                  label="State License #"
                  value={formik.values.stateLicenseNo}
                  name="stateLicenseNo"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
               
                />
                   <CustomField
                  type="text"
                  label="Anesthesia License #"
                  value={formik.values.anesthesiaLicenseNo}
                  name="anesthesiaLicenseNo"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
               
                />
                {/* * */}
                  <CustomField
                  type="text"
                  label="Marketer"
                  value={formik.values.marketer}
                  name="marketer"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
               
                />
              </Box>
            </div>
          </AccordionDetails>
        </CustomAccordion>
        </Box>
    </>
  )
}

export default ReferringProviderInfo

