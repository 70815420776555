// import React from "react";
// import { useTheme } from "@mui/material";
// import { Box, Typography } from "@mui/material";

// const MainHeading = ({ mainheading }) => {
//   const theme = useTheme(); 
//   return (
//     <>
//       <Box

//         sx={{
//           display: "flex",
//           // margin: "0 20px 0 ",
//           margin:"10px 0 15px",
//           width: "100%",
//           borderRadius: "10px",
//           background: theme.palette.backgroundMainHeading.primary, 
        
//         }}
//       >
//         <Typography
//           padding='12px'
//           component="h2"
//           fontWeight='800'
//           sx={{
//             color: theme.palette.textMainHeading.primary 
//           }}
          
//         >
//           {mainheading}
//         </Typography>
//       </Box>
//     </>
//   );
// };
// export default MainHeading;


import React from "react";
import { useTheme, Box, Typography } from "@mui/material";

const MainHeading = ({ mainheading, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "10px 0 15px",
        width: "100%",
        borderRadius: "10px",
        background: theme.palette.backgroundMainHeading.primary,
      }}
    >
      <Typography
        padding="12px"
        component="h2"
        fontWeight="800"
        sx={{
          color: theme.palette.textMainHeading.primary,
        }}
      >
        {mainheading}
      </Typography>
      <Box sx={{ 
        marginRight: "auto", 
        padding: "12px", color: theme.palette.textMainHeading.primary }}>
        {children}
      </Box>
    </Box>
  );
};

export default MainHeading;








