import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import CustomDataGrid from "../../../components/CustomDataGrid";
import path from "../../../config/apiUrl";
const ProcedureModal = ({handleCellClick}) => {
  const [procedures, setProcedures] = useState([]);
  const columns = [
    {
      field: "procedureCode",
      headerName: "Procedure Code",
      minWidth: 160,
      headerAlign: "center",
      filterable: true,
      align: "center",
    },
    {
      field: "procedureDescription",
      headerName: "Procedure Description",
      minWidth: 160,
      headerAlign: "center",
      filterable: true,
      align: "center",
    },
    {
      field: "price",
      headerName: "Amount",
      minWidth: 160,
      headerAlign: "center",
      filterable: true,
      align: "center",
    },
    {
        field: "inactive",
        headerName: "Inactive",
        minWidth: 160,
        headerAlign: "center",
        filterable: true,
        align: "center",
      },
  ];
  const fetchPrcodures = async () => {
    try {
      const response = await axios.get(`${path}/ct-procedureCodes`);
      if (response.status === 200) {
        const data = response.data.result.map((val ,i)=>{
            return({
                id:val.procedureCodeId,
                procedureCode:val.procedureCode,
                procedureDescription:val.procedureDescription,
                price:val.price,
                inactive: "N/A"
            })
        });
        console.log(data, "data");
        setProcedures(data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    fetchPrcodures();
  }, []);
  return (
    <Box>
      <Box>

        <CustomDataGrid
            rows={procedures}
            columns={columns}
            handleCellClick={(params) => handleCellClick(params)}
          />
      </Box>
    </Box>
  );
};

export default ProcedureModal;
