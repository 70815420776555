import React, { useEffect } from "react";
import { Box,Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getPracticeAction,
  getPracticeByIdAction,
  updatePracticeAction,
} from "../../../features/actions/practiceAction";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import PracticeInfo from "./PracticeInfo";
import MainHeading from "../../../components/MainHeading";
import { practiceInitVals } from "../../../utils/formikInitValues";
import { practiceSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";
import { toast } from "react-toastify";
import { useState } from "react";


const UpdatePractice = () => {
  const dispatch = useDispatch();
  // const handleCancel = () => {
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/practice");
  //   }
  // };
  const navigate = useNavigate();
  const { id } = useParams();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { practiceById, loading } = useSelector((state) => state.practices);
  console.log(practiceById?.result, "check practice");
  const findPractice = practiceById?.result;

  const formik = useFormik({
    initialValues: findPractice || practiceInitVals,
    validationSchema: practiceSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      try {
        dispatch(
          updatePracticeAction({
            practiceId: findPractice?.practiceId,
            ...values,
          })
        );
        toast.success("Practice update successfully");
      } catch (error) {
        toast.error("Error creating patient:", error);
      }
      action.resetForm();
      navigate("/practice");
    },
  });

  useEffect(() => {
    dispatch(getPracticeByIdAction(id));
  }, [dispatch]);
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/practice");
  };
  return (
    <>
      <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
        <div style={{ padding: "10px", marginLeft: "-30px" }}>
          <MainHeading mainheading={"Update Practice"} />
        </div>

        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >
          <CustomButton
            isBlue={true}
            type="submit"
            formId="edit-practice-form"
            isEdit={true}
          >
            Update
          </CustomButton>
          {/* <CustomButton
            type="submit"
            isCancel={true}
            handleClick={handleCancel}
          >
            Cancel
          </CustomButton> */}
            <CustomButton   type="submit" isCancel={true} handleClick={handleCancel}>
         Cancel
       </CustomButton>
        </Box>

        <form id="edit-practice-form" onSubmit={formik.handleSubmit}>
          <PracticeInfo formik={formik} />
        </form>
      </Box>
      <CustomDialog
    open={openCancelDialog}
    isWarning={true}
    handleClose={() => setOpenCancelDialog(false)}
    handleConfirm={handleConfirmCancel}
    handleCancel={() => setOpenCancelDialog(false)}
    confirmButtonTitle="OK"
    cancelButtonTitle="Cancel"
   >
    <Typography component="h3">Are you sure you want to cancel?</Typography>
   </CustomDialog>
    </>
  );
};

export default UpdatePractice;
