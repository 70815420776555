import { Box, Stack, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import CustomButton from "../../components/CustomButton";
import InsuranceInfo from "./InsuranceInfo";
import { useFormik } from "formik";
import { insuranceInfoValues } from "../../utils/formikInitValues";
import PayerInfo from "./PayerInfo";
import { insuranceSchema } from "../../schemas/patientSchema";

const InsuredModalForm = ({
  tabValue,
  setTabValue,
  formik,
  handleAddInsurance,
  priorityId,
}) => {
  const insuranceFormik = useFormik({
    initialValues: insuranceInfoValues,
    validationSchema: insuranceSchema,
    onSubmit: (values) => {
      console.log("danish");
      let updatePayerDetail = {
        ...values.payerDetailVals,
        insuredFirstName: values.insuredDetail.insuredFirstName,
        insuredLastName: values.insuredDetail.insuredLastName,
      }
      let updatedInsValues = {
        ...insuranceFormik.values,
        insuredDetail: {
          ...insuranceFormik.values.insuredDetail,
          insurancePoliciesDtos: [
            ...insuranceFormik.values.insuredDetail.insurancePoliciesDtos,
             updatePayerDetail
          ],
        },
      };
      handleAddInsurance(updatedInsValues);
      insuranceFormik.resetForm();
    },
  });

  // handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (priorityId) {
      insuranceFormik.setFieldValue(
        "payerDetailVals.payerInfoPriorityId",
        priorityId
      );
    }
  }, [priorityId]);
  return (
    <>
      <Box margin={"15px"}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              marginBottom: "10px",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Tab
              label="Insurance Info"
              style={{ fontSize: "18px", fontWeight: "bold" }}
              className="tabsdesigning"
              value={0}
            />
            <Tab
              label="Payer Info"
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                // borderBottom: payerInfoReq ? "2px solid red" : "none"
              }}
              className="tabsdesigning"
              value={1}
            />
          </Tabs>
          <CustomButton
            type="submit"
            isBlue={true}
            padding="5px 15px"
            // handleClick={handleAddInsurance}
            formId="insuranceForm"
          >
            Add
          </CustomButton>
        </Stack>
      </Box>
      <Box>
        <form
          onSubmit={insuranceFormik.handleSubmit}
          id="insuranceForm"
          className="formContent"
        >
          {tabValue === 0 && (
            <InsuranceInfo insuranceFormik={insuranceFormik} formik={formik} />
          )}
          {tabValue === 1 && <PayerInfo formik={formik} insuranceFormik={insuranceFormik} />}
        </form>
      </Box>
    </>
  );
};

export default InsuredModalForm;
