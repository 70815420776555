import axios from "axios";

export const postData = async (url, data) => {
  try {
    const response = await axios.post(url, data);
    if (response.status === 200) {
      
      return await response.data;
    } 
  } catch (error) {
    throw error;
  }
};

export const getData = async (url) => {
  try {
    const response = await axios.get(url);
    console.log(response.data, "getDataCustomFunc");
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Request failed with status: " + response.status);
    }
  } catch (error) {
    console.error(`Error in getData for URL ${url}:`, error);
    throw error;
  }
};

export const updateData = async (url, data) => {
  try {
    const response = await axios.put(url, data);
    console.log(response, "response6")
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Request failed with status: " + response.status);
    }
  } catch (error) {
    throw error;
  }
};
