import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import "./PatientInfo.css";
import { ErrorMessage, Field, Formik } from "formik";
// import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
// import { useState } from "react";
import FormInfoHeading from "../../components/FormInfoHeading";
import { useDispatch, useSelector } from "react-redux";
import { newPatientAction } from "../../features/actions/createPatientAction";
import { getData } from "../../config/axiosFunctions";
import { useEffect, useState } from "react";
import path from "../../config/apiUrl";
import CustomField from "../../components/CustomField";
import CustomSearchField from "../../components/CustomSearchField";
import CustomButton from "../../components/CustomButton";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomSelectBox2 from "../../components/CustomSelectBox2";
import CustomExpandIcon from "../../components/CustomExpandIcon";
import CustomAccordion from "../../components/CustomAccordion";
import CustomModal from "../../components/CustomModal";
import Loading from "../../components/Loading";
// import { createPatientSchema } from "../../schemas";

const PatientInfo = ({ formik }) => {
  console.log(formik.values, "formdata");
  // console.log(formik.handleDateChange,'handle');
  // const isNonMobile = useMediaQuery("(min-width:600px)");
  const [accountTypeOptions, setAccountTypeOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [maritalOptions, setMaritalOptions] = useState([]);
  const [raceOptions, setRaceOptions] = useState([]);
  const [sexOrientationOptions, setSexOrientationOptions] = useState([]);
  const [employeementOptions, setEmployeementOptions] = useState([]);
  const [referenceOptions, setReferenceOptions] = useState([]);
  const [patienRelationOpt, setPatientRelationOpt] = useState([]);
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [studentStatusOpt, setStudentStatusOpt] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [emergencyStateOptions, setEmergencyStateOptions] = useState([]);
  const [emergencyCityOptions, setEmergencyCityOptions] = useState([]);
  const [residenceOptions, setResidenceOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDefProvider, setOpenDefProvider] = useState(false);

  // Define data fetching URLs
  const dataFetchUrls = {
    accountType: `${path}/ct-accountType`,
    genderTypes: `${path}/ct-genderIdentity`,
    maritalStatus: `${path}/ct-maritalStatus`,
    raceStatus: `${path}/ct-raceStatus`,
    sexualOrientation: `${path}/ct-sexualorientatioStatus`,
    employmentStatus: `${path}/ct-employmentStatus`,
    referralSource: `${path}/ct-referralSource`,
    relationToPatient: `${path}/ct-relationToPatient`,
    ethnicityStatus: `${path}/ct-ethnicityStatus`,
    studentStatus: `${path}/ct-studentStatus`,
    cities: `${path}/city`,
    countries: `${path}/country`,
    states: `${path}/state`,
    residenceTypes: `${path}/ct-residenceType`,
    provider: `${path}/provider`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.accountType, setAccountTypeOptions);
    fetchDataOptions(dataFetchUrls.countries, setCountryOptions);
    fetchDataOptions(dataFetchUrls.states, setEmergencyStateOptions);
    fetchDataOptions(dataFetchUrls.employmentStatus, setEmployeementOptions);
    fetchDataOptions(dataFetchUrls.ethnicityStatus, setEthnicityOptions);
    fetchDataOptions(dataFetchUrls.genderTypes, setGenderOptions);
    fetchDataOptions(dataFetchUrls.maritalStatus, setMaritalOptions);
    fetchDataOptions(dataFetchUrls.raceStatus, setRaceOptions);
    fetchDataOptions(dataFetchUrls.referralSource, setReferenceOptions);
    fetchDataOptions(dataFetchUrls.relationToPatient, setPatientRelationOpt);
    fetchDataOptions(dataFetchUrls.residenceTypes, setResidenceOptions);
    fetchDataOptions(dataFetchUrls.sexualOrientation, setSexOrientationOptions);
    fetchDataOptions(dataFetchUrls.studentStatus, setStudentStatusOpt);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getData(dataFetchUrls.provider);
        console.log(response, "check response in default provider");
        if (
          response.result.length > 0 &&
          formik.values?.defaultProviderId === null
        ) {
          const defaultProvider = response.result[0];
          formik.setValues((prevValues) => ({
            ...prevValues,
            defaultProviderId: defaultProvider.providerId,
            defaultProviderFirstName: `${defaultProvider.providerFirstName}`,
            defaultProviderLastName: `${
              defaultProvider?.providerLastName
                ? defaultProvider?.providerLastName
                : ""
            }`,
            defaultProviderSeqNo: defaultProvider.providerSequenceNo,
            practiceAddress: defaultProvider.practiceAddress,
            practiceName: defaultProvider?.practiceName,
            practiceSequenceNo: defaultProvider?.practiceSequenceNo,
            npiNo: defaultProvider.providerNPINo,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (formik.values?.countryId) {
      fetchDataOptions(
        `${path}/state${
          formik.values?.countryId
            ? `?countryId=${formik.values?.countryId}`
            : ""
        }`,
        setStateOptions
      );
    }
    if (formik.values?.stateId) {
      fetchDataOptions(
        `${path}/city?stateId=${formik.values?.stateId}
        `,
        setCityOptions
      );
    }
    if (formik.values?.emergencyContactStateId) {
      fetchDataOptions(
        `${path}/city?stateId=${formik.values?.emergencyContactStateId}
        `,
        setEmergencyCityOptions
      );
    }
  }, [
    formik.values?.countryId,
    formik.values?.stateId,
    formik.values?.emergencyContactStateId,
  ]);

  return (
    <>
      {/* loading modal */}
      <CustomModal
        open={isLoading}
        // handleClose={() => setOpenDefProvider(false)}
      >
        <Loading />
      </CustomModal>
      <Box display="flex" flexDirection="column">
        {/* general Info */}
        <CustomAccordion defaultExpanded={true} heading={"General Information"}>
          <AccordionDetails>
            {/* account type here */}
            <Box
              display="grid"
              gap="30px"
              margin={"0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.accountTypeId}
                name="accountTypeId"
                dropdownOptions={accountTypeOptions?.map((opt) => ({
                  value: opt.accountType,
                  id: opt.accountTypeId,
                }))}
                label="Account Type"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                showDefaultOpt={true}
              />

              <CustomField
                type="text"
                label="Reference #"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                value={formik?.values?.referenceNo}
                name="referenceNo"
              />
            </Box>
            <Box
              display="grid"
              gap="30px"
              marginTop={"20px"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              <CustomField
                type="text"
                name="firstName"
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.firstName}
                label="First Name"
                pattern={/^[A-Za-z]+$/}
              />
              <CustomField
                type="text"
                name="lastName"
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.lastName}
                label="Last Name"
                pattern={/^[A-Za-z]+$/}
              />
              <CustomField
                type="text"
                name="mi"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.mi}
                label="MI"
              />
              <CustomField
                type="text"
                name="suffix"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.suffix}
                label="Suffix"
                pattern={/^[A-Za-z]+$/}
              />
            </Box>
            <Box
              display="grid"
              gap="30px"
              margin={"20px 0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              <CustomSelectBox2
                value={formik.values?.genderIdentityStatusId}
                name="genderIdentityStatusId"
                dropdownOptions={genderOptions?.map((opt) => ({
                  value: opt.genderIdentityName,
                  id: opt.genderIdentityId,
                }))}
                label="Gender"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
              />
              <div>
                <CustomDatePicker
                  formik={formik}
                  dateLabel="Date Of Birth"
                  dateValue={formik.values?.dateOfBirth}
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                  name="dateOfBirth"
                  // isRequired={true}
                />
                {/* <CustomDatePicker
  dateLabel="Date Of Birth"
  name="dateOfBirth"
  dateValue={formik.values?.dateOfBirth}
  handleDateChange={(date) => {
    formik.handleChange('dateOfBirth')(date); 
    console.log('changetobe', date);
  }}
  handleDateBlur={formik.handleBlur}
/> */}
              </div>
              <div>
                <CustomDatePicker
                  formik={formik}
                  dateLabel="Date Of Death"
                  name="dateOfDeath"
                  dateValue={formik.values?.dateOfDeath}
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />
                {/* <CustomDatePicker
  dateLabel="Date Of Death"
  name="dateOfDeath"
  dateValue={formik.values?.dateOfDeath}
  handleDateChange={(date) => {
    formik.handleChange('dateOfDeath')(date); 
    console.log('changeto', date);
  }}
  handleDateBlur={formik.handleBlur}
/> */}
              </div>
              <CustomField
                type="number"
                name="ssn"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.ssn}
                label="SSN"
              />
            </Box>

            <hr />
            <Box
              display="grid"
              gap="30px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              <CustomField
                type="text"
                name="email"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.email}
                label="Email"
              />
              <CustomField
                type="text"
                name="drivingLicense"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.drivingLicense}
                label="Driving License"
              />
              <CustomSelectBox2
                formik={formik}
                name="sexualOrientationId"
                value={formik.values?.sexualOrientationId}
                dropdownOptions={sexOrientationOptions?.map((opt) => ({
                  value: opt.sexualOrientationName,
                  id: opt.sexualOrientationId,
                }))}
                label="Sex Orientation"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />

              {/* marital status */}
              <CustomSelectBox2
                formik={formik}
                name="maritalStatusId"
                value={formik.values?.maritalStatusId}
                dropdownOptions={maritalOptions?.map((opt) => ({
                  value: opt.maritalStatusName,
                  id: opt.maritalStatusId,
                }))}
                label="Marital Status"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                showDefaultOpt={true}
              />

              {/* Race Status */}
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.raceStatusId}
                name="raceStatusId"
                dropdownOptions={raceOptions?.map((opt) => ({
                  value: opt.raceStatusName,
                  id: opt.raceStatusId,
                }))}
                label="Race Status"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
              {/* employeement status */}
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.employmentStatusId}
                name="employmentStatusId"
                dropdownOptions={employeementOptions?.map((opt) => ({
                  value: opt.employmentStatusName,
                  id: opt.employmentStatusId,
                }))}
                label="Employment Status"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                showDefaultOpt={true}
              />

              {/* referrel source*/}
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.referralSourceId}
                name="referralSourceId"
                dropdownOptions={referenceOptions?.map((opt) => ({
                  value: opt.referralSourceName,
                  id: opt.referralSourceId,
                }))}
                label="Referral Source"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />

              {/* Ethnicity Status*/}
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.ethnicityId}
                name="ethnicityId"
                dropdownOptions={ethnicityOptions?.map((opt) => ({
                  value: opt.ethnicityName,
                  id: opt.ethnicityId,
                }))}
                label="Ethnicity Status"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
              {/* student Status*/}
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.studentStatusId}
                name="studentStatusId"
                dropdownOptions={studentStatusOpt?.map((opt) => ({
                  value: opt.studentStatusName,
                  id: opt.studentStatusId,
                }))}
                label="Student Status"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                showDefaultOpt={true}
              />

              <CustomField
                type="number"
                name="cellPhone"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.cellPhone}
                label="Cell Number"
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
              />
              {/* home phone number */}
              <CustomField
                type="number"
                name="homePhone"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.homePhone}
                label="Home Number"
              />
              <CustomField
                type="number"
                name="workPhone"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.workPhone}
                label="Work Number"
              />
              <CustomField
                type="number"
                name="ext"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.ext}
                label="Ext"
              />
            </Box>
          </AccordionDetails>
        </CustomAccordion>

        {/* contact details */}
        {/* <CustomAccordion heading="Contact Details">
          <AccordionDetails>
            <Box
              display="grid"
              gap="30px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              <CustomField
                type="number"
                name="cellPhone"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.cellPhone}
                label="Phone Number"
              />
              <CustomField
                type="number"
                name="homePhone"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.homePhone}
                label="Home Number"
              />
              <CustomField
                type="number"
                name="workPhone"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.workPhone}
                label="Work Number"
              />
              <CustomField
                type="number"
                name="ext"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                value={formik.values?.ext}
                label="Ext"
              />
            </Box>
          </AccordionDetails>
        </CustomAccordion> */}

        {/* Birth Details */}
        {/* <CustomAccordion defaultExpanded={false} heading="Birth Details">
          <AccordionDetails>
            <Box
              display="grid"
              gap="30px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              <div>
                <CustomDatePicker
formik={formik}
                  dateLabel="Date Of Birth"
                  dateValue={formik.values?.dateOfBirth}
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                  name="dateOfBirth"
                />
              </div>
              <div>
                <CustomDatePicker
formik={formik}
                  dateLabel="Date Of Death"
                  name="dateOfDeath"
                  dateValue={formik.values?.dateOfDeath}
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />
              </div>
            </Box>
          </AccordionDetails>
        </CustomAccordion> */}

        {/* addres details */}

        <CustomAccordion
          defaultExpanded={false}
          heading="Address Details"
          hasErrors={
            formik.submitCount > 0 &&
            !!formik.errors.address &&
            !!formik.errors.cityId &&
            !!formik.errors.stateId &&
            !!formik.errors.countryId &&
            !!formik.errors.zipCode
          }
        >
          <AccordionDetails>
            <Box
              display="grid"
              gap="30px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              {/* street line address */}
              <CustomField
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                type="text"
                value={formik.values?.address}
                name="address"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                label="Street Line Address"
              />
              {/* zip code */}
              <CustomField
                isRequired={true}
                error={formik.errors}
                touched={formik.touched}
                type="text"
                value={formik.values?.zipCode}
                name="zipCode"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                label="Zipcode"
              />
              {/* country types */}
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.countryId}
                name="countryId"
                dropdownOptions={countryOptions?.map((opt) => ({
                  value: opt.countryName,
                  id: opt.countryId,
                }))}
                label="Country"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
              {/* state options */}
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.stateId}
                disable={!formik.values?.countryId}
                name="stateId"
                dropdownOptions={stateOptions?.map((opt) => ({
                  value: opt.stateName,
                  id: opt.stateId,
                }))}
                label="State"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
              {/* city types */}
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.cityId}
                disable={!formik.values?.stateId}
                name="cityId"
                dropdownOptions={cityOptions?.map((opt) => ({
                  value: opt.cityName,
                  id: opt.cityId,
                }))}
                label="City"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
              {/*  residence Type*/}
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.residenceTypeId}
                name="residenceTypeId"
                dropdownOptions={residenceOptions?.map((opt) => ({
                  value: opt.residenceTypeName,
                  id: opt.residenceTypeId,
                }))}
                label="Residence Type"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                showDefaultOpt={true}
              />
            </Box>
          </AccordionDetails>
        </CustomAccordion>

        {/* emergency contact */}
        <CustomAccordion defaultExpanded={false} heading="Emergency Contact">
          <AccordionDetails>
            <Box
              display="grid"
              gap="30px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                },
              }}
            >
              {/* relation to patient */}
              <CustomSelectBox2
                formik={formik}
                value={formik.values?.relationShipToPatientId}
                name="relationShipToPatientId"
                dropdownOptions={patienRelationOpt?.map((opt) => ({
                  value: opt.relationShipToPatientName,
                  id: opt.relationShipToPatientId,
                }))}
                label="Relation to Patient"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                showDefaultOpt={true}
              />
              {/* first name */}
              <CustomField
                type="text"
                value={formik.values?.emergencyContactFirstName}
                name="emergencyContactFirstName"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                label="First Name"
              />
              {/* last name */}
              <CustomField
                type="text"
                value={formik.values?.emergencyContactLastName}
                name="emergencyContactLastName"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                label="Last Name"
              />
              {/* address */}
              <CustomField
                type="text"
                value={formik.values?.emergencyContactAddress}
                name="emergencyContactAddress"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                label="Address"
              />
              {/* zipcode */}
              <CustomField
                type="text"
                value={formik.values?.emergencyContactZipCode}
                name="emergencyContactZipCode"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isOutlined={false}
                label="Zip Code"
              />
              {/* state */}
              <CustomSelectBox2
                label="State"
                value={formik.values?.emergencyContactStateId}
                name="emergencyContactStateId"
                formik={formik}
                handleChange={() => {
                  formik.setFieldValue("emergencyContactCityId", null);
                }}
                handleBlur={formik.handleBlur}
                dropdownOptions={emergencyStateOptions.map((option) => ({
                  value: option.stateName,
                  id: option.stateId,
                }))}
              />
              {/* city */}
              <CustomSelectBox2
                label="City"
                value={formik.values?.emergencyContactCityId}
                name="emergencyContactCityId"
                formik={formik}
                handleChange={formik.handleChange}
                disable={!formik.values?.emergencyContactStateId}
                handleBlur={formik.handleBlur}
                dropdownOptions={emergencyCityOptions.map((option) => ({
                  value: option.cityName,
                  id: option.cityId,
                }))}
              />
            </Box>
          </AccordionDetails>
        </CustomAccordion>
      </Box>
    </>
  );
};

export default PatientInfo;
