import { useTheme } from "@mui/material";
import styled from "styled-components";
import { MultiSelect } from "react-multi-select-component";

const CustomMultiSelect = ({ options, value, handleChange }) => {
  const theme = useTheme();

  const CustomDiv = styled.div`
    .multiselect .dropdown-container {
      background-color: ${theme.palette.customFieldsBackground.primary};
      color: ${theme.palette.fieldsText.primary};
    }
    .multiselect .dropdown-content .panel-content {
      background-color: ${theme.palette.customFieldsBackground.primary};
      color: ${theme.palette.fieldsText.primary};
    }
    .multiselect .dropdown-content .panel-content .select-panel .search input {
      background-color: ${theme.palette.customFieldsBackground.primary};
      color: ${theme.palette.fieldsText.primary};
    }
    .multiselect
      .dropdown-content
      .panel-content
      .select-panel
      .options
      .select-item {
      background-color: ${theme.palette.customFieldsBackground.primary};
      color: ${theme.palette.fieldsText.primary};
    }
  `;

  return (
    <CustomDiv>
      <MultiSelect
        className="multiselect"
        options={options}
        value={value}
        onChange={(e) => handleChange(e)}
      />
    </CustomDiv>
  );
};
export default CustomMultiSelect;
