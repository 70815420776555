import React from "react";
import { Box,Typography } from "@mui/material";
import { useState } from "react";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import UserForm from "./UserForm";
import { useFormik } from "formik";
import { register } from "../../../utils/formikInitValues";
import { newUserAction } from "../../../features/actions/userAction";
import { useDispatch } from "react-redux";
import { userSechema } from "../../../schemas/userSchema";
import CustomDialog from "../../../components/CustomDialog";
const CreateUser = () => {
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  // const handleCancel = () => {
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/users");
  //   }
  // };
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: register,
    validationSchema: userSechema,
    onSubmit: (val) => {
      if (formik.values.password === formik.values.confirmPassword) {
        dispatch(newUserAction(formik.values));
        navigate("/users");
      } else {
        alert("Passowrd Should be same");
      }
    },
  });
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/users");
  };
  return (
    <>
    <Box margin="20px">
      
        <MainHeading mainheading={"Create Users"} />
   
      <form onSubmit={formik.handleSubmit}>
        <Box
          marginTop="30px"
          marginBottom="10px"
          width={{ xs: "100%", sm: "65%", md: "100%" }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "row", md: "row", sm: "column" },
          }}
        >



          <Box    display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}>
            <CustomButton isBlue={true} isAdd={true} type="submit">
              Save
            </CustomButton>
            <CustomButton isBlue={false} isCancel={true} handleClick={handleCancel}>
           Cancel
            </CustomButton>
          </Box>
        </Box>
        <UserForm isCreate={true} formik={formik} />
      </form>
    </Box>
     <CustomDialog
     open={openCancelDialog}
     isWarning={true}
     handleClose={() => setOpenCancelDialog(false)}
     handleConfirm={handleConfirmCancel}
     handleCancel={() => setOpenCancelDialog(false)}
     confirmButtonTitle="OK"
     cancelButtonTitle="Cancel"
    >
     <Typography component="h3">Are you sure you want to cancel?</Typography>
    </CustomDialog>
  </>
  );
};

export default CreateUser;
