import { createSlice } from "@reduxjs/toolkit";
import { searchStatementPrintAction } from "../actions/StatementPrintAction";

const initialState = {
    statementSearchedData:{},
    loading: {
        searchLoading:false
    },
    error: null,
  };
  const statementBatchSlice = createSlice({
    name: "statementBatchPrint",
    initialState: initialState,
    extraReducers: {
      [searchStatementPrintAction.pending]: (state) => {
        state.loading.searchLoading = true;
      },
      [searchStatementPrintAction.fulfilled]: (state, action) => {
        state.loading.searchLoading = false;
        state.statementSearchedData = action.payload;
      },
      [searchStatementPrintAction.rejected]: (state, action) => {
        state.loading.searchLoading = false;
        state.error = action.payload;
      }}})

      export default statementBatchSlice.reducer



    