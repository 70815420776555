import * as yup from "yup";

export const userSechema = yup.object().shape({
  username: yup.string().trim().nullable().required("name is required"),
  email: yup.string().trim().nullable().required("email is required"),
  confirmPassword: yup
    .string()
    .trim()
    .nullable()
    .oneOf([yup.ref('password')], 'Passwords must match') // Check that confirmPassword matches newPassword
    // .matches(
    //   /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    //   "One capital , special , number required and minmun len"
    // )
    .required("Confirm Password is required"),
  password: yup
    .string()
    .trim()
    .nullable()
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      "One capital , special , number required"
    )
    .required("Password is required"),
  roleName: yup.string().nullable().required("User role is required"),
});

export const updateUserSechema = yup.object().shape({
  username: yup.string().trim().nullable().required("name is required"),
  email: yup.string().trim().nullable().required("email is required"),
  roleName: yup.string().nullable().required("User role is required"),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .trim()
    .nullable()
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      "One capital , special , number required and minmun len"
    )
    .required("Old Password is required"),
  newPassword: yup
    .string()
    .trim()
    .nullable()
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      "One capital , special , number required"
    )
    .required("New Password is required"),

  confirmPassword: yup
    .string()
    .trim()
    .nullable()
    .oneOf([yup.ref('newPassword')], 'Passwords must match') 
    // .matches(
    //   /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    //   "One capital , special , number required"
    // )
    .required("Confirm Password is required"),
});
