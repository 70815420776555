import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import {
  getClaimAction,
  getClaimBySearchTermAction,
} from "../../../features/actions/claimAction";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomPreview from "../../../layout/CustomPreview";
import { claimCols } from "../../../utils/gridCols";

const Claim = () => {
  const navigate = useNavigate();
  const { getClaims, loading } = useSelector((state) => state.claim);
  // rows
  const rows =
    getClaims && getClaims.result?.claims.length > 0
      ? getClaims.result?.claims.map((el) => ({
          id: el.claimNumber,
          fromDate: el?.getClaimChargesDto?.fromDate,
          ...el,
        }))
      : [];

  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={claimCols}
        paginationDetail={getClaims}
        searchLabel="Search by Claim Id,Patient Name,Dos"
        moduleHeading="All Claims"
        loading={loading}
        handleCell={(params) =>
          navigate(`/claims/update/${params.claimNumber}`)
        }
        handleGetAll={getClaimAction}
        handleBySearch={getClaimBySearchTermAction}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <CustomButton
            type="button"
            handleClick={() => navigate("/claims/new")}
            isBlue={true}
            padding="5px 30px"
            margin={"10px 10px 10px"}
            isAdd={true}
          >
            Add Claim
          </CustomButton>
        </Stack>
      </CustomPreview>
    </Box>
  );
};

export default Claim;
