import React from 'react';
import {Typography,useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const AdminAnnouncement = ({data}) => {
  // console.log(data, "data")
  const theme=useTheme();
  return (
    <>
          <Typography sx={{color:theme.palette.globalText.primary,}}fontWeight={'600'} className='announcementhead' variant='h3'>Announcement ( Admin )</Typography>
      <Card className='announcesCard' sx={{ width: '100%',background:theme.palette.announcementCard.primary,}}>
        <CardContent>
          <Typography sx={{color:theme.palette.announcementText.primary}} variant="h5"  className='adminAnnounces'>
          Please be assured that we are following strict protocols to sanitize and disinfect all areas regularly. We kindly request your cooperation in adhering to scheduled appointment times and notifying us in advance if you are experiencing any symptoms of illness.
          </Typography>
        </CardContent>
      </Card></>
  )
}

export default AdminAnnouncement