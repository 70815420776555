import { createSlice } from "@reduxjs/toolkit";
import { getModifiers } from "../actions/modifierAction";

const modifierSlice = createSlice({
  name: "modifier",
  initialState: {
    modifierData: [],
    loading: true,
    error: null,
  },
  extraReducers: {
    [getModifiers.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    [getModifiers.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        modifierData: action.payload,
      };
    },
    [getModifiers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    },
  },
});

export default modifierSlice.reducer;
