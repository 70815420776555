import React from "react";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import {
  getInventoryCodeAction,
  getInventoryCodeBySearchTermAction,
} from "../../../features/actions/inventoryCode";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";

import CustomPreview from "./../../../layout/CustomPreview";
import { inventoryCodeCols } from "../../../utils/gridCols";

const InventoryCode = () => {
  const navigate = useNavigate();
  const { loading, getInventoryCodeData } = useSelector(
    (state) => state.inventoryCode
  );

  const rows =
    getInventoryCodeData && getInventoryCodeData?.result
      ? getInventoryCodeData?.result?.map((val, i) => ({
          ...val,
          id: val?.inventoryCodeId,
        }))
      : [];

  return (

    <Box>
      <CustomPreview
        rows={rows}
        columns={inventoryCodeCols}
        loading={loading}
        handleBySearch={getInventoryCodeBySearchTermAction}
        handleGetAll={getInventoryCodeAction}
        handleCell={(params) => navigate(`/inventorycode/update/${params.id}`)}
        searchLabel="Search by Code, Description"
        moduleHeading="All Inventory Codes"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => navigate("/inventorycode/new")}
          >
            Add New
          </CustomButton>
        </Stack>
      </CustomPreview>
    </Box>
  );
};

export default InventoryCode;
