import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData,postData,updateData  } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";



export const newIcdProcedureAction = createAsyncThunk(
    "create icd procedure",
    async (data) => {
      try {
        const response = await postData(`${path}/ICDProcedureCode`, data);
        if (response) {
          return response;
        } 
      } catch (error) {
        console.log(error, "check Error");
        throw new Error(error);
      }
    }
  );


  export const getICDProcedureCodeAction = createAsyncThunk(
    "get icd procedure",
    async () => {
      try {
        const response = await getData(`${path}/ICDProcedureCode`);
        if (response) {
          return response;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  );

  export const getICDProcedureCodesBySearchTermAction = createAsyncThunk(
    "get icd procedure",
    async (val) => {
      try {
        const response = await getData(
          `${path}/ICDProcedureCode/SearchICDProcedure?searchValue=${val}`
        );
        console.log(response, "action icd procedure codes by search term");
        if (response) {
          return response;
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  );
  
  export const updateICDProcedureAction = createAsyncThunk(
    "updateICDProcedure",
    async (data) => {
      try {
        const response = await updateData(
          `${path}/ICDProcedureCode/${data.icdProcedureCodesId}`,
          data
        );
        console.log(response, "update prac action response");
        return response;
      } catch (error) {
        throw error;
      }
    }
  );