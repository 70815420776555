import { Box,Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import RevenueInfo from "./RevenueInfo";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getRevenueAction } from "../../../features/actions/revenueAction";
import { useFormik } from "formik";
import { revenueInitVals } from "../../../utils/formikInitValues";
import { updateRevenueAction } from "./../../../features/actions/revenueAction";
import { revenueSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";

const UpdateRevenue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { getRevenueData, loading } = useSelector((state) => state.revenue);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  console.log(getRevenueData, "getRevenueData");
  const findRevenue = getRevenueData.result?.find(
    (revenue) => revenue.revenueCodeId === Number(id)
  );
  console.log(findRevenue, "findRevenue");
  const formik = useFormik({
    initialValues: findRevenue || revenueInitVals,
    validationSchema : revenueSchema,
    onSubmit: (values) => {
      console.log(values, "checkUpdatedVals");
      try {
        dispatch(
          updateRevenueAction({
            revenueCodeId: findRevenue?.revenueCodeId,
            ...values,
          })
        );
      } catch (err) {
        console.log(err, "Error in updating revenue:");
        throw err;
      }
      navigate("/revenues");
    },
  });
  useEffect(() => {
    try {
      dispatch(getRevenueAction());
    } catch (error) {
      throw error;
    }
  }, [dispatch]);

  // handle cancel
  // const handleCancel = () => {
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/revenues");
  //   }
  // };
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/revenues");
  };
  return (
    <>
      <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
       
          <MainHeading mainheading={"Update Revenue"}/>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >
          <CustomButton
            formId="revenueForm"
            type="submit"
            isBlue={true}
            isEdit={true}
          >
            {loading ? "Updating..." : "Update"}
          </CustomButton>
          {/* <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton> */}
           <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>
        </Box>
        <form id="revenueForm" onSubmit={formik.handleSubmit}>
          <RevenueInfo formik={formik} />
        </form>
      </Box>
      <CustomDialog
     open={openCancelDialog}
     isWarning={true}
     handleClose={() => setOpenCancelDialog(false)}
     handleConfirm={handleConfirmCancel}
     handleCancel={() => setOpenCancelDialog(false)}
     confirmButtonTitle="OK"
     cancelButtonTitle="Cancel"
    >
     <Typography component="h3">Are you sure you want to cancel?</Typography>
    </CustomDialog>
    </>
  );
};

export default UpdateRevenue;
