import React from "react";
import { Box, Stack, Typography, useTheme,Grid} from "@mui/material";
import RCMLogo from "../../assets/rcmLogo.png";
import Toggle from "../../components/Toggle";
import RCMDark from "../../assets/rcmdark.png";

const Topbar = ({ handleThemeToggle, isLoginPage }) => {
  const theme = useTheme();
  const logo = theme.palette.mode === "dark" ? RCMDark : RCMLogo;
  const title = theme.palette.mode === "dark" ? "Light Mode" : "Dark Mode";

  const handleClick = () => {
    handleThemeToggle();
  };

  return (
  <Box
  sx={{
    height: "68px",
    background: theme.palette.topBarBackground.primary,
    boxShadow: "7px 0px 19px 0px rgba(0, 0, 0, 0.15)",
    display: "flex",
    position: "fixed",
    top: 0,
    zIndex: 3333,
    width: "100%",
    alignItems: "center",
    padding: "0 20px",
  }}
>
  <Grid container spacing={2} >
    <Grid item lg={6}>
      <img style={{ width: "210px", height: "47px" }} src={logo} alt="logo" />
    </Grid>
  </Grid>


  <Grid container spacing={2} justifyContent="flex-end" >

  <Grid item>
    <Typography variant="h6">{title}</Typography>
  </Grid>
    <Grid item>
  
      {!isLoginPage && <Toggle handleChange={handleClick} />}
    </Grid>
  
  </Grid>

</Box>

  );
};

export default Topbar;
