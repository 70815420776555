import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const  getAllRenderingProviderAction = createAsyncThunk("getRenderingProvider" , async()=>{
    try {
        const response = await getData(`${path}/renderingProvider`);

    if (response) {
      return response;
    } 
    } catch (error) {
        console.log(error, "action error");
        throw new Error(error);
    }
})