import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getFacilityAction = createAsyncThunk("getFacility", async (page) => {
  try {
    const response = await getData(`${path}/facility?page=${page}&pageSize=4`);

    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error, "action error");
    throw new Error(error);
  }
});

export const newFacilityAction = createAsyncThunk(
  "createFacility",
  async (data) => {
    try {
      const response = await postData(`${path}/facility`, data);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "check Error");
      throw new Error(error);
    }
  }
);

// search
export const getFacilityBySearchTermAction = createAsyncThunk("getFacility", async ({searchTerm, page}) => {
  console.log(searchTerm, "searchTerm")
  try {
    const response = await getData(
      // `${path}/facility/SearchFacility?searchValue=${val}`
     
      `${path}/facility/searchFacility?searchValue=${searchTerm}&page=${page}&pageSize=4`

    );
    console.log(response, "action Facility by search term");
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});


export const updateFacilityAction = createAsyncThunk(
  "updateFacility",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/facility/${data.facilityId}`,
        data
      );
      console.log(response, "update prac action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);
