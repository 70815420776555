import { FormControl, Grid, Stack,Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomField from "../../../components/CustomField";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { chargePanelProcedureValues } from "../../../utils/formikInitValues";
import TextArea from "../../../components/TextArea";
import CustomButton from "../../../components/CustomButton";
import CustomDataGrid from "../../../components/CustomDataGrid";
import { chargePanelProcedureCols } from "../../../utils/gridCols";
import CustomModal from "../../../components/CustomModal";
import AddPanelProcedure from "./AddPanelProcedure";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ChargePanelInfo = ({ formik}) => {
  const [claimTypeOpt, setClaimTypeOpt] = useState([]);
  const [openPanelProcedure, setOpenPanelProcedure] = useState(false);

  const columns = [
      {
        field: "procedureCode",
        headerName: "Code",
        minWidth: 100,
        flex: 2,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "posCode",
        headerName: "POS",
        minWidth: 100,
        flex: 1,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "tosCode",
        headerName: "TOS",
        minWidth: 100,
        flex: 1,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "modifierOption",
        headerName: "Modifier Options",
        minWidth: 100,
        flex: 3,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "mod_Code1",
        headerName: "Modifier 1",
        minWidth: 100,
        flex: 2,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "mod_Code2",
        headerName: "Modifier 2",
        minWidth: 100,
        flex: 2,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "mod_Code3",
        headerName: "Modifier 3",
        minWidth: 100,
        flex: 2,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "mod_Code4",
        headerName: "Modifier 4",
        minWidth: 100,
        flex: 2,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "priceOption",
        headerName: "Price Option",
        minWidth: 100,
        flex: 3,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "unitPrice",
        headerName: "Price",
        minWidth: 100,
        flex: 2,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "units",
        headerName: "Units",
        minWidth: 100,
        flex: 2,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "total",
        headerName: "Total",
        minWidth: 100,
        flex: 2,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
      },
      {
        field: "",
        headerName: "Action",
        minWidth: 100,
        flex: 2,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
        renderCell: (params) => (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DeleteIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteProcedure(params)}
              fontSize="medium"
            />
           
          </Box>
        ),
       
      },
    ];

    const handleDeleteProcedure = (e) => {
      let chargePanelCodeDtos = formik.values?.chargePanelCodeDtos
      let index = chargePanelCodeDtos.findIndex((el) => el.procedureCodeId === e.row.id);
      chargePanelCodeDtos.splice(index, 1);
      formik.setFieldValue("chargePanelCodeDtos",  chargePanelCodeDtos);
    };

    // const handleEditProcedure = (e) => {
    //   let chargePanelCodeDtos= formik.values?.chargePanelCodeDtos;
    //   let index = chargePanelCodeDtos.findIndex(
    //     (el) => el.procedureCodeId === e.row.id
    //   );
    //   if (index !== -1) {
    //     chargePanelProcedureValues.setValues((prevVals) => ({
    //       ...prevVals,
    //       ...chargePanelCodeDtos[index],
    //     }));
    //    setOpenPanelProcedure(true);
    //   }
    // };

    // const handleDeleteProcedure = (e) => {
    //   const chargePanelCodeDtos = formik.values?.chargePanelCodeDtos;
    //   const updatedList = chargePanelCodeDtos.filter(el => el.procedureCodeId !== e.row.id);
    //   formik.setFieldValue("chargePanelCodeDtos", updatedList);
    // };
    
  // rows for data grid
  const rows =
    formik.values.chargePanelCodeDtos &&
    formik.values.chargePanelCodeDtos.length > 0
      ? formik.values.chargePanelCodeDtos.map((el, index) => ({
          id: el.procedureCodeId,
          ...el,
        }))
      : [];

  const dataFetchUrls = {
    claimType: `${path}/ct-claimTypes`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.claimType, setClaimTypeOpt);
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomField
            label="Title"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.chargePanelTitle}
            name="chargePanelTitle"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <CustomField
            label="Code"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.chargePanelCode}
            name="chargePanelCode"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomSelectBox2
            dropdownOptions={claimTypeOpt?.map((option) => ({
              value: option.claimType,
              id: option.claimTypeId,
            }))}
            label="Type"
            value={formik.values.claimTypeId}
            name="claimTypeId"
            formik={formik}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            showDefaultOpt={true}
          />
        </Grid>

        <Grid item xs={12}>
          <TextArea
            name="chargePanelDescription"
            placeholder="Description"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.chargePanelDescription}
            minRows={4}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack gap={2}>
            <FormControl
              sx={{
                width: "200px",
              }}
            >
              <CustomButton
                isBlue={true}
                isAdd={true}
                handleClick={() => setOpenPanelProcedure(true)}
              >
                Add Procedure
              </CustomButton>
            </FormControl>

            {/* <CustomDataGrid columns={chargePanelProcedureCols} rows={rows} /> */}
            <CustomDataGrid columns={columns} rows={rows} />
          </Stack>
        </Grid>
      </Grid>

      {/* modal */}
      <CustomModal
        open={openPanelProcedure}
        handleClose={() => setOpenPanelProcedure(false)}
        width="60%"
        heading="Add Procedure Info"
        height="60%"
      >
        <AddPanelProcedure
          formik={formik}
          handleClose={() => setOpenPanelProcedure(false)}
        />
      </CustomModal>
    </>
  );
};

export default ChargePanelInfo;
