import React from "react";
import { Box, Stack } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { getDiagnosisAction } from "../../../features/actions/diagnosisAction";
import { getDiagnosisBySearchTermAction } from "../../../features/actions/diagnosisAction";
import CustomPreview from "./../../../layout/CustomPreview";
import { diagnosisCodesCols } from "../../../utils/gridCols";
import { useSelector } from "react-redux";

const Diagnosis = () => {
  const navigate = useNavigate();
  const { getDiagnosisData, loading } = useSelector((state) => state.diagnosis);

  const rows =
    getDiagnosisData && getDiagnosisData?.result
      ? getDiagnosisData?.result.map((v, i) => ({
          id: v?.diagnosisCodeId,
          diagnosisCode: v?.diagnosisCode,
          diagnosisDescription: v?.diagnosisDescription,
          isActive: v?.isActive,
          effectiveDate: v?.effectiveDate,
          terminationDate: v?.terminationDate,
        }))
      : [];

  return (
    <>
      <Box>
        <CustomPreview
          columns={diagnosisCodesCols}
          rows={rows}
          loading={loading}
          handleGetAll={getDiagnosisAction}
          searchLabel="Search by Code or Description"
          moduleHeading="All Diagnosis"
          handleBySearch={getDiagnosisBySearchTermAction}
          handleCell={(params) => navigate(`/diagnosis/update/${params?.id}`)}
        >
          <Stack alignItems="center" direction="row" gap={2}>
            <CustomButton
              isBlue={true}
              isAdd={true}
              handleClick={() => navigate("/diagnosis/new")}
            >
              New Diagnosis
            </CustomButton>
          </Stack>
        </CustomPreview>
      </Box>
    </>
  );
};

export default Diagnosis;
