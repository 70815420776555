import React from 'react'
import { Box,Typography,Stack,Radio,FormControl,FormControlLabel,Grid,useTheme } from '@mui/material'
import CustomField from '../../../../components/CustomField';
import { useState } from 'react';
import CheckBox from '../../../../components/CheckBox';

const ContractBasedPrices = () => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };
    const theme=useTheme()
    const contarctBased=[
        {id:'1',ctOption:'Use The Maximum contract Price For Each One'},
     {id:'2',ctOption:'Do not adjust the prices of the new fee schedule '},
    ]
    const adjustPrices=[
      {id:'1',ctOption:'Do not adjust the prices of the new fee schedule '},
   {id:'2',ctOption:'Do not adjust the prices of the new fee schedule '},
   {id:'3',ctOption:'Adjust Prices to'}
  ]
  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
        <FormControl fullWidth sx={{ padding: "10px" }}>
            <Stack flexDirection="column" alignItems="left" marginLeft="20px">
            {contarctBased.map((value) => (
            <FormControlLabel
              key={value.id}
              label={value.ctOption}
              control={
                <Radio
                value={value.id}
                checked={selectedOption === value.id}
                onChange={() => handleRadioChange(value.id)}
                name="radio-buttons"
                />
                
              }
            />
          ))}
        </Stack>
            </FormControl>
        </Grid>
      <Grid item md={12} sm={12} xs={12}>
      <Stack alignItems="left" margin="2px 20px">
      {selectedOption === '1' && (
              <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px", margin: '15px' }}>
              <Box
                sx={{
                  background: theme.palette.revenueStatement.primary,
                  height: "33px",
                }}
              >
                <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
                  Adjust Prices
                </Typography>
              </Box>
  
              <FormControl fullWidth sx={{ padding: "10px" }}>
                <Stack flexDirection="column" alignItems="left" marginLeft="20px">
                  {adjustPrices.map((value) => (
                    <Stack key={value.id} direction="row" alignItems="center" spacing={2}>
                      <FormControlLabel
                        label={value.ctOption}
                        control={
                          <Radio
                            value={value.id}
                            checked={selectedOption === value.id}
                            onChange={() => handleRadioChange(value.id)}
                            name="radio-buttons"
                          />
                        }
                      />
                      {value.id === '1' && (
                    <CustomField/>
                      )}
                      {value.id === '2' && (
                         <CustomField/>
                      )}
                       {value.id === '3' && (
                          <>
                         <CustomField/>
                         </>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </FormControl>
              <FormControl fullWidth>
                <CheckBox
                  label="Print this code on superbills"
                />
              </FormControl>
            </Box>
                    )}
                    {selectedOption  === '2' && (
                       <CustomField />
                    )}
                    </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default ContractBasedPrices
