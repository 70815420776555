import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomButton from "../../../components/CustomButton";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";

const TypeOfBill = ({ formik, setOpenTypeOfBillModal }) => {
  const [frequency, setFrequency] = useState([]);
  const [typeOfCare, setTypeOfCare] = useState([]);
  const [typeOfFacility, setTypeOfFacility] = useState([]);

  const dataFetchUrls = {
    frequencyType: `${path}/ct-frequencyType`,
    facilityType: `${path}/ct-facilityType`,
    careType: `${path}/ct-careType`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleBill = () => {
    let facility = formik.values?.facilityTypeId
      ? formik.values?.facilityTypeId
      : 1;
    let careType = formik.values?.careTypeId ? formik.values?.careTypeId : 1;
    let frequencyType = formik.values?.frequencyTypeId
      ? formik.values?.frequencyTypeId
      : 2;
    formik.setFieldValue("facilityTypeId", facility);
    formik.setFieldValue("careTypeId", careType);
    formik.setFieldValue("frequencyTypeId", frequencyType);
    formik.setFieldValue(
      "typeOfBill",
      `${facility}${careType}${frequencyType}`
    );
    setOpenTypeOfBillModal(false);
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.frequencyType, setFrequency);
    fetchDataOptions(dataFetchUrls.facilityType, setTypeOfFacility);
    fetchDataOptions(dataFetchUrls.careType, setTypeOfCare);
  }, []);
  // useEffect(() => {
  //       if (formik.values.claimTypeId === 1) {
  //         formik.setFieldValue("facilityTypeId", null);
  //         formik.setFieldValue("careTypeId", null);
  //         formik.setFieldValue("frequencyTypeId", null);
  //         formik.setFieldValue("typeOfBill", null);
  //       }
  //     }, [formik.values.claimTypeId]);
  return (
    <Box sx={{ height: "450px", width: "100%" }} padding={"20px"}>
      <Box sx={{ padding: "20px 5px" }}>
        <CustomSelectBox2
          value={formik.values.facilityTypeId}
          name="facilityTypeId"
          showDefaultOpt={true}
          dropdownOptions={typeOfFacility?.map((opt) => ({
            value: opt.facilityType,
            id: opt.facilityTypeId,
          }))}
          label="Type of Facility"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          formik={formik}
        />
      </Box>
      <Box sx={{ padding: "20px 5px" }}>
        <CustomSelectBox2
          value={formik.values.careTypeId}
          name="careTypeId"
          showDefaultOpt={true}
          dropdownOptions={typeOfCare?.map((opt) => ({
            value: opt.careType,
            id: opt.careTypeId,
          }))}
          label="Type Of Care"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          formik={formik}
        />
      </Box>
      <Box sx={{ padding: "20px 5px" }}>
        <CustomSelectBox2
          value={formik.values.frequencyTypeId}
          name="frequencyTypeId"
          showDefaultOpt={true}
          dropdownOptions={frequency?.map((opt) => ({
            value: opt.frequencyType,
            id: opt.frequencyTypeId,
          }))}
          label="Frequncy"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          formik={formik}
        />
      </Box>
      <CustomButton
        handleClick={handleBill}
        variant="contained"
        isBlue={true}
        margin="30px 5px 10px"
      >
        Apply
      </CustomButton>
    </Box>
  );
};

export default TypeOfBill;


// import React, { useEffect, useState } from "react";
// import { Box, Typography } from "@mui/material";
// import CustomSelectBox2 from "../../../components/CustomSelectBox2";
// import CustomButton from "../../../components/CustomButton";
// import path from "../../../config/apiUrl";
// import { getData } from "../../../config/axiosFunctions";
// import CustomSearchField from "../../../components/CustomSearchField"; // Make sure you import CustomSearchField if it's in a different file

// const YourComponent = ({ formik, setOpenTypeOfBillModal }) => {
//   const [frequency, setFrequency] = useState([]);
//   const [typeOfCare, setTypeOfCare] = useState([]);
//   const [typeOfFacility, setTypeOfFacility] = useState([]);

//   const dataFetchUrls = {
//     frequencyType: `${path}/ct-frequencyType`,
//     facilityType: `${path}/ct-facilityType`,
//     careType: `${path}/ct-careType`,
//   };

//   const fetchDataOptions = async (url, setter) => {
//     try {
//       const response = await getData(url);
//       setter(response.result);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleBill = () => {
//     let facility = formik.values?.facilityTypeId
//       ? formik.values?.facilityTypeId
//       : 1;
//     let careType = formik.values?.careTypeId ? formik.values?.careTypeId : 1;
//     let frequencyType = formik.values?.frequencyTypeId
//       ? formik.values?.frequencyTypeId
//       : 2;
//     formik.setFieldValue("facilityTypeId", facility);
//     formik.setFieldValue("careTypeId", careType);
//     formik.setFieldValue("frequencyTypeId", frequencyType);
//     formik.setFieldValue(
//       "typeOfBill",
//       `${facility}${careType}${frequencyType}`
//     );
//     setOpenTypeOfBillModal(false);
//   };

//   useEffect(() => {
//     fetchDataOptions(dataFetchUrls.frequencyType, setFrequency);
//     fetchDataOptions(dataFetchUrls.facilityType, setTypeOfFacility);
//     fetchDataOptions(dataFetchUrls.careType, setTypeOfCare);
//   }, []);


//   useEffect(() => {
//     if (formik.values.claimTypeId === 1) {
//       formik.setFieldValue("facilityTypeId", null);
//       formik.setFieldValue("careTypeId", null);
//       formik.setFieldValue("frequencyTypeId", null);
//       formik.setFieldValue("typeOfBill", null);
//     }
//   }, [formik.values.claimTypeId]);

//   return (
//     <Box>
//       {formik.values.claimTypeId === 1 ? (
//         <>
//           <CustomSelectBox2
//             showDefaultOpt={true}
//             value={formik.values.claimFrequencyId}
//             name="claimFrequencyId"
//             dropdownOptions={frequency.map((opt) => ({
//               value: opt.claimFrequencyName,
//               id: opt.claimFrequencyId,
//             }))}
//             label="Frequency Type"
//             handleChange={formik.handleChange}
//             handleBlur={formik.handleBlur}
//             formik={formik}
//             isRequired={true}
//           />
//         </>
//       ) : (
//         <>
//           <CustomSearchField
//             label="Type of Bill"
//             type="text"
//             name="typeOfBill"
//             handleModalOpen={() => setOpenTypeOfBillModal(true)}
//             handleChange={formik.handleChange}
//             handleBlur={formik.handleBlur}
//             fieldVal={formik.values.typeOfBill ? formik.values.typeOfBill : ""}
//             resetField={{
//               facilityTypeId: null,
//               careTypeId: null,
//               frequencyTypeId: null,
//               typeOfBill: "",
//             }}
//             formik={formik}
//             isRequired={true}
//           />
//           <CustomButton
//             handleClick={handleBill}
//             variant="contained"
//             isBlue={true}
//             margin="30px 5px 10px"
//           >
//             Apply
//           </CustomButton>
//         </>
//       )}
//     </Box>
//   );
// };

// export default YourComponent;


