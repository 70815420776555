import { createSlice } from "@reduxjs/toolkit";
import { createNewPayerAction, getPayerAction, updatePayerAction } from "../actions/payerAction";
import { toast } from "react-toastify";

const initialState = {
  payerData: {},
  getAllPayer: [],
  loading: false,
  error: null,
};

const payerSlice = createSlice({
  name: "payer",
  initialState: initialState,
  extraReducers: {
    [createNewPayerAction.pending]: (state) => {
      state.loading = true;
      console.log("payerAction: Loading")
    },
    [createNewPayerAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.payerData = action.payload;
      console.log(action, "payerAction")
      toast.success("Payer Created Successfully!");
    },
    [createNewPayerAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Payer Code creration failed!");
    },
    [getPayerAction.pending]: (state) => {
      state.loading = true;
    },
    [getPayerAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getAllPayer = action.payload;
    },
    [getPayerAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updatePayerAction.pending]: (state) => {
      state.loading = true;
    },
    [updatePayerAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.payerData = action.payload;
      toast.success("Payer Updated Successfully!");
    },
    [updatePayerAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Payer Code updation failed!");
    },
  },
});

export default payerSlice.reducer;
