// import { createSlice } from "@reduxjs/toolkit";
// import { searchFollowUpDataAction } from "../actions/followUpManagementAction";

// const initialState = {
//     followUpSearchedData:{},
//     loading: {
//         searchLoading:false
//     },
//     error: null,
//   };
//   const followUpManagementSilce = createSlice({
//     name: "followUpManagement",
//     initialState: initialState,
//     extraReducers: {
//       [searchFollowUpDataAction.pending]: (state) => {
//         state.loading.searchLoading = true;
//       },
//       [searchFollowUpDataAction.fulfilled]: (state, action) => {
//         state.loading.searchLoading = false;
//         state.followUpSearchedData = action.payload;
//       },
//       [searchFollowUpDataAction.rejected]: (state, action) => {
//         state.loading.searchLoading = false;
//         state.error = action.payload;
//       }}})

//       export default followUpManagementSilce.reducer

// slices/followUpManagementSlice.js
// slices/followUpManagementSlice.js


import { createSlice } from "@reduxjs/toolkit";
import { searchFollowUpDataAction } from "../actions/followUpManagementAction";

const initialState = {
  followUpSearchedData: {},
  loading: {
    searchLoading: false,
  },
  error: null,
};

const followUpManagementSlice = createSlice({
  name: "followUpManagement",
  initialState,
  extraReducers: {
    [searchFollowUpDataAction.pending]: (state) => {
      state.loading.searchLoading = true;
      state.error = null;
    },
    [searchFollowUpDataAction.fulfilled]: (state, action) => {
      state.loading.searchLoading = false;
      state.followUpSearchedData = action.payload;
    },
    [searchFollowUpDataAction.rejected]: (state, action) => {
      state.loading.searchLoading = false;
      state.error = action.payload;
    }}
});

export default followUpManagementSlice.reducer;

