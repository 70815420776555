import { Grid,  FormControl,Stack,Box
 } from '@mui/material'
import React from 'react'
import CustomSelectBox2 from '../../../components/CustomSelectBox2';
import { useEffect,useState} from 'react';
import path from '../../../config/apiUrl';
import { getData } from '../../../config/axiosFunctions';
import { paymentPlanCols } from '../../../utils/gridCols';
import CustomButton from '../../../components/CustomButton';
import { DataGrid } from '@mui/x-data-grid';

const AddCharges = () => {
  const [dateOfServiceOtions, setDateOfServiceOptions] = useState([]);

  const dataFetchUrls = {
    dateOfService: `${path}/ct-dos`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

useEffect(() => {
    fetchDataOptions(dataFetchUrls.dateOfService, setDateOfServiceOptions);
  }, []);

  const dummyTest = [
    {
        id:1,
        dos:"James",
        claimNo:'20987',
        cpt:'9875',
        status:'Claim At Geico Insurance ',
        amount:'1200',
        balance:'10',
        practice:'Brucel Feldman(#12333)'
    },
    {
      id:2,
      dos:"James",
      claimNo:'20987',
      cpt:'9875',
      status:'Claim At Geico Insurance ',
      amount:'1200',
      balance:'10',
      practice:'Brucel Feldman(#12333)'
    },
    {
      id:3,
      dos:"James",
      claimNo:'20987',
      cpt:'9875',
      status:'Claim At Geico Insurance ',
      amount:'1200',
      balance:'10',
      practice:'Brucel Feldman(#12333)'
    },
]

const rows = dummyTest.map((el) => ({
    id: el?.id,
    ...el
}))

  return (
    <>
    <Grid container spacing={2}  padding="25px">
<Grid md={3} sm={12} xs={12}>
<FormControl fullWidth>
                  <CustomSelectBox2
                    dropdownOptions={dateOfServiceOtions?.map((opt) => ({
                      value: opt.dos,
                      id: opt.dosId,
                    }))}
                    label="Date Of Service"
                    showDefaultOpt={true}
              
                  />
                </FormControl>
</Grid>
    </Grid>


  <Box margin={'10px'}>
        <DataGrid
          rows={rows}
          columns={paymentPlanCols}

          sx={{
            "& .header-bg": {
              backgroundColor: "lightgrey",
              color: "black",
              fontSize: "14px",
            },
            "& .cell-content": {
              fontSize: "12.5px",
            },
          }}
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSize={20}
          components={{
            NoRowsOverlay: () => (
              <div
                style={{ width: "100%", textAlign: "center", padding: "16px" }}
              >
              </div>
            ),
          }}
        />

        <Stack direction="row" justifyContent={'end'} padding="10px" gap={2}>
          <CustomButton isBlue={true} isAdd={true}
           >
            Done
          </CustomButton>
            <CustomButton isBlue={true} isCancel={true} 
            >
           Cancel
          </CustomButton>
        </Stack>
      </Box>
    </>
  )
}

export default AddCharges
