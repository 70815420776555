import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
const DxPointer = ({
  handleClose,
  formik,
  procedureValues,
  setProcedureValues,
}) => {
  const [dxPointerCurrent, setDxPointerCurrent] = useState(
    procedureValues?.dxPointer.split("")
  );

  const handleClear = () => {
    setDxPointerCurrent([]);
  };
  const handleAddClick = (v) => {
    let find = dxPointerCurrent.find((val) => val === v.dxPointerLabel);
    if (!find && dxPointerCurrent.length < 4) {
      setDxPointerCurrent([...dxPointerCurrent, v.dxPointerLabel]);
    }
  };
  const handleSave = (v) => {
    setProcedureValues.setValues({
      ...procedureValues,
      dxPointer: dxPointerCurrent.join(""),
    });
    handleClose();
  };
  return (
    <Box sx={{ padding: "12px 10px" }}>
      {console.log(dxPointerCurrent)}
      <Typography variant="h4">
        Click on ICD codes to select or deselect them.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {formik.values?.icD_DiagnosisDetailDto?.length > 0 &&
          formik.values?.icD_DiagnosisDetailDto?.map((v, i) => {
            return (
              <Box
                onClick={() => handleAddClick(v)}
                sx={{
                  display: "flex",
                  border: dxPointerCurrent?.find(
                    (val) => val === v.dxPointerLabel
                  )
                    ? "2px solid red"
                    : "1px solid gray",
                  justifyContent: "space-between",
                  aliginItem: "center",
                  borderRadius: "5px",
                  padding: "7px",
                  margin: "7px",
                  flexBasis: "23%",
                  cursor:
                    dxPointerCurrent.length < 4
                      ? formik.values?.icD_DiagnosisDetailDto?.length ===
                        dxPointerCurrent.length
                        ? "not-allowed"
                        : "pointer"
                      : "not-allowed",
                }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: "1200" }}>
                  {v?.dxPointerLabel}
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: "1200" }}
                  variant="h6"
                >
                  {v?.diagnosisCode}
                </Typography>
              </Box>
            );
          })}
      </Box>
      <Box sx={{ height: "35px" }}>
        <Typography variant="h2" textAlign={"center"}>
          {dxPointerCurrent.join("") || ""}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <CustomButton handleClick={handleClear} children={"Clear"} />
        <CustomButton
          handleClick={handleSave}
          isBlue={true}
          isSave={true}
          children={"Save"}
        />
      </Box>
    </Box>
  );
};

export default DxPointer;
