import React, { useEffect } from "react";
import MainHeading from "../../../components/MainHeading";
import { Box, Stack,Typography } from "@mui/material";
import ProcedureInfo from "./ProcedureInfo";
import { procedureInitValues } from "../../../utils/formikInitValues";
import { useFormik } from "formik";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { procedureSchema } from "../../../schemas/patientSchema";
import { useDispatch } from "react-redux";
import { handleCancel } from "../../../utils/customFunctions";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";
import {
  getProcedureCodeAction,
  newProcedureCodeAction,
  updateProcedureCodeAction,
} from "../../../features/actions/procedureCodeAction";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const UpdateProcedure = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { id } = useParams();
  const { getProcedureCodes, loading } = useSelector(
    (state) => state.procedureCodes
  );

  let findProcedure = getProcedureCodes.find(
    (v) => v?.procedureCodeId === Number(id)
  );

  const formik = useFormik({
    initialValues: findProcedure || procedureInitValues,
    validationSchema: procedureSchema,
    onSubmit: (values, action) => {
      try {
        dispatch(updateProcedureCodeAction(values));
        navigate("/procedure-codes");
      } catch (err) {
        console.log(err, ": Error in creating new procedure code");
        throw err;
      }
    },
  });
  useEffect(() => {
    dispatch(getProcedureCodeAction());
  }, []);
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/procedure-codes");
  };
  return (
    <>
    <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
      <MainHeading mainheading={"Update Procedure"} />

      <Stack margin="15px" direction="row" alignItems="center" gap={2}>
        <CustomButton
          formId="procedureForm"
          isBlue={true}
          isSave={true}
          type="submit"
        >
          Update
        </CustomButton>
        {/* <CustomButton isBlue={false} isCancel={true}    handleClick={() => {
                handleCancel(
                  formik,
                   "/procedure-codes", navigate);
              }}>
          Cancel
        </CustomButton> */}
        <CustomButton   type="submit" isCancel={true} handleClick={handleCancel}>
         Cancel
       </CustomButton>
      </Stack>
      <form id="procedureForm" onSubmit={formik.handleSubmit}>
        <ProcedureInfo formik={formik} />
      </form>
    </Box>
     <CustomDialog
     open={openCancelDialog}
     isWarning={true}
     handleClose={() => setOpenCancelDialog(false)}
     handleConfirm={handleConfirmCancel}
     handleCancel={() => setOpenCancelDialog(false)}
     confirmButtonTitle="OK"
     cancelButtonTitle="Cancel"
    >
     <Typography component="h3">Are you sure you want to cancel?</Typography>
    </CustomDialog>
  </>
  );
};

export default UpdateProcedure;
