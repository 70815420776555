import React from "react";
import PayerForm from "./PayerForm";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { createNewPayerAction } from "../../../features/actions/payerAction";
import { useNavigate } from "react-router-dom";
import { payerIntialVal } from "../../../utils/formikInitValues";
import CustomButton from "../../../components/CustomButton";
import MainHeading from "../../../components/MainHeading";
import { Box,Typography } from "@mui/material";
import { payerSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";

const CreatePayer = () => {
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: payerIntialVal,
    validationSchema : payerSchema,
    onSubmit: (values, action) => {
      console.log(values, "check payer Val");
      dispatch(createNewPayerAction(values));
      action.resetForm();
      navigate("/payer");
    },
  });
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/payer");
  };
  return (
    <>
      <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
        <MainHeading mainheading={"Create Payer"} />
        <form id="payerForm" onSubmit={formik.handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 150px))",
                sm: "repeat(1, minmax(0, 150px))",
                md: "repeat(2, minmax(0, 150px))",
              },
            }}
          >
            <CustomButton type="submit" isBlue={true} isSave={true} id="payerForm">
              Save
            </CustomButton>
            {/* <CustomButton
             
              isCancel={true}
              handleClick={() => {
                let confirm = window.confirm(
                  "Are you sure you want to cancel?"
                );
                if (confirm) {
                  formik.resetForm();
                  navigate("/payer");
                }
              }}
            >
              Cancel
            </CustomButton> */}
            <CustomButton isCancel={true} handleClick={handleCancel}>
         Cancel
       </CustomButton>
          </Box>
          <PayerForm formik={formik} />
        </form>
      </Box>
      <CustomDialog
    open={openCancelDialog}
    isWarning={true}
    handleClose={() => setOpenCancelDialog(false)}
    handleConfirm={handleConfirmCancel}
    handleCancel={() => setOpenCancelDialog(false)}
    confirmButtonTitle="OK"
    cancelButtonTitle="Cancel"
   >
    <Typography component="h3">Are you sure you want to cancel?</Typography>
   </CustomDialog>
    </>
  );
};

export default CreatePayer;
