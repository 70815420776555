import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

export const getChargePanelAction = createAsyncThunk(
  "getChargePanel",
  async () => {
    try {
      const response = await getData(`${path}/chargePanel`);
      if (response) {
        return response;
      }
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }
);

export const createNewChargePanelAction = createAsyncThunk(
  "createChargePanel",
  async (data) => {
    try {
      const response = await postData(`${path}/chargePanel`, data);
      console.log(response, "action create charge panel response");
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateChargePanelAction = createAsyncThunk(
  "updateChargePanel",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/chargePanel/${data.chargerPanelId}`,
        data
      );
      console.log(response, "update charge panel action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);