import { createSlice } from "@reduxjs/toolkit";
import {
  getDiagnosisAction,
  newDiagnosisAction,
  updateDiagnosisAction,
} from "../actions/diagnosisAction";
import { toast } from "react-toastify";

const initialState = {
  getDiagnosisData: [],
  createDiagnosisData: {},
  error: null,
  loading: false,
};
const diagnosisSlice = createSlice({
  name: "diagnosis",
  initialState: initialState,

  extraReducers: {
    [getDiagnosisAction.pending]: (state) => {
      state.loading = true;
    },
    [getDiagnosisAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getDiagnosisData = action.payload;
    },
    [getDiagnosisAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "getDiagnosisDataErr");
    },
    [newDiagnosisAction.pending]: (state) => {
      state.loading = true;
    },
    [newDiagnosisAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createDiagnosisData = action.payload;
      toast.success("Diagnosis has been created successfully!");
    },
    [newDiagnosisAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "DiagnosisErr");
      toast.error("Diagnosis Creation Failed.");
    },
    [updateDiagnosisAction.pending]: (state) => {
      state.loading = true;
    },
    [updateDiagnosisAction.fulfilled]: (state, action) => {
      state.loading = false;
      // state.createFacilityData = action.payload;
      const updateDiagnosisIndex = state.getDiagnosisData.result?.findIndex(
        (item) => item.diagnosisCodeId === action.payload.diagnosisCodeId
      );
      console.log("action" , action.payload)
      if (updateDiagnosisIndex !== -1) {
        state.getDiagnosisData.result[updateDiagnosisIndex] = {
          // ...state.getPractices.result[updateDiagnosisIndex],
          ...action.payload,
        };
      }
      toast.success("Diagnosis Updated Successfully!");
    },
    [updateDiagnosisAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "DiagnosisErr");
      toast.error("Diagnosis Updation Failed.");
    },
  },
});

export default diagnosisSlice.reducer;
