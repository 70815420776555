import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRemarkRemittanceAction } from "../../../features/actions/remittanceAction";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "../../../components/Loading";
import CustomButton from "../../../components/CustomButton";
import { postData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { toast } from "react-toastify";

const RemarkTable = ({ handleClose }) => {
  const dispatch = useDispatch();
  const { remarkData, loading } = useSelector((state) => state.remittance);
  const [selectedRemark, setSelectedRemark] = useState(null);
  console.log(selectedRemark, "selectedRemark");
  const rows =
    remarkData && remarkData.length > 0
      ? remarkData.map((item) => ({
          id: item.remarkCodeId,
          ...item,
        }))
      : [];
  const columns = [
    {
      field: "remarkCode",
      headerName: "Code",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "longDescription",
      minWidth: 100,
      flex: 2,
      headerName: "Description",
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];
  useEffect(() => {
    try {
      dispatch(getRemarkRemittanceAction());
    } catch (e) {
      console.log(e, ": Error fetching remark remittance");
      throw e;
    }
  }, []);

  // handle add remarks
  const handleAddRemark = async () => {
    const selectedAllRemarks = selectedRemark?.map((remark) =>
      rows?.find((el) => el.id === remark)
    );
    const remarkUpdateStructure = selectedAllRemarks.map((remark) => ({
      remittanceCodeName: remark?.remarkCode,
      remittanceTypeId: remark?.remittanceTypeId,
      remittanceType: remark?.remittanceType,
      remittanceInfoLevelId: remark?.remittanceInfoLevelId,
      remittanceInfoLevel: remark?.remittanceInfoLevel,
      reportDescription: remark?.reportDescription,
      longDescription: remark?.longDescription,
      isMemoline: remark?.isMemoline,
      isMemolineAmount: remark?.isMemolineAmount,
      remittanceCodeDetailDtos: remark?.remittanceCodeDetailDtos,
    }));

    try {
      const response = await postData(
        `${path}/remittanceCode`,
        remarkUpdateStructure
      );
      if (response) {
        console.log(response, "check posting remark response");
        toast.success("Remark code added successfully");
        handleClose();
        return response;
      }
    } catch (err) {
      console.log(err, ": Error inposting remittance remark");
      handleClose();
      toast.error(err.response.data.message);
    }
  };

  return (
    <Box>
      {loading ? (
        <Loading />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          sx={{
            "& .header-bg": {
              backgroundColor: "lightgrey",
              color: "black",
              fontSize: "14px",
            },
            "& .cell-content": {
              fontSize: "12.5px",
            },
          }}
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSize={20}
          onSelectionModelChange={(newRemark) => setSelectedRemark(newRemark)}
          components={{
            NoRowsOverlay: () => (
              <div
                style={{ width: "100%", textAlign: "center", padding: "16px" }}
              >
                {remarkData.length === 0 && "No Data Is Added"}
              </div>
            ),
          }}
        />
      )}

      <div
        style={{
          position: "sticky",
          bottom: "0",
          borderTop: ".5px solid grey",
          width: "100%",
          background: "#fff",
          padding: "10px",
        }}
      >
        <CustomButton isBlue={true} isAdd={true} handleClick={handleAddRemark}>
          Add
        </CustomButton>
      </div>
    </Box>
  );
};

export default RemarkTable;
