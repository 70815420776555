import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import { adjustmentInitVals } from "../../../utils/formikInitValues";
import { Box, Grid, Stack,Typography } from "@mui/material";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import AdjustmentInfo from "./AdjustmentInfo";
import { useNavigate } from "react-router";
import { newAdjustmentAction } from "../../../features/actions/adjustmentAction";
import { adjustmentSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";

const NewAdjustmentCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { loading } = useSelector((state) => state.adjustments);
  const formik = useFormik({
    initialValues: adjustmentInitVals,
    validationSchema : adjustmentSchema,
    onSubmit: (values, action) => {
      console.log(values, "values");
      try {
        dispatch(newAdjustmentAction(values));
      } catch (err) {
        console.log(err, "create adjustment error");
        throw err;
      }
      action.resetForm();
      navigate("/adjustment");
    },
  });

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/adjustment");
  };
  return (
    <>
        <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
     
     <MainHeading mainheading={"Add New Adjustment Code"} />
  

   <Stack direction="row" gap="20px">
     {/* <CustomButton
       type="submit"
       formId="adjustmentInfo"
       isBlue={true}
       isSave={true}
     >
       {loading ? "Saving..." : "Save"}
     </CustomButton>
     <CustomButton isCancel={true} handleClick={handleCancel}>
       Cancel
     </CustomButton> */}
       <CustomButton
       type="submit"
       formId="adjustmentInfo"
       isBlue={true}
       isSave={true}
     >
       {loading ? "Saving..." : "Save"}
     </CustomButton>
         <CustomButton isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>
   </Stack>

   <form id="adjustmentInfo" onSubmit={formik.handleSubmit}>
     <AdjustmentInfo formik={formik} />
   </form>
 </Box>
 <CustomDialog
 open={openCancelDialog}
 isWarning={true}
 handleClose={() => setOpenCancelDialog(false)}
 handleConfirm={handleConfirmCancel}
 handleCancel={() => setOpenCancelDialog(false)}
 confirmButtonTitle="OK"
 cancelButtonTitle="Cancel"
>
 <Typography component="h3">Are you sure you want to cancel?</Typography>
</CustomDialog></>

  );
};

export default NewAdjustmentCode;
