import { Box, Modal, Stack, useTheme, Typography, Grid } from "@mui/material";
import React from "react";
import CustomButton from "./CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { Flex } from "antd";
import zIndex from "@mui/material/styles/zIndex";

const CustomModal = ({
  children,
  open,
  handleClose,
  width,
  showButton,
  isLoading = false,
  height,
  heading,
  btnLabel,
  handleClick,
}) => {
  const theme = useTheme();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "52%",
    transform: "translate(-50%, -50%)",
    width: width,
    minWidth: { xs: "80%", sm: "450px", md: "50%" },
    // bgcolor: theme.palette.background.paper,
    background: theme.palette.modalBackground.primary,
    border: "1px solid grey",
    boxShadow: 24,
    minHeight:'70%',
    height:height,
    overflowY: "auto", 
    zIndex:444
  };

  return (
    <Modal onClose={handleClose} open={open} sx={{ zIndex: "4444" }}>
      <Box sx={modalStyle}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          // marginRight="14px"
          padding="10px"
          sx={{
            position: "sticky",
            top: "0",
            zIndex: "666",
            width: "100%",
            background: theme.palette.modalHeading.primary,
            // background: "#f0f5f7",
          }}
        >
          <Grid container>
            <Grid item lg={10} md={10}>
              <Typography
                variant="h2"
                component="h3"
                fontWeight="600"
                margin="7px"
              >
                {heading}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item>
              {!isLoading && (
                <IconButton style={{color:theme.palette.searchIcon.primary }} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
            <hr />
          </Grid>
        </Stack>

        <Box>{children}</Box>
        {showButton && (
          <Stack
            sx={{
              alignItems: "center",
              flexDirection: "row",
              padding: "12px",
              justifyContent: "flex-end",
              bottom: "0",
              background: "#f0f5f7",
              position: "sticky",
              zIndex: "666",
            }}
            gap={2}
          >
            <CustomButton isBlue={true} handleClick={handleClick}>
              {btnLabel}
            </CustomButton>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default CustomModal;




// import React from 'react';
// import { Box, Modal, Stack, Typography, IconButton, useTheme,Grid} from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import CustomButton from './CustomButton';

// const CustomModal = ({
//   children,
//   open,
//   handleClose,
//   width = '50%',
//   // height = '60vh',
//   height,
//   showButton,
//   isLoading = false,
//   heading,
//   btnLabel,
//   handleClick,
// }) => {
//   const theme = useTheme();
//   const modalStyle = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: width,
//     height: height,
//     maxHeight: '90vh', 
//     overflowY: 'auto', 
//     background: theme.palette.background.paper,
//     border: '1px solid #ccc',
//     boxShadow: theme.shadows[5],
//     // padding: theme.spacing(2, 4, 3),
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//      sx={{ zIndex: "4444" }}
//     >
//       <Box sx={modalStyle}>
//         <Stack
//         direction="row"
//                   justifyContent="flex-end"
//                   // marginRight="14px"
//                   padding="10px"
//                   sx={{
//                     position: "sticky",
//                     top: "0",
//                     zIndex: "666",
//                     width: "100%",
//                     background: theme.palette.modalHeading.primary,
//                     // background: "#f0f5f7",
//                   }}
//         >
//           <Grid container>
//             <Grid item lg={10} md={10}>
//           <Typography 
//            variant="h2"
//                            component="h3"
//                            fontWeight="600"
//                            margin="7px"
//            >
//             {heading}
//           </Typography>
//           </Grid>
//           </Grid>

//           <Grid container justifyContent="flex-end">
//        <Grid item>
//             {!isLoading && (
//                 <IconButton style={{color:theme.palette.searchIcon.primary }} onClick={handleClose}>
//                   <CloseIcon />
//                 </IconButton>
//               )}
//             </Grid>
//             <hr />
//           </Grid>

//           {/* {!isLoading && (
//             <IconButton onClick={handleClose}>
//               <CloseIcon />
//             </IconButton>
//           )} */}
//         </Stack>

//         <Box id="modal-modal-description" sx={{ mt: 2 }}>
//           {children}
//         </Box>

//         {/* {showButton && (
//           <Stack
//             direction="row"
//             justifyContent="flex-end"
//             sx={{ mt: 2 }}
//           >
//             <CustomButton onClick={handleClick}>
//               {btnLabel}
//             </CustomButton>
//           </Stack>
//         )} */}


// {showButton && (
//           <Stack
//             sx={{
//               alignItems: "center",
//               flexDirection: "row",
//               padding: "12px",
//               justifyContent: "flex-end",
//               position: "sticky",
//               bottom: "0",
//               background: "#f0f5f7",
//             }}
//             gap={2}
//           >
//             <CustomButton isBlue={true} handleClick={handleClick}>
//               {btnLabel}
//             </CustomButton>
//           </Stack>
//         )}
//       </Box>
//     </Modal>
//   );
// };

// export default CustomModal;







