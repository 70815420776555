import React, { useCallback, useEffect, useState } from "react";
import { getData } from "../config/axiosFunctions";
import path from "../config/apiUrl";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "react-bootstrap";
import CustomButton from "./CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomExpandIcon from "./CustomExpandIcon";
import SummaryIcon from "./SummaryIcon";
import CustomDatePicker from "./CustomDatePicker";
import CustomField from "./CustomField";
import FileUpload from "./pdfs/FileUpload";
import axios from "axios";
import { toast } from "react-toastify";
const AccountSummary = ({ accountNo, handleClose, formik }) => {
  const [files, setFiles] = useState([]);
  const [accountSumaryData, setAccountSummaryData] = useState(null);

  const [expanded, setExpanded] = useState("panel1");
  const handleAccordionChange = useCallback(
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    },
    [setExpanded]
  );
  console.log(accountSumaryData, "CheckinAccSummary");
  // account summary
  const getAccountSummary = async () => {
    // console.log(Number(accountNo), "checkAccNo");
    try {
      const response = await getData(
        `${path}/patientAccountSummary?accountNo=${accountNo}`
      );
      if (response.status === "Success") {
        setAccountSummaryData(response.result);
      }
    } catch (err) {
      console.log(err, "Getting Account Summary");
    }
  };

  const getDocumentFiles = async () => {
    try {
      const response = await getData(`${path}/encryptFile/ViewPatientDocument?AccountNumber=${accountNo}`);
      if (response.status === "success") {
        console.log("response of account summary", response);
      }
    } catch (error) {
      console.log("error whike fetching files of patient", error);
    }
  };

  useEffect(() => {
    getAccountSummary();
    getDocumentFiles()
  }, [setAccountSummaryData]);

  function handleMultipleSubmit(event) {
    event.preventDefault();
    const url = `${path}/encryptFile/UploadPatientDocument`;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    formData.set("patientId", accountNo);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        toast.success("Document uploaded successfully");
      })
      .catch((error) => {
        toast.error("Server error");
        console.error("Error uploading files: ", error);
      });
    handleClose();
  }

  const theme = useTheme();
  return (
    <Box>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          className="closeButton"
          style={{ background: "#002F7A", border: "1px solid #002F7A" }}
          size="small"
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: "24px" }} />
        </Button>
      </Stack>
      <Accordion
        elevation={0}
        disableGutters
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
        sx={{
          margin: "0px !important",
        }}
      >
        <AccordionSummary
          sx={{
            fontSize: "1.5rem",
            // background:'#216FED',
            // color:'white'

            background: theme.palette.accSummaryAccord.primary,
            color: theme.palette.btnSaveText.primary,
          }}
          expandIcon={
            <SummaryIcon
              sx={{ background: theme.palette.accordiansHeadingText.primary }}
            />
          }
        >
          Account Summary
        </AccordionSummary>

        <AccordionDetails
          style={{ background: theme.palette.accordiansBodyBackground.primary }}
        >
          <Box
            sx={{
              maxHeight: "200px",
              overflowY: "scroll",
              padding: "12px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Family Account Type:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.familyAccountType === null
                  ? "No Account"
                  : accountSumaryData?.familyAccountType}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Patient Balance:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${accountSumaryData?.patientBalance}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Last Patient Payment:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${accountSumaryData?.lastPatientPayment}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Insurance Balance:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${accountSumaryData?.insuranceBalance}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Last Insurance Payment:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${accountSumaryData?.lastInsurancePayment}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Collection Balance:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${accountSumaryData?.collectionBalance}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Primary Insurance:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.primaryInsurance === null
                  ? "No Patient Summary Added"
                  : accountSumaryData?.primaryInsurance}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Copay:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                $
                {accountSumaryData?.copay === null
                  ? 0
                  : accountSumaryData?.copay}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Last Statement:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.lastStatement === null
                  ? 0
                  : accountSumaryData?.lastStatement}
              </Typography>
            </Stack>

            {/* statements mailed count */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography
                type="number"
                component="h3"
                fontSize="1.4rem !important"
              >
                Statements Mailed Count:
              </Typography>
              {/* <Typography component="p" fontSize="1.4rem !important">
                $
                {accountSumaryData?.lastStatement === null
                  ? 0
                  : accountSumaryData?.lastStatement}
              </Typography> */}
              <CustomField type="number" />
            </Stack>

            {/* Electronic Statement Count: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Electronic Statement Count:
              </Typography>
              {/* <Typography type='number'component="p" fontSize="1.4rem !important">
                $
                {accountSumaryData?.lastStatement === null
                  ? 0
                  : accountSumaryData?.lastStatement}
              </Typography> */}
              <CustomField type="number" />
            </Stack>

            {/* Last Visit: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Last Visit:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.lastVisitDate === null
                  ? 0
                  : accountSumaryData?.lastVisitDate}
              </Typography>
            </Stack>

            {/* Next Appointment: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Next Appointment:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {accountSumaryData?.nextAppointment === null
                  ? 0
                  : accountSumaryData?.nextAppointment}
              </Typography>
            </Stack>

            {/* Follow-Up Date: */}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Follow-Up Date:
              </Typography>
              {/* <Typography component="p" fontSize="1.4rem !important">
                $
                {accountSumaryData?.followUpDate === null
                  ? 0
                  : accountSumaryData?.followUpDate
                }
              </Typography> */}
              <CustomDatePicker formik={formik} />
            </Stack>

            {/* Recall Date: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Recall Date:
              </Typography>
              {/* <Typography component="p" fontSize="1.4rem !important">
                $
                {accountSumaryData?.recallDate === null
                  ? 0
                  : accountSumaryData?.recallDate

                }
              </Typography> */}
              <CustomDatePicker formik={formik} />
            </Stack>

            {/* Last Eligibility Check: */}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                <span style={{ color: "#216FED" }}>?</span> Last Eligibility
                Check:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
             
                {accountSumaryData?.lastEligibilityCheck === null
                  ? 0
                  : accountSumaryData?.lastEligibilityCheck}
              </Typography>
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion
        expanded={expanded === "panel2"}
        onChange={handleAccordionChange("panel2")}
      >
        <AccordionSummary
          sx={{
            fontSize: "1.5rem",
            // background:'#216FED',
            // color:'white'
            background: theme.palette.accSummaryAccord.primary,
            color: theme.palette.btnSaveText.primary,
          }}
          expandIcon={<SummaryIcon />}
        >
          Notes
        </AccordionSummary>
        <AccordionDetails
          style={{ background: theme.palette.accordiansBodyBackground.primary }}
        >
          <FileUpload
            files={files}
            setFiles={setFiles}
            handleMultipleSubmit={handleMultipleSubmit}
            multiple={true}
          />
        </AccordionDetails>
      </Accordion> */}
    </Box>
  );
};

export default AccountSummary;
