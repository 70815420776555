import MainHeading from "../../../components/MainHeading";
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { claimTrackerIntVal } from "../../../utils/formikInitValues";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ClaimTrackerForm from "./ClaimTrackerForm";
import { useDispatch, useSelector } from "react-redux";
import { getClaimTracker } from "../../../features/actions/claimTrackerAction";
import { useTheme } from "@emotion/react";
import Loading from "../../../components/Loading";
import { useState } from "react";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";

import {
  handleArrowChange,
  handleCancel,
} from "../../../features/slice/claimTrackerSlice";
import { useNavigate } from "react-router";
import CustomButton from "../../../components/CustomButton";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
const dateOption = {
  1: {
    fromDate: "",
    toDate: "",
  },
  2: {
    fromDate: "",
    toDate: "",
  },
  3: {
    fromDate: formatDate(new Date()),
    toDate: formatDate(new Date()),
  },
  4: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      )
    ),
    toDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      )
    ),
  },
  5: {
    fromDate: (function () {
      let date = new Date();
      let diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return formatDate(new Date(date.setDate(diff)));
    })(),
    toDate: formatDate(new Date()),
  },
  6: {
    fromDate: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    toDate: formatDate(new Date()),
  },
  7: {
    fromDate: formatDate(new Date(new Date().getFullYear(), 0, 1)),
    toDate: formatDate(new Date()),
  },
  8: {
    fromDate: (function () {
      let date = new Date();
      let diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return formatDate(new Date(date.setDate(diff - 7)));
    })(),
    toDate: (function () {
      let date = new Date();
      let diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return formatDate(new Date(date.setDate(diff - 1)));
    })(),
  },
  9: {
    fromDate: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
    ),
    toDate: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 0)
    ),
  },
  10: {
    fromDate: formatDate(new Date(new Date().getFullYear() - 1, 0, 1)),
    toDate: formatDate(new Date(new Date().getFullYear() - 1, 12, 0)),
  },
  11: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      )
    ),
    toDate: formatDate(new Date()),
  },
  12: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 30
      )
    ),
    toDate: formatDate(new Date()),
  },
  13: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 60
      )
    ),
    toDate: formatDate(new Date()),
  },
  14: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 90
      )
    ),
    toDate: formatDate(new Date()),
  },
  15: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 12,
        new Date().getDate()
      )
    ),
    toDate: formatDate(new Date()),
  },
};
const ClaimTracker = () => {
  const { state } = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { claimTrackerData, loading, dataExits } = useSelector(
    (state) => state.claimTracker
  );
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const formik = useFormik({
    initialValues: claimTrackerIntVal,
    onSubmit: (values, action) => {
      let billingProvidersId = values.billingProvidersId.map(
        (provider) => provider.id
      );
      let claimStatusId = values.claimStatusId.map((v) => v.value);
      let claimTypeId = values.claimTypeId.map((v) => v.value);
      let facilityId = values.facilityId.map((v) => v.id);
      let payer = values.payer.map((v) => v.id);
      let payerTypeId = values.payerTypeId.map((v) => v.value);
      let processingModeId = values.processingModeId.map((v) => v.value);
      let renderingProviderId = values.renderingProviderId.map((v) => v.id);
      let submissionTypeId = values.submissionTypeId.map((v) => v.value);
      let trackingStatusSourceId = values.trackingStatusSourceId.map(
        (v) => v.value
      );
      let trackingStatusTypeId = values.trackingStatusTypeId.map(
        (v) => v.value
      );
      let postDataValues = {
        ...values,
        billingProvidersId,
        claimStatusId,
        claimTypeId,
        facilityId,
        payer,
        payerTypeId,
        processingModeId,
        renderingProviderId,
        submissionTypeId,
        trackingStatusSourceId,
        trackingStatusTypeId,
      };
      dispatch(getClaimTracker(postDataValues));
    },
  });

  const handleDosChange = (e, type) => {
    let { fromDate, toDate } = dateOption[e.target.value];
    formik.setValues((value) => ({
      ...value,
      ctDosId: e.target.value,
      [`${type}FromDate`]: fromDate,
      [`${type}ToDate`]: toDate,
    }));
  };

  const rendersRow = (el, level) => {
    let marginLeft = level * 15;
    return (
      <>
        {el.isFalse ? (
          <TableRow>
            <TableCell
              sx={{
                fontSize: "1.2rem",
                marginLeft: `${marginLeft}px`,
                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              {el?.claimTrackerList && el?.claimTrackerList?.length > 0 && (
                <div
                  onClick={() => handleArrow(level, el?.claimNumber, el?.tcn)}
                  style={{ cursor: "pointer" }}
                >
                  {el?.arrow ? (
                    <ArrowDropDownIcon fontSize="large" />
                  ) : (
                    <ArrowRightIcon fontSize="large" />
                  )}
                </div>
              )}{" "}
              <div>
                <Checkbox />
              </div>
              <div>{el?.claimNumber || el?.tcn || ""}</div>
            </TableCell>
            <TableCell sx={{ fontSize: "1.2rem" }}>
              {(el?.dateOfService &&
                new Date(el?.dateOfService).toLocaleDateString("en-US")) ||
                (el?.statusDate &&
                  new Date(el?.statusDate).toLocaleDateString("en-US")) ||
                ""}
            </TableCell>
            <TableCell sx={{ fontSize: "1.2rem", width: "40%" }}>
              {(el?.firstName && el?.firstName + " " + el?.lastName) ||
                (el?.status && el?.status) ||
                (el?.clearingHouseStatus && el?.clearingHouseStatus) ||
                ""}
            </TableCell>
            <TableCell sx={{ fontSize: "1.2rem" }}>
              {(el?.totalAmount && el?.totalAmount) ||
                (el?.billedAmount && el?.billedAmount) ||
                ""}
            </TableCell>
            <TableCell sx={{ fontSize: "1.2rem" }}>
              {(el?.payerName && el?.payerName) || ""}
            </TableCell>
          </TableRow>
        ) : null}
        {el.claimTrackerList &&
          el.claimTrackerList.length > 0 &&
          el.claimTrackerList.map((innerEl) => rendersRow(innerEl, level + 1))}
      </>
    );
  };

  const handleArrow = (level, claimNo, tcnNo) => {
    let claimTrackerList = [...claimTrackerData];

    if (level === 0) {
      const findClaimNo = claimTrackerList?.find(
        (v) => v?.claimNumber === claimNo
      );
      if (findClaimNo) {
        let claimTrackerListAfterUpdate = findClaimNo.claimTrackerList.map(
          (e) => ({
            ...e,
            isFalse: !e?.isFalse,
            arrow: findClaimNo?.arrow && false,
            claimTrackerList:
              findClaimNo.isFalse &&
              e?.claimTrackerList.map((ele) => ({ ...ele, isFalse: false })),
          })
        );
        console.log("findClaimNo", claimTrackerListAfterUpdate);
        if (findClaimNo.isFalse) {
          // claimTrackerListAfterUpdate = findClaimNo.claimTrackerList.map(
          //   (el) => ({
          //     ...el,
          //     claimTrackerList: el?.claimTrackerList.map((ele) => ({
          //       ...ele,
          //       isFalse: false,
          //     })),
          //   })
          // );
        }
        const updatedClaimNo = {
          ...findClaimNo,
          claimTrackerList: claimTrackerListAfterUpdate,
          arrow: !findClaimNo?.arrow,
        };
        const updatedClaimTrackerList = claimTrackerList.map((claim) => {
          return claim.claimNumber === claimNo ? updatedClaimNo : claim;
        });
        claimTrackerList = updatedClaimTrackerList;
      }
    } else if (level === 1) {
      console.log("claim", claimNo);
      const findClaimNo = claimTrackerList?.find(
        (v) => v?.claimNumber === claimNo
      );

      if (findClaimNo && findClaimNo?.isFalse && tcnNo) {
        const findTcnNo = findClaimNo?.claimTrackerList.find(
          (v) => v.tcn === tcnNo
        );
        if (findTcnNo) {
          console.log("findTcnNo", findTcnNo);
          const updatedTcnNo = {
            ...findTcnNo,
            arrow: !findTcnNo?.arrow,
            claimTrackerList: findTcnNo?.claimTrackerList.map((e) => ({
              ...e,
              isFalse: !e?.isFalse,
            })),
          };
          const updatedClaimNo = {
            ...findClaimNo,
            claimTrackerList: findClaimNo.claimTrackerList.map((tcn) => {
              return tcn.tcn === tcnNo ? updatedTcnNo : tcn;
            }),
          };
          const updatedClaimTrackerList = claimTrackerList.map((claim) => {
            return claim.claimNumber === claimNo ? updatedClaimNo : claim;
          });
          claimTrackerList = updatedClaimTrackerList;
        }
      }
    }
    dispatch(handleArrowChange(claimTrackerList));
  };
  useEffect(() => {
    if (state) {
      let values = {
        ...formik.values,
        claimNo: state?.claimNumber,
      };
      dispatch(getClaimTracker(values));
    }
  }, []);

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    dispatch(handleCancel());
    formik.resetForm();
    setOpenCancelDialog(false);
  };
  return (
    <>
      {!dataExits ? (
        loading ? (
          <Loading />
        ) : (
          <Box
            margin={"20px"}
            sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}
          >
            <MainHeading mainheading={"Claim Tracker"} />
            <form onSubmit={formik.handleSubmit}>
              <ClaimTrackerForm
                formik={formik}
                handleDosChange={handleDosChange}
              />
            </form>
          </Box>
        )
      ) : (
        <>
          {!loading ? (
            <Box margin="20px" paddingBottom={"25px"}>
              <MainHeading mainheading={"Claim Tracker"} />
              <Box margin="20px" display={"flex"} gap={"10px"}>
                <CustomButton children={"Mark as Fixed"} isBlue={true} />
                {/* <CustomButton
                  handleClick={() => {
                    dispatch(handleCancel());
                    formik.resetForm();
                  }}
                  children={"Cancel"}
                  isCancel={true}
                /> */}
                 <CustomButton isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>

              </Box>
              <TableContainer component={Paper} sx={{margin:'15px 0'}}>
                <Table>
                  <TableHead
                      style={{
                        background: theme.palette.modalHeading.primary,
                        color: theme.palette.textMainHeading.primary,
                      }}
                    // id="t-header"
                  >
                    <TableRow sx={{ border: 1, borderColor: 'lightgrey' }}>
                      <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>claim #/TCN</TableCell>

                      <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>DOS/Status Date</TableCell>

                      <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>Patient/Status</TableCell>

                      <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>
                        Claim Amount/Billed Amount
                      </TableCell>

                      <TableCell id="description" sx={{ border: 1, borderColor: 'lightgrey' }}>Payer</TableCell>
                    </TableRow>
                  </TableHead>
                  {claimTrackerData &&
                    claimTrackerData.length > 0 &&
                    claimTrackerData?.map((el, id) => {
                      return <TableBody>{rendersRow(el, 0)}</TableBody>;
                    })}
                </Table>
              </TableContainer>
             

            </Box>
          ) : (
            <Loading />
          )}
        </>
      )}
       <CustomDialog
 open={openCancelDialog}
 isWarning={true}
 handleClose={() => setOpenCancelDialog(false)}
 handleConfirm={handleConfirmCancel}
 handleCancel={() => setOpenCancelDialog(false)}
 confirmButtonTitle="OK"
 cancelButtonTitle="Cancel"
>
 <Typography component="h3">Are you sure you want to cancel?</Typography>
</CustomDialog>
    </>
  );
};

export default ClaimTracker;



