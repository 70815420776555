import React from "react";
import MainHeading from "../../../components/MainHeading";
import { Box,Typography } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useFormik } from "formik";
import { newDiagnosisAction, updateDiagnosisAction } from "../../../features/actions/diagnosisAction";
import { useNavigate ,useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { diagnosisInitVal } from "../../../utils/formikInitValues";
import DiagnosisForm from "./DiagnosisForm";
import { diagnosisSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";

const UpdateDiagnosis = () => {
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  // const handleCancel = () => {
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/diagnosis");
  //   }
  // };
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/diagnosis")
  };
  const dispatch = useDispatch();
  const {id} = useParams()
  const {getDiagnosisData , loading} = useSelector(state=> state.diagnosis)
  const diagnosisValById = getDiagnosisData?.result.find((v)=> v?.diagnosisCodeId === Number(id))  
  console.log("diagnosisValById" , diagnosisValById)
  const formik = useFormik({
    initialValues: diagnosisValById || diagnosisInitVal,
    validationSchema : diagnosisSchema,
    onSubmit: (values, action) => {
      console.log(values, "checkDiagnosisUpdate");
      dispatch(
        updateDiagnosisAction({
          id: diagnosisValById?.diagnosisCodeId,
          ...values,
        })
      );
      navigate("/diagnosis");
    },
  });
  return (
    <>
    {/* {console.log("formik.values" , formik.values)} */}
    <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
        <MainHeading mainheading={"Update Diagnosis"} />
    

      <form onSubmit={formik.handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >
          <CustomButton type="submit" isBlue={true} isSave={true}>
            Update
          </CustomButton>
          <CustomButton isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>
        </Box>
        <DiagnosisForm formik={formik} />
       
      </form>
      </Box>
      <CustomDialog
 open={openCancelDialog}
 isWarning={true}
 handleClose={() => setOpenCancelDialog(false)}
 handleConfirm={handleConfirmCancel}
 handleCancel={() => setOpenCancelDialog(false)}
 confirmButtonTitle="OK"
 cancelButtonTitle="Cancel"
>
 <Typography component="h3">Are you sure you want to cancel?</Typography>
</CustomDialog>
    </>
  );
};

export default UpdateDiagnosis;
