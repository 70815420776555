import {
  Box,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import CheckBox from "../../../components/CheckBox";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";

const InsClaimCharges = ({formik}) => {
  //  description from type
  const descriptionFrom = [
    {
      id: 1,
      type: "REVCODE",
    },
    {
      id: 2,
      type: "HCPCS",
    },
  ];

  return (
    <Box>
      <Typography sx={{fontSize:'1.5rem',fontWeight:'600', marginBottom:'15px' }}>Charge Options</Typography>
      <FormGroup>
        <FormControlLabel
          control={<CheckBox />}
          label={<Typography fontSize={'1.3rem'} fontWeight="500">
          Update patient Procedure Code defaults
        </Typography>}
        />
        <FormControlLabel
          control={<CheckBox />}
          label={<Typography fontSize={'1.3rem'} fontWeight="500">
        Create a new charge panel from procedures(s)
        </Typography>}
        />
      </FormGroup>

      <Box 
        display="grid"
        gap="30px"
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(2, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
             marginTop:'15px'
          },
        }}
      >
        <CustomSelectBox2
          value={formik.values.descriptionFrom}
          name="descriptionFrom"
          dropdownOptions={descriptionFrom?.map((opt) => ({
            value: opt?.type,
            id: opt?.id,
          }))}
          label="Use Description From"
          formik={formik}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
      </Box>
    </Box>
  );
};

export default InsClaimCharges;
