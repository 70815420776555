import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getRevenueAction } from "../../../features/actions/revenueAction";
import { Box, Stack } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { getRevenueCodeBySearchTermAction } from "../../../features/actions/revenueAction";
import CustomModal from "../../../components/CustomModal";
import CustomPreview from "./../../../layout/CustomPreview";
import { revenueCodesCols } from "../../../utils/gridCols";
import SearchRevMaster from "./SearchRevMaster";

const Revenue = () => {
  const [openMasterList, setOpenMasterList] = useState(false);
  const { getRevenueData, loading } = useSelector((state) => state.revenue);
  console.log(getRevenueData, "getRevenueData");
  const navigate = useNavigate();

  const rows =
    getRevenueData && getRevenueData.result
      ? getRevenueData.result.map((item) => ({
          id: item.revenueCodeId,
          ...item,
        }))
      : [];

  return (
    <>
      <Box>
        <CustomPreview
          rows={rows}
          columns={revenueCodesCols}
          handleGetAll={getRevenueAction}
          handleBySearch={getRevenueCodeBySearchTermAction}
          moduleHeading={"Revenue"}
          loading={loading}
          searchLabel="Search by Codes, or Description"
          handleCell={(params) => navigate(`/revenues/update/${params.id}`)}
        >
          <Stack direction="row" alignItems="center">
            <CustomButton
              isBlue={true}
              isAdd={true}
              handleClick={() => navigate("/revenues/new")}
            >
              Add Revenue
            </CustomButton>
            <CustomButton
              margin={"5px"}
              isBlue={false}
              isAdd={true}
              handleClick={() => setOpenMasterList(true)}
            >
              Add From Master List
            </CustomButton>
          </Stack>
        </CustomPreview>
      </Box>

      <CustomModal
        open={openMasterList}
        handleClose={() => setOpenMasterList(false)}
        height={"20%"}
      >

        <SearchRevMaster handleClose={() => setOpenMasterList(false)} />

      </CustomModal>
    </>
  );
};

export default Revenue;