import { useFormik } from "formik";
import React, { useEffect,useState } from "react";
import { chargePanelInitVal } from "../../../utils/formikInitValues";
import { Box, Stack,Typography } from "@mui/material";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import ChargePanelInfo from "./ChargePanelInfo";
import { useDispatch, useSelector } from "react-redux";
import {  getChargePanelAction, updateChargePanelAction } from "../../../features/actions/chargePanelAction";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import CustomDialog from "../../../components/CustomDialog";

const UpdateChargePanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const {id} = useParams()
   const {getChargePanelData, loading} = useSelector(state => state.chargePanel) 
   const findPanel = getChargePanelData?.find(el => el.chargerPanelId === Number(id))
   console.log(findPanel, "findPanel")

  const formik = useFormik({
    initialValues: findPanel || chargePanelInitVal,
    enableReinitialize:true,
    onSubmit: (values) => {
      console.log(values, "charge panel values");
      try {
        dispatch(updateChargePanelAction({
            chargerPanelId: findPanel?.chargerPanelId,
            ...values
        }))
        toast.success("Charge panel updated successfully!")
        navigate("/charge-panel")
      }catch (err) {
        console.log(err, ": error in creating charge panel")
        
      }
    },
  });

  useEffect(() => {
    dispatch(getChargePanelAction())
  }, [])
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/charge-panel")
  };
  return (
    <>
    <Box margin="20px" sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
      <MainHeading mainheading="Update Charge Panel" />

      <Stack direction="row" alignItems="center" gap="10px">
        <CustomButton
          type="submit"
          formId="chargePanelInfo"
          isBlue={true}
          isSave={true}
        >
          {loading? "Updating..." : "Update"}
         
        </CustomButton>
        {/* <CustomButton isBlue={false} isCancel={true}>
          Cancel
        </CustomButton> */}
         <CustomButton isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>
      </Stack>

      <Box id="chargePanelInfo" component="form" onSubmit={formik.handleSubmit}>
        {
            loading ? <Loading /> :  <ChargePanelInfo formik={formik} />
        }
       
      </Box>
    </Box>
    <CustomDialog
    open={openCancelDialog}
    isWarning={true}
    handleClose={() => setOpenCancelDialog(false)}
    handleConfirm={handleConfirmCancel}
    handleCancel={() => setOpenCancelDialog(false)}
    confirmButtonTitle="OK"
    cancelButtonTitle="Cancel"
   >
    <Typography component="h3">Are you sure you want to cancel?</Typography>
   </CustomDialog>
   </>
  );
};

export default UpdateChargePanel;
