import React, { useCallback, useEffect, useState } from "react";
import { getData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import SummaryIcon from "../SummaryIcon";
import CustomDatePicker from "../CustomDatePicker";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import FileUpload from "./FileUpload";
import { getClaimByNumberAction } from "../../features/actions/claimAction";
import { useDispatch, useSelector } from "react-redux";
const ClaimSummary = ({ handleClose, formik }) => {
  const dispatch = useDispatch();
  const { singleClaimData } = useSelector((state) => state.claim);

  const [files, setFiles] = useState([]);

  const [expanded, setExpanded] = useState("panel1");
  const { claimNumber } = useParams();
  const handleAccordionChange = useCallback(
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    },
    [setExpanded]
  );
  // account summary
  // const getAccountSummary = async () => {
  //   console.log(Number(claimNo), "checkAccNo");
  //   try {
  //     const response = await getData(`${path}/ClaimSummary?ClaimNo=${claimNo}`);
  //     if (response.status === "Success") {

  //     }
  //   } catch (err) {
  //     console.log(err, "Getting Account Summary");
  //   }
  // };

  const getDocument = async () => {
    try {
      const response = await getData(
        `${path}/encryptFile/ViewClaimDocument?AccountNumber=${singleClaimData.patientAccountNo}&claimnumber=${claimNumber}`
      );
      console.log("singleClaimData323", response);
    } catch (error) { }
  };

  // const updateTotalAmount = useCallback(() => {
  //   let claimChargesDto = formik.values.claimChargesDto
  //   let totalAmount = 0;
  //   if (claimChargesDto.length > 0) {
  //     for (let i = 0; i < claimChargesDto.length; i++) {
  //       let val = claimChargesDto[i]?.unitPrice * claimChargesDto[i]?.units
  //       totalAmount = totalAmount + val
  //     }
  //     formik.setValues((prev) => {
  //       return {
  //         ...prev,
  //         claimSummaryDto: {
  //           ...prev.claimSummaryDto,
  //           totalAmount: totalAmount,
  //           balance: Number(totalAmount - prev?.claimSummaryDto?.insPayments - prev?.claimSummaryDto?.patPayment - prev?.claimSummaryDto?.adjustments),
  //           dateOfService: claimChargesDto[0]?.fromDate
  //         }
  //       }
  //     })
  //   }

  // }, [formik])


  // useEffect(() => {
  //   updateTotalAmount()
  // }, [formik.values.claimChargesDto])


  useEffect(() => {
    // dispatch(getClaimByNumberAction(Number(claimNumber)));
    // getAccountSummary();
    getDocument();
  }, []);

  const theme = useTheme();

  function handleMultipleSubmit(event) {
    event.preventDefault();
    const url = `${path}/encryptFile/UploadClaimDocument`;
    console.log("fiels", files);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    formData.set("ClaimNumber", claimNumber);
    formData.set("AccountNumber", singleClaimData.patientAccountNo);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
        toast.success("Document uploaded successfully");
      })
      .catch((error) => {
        toast.error("Server error");
        console.error("Error uploading files: ", error);
      });
    handleClose();
  }
  return (
    <Box>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          className="closeButton"
          style={{ background: "#002F7A", border: "1px solid #002F7A" }}
          size="small"
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: "24px" }} />
        </Button>
      </Stack>
      <Accordion
        elevation={0}
        disableGutters
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
        sx={{
          margin: "0px !important",
        }}
      >
        <AccordionSummary
          sx={{
            fontSize: "1.5rem",
            // background:'#216FED',
            // color:'white'

            background: theme.palette.accSummaryAccord.primary,
            color: theme.palette.btnSaveText.primary,
          }}
          expandIcon={
            <SummaryIcon
              sx={{ background: theme.palette.accordiansHeadingText.primary }}
            />
          }
        >
          Claim Summary
        </AccordionSummary>

        <AccordionDetails
          style={{ background: theme.palette.accordiansBodyBackground.primary }}
        >
          <Box
            sx={{
              maxHeight: "200px",
              overflowY: "scroll",
              padding: "12px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              "&::-webkit-scrollbar": {
                width: "4px",
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Patient:
              </Typography>
              {formik.values?.claimSummaryDto?.firstName && <Typography component="p" fontSize="1.4rem !important">
                {`${formik.values?.claimSummaryDto?.firstName} , ${formik.values?.claimSummaryDto?.lastName} (Acct # ${formik.values?.claimSummaryDto?.accountNo})` ||
                  ""}
              </Typography>}
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                DOB:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography component="p" fontSize="1.4rem !important">
                  {`${(formik.values?.claimSummaryDto?.dateOfBirth && new Date(
                    formik.values?.claimSummaryDto?.dateOfBirth
                  ).toDateString()) || ''}`}
                </Typography>
                {formik.values?.claimSummaryDto?.gender && <Typography
                  margin={"0px 5px 0px 40px"}
                  component="p"
                  fontSize="1.4rem !important"
                >
                  {formik.values?.claimSummaryDto?.gender === "Male"
                    ? "M"
                    : "F"}
                </Typography>}
              </Box>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Form Version:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {formik.values?.claimSummaryDto?.formType || ""}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Total Amount:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${formik.values?.claimSummaryDto?.totalAmount || 0}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Ins Payments:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${formik.values?.claimSummaryDto?.insPayments || 0}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Pat Payments:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${formik.values?.claimSummaryDto?.patPayment || 0}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Adjustments:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${formik.values?.claimSummaryDto?.adjustments || 0}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Balance:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${formik.values?.claimSummaryDto?.balance || 0}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Patient Credits:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${formik.values?.claimSummaryDto?.patientCredits || 0}
              </Typography>
            </Stack>

            {/* Follow-Up Date: */}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Patient Follow-Up Date:
              </Typography>
              {/* <Typography component="p" fontSize="1.4rem !important">
                $
                {claimSumaryData?.followUpDate === null
                  ? 0
                  : claimSumaryData?.followUpDate
                }
              </Typography> */}
              <CustomDatePicker
                name="claimSummaryDto.patientFollowUpDate"
                dateValue={formik.values.claimSummaryDto?.patientFollowUpDate}
                handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
                formik={formik}
              />
            </Stack>

            {/* Recall Date: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Patient Recall Date:
              </Typography>
              {/* <Typography component="p" fontSize="1.4rem !important">
                $
                {claimSumaryData?.recallDate === null
                  ? 0
                  : claimSumaryData?.recallDate

                }
              </Typography> */}
              <CustomDatePicker
                name="claimSummaryDto.patientRecallDate"
                dateValue={formik.values.claimSummaryDto?.patientRecallDate}
                handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
                formik={formik}
              />
            </Stack>

            {/* Date of Visit: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Date of Service:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {`${(formik.values.claimSummaryDto?.dateOfService !== null && new Date(
                  formik.values.claimSummaryDto?.dateOfService
                ).toDateString())}` || ""}
              </Typography>
            </Stack>

            {/* Date Entered: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Date Entered:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {`${(formik.values.claimSummaryDto?.dateEntered !== null && new Date(
                  formik.values.claimSummaryDto?.dateEntered
                ).toDateString())}` || ""}
              </Typography>
            </Stack>

            {/* last billed date: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Last Billed Date:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                {formik.values.claimSummaryDto && formik.values.claimSummaryDto.lastBilledDate !== null
                  ? new Date(formik.values.claimSummaryDto.lastBilledDate).toDateString()
                  : ""}
              </Typography>
            </Stack>

            {/*Last Statment: */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Last Statement:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${formik.values.claimSummaryDto?.lastStatement || 0}
              </Typography>
            </Stack>

            {/* Follow-Up Date: */}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Follow-Up Date:
              </Typography>
              {/* <Typography component="p" fontSize="1.4rem !important">
                $
                {claimSumaryData?.followUpDate === null
                  ? 0
                  : claimSumaryData?.followUpDate
                }
              </Typography> */}
              <CustomDatePicker
                name="claimSummaryDto.followUpDate"
                dateValue={formik.values.claimSummaryDto?.followUpDate}
                handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
                formik={formik}
              />
            </Stack>

            {/* Last Copay remaining: */}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="2px solid lightgrey"
              paddingBottom="6px"
            >
              <Typography component="h3" fontSize="1.4rem !important">
                Copay Remaining:
              </Typography>
              <Typography component="p" fontSize="1.4rem !important">
                ${formik.values.claimSummaryDto?.copayRemaining || 0}
              </Typography>
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        sx={{
          margin: "0px !important",
        }}
        onChange={handleAccordionChange("panel2")}
      >
        <AccordionSummary
          sx={{
            fontSize: "1.5rem",
            // background:'#216FED',
            // color:'white'
            background: theme.palette.accSummaryAccord.primary,
            color: theme.palette.btnSaveText.primary,
          }}
          expandIcon={<SummaryIcon />}
        >
          Estimate
        </AccordionSummary>
        <AccordionDetails
          style={{ background: theme.palette.accordiansBodyBackground.primary }}
        >
          <h5>
            Contact your Authorized Representative to enable Estimates, which
            allow you to generate Estimates for patient responsibility and send
            statements, collect copays on the Payment Portal, and print
            estimates compliant with the No Surprises Act.
          </h5>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        sx={{
          margin: "0px !important",
        }}
        onChange={handleAccordionChange("panel3")}
      >
        <AccordionSummary
          sx={{
            fontSize: "1.5rem",
            // background:'#216FED',
            // color:'white'
            background: theme.palette.accSummaryAccord.primary,
            color: theme.palette.btnSaveText.primary,
          }}
          expandIcon={<SummaryIcon />}
        >
          Patient Notes
        </AccordionSummary>
        <AccordionDetails
          style={{ background: theme.palette.accordiansBodyBackground.primary }}
        >
          <h5>Patient Notes Here!</h5>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleAccordionChange("panel4")}
        sx={{
          margin: "0px !important",
        }}
      >
        <AccordionSummary
          sx={{
            fontSize: "1.5rem",
            // background:'#216FED',
            // color:'white'
            background: theme.palette.accSummaryAccord.primary,
            color: theme.palette.btnSaveText.primary,
          }}
          expandIcon={<SummaryIcon />}
        >
          Follow Up Activity
        </AccordionSummary>
        <AccordionDetails
          style={{ background: theme.palette.accordiansBodyBackground.primary }}
        >
          <h5>Will Write Follow Up Activity Here!</h5>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleAccordionChange("panel5")}
        sx={{
          margin: "0px !important",
        }}
      >
        <AccordionSummary
          sx={{
            fontSize: "1.5rem",
            // background:'#216FED',
            // color:'white'
            background: theme.palette.accSummaryAccord.primary,
            color: theme.palette.btnSaveText.primary,
          }}
          expandIcon={<SummaryIcon />}
        >
          Alert
        </AccordionSummary>
        <AccordionDetails
          style={{ background: theme.palette.accordiansBodyBackground.primary }}
        >
          <h5>Add Alert</h5>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleAccordionChange("panel6")}
        sx={{
          margin: "0px !important",
        }}
      >
        <AccordionSummary
          sx={{
            fontSize: "1.5rem",
            // background:'#216FED',
            // color:'white'
            background: theme.palette.accSummaryAccord.primary,
            color: theme.palette.btnSaveText.primary,
          }}
          expandIcon={<SummaryIcon />}
        >
          Tasks
        </AccordionSummary>
        <AccordionDetails
          style={{ background: theme.palette.accordiansBodyBackground.primary }}
        >
          <h5>Create Task Here!</h5>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleAccordionChange("panel7")}
        sx={{
          margin: "0px !important",
        }}
      >
        <AccordionSummary
          sx={{
            fontSize: "1.5rem",

            // background:'#216FED',
            // color:'white'
            background: theme.palette.accSummaryAccord.primary,
            color: theme.palette.btnSaveText.primary,
          }}
          expandIcon={<SummaryIcon />}
        >
          Documents
        </AccordionSummary>
        <AccordionDetails
          style={{
            background: theme.palette.accordiansBodyBackground.primary,
            overflowY: "scroll",
          }}
        >
          <FileUpload
            files={files}
            setFiles={setFiles}
            handleMultipleSubmit={handleMultipleSubmit}
            multiple={true}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel8"}
        onChange={handleAccordionChange("panel8")}
        sx={{
          margin: "0px !important",
        }}
      >
        <AccordionSummary
          sx={{
            fontSize: "1.5rem",
            // background:'#216FED',
            // color:'white'
            background: theme.palette.accSummaryAccord.primary,
            color: theme.palette.btnSaveText.primary,
          }}
          expandIcon={<SummaryIcon />}
        >
          Payment
        </AccordionSummary>
        <AccordionDetails
          style={{ background: theme.palette.accordiansBodyBackground.primary }}
        >
          <h5>Payment Section Here!</h5>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ClaimSummary;
