import React, { useEffect } from "react";
import { Formik, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getICDProcedureCodeAction } from "../../../features/actions/icdProcedureAction";
import { updateICDProcedureAction } from "../../../features/actions/icdProcedureAction";
import { useNavigate, useParams } from "react-router-dom";
import { icdProcedureInitVals } from "../../../utils/formikInitValues";
import CustomButton from "../../../components/CustomButton";
import MainHeading from "../../../components/MainHeading";
import { Box,Typography } from "@mui/material";
import { toast } from "react-toastify";
import ICDProcedureCodesInfo from "./ICDProcedureCodesInfo";
import CustomDialog from "../../../components/CustomDialog";


const  UpdateICDProcedure = () => {
  const dispatch = useDispatch();
  // const handleCancel = () => {
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/icd-procedures");
  //   }
  // };
  const navigate = useNavigate();
  const { id } = useParams();
  const {  getIcdProcedureData, loading } = useSelector((state) => state.icdProcedure);
  console.log(getIcdProcedureData, "getICDProcedureData");
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
const iCDProcedureDataToEdit = getIcdProcedureData?.find(
  (item) => item.icdProcedureCodesId === Number(id)
);
console.log(iCDProcedureDataToEdit, "iCDProcedureDataToEdit")
const formik = useFormik({
initialValues:  iCDProcedureDataToEdit  || icdProcedureInitVals ,
onSubmit: (values, action) => {
  console.log(values, "checkICDProValues");
  dispatch(
    updateICDProcedureAction({
      icdProcedureCodesId: iCDProcedureDataToEdit?.icdProcedureCodesId ,
      ...values,
    })
  );
  navigate("/icd-procedures");
},
});
useEffect(() => {
try {
  dispatch(getICDProcedureCodeAction());
} catch (error) {
  throw error;
}
}, [dispatch]);
const handleCancel = () => {
  setOpenCancelDialog(true);
};

const handleConfirmCancel = () => {
  formik.resetForm();
  navigate("/icd-procedures")
};
  return (
    <>
    <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
    
        <MainHeading mainheading={"Update Facility"} />
      <form onSubmit={formik.handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >
          <CustomButton type="submit" isBlue={true} isEdit={true} >
            Update
          </CustomButton>
          {/* <CustomButton
            
            type="submit"
            isCancel={true}
            handleClick={handleCancel}
          >
            Cancel
          </CustomButton> */}
            <CustomButton   type="submit" isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>
        </Box>
        <ICDProcedureCodesInfo formik={formik}/>
      </form>
      </Box>
    </>
  );
};

export default UpdateICDProcedure;
