import React from "react";
import { Box, Typography } from "@mui/material";
const RevCode = () => {
  return (
    <Box sx={{display : 'flex' , justifyContent : 'center' , alignItems : 'center'}}>
      <Typography>No Item Found</Typography>
    </Box>
  );
};

export default RevCode;
