import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import CustomSelectBox2 from "../../../components/CustomSelectBox2.jsx";
import "../../../components/Custom-styling/CustomSelectBox.css";
import CustomField from "./../../../components/CustomField";
import CustomSearchField from "../../../components/CustomSearchField.jsx";
import CustomModal from "../../../components/CustomModal.jsx";
import MultiProvider from "./MultiProvider.jsx";
import MultiPractice from "./MultiPractice.jsx";
import CheckBox from "../../../components/CheckBox.jsx";
import MultiSelectCustomPreview from "../../../layout/MultiSelectCustomPreview.jsx";
import { practiceCols, providerCols } from "../../../utils/gridCols.js";
import { useSelector } from "react-redux";
import {
  getProviderAction,
  getProviderBySearchTermAction,
} from "../../../features/actions/providerAction.js";
import { useConfirm } from "material-ui-confirm";
import {
  getPracticeAction,
  getPracticeBySearchTermAction,
} from "../../../features/actions/practiceAction.js";
export const OutstandingChargesStatement = ({ formik, moduleName }) => {
  let confirm = useConfirm();
  const { getProviders, loading } = useSelector((state) => state.provider);
  const { getPractices } = useSelector((state) => state.practices);
  const practiceLoading = useSelector((state) => state.practices.loading);

  const theme = useTheme();
  const [chargesToIncludeList, setChargesToIncludeList] = useState([]);
  const [seperateStatementsList, setSeperateStatementsList] = useState([]);
  const [providerSelectedRow, setProviderSelectedRow] = useState([]);
  console.log()
  const [practiceSelectedRow, setPracticeSelectedRow] = useState([]);
  const [accountTypeOpt, setAccountTypeOpt] = useState([]);
  const [chargeStatusOpt, setChargeStatusOpt] = useState([]);
  console.log(chargeStatusOpt, "chargeStatusOpt");
  const [filterData, setFilterData] = useState([
    {
      filterId: 1,
      filterName: "Statement Amount",
      filterKey: "statementAmount",
      query: "Statement_Amount",
      options: [],
    },
    {
      filterId: 2,
      filterName: "Electronic Statement Sent",
      filterKey: "electStatement",
      query: "Electronic_Statement_Sent",
      options: [],
    },
    {
      filterId: 3,
      filterName: "Paper Statement Sent",
      filterKey: "paperStatement",
      query: "Paper_Statement_Sent",
      options: [],
    },
    {
      filterId: 4,
      filterName: "Total Statement Sent",
      filterKey: "totalStatement",
      query: "Total_Statement_Sent",
      options: [],
    },
    {
      filterId: 5,
      filterName: "Day Since Last Statement",
      filterKey: "daysLastStatement",
      query: "Days_Since_Last_Statement",
      options: [],
    },
  ]);
  const [openProviderMod, setOpenProviderMod] = useState(false);
  const [openPracticeMod, setOpenPracticeMod] = useState(false);
  useEffect(() => {
    (async function () {
      const listofFilters = [...filterData];
      try {
        for (const filter of listofFilters) {
          let endpoint = `${path}/ct-ComparisionFilters/ComparisionFilters?ModuleName=${moduleName}&ContentName=${filter.query}`;
          let { result } = await getData(endpoint);
          filter.options = result;
        }
        setFilterData(listofFilters);
      } catch (error) {}
    })();
  }, []);
  const dataFetchUrl = {
    arFilters: `/ct-arFilters`,
    chargesToInclude: `/ct-includedCharges`,
    seperateStatement: `/ct-seperateStatement`,
    accountType: "/ct-accountType",
    chargeStatus: `/ct-claimStatusModules/GetClaimStatusModulesByModuleName?ModuleName=${moduleName}`,
  };

  const fetchDataOptions = async (endpoint, setter) => {
    try {
      const response = await getData(`${path}${endpoint}`);
      setter(response.result);
    } catch (error) {
      console.log("Fetching Dropdown Option Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrl.chargesToInclude, setChargesToIncludeList);
    fetchDataOptions(dataFetchUrl.seperateStatement, setSeperateStatementsList);
    fetchDataOptions(dataFetchUrl.accountType, setAccountTypeOpt);
    fetchDataOptions(dataFetchUrl.chargeStatus, setChargeStatusOpt);
  }, []);

  const handleSelectChange = (e, el) => {
    let idKey = el.filterKey + "Id";
    formik.setValues((preVals) => ({
      ...preVals,
      [idKey]: Number(e.target.value),
    }));
  };

  return (
    <>
      <Box
        sx={{
          display: "grid",
          marginTop: "10px",
          padding: "0px 0px 20px 20px",
          borderBottom: "1px solid black",
          gap: "30px",
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(1, minmax(0, 1fr))",
          },
        }}
      >
        {filterData?.map((el) => (
          <>
            <Grid container spacing={2} alignItems="end">
              <Grid item md={5}>
                <FormControl fullWidth>
                  <label
                    className="customLabel"
                    style={{
                      color: theme.palette.Labeltext.primary,
                    }}
                  >
                    {el?.filterName}
                  </label>
                  <Select
                    sx={{
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiNativeSelect-icon": {
                        fontSize: "35px !important",
                      },
                      background: theme.palette.accordiansBackground.primary,
                      color: theme.palette.textMainHeading.primary,
                    }}
                    className="customSelectBox"
                    value={formik.values[el.filterKey + "Id"]}
                    type="text"
                    onChange={(event) => handleSelectChange(event, el)}
                    native
                  >
                    {el?.options?.map((opt) => (
                      <>
                        <option
                          style={{
                            background: theme.palette.fieldsDDown.primary,
                          }}
                          key={opt.filterId}
                          value={opt.filterId}
                        >
                          {opt.filtersName}
                        </option>
                      </>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {formik.values[el?.filterKey + "Id"] === 5 ? (
                <>
                  <Grid item md={3}>
                    <CustomField
                      type="number"
                      value={formik.values[el?.filterKey + "Value1"]}
                      name={el?.filterKey + "Value1"}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <CustomField
                      type="number"
                      value={formik.values[el?.filterKey + "Value2"]}
                      name={el?.filterKey + "Value2"}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                  </Grid>
                </>
              ) : formik.values[el?.filterKey + "Id"] === 1 ? (
                <Stack display="none"></Stack>
              ) : (
                <Grid item md={3}>
                  <CustomField
                    type="number"
                    value={formik.values[el?.filterKey + "Value1"]}
                    name={el?.filterKey + "Value1"}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </Grid>
              )}
            </Grid>
          </>
        ))}
        <FormControl fullWidth>
          <label
            className="customLabel"
            style={{
              color: theme.palette.Labeltext.primary,
            }}
          >
            Account Type
          </label>
          <MultiSelect
            options={accountTypeOpt?.map((el) => ({
              label: el?.accountType,
              value: el?.accountTypeId,
            }))}
            value={formik.values.accountTypeIds}
            onChange={(selectedOption) => {
              formik.setFieldValue("accountTypeIds", selectedOption);
            }}
            style={{
              control: (base) => ({
                ...base,
                backgroundColor: "red",
              }),
              option: (provided) => ({
                ...provided,
                color: "blue",
              }),
            }}
          />
        </FormControl>
      </Box>
      <Box sx={{ margin: "10px 0px" }}>
        <Typography sx={{ fontSize: "1.4rem" }}>Charges To Include</Typography>
        <Stack flexDirection={"column"} alignItems={"left"} marginLeft={"25px"}>
          {chargesToIncludeList.map((value) => {
            return (
              <FormControlLabel
                label={value.includedChargesName}
                control={
                  <Radio
                    value={value.includedChargesName}
                    variant="soft"
                    name="radio-buttons"
                    checked={
                      formik.values.chargesIncludeId ===
                      value?.includedChargesId
                    }
                    onChange={() =>
                      formik.setFieldValue(
                        "chargesIncludeId",
                        value?.includedChargesId
                      )
                    }
                  />
                }
              />
            );
          })}
          {formik.values.chargesIncludeId === 4 && (
            <FormControl fullWidth>
              <label
                className="customLabel"
                style={{
                  color: theme.palette.Labeltext.primary,
                }}
              >
                Charges Status
              </label>
              <MultiSelect
                options={accountTypeOpt}
                value={[]}
                //   onChange={}
                style={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "red",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "blue",
                  }),
                }}
              />
            </FormControl>
          )}
        </Stack>
      </Box>
      <Box>
        <FormControlLabel
          label={"Seperate statements"}
          control={
            <Checkbox
              value={"Seperate statements"}
              variant="soft"
              checked={formik.values.isSeparateStatement}
              onChange={() => {
                formik.setFieldValue(
                  "isSeparateStatement",
                  !formik.values?.isSeparateStatement
                );
                if (!formik.values.isSeparateStatement === true) {
                  formik.setFieldValue("separateStatementId", 1);
                } else {
                  formik.setFieldValue("separateStatementId", null);
                }
              }}
            />
          }
        />
        <Box sx={{ marginLeft: "20px" }}>
          {seperateStatementsList.map((value) => {
            console.log(value, "checkValue");
            return (
              <FormControlLabel
                label={value.seperateStatementName}
                control={
                  <Radio
                    value={value.seperateStatementName}
                    disabled={!formik.values.isSeparateStatement}
                    variant="soft"
                    name="radio-buttons"
                    checked={
                      formik.values.separateStatementId ===
                      value?.seperateStatementId
                    }
                    onChange={() => {
                      formik.setFieldValue(
                        "separateStatementId",
                        value?.seperateStatementId
                      );
                      if (formik.values.separateStatementId === 1) {
                        formik.setFieldValue("practiceSequenceNumbers", []);
                      } else if (formik.values.separateStatementId === 2) {
                        formik.setFieldValue("providerSequenceNumbers", []);
                      } else {
                        return;
                      }
                    }}
                  />
                }
              />
            );
          })}
          {formik.values.separateStatementId === 1 ? (
            <>
              <CustomSearchField
                label="Provider"
                fieldVal={formik.values.providerSequenceNumbers?.map(
                  (el) => el?.providerName
                )}
                handleModalOpen={() => setOpenProviderMod(true)}
                isRequired={true}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                name="providerSequenceNumbers"
                formik={formik}
                resetField={{
                  providerSequenceNumbers: [],
                }}
              />
            </>
          ) : formik.values.separateStatementId === 2 ? (
            <>
              <CustomSearchField
                label="Practices"
                fieldVal={formik.values.practiceSequenceNumbers?.map(
                  (el) => el?.practiceName
                )}
                handleModalOpen={() => setOpenPracticeMod(true)}
                isRequired={true}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                name="practiceSequenceNumbers"
                formik={formik}
                resetField={{
                  practiceSequenceNumbers: [],
                }}
              />
            </>
          ) : null}
        </Box>
        <Stack flexDirection={"column"} alignItems={"left"} marginLeft={"25px"}>
          <CheckBox
            label="Show the patient's last payment"
            CheckedValue={formik.values.showLastPayment === true}
            name="showLastPayment"
            handleChange={() =>
              formik.setFieldValue(
                "showLastPayment",
                !formik.values.showLastPayment
              )
            }
          />
          <CheckBox
            label="Do not include account credits on statements"
            CheckedValue={formik.values.accountCredits === true}
            name="accountCredits"
            handleChange={() =>
              formik.setFieldValue(
                "accountCredits",
                !formik.values.accountCredits
              )
            }
          />
        </Stack>
      </Box>

      {/* custom modals */}
      <CustomModal
        height="20px"
        heading="Providers"
        open={openProviderMod}
        handleClose={() => setOpenProviderMod(false)}
      >
        <MultiSelectCustomPreview
          rows={
            getProviders?.result?.providers.length > 1
              ? getProviders?.result?.providers?.map((el) => ({
                  id: el?.providerId,
                  ...el,
                }))
              : []
          }
          columns={providerCols}
          isModal={true}
          loading={loading}
          paginationDetail={getProviders}
          setState={setProviderSelectedRow}
          handleGetAll={getProviderAction}
          handleBySearch={getProviderBySearchTermAction}
          searchLabel="Search by Provider Name and Sequence No"
          state={providerSelectedRow}
          handleCell={(selectedRows) => {
            let setRowData = selectedRows?.map((el) => ({
              sequenceNumber: el?.providerSequenceNo,
              providerName: `${el?.providerFirstName} ${
                el?.providerLastName || ""
              }`,
            }));
            formik.setValues((preVals) => ({
              ...preVals,
              providerSequenceNumbers: [...setRowData],
            }));
            setOpenProviderMod(false);
          }}
        />
      </CustomModal>
      {/* practice */}
      <CustomModal
        height="20px"
        heading="Practices"
        open={openPracticeMod}
        handleClose={() => setOpenPracticeMod(false)}
      >
        {/* <MultiPractice
          formik={formik}
          handleClose={() => setOpenPracticeMod(false)}
        /> */}
        <MultiSelectCustomPreview
          rows={
            getPractices?.result?.practice.length > 0
              ? getPractices?.result?.practice?.map((el) => ({
                  id: el?.practiceId,
                  ...el,
                }))
              : []
          }
          columns={practiceCols}
          isModal={true}
          loading={practiceLoading}
          paginationDetail={getPractices}
          setState={setPracticeSelectedRow}
          handleGetAll={getPracticeAction}
          handleBySearch={getPracticeBySearchTermAction}
          searchLabel="Search by Practice Name and Sequence No"
          state={practiceSelectedRow}
          handleCell={(selectedRows) => {
            let setRowData = selectedRows?.map((el) => ({
              sequenceNumber: el?.sequenceNo,
              practiceName: el?.practiceName,
            }));
            formik.setValues((preVals) => ({
              ...preVals,
              practiceSequenceNumbers: [...setRowData],
            }));
            setOpenPracticeMod(false);
          }}
        />
      </CustomModal>
    </>
  );
};
