import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

export const getPayerInfoAction = createAsyncThunk("getPayerInfo", async () => {
  try {
    const response = await getData(`${path}/payerInfo`);
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});
