import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CustomExpandIcon from "./CustomExpandIcon";

const CustomAccordion = ({
  heading,
  children,
  defaultExpanded,
  maxHeight,
  hasErrors,
}) => {
  const theme = useTheme();

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        // sx={{ backgroundColor: "#FAFAFA" }}
        sx={{
          background: theme.palette.accordiansBackground.primary,
          border: hasErrors ?  theme.palette.errorFieldsBorder.primary : "none",
        }}
        expandIcon={
          <CustomExpandIcon
            sx={{ background: theme.palette.accordiansHeadingText.primary }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          className="accordianSummaryHeading"
          sx={{ color: theme.palette.accordiansHeadingText.primary }}
          component="h3"
          fontWeight="700"
        >
          {heading}
        </Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          maxHeight: maxHeight,
          overflowY: maxHeight && "scroll",
          background: theme.palette.accordiansBodyBackground.primary,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
