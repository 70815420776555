// import { useTheme } from "@mui/material";
// import React from "react";
// import "./Custom-styling/CustomField.css";

// const CustomField = ({
//   type,
//   label,
//   value,
//   handleChange,
//   disable,
//   handleBlur,
//   name,
//   error,
//   touched,
//   isRequired,
//   isDecimal,
// }) => {
//   const formattedValue = isDecimal ? parseFloat(value).toFixed(2) : value;
//   const hasError = isRequired && touched[name] && error[name];
//   const theme = useTheme();
//   return (
//     <div>
//       <label
//         className="customLabel"
//         style={{color: theme.palette.Labeltext.primary}}
//       >
//         {label}
//       </label>

//       <div
//         style={{
//           pointerEvents: disable && "none",
//           opacity: disable && "0.6",
//         }}
//       >
//         <input
//           style={{
//             background: theme.palette.customFieldsBackground.primary,
//             color: theme.palette.fieldsText.primary,
//             border: hasError
//             ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
//             : `1px solid ${theme.palette.fieldsBorder.primary}`,
//           }}
//           className="customField"
//           type={type}
//           value={formattedValue}
//           onChange={handleChange}
//           onBlur={handleBlur}
//           name={name}
//           disabled={disable}
//           spellCheck={false}
//           min={0}
//         />
//       </div>
//     </div>
//   );
// };

// export default CustomField;


import { useTheme } from "@mui/material";
import React from "react";
import "./Custom-styling/CustomField.css";

const CustomField = ({
  type,
  label,
  value,
  handleChange,
  disable,
  handleBlur,
  name,
  error,
  touched,
  isRequired,
  isDecimal,
  isNested = false
}) => {
  const theme = useTheme();


  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  // adding variable for nasted form field
  const fieldName = isNested ? name.split('.').shift() : name;
// console.log(fieldName,"danish");
  const formattedValue = isDecimal ? parseFloat(value).toFixed(2) : value;
  const hasError = isRequired && touched[fieldName] && error[fieldName];
 


  const onChange = (e) => {
    
    const capitalizedValue = capitalizeWords(e.target.value);
    handleChange({ target: { name, value: capitalizedValue } });
  };
  return (
    <div>
      <label
        className="customLabel"
        style={{color: theme.palette.Labeltext.primary}}
      >
        {label}
      </label>

      <div
        style={{
          pointerEvents: disable && "none",
          opacity: disable && "0.6",
        }}
      >
        <input
          style={{
            background: theme.palette.customFieldsBackground.primary,
            color: theme.palette.fieldsText.primary,
            border: hasError
              ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
              : `1px solid ${theme.palette.fieldsBorder.primary}`,
          }}
          className="customField"
          type={type}
          value={formattedValue}
          onChange={onChange}
          onBlur={handleBlur}
          name={fieldName}
          disabled={disable}
          spellCheck={false}
          min={0}
        />
      
      </div>
    </div>
  );
};

export default CustomField;

