import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CustomField from "../../../components/CustomField";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomSelectBox from "../../../components/CustomSelectBox";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
const UserForm = ({ formik, isCreate }) => {
  const [userRoles, setUserRoles] = useState([]);
  const fetchRoles = async () => {
    try {
      let response = await getData(`${path}/authenticate/roles`);
      setUserRoles(response.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchRoles();
  }, []);
  return (
    <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(1, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          label={"Name"}
          value={formik.values.username}
          isRequired={true}
          error={formik.errors}
          touched={formik.touched}
          name="username"
          handleChange={(e)=>{
             formik.setFieldValue("username" , e.target.value.replaceAll(" " , ""))
          }}
          handleBlur={formik.handleBlur}
          type={"text"}
        />
      </Box>
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(1, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          value={formik.values.email}
          isRequired={true}
          error={formik.errors}
          touched={formik.touched}
          name="email"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          label={"Email"}
          type={"email"}
        />
      </Box>

      {isCreate && (
        <>
          {" "}
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(1, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              label={"Password"}
              type={"text"}
              value={formik.values.password}
              name="password"
              isRequired={true}
          error={formik.errors}
          touched={formik.touched}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
          </Box>
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(1, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              value={formik.values.confirmPassword}
              name="confirmPassword"
              handleChange={formik.handleChange}
              isRequired={true}
          error={formik.errors}
          touched={formik.touched}
              handleBlur={formik.handleBlur}
              label={"Confirm Password"}
              type={"text"}
            />
          </Box>{" "}
        </>
      )}
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(1, minmax(0, 1fr))",
          },
        }}
      >
        <CustomSelectBox
          name="roleName"
          value={formik.values.roleName}
          dropdownOptions={userRoles?.map((opt) => ({
            value: opt.name,
            id: opt.name,
          }))}
          label="User Type"
          handleChange={(e) => {
            console.log("eeeff" , e)
            formik.setFieldValue("roleName", e.target.value);
          }}
          handleBlur={formik.handleBlur}
          formik={formik}
        />
      </Box>
    </Box>
  );
};

export default UserForm;
