import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography ,Box, Stack, Button} from '@mui/material';

const DoctorsAppointment = () => {
  return (
    <>
 <Card className='appointmentCard' sx={{ width:'100%',height:400 }}>
 <CardContent>
 <Typography  fontWeight={'600'} className='todayPatientHead'>Doctors appointments schedule</Typography>
 <Box  className='docBox'>
 <Typography  fontWeight={'500'} >Dr.Capstain</Typography>
</Box>
 <Box sx={{background:'#EDF2F6'}}>
  <Stack direction='row'>
    <Button></Button>
  </Stack>
 
 </Box>
  </CardContent>
      </Card>
    </>
  )
}

export default DoctorsAppointment