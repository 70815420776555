import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Stack, Typography,useTheme } from "@mui/material";
import React from "react";
import { useState } from "react";
import IntermediateCategory from "./IntermediateCategory";
import '../../../../components/Custom-styling/Taxonomy.css';

const MajorCategory = ({
  majorOption,
  selectedCategory,
  setSelectedCategory,
  setSelectedCode,
  selectedCode,
  setTaxonomyName,


}) => {
  const theme=useTheme()
  console.log(majorOption, "majorOptions");
  const [isOpen, setIsOpen] = useState(false);

 
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box margin={"20px 0"}>
      <Box display={"flex"} alignItems={"center"}>
        <Stack onClick={toggleOpen} alignItems={"center"} flexDirection={"row"}>
          {isOpen ? (
            <ArrowDropDownIcon sx={{ fontSize: "2.2rem" ,color:'#2F78EE'}} />
          ) : (
            <ArrowRightIcon sx={{ fontSize: "2.2rem" , color:theme.palette.fieldsText.primary,}} />
          )}
        </Stack>
        <Typography


          letterSpacing={"1.24px"}
          sx={{ cursor: "default" , cursor: 'pointer',
          color:theme.palette.fieldsText.primary,

         
        }}
          variant="h4"
          component={"h3"}
          fontWeight={'700'}
        >
          {majorOption.taxonomyMajorCategoryName}
        </Typography>

      </Box>

      {isOpen &&
        majorOption.taxonomyIntermediateCategoryDtos?.map(
          (intermediateCat, index) => (
            <IntermediateCategory
              key={index}
              intermediateOptions={intermediateCat}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setSelectedCode={setSelectedCode}
              selectedCode={selectedCode}
              setTaxonomyName={setTaxonomyName}
            />
          )
        )}
    </Box>
  );
};

export default MajorCategory;







// ... (previous imports and code)

// const MajorCategory = ({
//   majorOption,
//   selectedCategory,
//   setSelectedCategory,
//   setSelectedCode,
//   selectedCode,
//   setTaxonomyName,
// }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const [isClicked, setIsClicked] = useState(false);
//   const [isHovered, setIsHovered] = useState(false);

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   const handleClick = () => {
//     setIsClicked(!isClicked);
//   };

//   const toggleOpen = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <Box margin={"20px 0"}>
//       <Box display={"flex"} alignItems={"center"}>
//         <Stack onClick={toggleOpen} alignItems={"center"} flexDirection={"row"}>
//           {isOpen ? (
//             <ArrowDropDownIcon sx={{ fontSize: "2.2rem" }} />
//           ) : (
//             <ArrowRightIcon sx={{ fontSize: "2.2rem" }} />
//           )}
//         </Stack>
//         <Typography
//           onMouseEnter={handleMouseEnter}
//           onMouseLeave={handleMouseLeave}
//           onClick={handleClick}
//           letterSpacing={"1.24px"}
//           sx={{
//             cursor: "default",
//             cursor: isClicked ? "default" : "pointer",
//             color: isOpen ? "green" : "blue",
//           }}
//           variant="h4"
//           component={"h3"}
//           fontWeight={"700"}
//         >
//           {majorOption.taxonomyMajorCategoryName}
//         </Typography>
//       </Box>

//       {isOpen &&
//         majorOption.taxonomyIntermediateCategoryDtos?.map(
//           (intermediateCat, index) => (
//             <IntermediateCategory
//               key={index}
//               intermediateOptions={intermediateCat}
//               selectedCategory={selectedCategory}
//               setSelectedCategory={setSelectedCategory}
//               setSelectedCode={setSelectedCode}
//               selectedCode={selectedCode}
//               setTaxonomyName={setTaxonomyName}
//             />
//           )
//         )}
//     </Box>
//   );
// };

// export default MajorCategory;
