import { FormControl, Grid,useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomField from "../../../components/CustomField";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import TextArea from "../../../components/TextArea";
const AdjustmentInfo = ({ formik }) => {
  const [adjustmentTypeOpt, setAdjustmentTypeOpt] = useState([]);
  const theme=useTheme()

  const getAdjustmentTypes = async () => {
    try {
      const response = await getData(`${path}/ct-adjustmentCodeType`);
      console.log(response, "check response");
      if (response) {
        setAdjustmentTypeOpt(response.result);
      }
    } catch (err) {
      console.log(err, "fetching adjustment codes err:");
      throw err;
    }
  };
  useEffect(() => {
    getAdjustmentTypes();
  }, []);
  return (
    <Grid container spacing={2} rowGap={2} marginTop="15px">
      <Grid item md={6}>
        <CustomField
          label="Code"
          isRequired={true}
          error={formik.errors}
          touched={formik.touched}
          type="text"
          value={formik.values.adjustmentCode}
          name="adjustmentCode"
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
      </Grid>
      <Grid item md={6}>
        <CustomSelectBox2
          label="Adjust Type"
          value={formik.values.adjustmentCodeTypeId}
          name="adjustmentCodeTypeId"
          formik={formik}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          dropdownOptions={adjustmentTypeOpt.map((opt) => ({
            id: opt.adjustmentCodeTypeId,
            value: opt.adjustmentCodeType,
          }))}
        />
      </Grid>

      <Grid item md={12}>
        <TextArea
          placeholder="Description"
          style={{ fontSize: "1.3rem" }}
          minRows={5}
          value={formik.values.description}
          name="description"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
        {formik.errors["description"] && formik.touched["description"] && (
          <div
            style={{
              color: "red",
              // border: "1px solid red",
              border:`2px solid ${theme.palette.errorFieldsBorder.primary}`,
              fontSize: "1.3rem",
              marginTop: "8px",
              display: "block",
            }}
          >
            {formik.errors["description"]}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default AdjustmentInfo;
