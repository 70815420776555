import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { statusControlAction } from "../actions/statusControlAction";

const initialState = {
  searchStatusControl: {},
  error: null,
  loading: false,
};
const statusControlSlice = createSlice({
  name: "statusControl",
  initialState: initialState,

  extraReducers: {
    [statusControlAction.pending]: (state) => {
      state.loading = true;
    },
    [statusControlAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createRevenueData = action.payload;
    //   toast.success("Revenue created successfully!");
    },
    [statusControlAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    //   toast.error("Revenue creation failed");
    }}})

    export default statusControlSlice.reducer