import * as yup from "yup";
// import { parse, isDate } from "date-fns";
// import {
//   createFieldsValidation,
//   createSelectBoxValidation,
// } from "./createValidation";

const minLength = (lengthNum) => {
  return `Minimum ${lengthNum} characters are required!`;
};

const maxLength = (lengthNum) => {
  return `Maximum ${lengthNum} characters are required!`;
};

const requireMsg = (field) => {
  return `${field} is Required!`;
};

export const patientSchema = yup.object().shape({
  // fields
  firstName: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
    .min(3, minLength(3))
    .max(20, maxLength())
    .required(requireMsg("First Name")),
  lastName: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
    .min(3, minLength(3))
    .max(20, maxLength(20))
    .required(requireMsg("Last Name")),
  address: yup.string().trim().required(requireMsg("Address")),
  zipCode: yup.string().trim().required(requireMsg("Zipcode")),
  cellPhone: yup.number().nullable().required(requireMsg("Cellphone")),
  // selectboxes
  genderIdentityStatusId: yup
    .number()
    .nullable()
    .required("Gender is required"),
  countryId: yup.number().nullable().required("Country is required"),
  stateId: yup.number().nullable().required("State is required"),
  cityId: yup.number().nullable().required("City is required"),
  dateOfBirth: yup
    .date("Invalid Date")
    .nullable()
    .required("Date of Birth is required"),
});


export const insuranceSchema = yup.object().shape({
  insuredDetail: yup.object().shape({
    insuredFirstName: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z]+$/, "Only alphabets are allowed")
    .min(3, minLength(3))
    .max(20, maxLength(20))
    .required(requireMsg("First Name")),
    insuredLastName:yup.string().required(requireMsg("Last Name")),
    insuredDateOfBirth:yup.date("Invalid date").required("Date Of Birth"),
    insuredZipCode:yup.string().required(requireMsg("Zip code")),
    
  }),
  payerDetailVals:yup.object().shape({
    payerInfoMemberId:yup.string().required(requireMsg("Member Id")),
    payerInfoPayerName:yup.string().required(requireMsg("search field "))
  })

});

export const remittanceSchema = yup.object().shape({
  remittanceCodeName: yup.string().required(requireMsg("Code Name")),
  remittanceTypeId: yup.number().nullable().required(requireMsg("Type")),
  remittanceInfoLevelId: yup
    .number()
    .nullable()
    .required(requireMsg("Information Level")),
});

export const paymentSchemaForPatient = yup.object().shape({
  paymentBy: yup.string().required(requireMsg("Payment By")),
  paymentAmount: yup
    .number()
    .nullable()
    .typeError(requireMsg("Amount"))
    .required(requireMsg("Amount"))
    .min(1, "Amount must be greater than 0"),
  paymentTypeId: yup.number().nullable().required(requireMsg("Payment Type")),
  checkNumber: yup.string().nullable().required("Enter Check #"),
});

export const paymentSchemaForInsurance = yup.object().shape({
  paymentBy: yup.string().required(requireMsg("Payment By")),
  paymentFromName: yup.string().required(requireMsg("Payment From")),
  paymentAmount: yup
    .number()
    .nullable()
    .typeError(requireMsg("Amount"))
    .required(requireMsg("Amount"))
    .min(1, "Amount must be greater than 0"),
  checkNumber: yup.string().nullable().required("Enter Check #"),
  // paymentDate: yup

  // .date('Invalid Date')
  // .nullable()
  // .required(requireMsg("Date")),
  // paymentModeId: yup.number().nullable().required(requireMsg("Mode")),
});

export const practiceSchema = yup.object().shape({
  practiceName: yup.string().required(requireMsg("Name")),
  practiceNpi: yup.number().nullable().required(requireMsg("NPI")),
  taxonomySpecialty: yup.string().nullable().required(requireMsg("Taxonomy")),
  primaryAddress: yup.string().required(requireMsg("Address")),
  primaryStateId: yup.number().nullable().required(requireMsg("State")),
  primaryCityId: yup.number().nullable().required(requireMsg("City")),
  primaryZipCode: yup.string().required(requireMsg("ZIP Code")),
  primaryPhone: yup.number().nullable().required(requireMsg("Phone No")),
});

export const providerSchema = yup.object().shape({
  providerTypeId: yup.number(),
  providerFirstName: yup.string().required(requireMsg("First Name")),
  providerLastName: yup.string().when("providerTypeId", {
    is: 1,
    then: yup.string().nullable().required("Last Name is required"),
    otherwise: yup.string().nullable(),
  }),
  providerNPINo: yup.string().required(requireMsg("NPI")),
  practiceName: yup.string().required(requireMsg("Practice")),
  providerTaxonomySpecialtyCode: yup
    .string()
    .nullable()
    .required(requireMsg("Taxonomy")),
  id_NoValue: yup.string().nullable().required(requireMsg("#")),
  claimBillingId: yup.number().nullable().required(requireMsg("Bill")),
});

export const facilitySchema = yup.object().shape({
  facilityName: yup.string().nullable().required(requireMsg("Name")),
  facilityAddress: yup.string().nullable().required(requireMsg("Address")),
  facilityStateId: yup.number().nullable().required(requireMsg("State")),
  facilityCityId: yup.number().nullable().required(requireMsg("City")),
  facilityZipCode: yup.string().required(requireMsg("ZIP Code")),
});

export const procedureSchema = yup.object().shape({
  procedureCode: yup.string().nullable().required(requireMsg("Code")),
  procedureDescription: yup
    .string()
    .nullable()
    .required(requireMsg("Description")),
});

export const diagnosisSchema = yup.object().shape({
  diagnosisCode: yup.string().nullable().required(requireMsg("Code")),
  diagnosisDescription: yup
    .string()
    .nullable()
    .required(requireMsg("Description")),
});

export const adjustmentSchema = yup.object().shape({
  adjustmentCode: yup.string().nullable().required(requireMsg("Code")),
  description: yup.string().nullable().required(requireMsg("Description")),
});

export const revenueSchema = yup.object().shape({
  revenueCode: yup.string().nullable().required(requireMsg("Code")),
  revenueCodeDescription: yup
    .string()
    .nullable()
    .required(requireMsg("Description")),
});

export const inventoryScehma = yup.object().shape({
  code: yup.string().nullable().required(requireMsg("Code")),
  // alertDescription : yup.string().nullable().required(requireMsg("Alert Description")),
});

export const claimSchema = yup.object().shape({
  patientFirstName: yup.string().nullable().required(),
  billingProviderFirstName: yup.string().nullable().required(),
  renderingProviderFirstName: yup.string().nullable().required(),
  statementCoversFromDate: yup.date().when("claimTypeId", {
    is: 2,
    then: yup.date().nullable().required(),
    otherwise: yup.date().nullable(),
  }),
  typeOfBill: yup.string().when("claimTypeId", {
    is: 2,
    then: yup.string().nullable().required(),
    otherwise: yup.string().nullable(),
  }),
  statementCoversToDate: yup.date().when("claimTypeId", {
    is: 2,
    then: yup.date().nullable().required(),
    otherwise: yup.date().nullable(),
  }),
  admissionTypeId: yup.number().when("claimTypeId", {
    is: 2,
    then: yup.number().nullable().required(),
    otherwise: yup.number().nullable(),
  }),
  patientStatusId: yup.number().when("claimTypeId", {
    is: 2,
    then: yup.number().nullable().required(),
    otherwise: yup.number().nullable(),
  }),
  principalDiagnosisId: yup.number().when("claimTypeId", {
    is: 2,
    then: yup.number().nullable().required(),
    otherwise: yup.number().nullable(),
  }),
});

export const payerSchema = yup.object().shape({
  payerName: yup.string().nullable().required(requireMsg("Payer Name")),
  payerAddress: yup.string().nullable().required(requireMsg("Address")),
  payerZipCode: yup.string().nullable().required(requireMsg("Zip Code")),
  payerStateId: yup.number().nullable().required(requireMsg("Member Id")),
  payerCityId: yup.number().nullable().required(requireMsg("Member Id")),
});
export const icdProcedureSchema = yup.object().shape({
  icdCode: yup.string().nullable().required(requireMsg("Codes")),
  description: yup.string().nullable().required(requireMsg("Description")),
});

export const proceduresSchemaForProfessionalClaim = yup.object().shape({
  procedureCode: yup.string().nullable().required(requireMsg("Code")),
  posCode: yup.string().nullable().required(requireMsg("Pos Code")),
  tosCode: yup.string().nullable().required(requireMsg("Tos Code")),
  claimStatusId: yup.number().nullable().required("Claim Status is required"),
});

export const proceduresSchemaForInsitutionalClaim = yup.object().shape({
  procedureCode: yup.string().nullable().required(requireMsg("Code")),
});

export const debitSchemaForDebit = yup.object().shape({
  debitCodeId: yup.number().nullable().required(),
  providerId: yup.number().nullable().required(),
  amount: yup.number().min(1, "").required(),
  debitDate: yup.date().nullable().required(),
});
