import { createSlice } from "@reduxjs/toolkit";
import {
  checkDuplicatePatAction,
  getPatientAction,
  getPatientByAccountNoAction,
  getPatientClaimsAction,
  getSearchedPatient,
  getSinglePatientData,
  newPatientAction,
  updatePatientAction,
} from "../actions/createPatientAction";
import { toast } from "react-toastify";

const initialState = {
  patientData: {},
  getAllPatients: [],
  singlePatientData: {},
  patientClaimsData: [],
  duplicateData:[],
  loading: false,
  singlePatientLoading: false,
  duplicateLoading:false,
  error: null,
};
const patientSlice = createSlice({
  name: "patient",
  initialState: initialState,
  extraReducers: {
    [newPatientAction.pending]: (state) => {
      state.loading = true;
    },
    [newPatientAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.patientData = action.payload;
      toast.success("Patient Has Been Created Successfully!");
    },
    [newPatientAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Patient Not Successfully Created");
    },
    [getPatientByAccountNoAction.pending]: (state) => {
      state.singlePatientLoading = true;
    },
    [getPatientByAccountNoAction.fulfilled]: (state, action) => {
      state.singlePatientLoading = false;
      state.singlePatientData = action.payload;
    },
    [getPatientByAccountNoAction.rejected]: (state, action) => {
      state.singlePatientLoading = false;
      state.error = action.payload;
    },
    [getPatientAction.pending]: (state) => {
      state.loading = true;
    },
    [getPatientAction.fulfilled]: (state, action) => {
      state.getAllPatients = action.payload;
      state.loading = false;
    },
    [getPatientAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getPatientClaimsAction.pending]: (state) => {
      state.loading = true;
    },
    [getPatientClaimsAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.patientClaimsData = action.payload;
    },
    [getPatientClaimsAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // [getSinglePatientData.pending]: (state) => {
    //   state.loading = true;
    // },
    // [getSinglePatientData.fulfilled]: (state, action) => {
    //   state.singlePatientData = action.payload;
    //   state.loading = false;
    // },
    // [getSinglePatientData.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
    [updatePatientAction.pending]: (state) => {
      state.loading = true;
    },
    // Update the patient data in the list
    [updatePatientAction.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success("Patient Has Been Updated Successfully!");
    },

    [updatePatientAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Patient Updation Failed!");
    },
    [checkDuplicatePatAction.pending]: (state) => {
      state.duplicateLoading = true;
    },
    // Update the patient data in the list
    [checkDuplicatePatAction.fulfilled]: (state, action) => {
      state.duplicateLoading = false;
      state.duplicateData = action.payload;
      // toast.success("Patient Has Been Updated Successfully!");
    },

    [checkDuplicatePatAction.rejected]: (state, action) => {
      state.duplicateLoading = false;
      state.error = action.payload;
      // toast.error("Patient Updation Failed!");
    },
  },
});

export default patientSlice.reducer;
