// import { createContext, useState, useMemo } from "react";
// import { createTheme } from "@mui/material/styles";

// // color design tokens export
// export const tokens = (mode) => ({
//   ...(mode === "dark"
//     ? {
//         grey: {
//           100: "#e0e0e0",
//           200: "#c2c2c2",
//           300: "#a3a3a3",
//           400: "#858585",
//           500: "#666666",
//           600: "#525252",
//           700: "#3d3d3d",
//           800: "#292929",
//           900: "#141414",
//         },
//         primary: {
//           100: "#d0d1d5",
//           200: "#a1a4ab",
//           300: "#727681",
//           400: "#1F2A40",
//           500: "#141b2d",
//           600: "#101624",
//           700: "#0c101b",
//           800: "#080b12",
//           900: "#040509",
//         },
//         greenAccent: {
//           100: "#dbf5ee",
//           200: "#b7ebde",
//           300: "#94e2cd",
//           400: "#70d8bd",
//           500: "#4cceac",
//           600: "#3da58a",
//           700: "#2e7c67",
//           800: "#1e5245",
//           900: "#0f2922",
//         },
//         redAccent: {
//           100: "#f8dcdb",
//           200: "#f1b9b7",
//           300: "#e99592",
//           400: "#e2726e",
//           500: "#db4f4a",
//           600: "#af3f3b",
//           700: "#832f2c",
//           800: "#58201e",
//           900: "#2c100f",
//         },
//         blueAccent: {
//           100: "#e1e2fe",
//           200: "#c3c6fd",
//           300: "#a4a9fc",
//           400: "#868dfb",
//           500: "#6870fa",
//           600: "#535ac8",
//           700: "#3e4396",
//           800: "#2a2d64",
//           900: "#151632",
//           1000: "#216FED"
//         },
//       }
//     : {
//         grey: {
//           100: "#141414",
//           200: "#292929",
//           300: "#3d3d3d",
//           400: "#525252",
//           500: "#666666",
//           600: "#858585",
//           700: "#a3a3a3",
//           800: "#c2c2c2",
//           900: "#e0e0e0",
//         },
//         primary: {
//           100: "#040509",
//           200: "#080b12",
//           300: "#0c101b",
//           400: "#f2f0f0", // manually changed
//           500: "#141b2d",
//           600: "#1F2A40",
//           700: "#727681",
//           800: "#a1a4ab",
//           900: "#d0d1d5",
//         },
//         greenAccent: {
//           100: "#0f2922",
//           200: "#1e5245",
//           300: "#2e7c67",
//           400: "#3da58a",
//           500: "#4cceac",
//           600: "#70d8bd",
//           700: "#94e2cd",
//           800: "#b7ebde",
//           900: "#dbf5ee",
//         },
//         redAccent: {
//           100: "#2c100f",
//           200: "#58201e",
//           300: "#832f2c",
//           400: "#af3f3b",
//           500: "#db4f4a",
//           600: "#e2726e",
//           700: "#e99592",
//           800: "#f1b9b7",
//           900: "#f8dcdb",
//         },
//         blueAccent: {
//           100: "#151632",
//           200: "#2a2d64",
//           300: "#3e4396",
//           400: "#535ac8",
//           500: "#6870fa",
//           600: "#868dfb",
//           700: "#a4a9fc",
//           800: "#c3c6fd",
//           900: "#e1e2fe",
//         },
//       }),
// });

// // mui theme settings
// export const themeSettings = (mode) => {
//   const colors = tokens(mode);
//   return {
//     palette: {
//       mode: mode,
//       ...(mode === "dark"
//         ? {
//             // palette values for dark mode
//             primary: {
//               main: colors.primary[500],
//             },
//             secondary: {
//               main: colors.greenAccent[500],
//             },
//             neutral: {
//               dark: colors.grey[700],
//               main: colors.grey[500],
//               light: colors.grey[100],
//             },
//             background: {
//               default: colors.primary[500],
//             },
//           }
//         : {
//             // palette values for light mode
//             primary: {
//               main: colors.primary[100],
//             },
//             secondary: {
//               main: colors.greenAccent[500],
//             },
//             neutral: {
//               dark: colors.grey[700],
//               main: colors.grey[500],
//               light: colors.grey[100],
//             },
//             background: {
//               default: "#fcfcfc",
//             },
//           }),
//     },
//     typography: {
//       fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//       fontSize: 12,
//       h1: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 40,
//       },
//       h2: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 32,
//       },
//       h3: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 24,
//       },
//       h4: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 20,
//       },
//       h5: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 16,
//       },
//       h6: {
//         fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
//         fontSize: 14,
//       },
//     },
//   };
// };

// // context for color mode
// export const ColorModeContext = createContext({
//   toggleColorMode: () => {},
// });

// export const useMode = () => {
//   const [mode, setMode] = useState("light");

//   const colorMode = useMemo(
//     () => ({
//       toggleColorMode: () =>
//         setMode((prev) => (prev === "light" ? "dark" : "light")),
//     }),
//     []
//   );

//   const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
//   return [theme, colorMode];
// };

import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#999393",
          300: "#2A4C72",
          400: "#202F4B",
          500: "#2E4469",
          600: "#2E4469",
          700: "#384A6A",
          800: "#1F314F",
          900: "#2E4469",
        },

        blueAccent: {
          100: "#31B4FF",
          200: "#b8b4b4",
          300: "#4a5f82",
          400: "#5573A5",
          500: "#2A4C72",
          600: "#202F4B",
          700: "linear-gradient(92.76deg, #3582B5 2.72%, #3A6698 98.14%)",
          800: "#1F314F",
          900: "#49628C",
        },
        white: {
          100: "#FFF",
          200: "#D3D3D3",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#D3D3D3",
          300: "#8E8E8E",
          400: "#373737",
          500: "#666666",
          600: "#ced4da",
          700: "#a3a3a3",
          800: "#1B1C1E",
          900: "#e0e0e0",
        },
        blueAccent: {
          100: "lightblue",
          200: "#2F78EE",
          300: "#2A72AD",
          400: "linear-gradient(93deg, #3582B5 2.72%, #3A6698 98.14%)",
          500: "#F2F6FC",
          600: "#1A5789",
          700: "#2fa8ee",
          800: "#ebe4e4",
          900: "#e1e2fe",
        },
        white: {
          100: "#FFF",
          200: "#808080",
          300:'#F2F6FC',

        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            background: {
              default: "#151F32",
            },
          }
        : {
            background: {
              default: "#EDF2F6",
            },
          }),

      Labeltext: {
        primary: mode === "dark" ? colors.grey[100] : colors.blueAccent[600],
      },
      backgroundMainHeading: {
        primary: mode === "dark" ? colors.grey[500] : colors.white[100],
      },
      textMainHeading: {
        primary: mode === "dark" ? colors.white[100] : colors.grey[100],
      },
      bottomBorder: {
        primary: mode === "dark" ? colors.white[100] : colors.grey[100],
      },
      accordiansBackground: {
        primary: mode === "dark" ? colors.grey[600] : colors.white[100],
      },
      accordiansBodyBackground: {
        primary: mode === "dark" ? colors.grey[800] : colors.white[100],
      },
      accordiansHeadingText: {
        primary: mode === "dark" ? colors.white[500] : colors.grey[100],
      },
      accordiansIcon: {
        primary: mode === "dark" ? colors.white[100] : colors.blueAccent[400],
      },
      sidebarBackground: {
        primary: mode === "dark" ? colors.grey[400] : colors.blueAccent[400],
      },
      sidebarHeadingBackground: {
        primary: mode === "dark" ? colors.grey[300] : colors.blueAccent[500],
      },
      sidebarDropDowns: {
        primary: mode === "dark" ? colors.grey[700] : colors.blueAccent[600],
      },
      sidebarDDownText: {
        primary: mode === "dark" ? colors.white[100] : colors.white[100],
      },
      sidebarIcon: {
        primary: mode === "dark" ? colors.white[100] : colors.blueAccent[600],
      },
      sidebarText: {
        primary: mode === "dark" ? colors.white[100] : colors.white[100],
      },
      customFieldsBackground: {
        primary: mode === "dark" ? colors.grey[800] : colors.white[100],
      },
      disablecustomFieldsBackground: {
        primary: mode === "dark" ? '#ebe4e4' :'#e3e1e1',
      },
      searchIcon: {
        primary: mode === "dark" ? colors.white[100] : colors.grey[100],
      },
      tabsBackground: {
        primary: mode === "dark" ? colors.white[100] : colors.grey[100],
      },
      radioButtons: {
        primary: mode === "dark" ? colors.white[100] : colors.grey[100],
      },
      innerBox: {
        primary: mode === "dark" ? colors.background : colors.white[100],
      },
      accSummary: {
        primary:
          mode === "dark" ? colors.blueAccent[400] : colors.blueAccent[600],
      },
      accSummaryAccord: {
        primary: mode === "dark" ? colors.grey[600] : colors.blueAccent[600],
      },
      saveBtn: {
        primary: mode === "dark" ? colors.grey[200] : colors.blueAccent[200],
      },
      cancelBtn: {
        primary: mode === "dark" ? colors.grey[900] : colors.white[100],
      },
      btnSaveText: {
        primary: mode === "dark" ? colors.white[100] : colors.white[100],
      },
      btnCancelText: {
        primary: mode === "dark" ? colors.white[100] : '#808080',
      },
      saveHover: {
        primary:
          mode === "dark" ? colors.blueAccent[200] : colors.blueAccent[700],
      },
      cancelHover: {
        primary:
          mode === "dark" ? colors.blueAccent[300] : colors.blueAccent[800],
      },
      saveTextHover: {
        primary: mode === "dark" ? colors.white[100] : colors.white[100],
      },
      cancelTextHover: {
        primary: mode === "dark" ? colors.blueAccent[100] : colors.grey[100],
      },
      dataGridHeading: {
        primary: mode === "dark" ? colors.grey[600] : "#F9F9F9",
      },
      addNewBtn: {
        primary: mode === "dark" ? colors.grey[900] : colors.white[100],
      },
      addNewText: {
        primary: mode === "dark" ? colors.white[100] : "#0554D3",
      },
      fieldsDDown: {
        primary: mode === "dark" ? colors.grey[700] : colors.white[100],
      },
      accordInnerHeading: {
        primary: mode === "dark" ? colors.grey[500] : colors.blueAccent[900],
      },
      infocodesHeader: {
        primary: mode === "dark" ? colors.grey[600] : colors.white[200],
      },
      activeDropDown:{
        primary: mode === "dark" ? colors.blueAccent[600] : colors.blueAccent[300],
      },
      topBarBackground:{
        primary: mode === "dark" ? colors.blueAccent[500] : colors.white[300],
      },
    revenueStatement:{
        primary: mode === "dark" ? colors.grey[800] : colors.blueAccent[100],
      },
      profileCard:{
        primary: mode === "dark" ? colors.blueAccent[700] : colors.blueAccent[400],
      },
      announcementCard:{
        primary: mode === "dark" ? colors.blueAccent[800] : colors.white[100],
      },
      announcementText:{
        primary: mode === "dark" ? colors.white[100] : colors.grey[300],
      },
      globalText:{
        primary: mode === "dark" ? colors.white[100] : colors.grey[100],
      },
      patientsHeading:{
        primary: mode === "dark" ? colors.white[100] : colors.grey[400],
      },
      allPatientHeading:{
        primary: mode === "dark" ? colors.white[100] : colors.blueAccent[200],
      },
      patientAcc:{
        primary: mode === "dark" ? colors.blueAccent[900] : "#EDEDED",
      },
   totalClaim:{
        primary: mode === "dark" ? "#FCFF71" : "#1B70FA",
      },
      preRecords:{
        primary: mode === "dark" ? colors.white[100]  : " #00D115",
      },
      chartGrid:{
        primary: mode === "dark" ? '#717573' : "#c4cfc9",
      },
 fieldsText:{
  primary: mode === "dark" ? '#FFFFFF' : "#111111",
 },
 fieldsBorder:{
  primary: mode === "dark" ? '#FFFFFF' : "#6A6A6A",
 },
 modalHeading:{
  primary: mode === "dark" ? colors.grey[500]: "#f0f5f7",
 },
 modalBackground:{
    primary: mode === "dark" ?   '#151F32': colors.white[100],
  
  },
  arCharges: {
    primary: mode === "dark" ? colors.grey[600] : "lightgre",
  },
  previewEdiInfo:{
    primary: mode === "dark" ?   colors.grey[600]: '#fff',
  
  },
  previewEdi:{
      primary: mode === "dark" ?   colors.grey[600]: '#c8e8fa',
    
    },
    multiCustomPreview:{
      primary:mode==="dark" ? "#151F32": 'white'
    },
    errorFieldsBorder:{
      primary:mode ==="dark" ? "#e84a3f": '#e84a3f'
    },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode:() => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};