import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
  
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomSelectBox2 from "../../components/CustomSelectBox2";
import { getData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomField from "../../components/CustomField";
import CheckBox from "../../components/CheckBox";
import { ExpandMore } from "@mui/icons-material";
import CustomAccordion from "../../components/CustomAccordion";
import TextArea from "../../components/TextArea";

const BillingInfo = ({ formik }) => {
  const [statementType, setStatementType] = useState([]);
  const [mailStatementTo, setMailStatementTo] = useState([]);
  const [relationToPatient, setRelationToPatient] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  // Define data fetching URLs
  const dataFetchUrls = {
    statementType: `${path}/ct-statementType`,
    mailStatementTo: `${path}/ct-mailStatement`,
    relationToPatient: `${path}/ct-relationToPatient`,
    cities: `${path}/city`,
    states: `${path}/state`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.statementType, setStatementType);
    fetchDataOptions(dataFetchUrls.mailStatementTo, setMailStatementTo);
    fetchDataOptions(dataFetchUrls.relationToPatient, setRelationToPatient);
    fetchDataOptions(dataFetchUrls.states, setStateOptions);
  }, []);
  useEffect(() => {
    if (formik.values?.guarantorStateId) {
      fetchDataOptions(
        `${path}/city?stateId=${formik.values?.guarantorStateId}
    `,
        setCityOptions
      );
    }
  }, [formik.values?.guarantorStateId]);
  const theme = useTheme(); // Access the current theme
  const mode = theme.palette.mode; // Extract the mode from the theme
  const colors = theme.palette; // Extract the colors from the theme
  return (
    <Box display="flex" flexDirection="column">
      <CustomAccordion defaultExpanded={true} heading={"Statement Options"}>
        <AccordionDetails>
          <Box display="flex" gap="20px" flexDirection="column">
            {/* statement type */}
            <Box
              display="grid"
              gap="30px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 1fr))",
                },
              }}
            >
              <CustomSelectBox2
                value={formik.values?.statementTypeId}
                name="statementTypeId"
                dropdownOptions={statementType?.map((opt) => ({
                  value: opt.statementType,
                  id: opt.statementTypeId,
                }))}
                label="State Type"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
              />
              {/* mail statement */}
              <CustomSelectBox2
                value={formik.values?.mailStatementId}
                name="mailStatementId"
                dropdownOptions={mailStatementTo?.map((opt) => ({
                  value: opt.mailStatementType,
                  id: opt.mailStatementId,
                }))}
                label="Mail Statement To"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
              />
            </Box>
            {/* comment */}
            <Stack>
              <label className="customLabel">
                Patient Comment (applies to user print statements)
              </label>
              <TextArea
                minRows={7}
                value={formik.values?.patientComment1}
                name="patientComment1"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Stack>
            <Box sx={{ width: { xs: "100%", sm: "100%", md: "50%" } }}>
              <label className="customLabel" style={{ display: "block" }}>
                Patient Comment (applies to automated statements)
              </label>
              <TextArea
                minRows={4}
                value={formik.values?.patientComment2}
                name="patientComment2"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </CustomAccordion>
      {/* collection options */}
      <CustomAccordion heading={"Collection Options"}>
        <AccordionDetails>
          <FormGroup>
            <FormControlLabel
              control={
                <CheckBox
                  CheckedValue={formik.values?.isSendStatement}
                  handleChange={(e) => {
                    formik.setFieldValue(
                      "isSendStatement",
                      !formik.values?.isSendStatement
                    );
                  }}
                />
              }
              label={
                <Typography variant="h5" fontWeight="600">
                  Send Statement
                </Typography>
              }
            />
            <Typography
              variant="h3"
              component="p"
              fontSize="1.6rem"
              marginBottom={"5px"}
              marginTop={"12px"}
            >
              (applies to statement automation and batch printing)
            </Typography>
          </FormGroup>
          {/*  */}
          <Box
            display="flex"
            gap="20px"
            flexDirection={{ xs: "column", sm: "row", md: "row" }}
            alignItems={{ xs: "flex-start", sm: "flex-end", md: "flex-end" }}
            justifyContent="space-between"
          >
            <Box width={{ xs: "100%", sm: "30%", md: "35%" }}>
              <div style={{ marginTop: "20px" }}>
                <CustomDatePicker
                  formik={formik}
                  dateLabel="Collection Date"
                  dateValue={formik.values?.collectionDate}
                  name="collectionDate"
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />
              </div>
            </Box>

            <Box width={{ xs: "100%", sm: "70%", md: "65%" }}>
              <CustomField
                type="text"
                label="Collection Reason"
                value={formik.values?.collectionReason}
                name="collectionReason"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </CustomAccordion>
      {/* payment portal */}
      <CustomAccordion heading={"Payment Portal Options"}>
        <AccordionDetails>
          <Box>
            <Typography component="h4" fontWeight="500">
              No Payment Portal
            </Typography>
          </Box>
        </AccordionDetails>
      </CustomAccordion>
      {/* guarantor */}
      <CustomAccordion heading={"Guarantor"}>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gap="20px">
            <Box width={{ xs: "100%", sm: "60%", md: "50%" }}>
              <CustomSelectBox2
                value={formik.values?.billingRelationshipToPatientId}
                name="billingRelationshipToPatientId"
                dropdownOptions={relationToPatient?.map((opt) => ({
                  value: opt.relationShipToPatientName,
                  id: opt.relationShipToPatientId,
                }))}
                label="Relation to Patient"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
              />
            </Box>

            <Stack
              direction={{ xs: "column", sm: "column", md: "row" }}
              justifyContent="space-between"
              gap="20px"
            >
              <FormControl
                sx={{ width: { xs: "100%", sm: "100%", md: "38%" } }}
              >
                <CustomField
                  label="Last Name"
                  value={formik.values?.guarantorLastName}
                  name="guarantorLastName"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                sx={{ width: { xs: "100%", sm: "100%", md: "38%" } }}
              >
                <CustomField
                  label="First Name"
                  value={formik.values?.guarantorFirstName}
                  name="guarantorFirstName"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl
                sx={{ width: { xs: "100%", sm: "100%", md: "20%" } }}
              >
                <CustomField
                  label="MI"
                  value={formik.values?.guarantorMI}
                  name="guarantorMI"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                />
              </FormControl>
            </Stack>

            <Box
              display="grid"
              alignItems="end"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 300px))",
                  md: "repeat(2, minmax(0, 300px))",
                },
              }}
            >
              <CustomDatePicker
                formik={formik}
                dateLabel="Date of Birth"
                dateValue={formik.values?.guarantorDOB}
                name="guarantorDOB"
                handleDateBlur={formik.handleBlur}
                handleDateChange={formik.handleChange}
              />

              <CustomField
                label="SSN"
                type="text"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                value={formik.values?.guarantorSSN}
                name="guarantorSSN"
              />
            </Box>

            <Stack>
              <label className="customLabel">Address</label>
              <TextArea
                value={formik.values?.guarantorAddress}
                name="guarantorAddress"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                minRows={4}
              />
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row", md: "row" }}
              justifyContent="space-between"
              gap="20px"
            >
              <FormControl sx={{ width: { xs: "100%", sm: "33%", md: "33%" } }}>
                <CustomSelectBox2
                  label="State"
                  value={formik.values?.guarantorStateId}
                  name="guarantorStateId"
                  formik={formik}
                  handleChange={() => {
                    formik.setFieldValue("guarantorCityId", null);
                  }}
                  handleBlur={formik.handleBlur}
                  dropdownOptions={stateOptions.map((option) => ({
                    value: option.stateName,
                    id: option.stateId,
                  }))}
                />
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", sm: "33%", md: "33%" } }}>
                <CustomSelectBox2
                  label="City"
                  value={formik.values?.guarantorCityId}
                  disable={!formik.values?.guarantorStateId}
                  name="guarantorCityId"
                  formik={formik}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  dropdownOptions={cityOptions.map((option) => ({
                    value: option.cityName,
                    id: option.cityId,
                  }))}
                />
              </FormControl>

              <FormControl sx={{ width: { xs: "100%", sm: "33%", md: "33%" } }}>
                <CustomField
                  label="Zip Code"
                  value={formik.values?.guarantorZipCode}
                  name="guarantorZipCode"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
              </FormControl>
            </Stack>
            <FormGroup>
              <FormControlLabel
                control={
                  <CheckBox
                    CheckedValue={formik.values?.isInternationalAddress}
                    handleChange={() =>
                      formik.setFieldValue(
                        "isInternationalAddress",
                        !formik.values?.isInternationalAddress
                      )
                    }
                  />
                }
                label={
                  <Typography variant="h5" fontWeight="600">
                    International Address
                  </Typography>
                }

                // label="International Address"
              />
            </FormGroup>

            <Box
              display="grid"
              alignItems="end"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 300px))",
                  md: "repeat(2, minmax(0, 300px))",
                },
              }}
            >
              <CustomField
                label="Home Phone"
                type="text"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                value={formik.values?.guarantorHomePhone}
                name="guarantorHomePhone"
              />

              <CustomField
                label="Cell Phone"
                type="text"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                value={formik.values?.guarantorCellPhone}
                name="guarantorCellPhone"
              />
              <CustomField
                label="Work Phone"
                type="text"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                value={formik.values?.guarantorWorkPhone}
                name="guarantorWorkPhone"
              />
            </Box>

            <FormControl fullWidth>
              <CustomField
                type="email"
                value={formik.values?.guarantorEmail}
                name="guarantorEmail"
                label="Email"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </FormControl>
            <Stack>
              <label className="customLabel">Remarks</label>
              <TextArea
                minRows={4}
                value={formik.values?.guarantorRemarks}
                name="guarantorRemarks"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Stack>
          </Box>
        </AccordionDetails>
      </CustomAccordion>
    </Box>
  );
};

export default BillingInfo;
