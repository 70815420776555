// import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import CustomSelectBox from "../../components/CustomSelectBox";
// import path from "../../config/apiUrl";
// import { getData, postData } from "../../config/axiosFunctions";
// import { useDispatch, useSelector } from "react-redux";
// import { Add } from "@mui/icons-material";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import CustomModal from "../../components/CustomModal";
// import NewPayer from "./NewPayer";
// import PayerList from "./PayerList";
// import CustomSearchField from "../../components/CustomSearchField";
// import CustomField from "../../components/CustomField";
// import CustomDatePicker from "../../components/CustomDatePicker";
// import CustomSelectBox2 from "../../components/CustomSelectBox2";
// import CustomPreview from "../../layout/CustomPreview";
// import { getPayerAction } from "../../features/actions/payerAction";
// import { getPayerBySearchTermAction } from "../../features/actions/payerAction";
// import { payerCol } from "../../utils/gridCols";
// import PayerForm from "../custom-setup/payer/PayerForm";
// import { useFormik } from "formik";
// import { payerIntialVal } from "../../utils/formikInitValues";
// import { payerSchema } from "../../schemas/patientSchema";
// import CustomButton from "../../components/CustomButton";

// const PayerInfo = ({ newFormik, fieldDisable }) => {
//   const dispatch = useDispatch();
//   // console.log(newFormik.values.payerInfoPolicyId, "policy");

//   // const [priorityOptions, setPriorityOptions] = useState([]);
//   const [policyTypeOptions, setPolicyTypeOptions] = useState([]);
//   const [openNewPayerModal, setOpenNewPayerModal] = useState(false);
//   const [openPyerListModal, setOpenPyerListModal] = useState(false);
//   // use selectore
//   const { getAllPayer, loading } = useSelector((state) => state.payer);
//   console.log(getAllPayer, "getAllPayer");
//   const dataFetchUrls = {
//     // priorityType: `${path}/ct-priorityType`,
//     policyTypes: `${path}/ct-policyType`,
//   };

//   // Define a reusable function to fetch data for a given URL
//   const fetchDataOptions = async (url, setter) => {
//     try {
//       const response = await getData(url);
//       setter(response.result);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     // fetchDataOptions(dataFetchUrls.priorityType, setPriorityOptions);
//     fetchDataOptions(dataFetchUrls.policyTypes, setPolicyTypeOptions);
//   }, [dispatch]);

//   const handleSelectPayer = (val) => {
//     console.log(val, "selectpayervalue");
//     newFormik.setFieldValue("payerInfoSequenceNumber", val.payerSequenceNo);
//     newFormik.setFieldValue(
//       "payerInfoPayerName",
//       `${val.payerName} (${val.payerSequenceNo})`
//     );
//     newFormik.setFieldValue("payerId", val.id);
//     setOpenPyerListModal(false);
//   };

//   const formik = useFormik({
//     initialValues: payerIntialVal,
//     validationSchema: payerSchema,
//     onSubmit: async (values, action) => {
//       const response = await postData(`${path}/payer`, values);
//       // let val = response?.result;
//       let val = response?.result[response?.result.length - 1];
//       if (val) {
//         newFormik.setFieldValue(
//           "payerInfoSequenceNumber",
//           val?.payerSequenceNo
//         );
//         newFormik.setFieldValue(
//           "payerInfoPayerName",
//           `${val?.payerName} (${val?.payerSequenceNo})`
//         );
//         newFormik.setFieldValue("payerId", val?.payerId);
//         action.resetForm();
//         setOpenNewPayerModal(false);
//       }
//       // navigate("/payer");
//     },
//   });
//   return (
//     <>
//       <CustomModal
//         open={openPyerListModal}
//         handleClose={() => setOpenPyerListModal(false)}
//         heading={"Payer List"}
//         height={"10px"}
//       >
//         {/* <PayerList
//           handleClose={() => setOpenPyerListModal(false)}
//           handleSelectPayer={handleSelectPayer}
//         /> */}
//         <CustomPreview
//           rows={
//             getAllPayer && getAllPayer.result
//               ? getAllPayer.result?.map((el) => ({
//                   id: el.payerId,
//                   ...el,
//                 }))
//               : []
//           }
//           columns={payerCol}
//           loading={loading}
//           handleGetAll={getPayerAction}
//           handleCell={handleSelectPayer}
//           searchLabel={"Search by Payer Name, Plan Name, Address"}
//           isModal={true}
//           handleBySearch={getPayerBySearchTermAction}
//         />
//       </CustomModal>

//       <CustomModal
//         open={openNewPayerModal}
//         handleClose={() => setOpenNewPayerModal(false)}
//         height={"20px"}
//         heading={"Create Payer"}
//       >
//         <Box
//           margin={"20px"}
//           sx={{ width: { xs: "95%", sm: "95%", md: "95%" } }}
//         >
//           <form id="payerForm" onSubmit={formik.handleSubmit}>
//             <Box
//               display="grid"
//               gap="30px"
//               margin={"20px 0"}
//               sx={{
//                 gridTemplateColumns: {
//                   xs: "repeat(1, minmax(0, 150px))",
//                   sm: "repeat(1, minmax(0, 150px))",
//                   md: "repeat(2, minmax(0, 150px))",
//                 },
//               }}
//             >
//               <CustomButton
//                 type="submit"
//                 isBlue={true}
//                 isSave={true}
//                 id="payerForm"
//               >
//                 Create Payer
//               </CustomButton>
//             </Box>
//             <PayerForm formik={formik} />
//           </form>
//         </Box>
//       </CustomModal>

//       <Box display="flex" flexDirection="column" gap={5}>
//         <Typography component="h2" fontWeight="600">
//           Payer Information:
//         </Typography>

//         <Box
//           display="grid"
//           gap="30px"
//           sx={{
//             gridTemplateColumns: {
//               xs: "repeat(1, minmax(0, 1fr))",
//               sm: "repeat(2, minmax(0, 1fr))",
//               md: "repeat(4, minmax(0, 1fr))",
//             },
//           }}
//         >
//           <CustomField
//             type="number"
//             label="Copay"
//             isDecimal={true}
//             value={newFormik.values.payerInfoCopayAmount}
//             disable={fieldDisable}
//             name="payerInfoCopayAmount"
//             handleChange={newFormik.handleChange}
//             handleBlur={newFormik.handleBlur}
//           />
//           <CustomField
//             type="number"
//             label="Co-Insurance %"
//             value={newFormik.values.payerInfoCoInsurancePercent}
//             disable={fieldDisable}
//             name="payerInfoCoInsurancePercent"
//             handleChange={newFormik.handleChange}
//             handleBlur={newFormik.handleBlur}
//           />
//           <CustomField
//             type="number"
//             label="Deductible"
//             value={newFormik.values.payerInfoDeductibleAmount}
//             disable={fieldDisable}
//             name="payerInfoDeductibleAmount"
//             handleChange={newFormik.handleChange}
//             handleBlur={newFormik.handleBlur}
//             isDecimal={true}
//           />
//           <CustomField
//             type="number"
//             label="Out of Pocket Max"
//             value={newFormik.values.payerInfoOutOfPocketMax}
//             disable={fieldDisable}
//             name="payerInfoOutOfPocketMax"
//             handleChange={newFormik.handleChange}
//             handleBlur={newFormik.handleBlur}
//             isDecimal={true}
//           />
//         </Box>

//         <Box
//           display="grid"
//           gap="30px"
//           sx={{
//             gridTemplateColumns: {
//               xs: "repeat(1, minmax(0, 1fr))",
//               sm: "repeat(2, minmax(0, 1fr))",
//               md: "repeat(4, minmax(0, 1fr))",
//             },
//           }}
//         ></Box>

//         <Box
//           display="grid"
//           gap="30px"
//           sx={{
//             gridTemplateColumns: {
//               xs: "repeat(1, minmax(0, 1fr))",
//               sm: "repeat(2, minmax(0, 1fr))",
//               // sm: "1fr 3fr",
//               md: "repeat(4, minmax(0, 1fr))",
//             },
//             // width:{xs:'90%',md:'90%'}
//             width: { xs: "90%", md: "100%" },
//           }}
//         >
//           {/* priority type
//           // <CustomSelectBox
//           //   value={newFormik.values.payerInfoPriorityName}
//           //   name="payerInfoPriorityName"
//           //   dropdownOptions={priorityOptions?.map((opt) => ({
//           //     value: opt.priorityType,
//           //     id: opt.priorityTypeId,
//           //   }))}
//           //   label="Priority Type"
//           //   handleChange={newFormik.handleChange}
//           //   handleBlur={newFormik.handleBlur}
//           // /> */}

//           {/* policy type */}
//           <CustomSelectBox2
//             value={newFormik.values.payerInfoPolicyId}
//             disable={fieldDisable}
//             name="payerInfoPolicyId"
//             dropdownOptions={policyTypeOptions?.map((opt) => ({
//               value: opt.policyType,
//               id: opt.policyTypeId,
//             }))}
//             label="Policy Type"
//             handleChange={newFormik.handleChange}
//             handleBlur={newFormik.handleBlur}
//             formik={newFormik}
//           />

//           <CustomField
//             style={{ marginBottom: "1rem" }}
//             label="Member Id"
//             type="number"
//             handleChange={newFormik.handleChange}
//             value={newFormik.values.payerInfoMemberId}
//             disable={fieldDisable}
//             name="payerInfoMemberId"
//             handleBlur={newFormik.handleBlur}
//             error={newFormik.errors}
//             touched={newFormik.touched}
//             isRequired={true}
//           />
//           {/* group id */}
//           <CustomField
//             label="Group Id"
//             type="number"
//             handleChange={newFormik.handleChange}
//             disable={fieldDisable}
//             value={newFormik.values.payerInfoGroupId}
//             name="payerInfoGroupId"
//             handleBlur={newFormik.handleBlur}
//           />
//         </Box>

//         {/* <Box
//           display="grid"
//           gap="30px"
//           sx={{
//             gridTemplateColumns: {
//               xs: "repeat(1, minmax(0, 1fr))",
//               sm: "repeat(2, minmax(0, 1fr))",
//               md: "3fr 1fr 1fr",

//             },
//           }}
//         > */}
//         <Box
//           sx={{
//             display: "flex",
//             width: "100%",

//             // gridColumn: { xs: "1 / span 1", sm: "1 / span 2", md: "auto" },

//             gridTemplateColumns: {
//               xs: "repeat(1, minmax(0, 1fr))",
//               sm: "repeat(1, minmax(0, 1fr))",
//               md: "repeat(1, minmax(0, 1fr))",
//             },
//           }}
//         >
//           <FormControl
//             sx={{
//               width: { xs: "100%", sm: "100%" },
//               marginTop: "8px",
//               fontSize: "1rem",
//             }}
//           >
//             <CustomSearchField
//               type="text"
//               label="Payer Name"
//               disable={fieldDisable}
//               fieldVal={newFormik.values.payerInfoPayerName}
//               name="payerInfoPayerName"
//               handleChange={newFormik.handleChange}
//               handleBlur={newFormik.handleBlur}
//               handleModalOpen={() => setOpenPyerListModal(true)}
//               error={newFormik.errors}
//               touched={newFormik.touched}
//               isRequired={true}
//             />
//           </FormControl>
//           <Button
//             sx={{
//               marginTop: "38px",
//               marginRight: "40px",
//               marginLeft: "10px",
//               marginBottom: "4px",
//               color: "#216FED",
//             }}
//             disabled={fieldDisable}
//             variant="outlined"
//             onClick={() => setOpenNewPayerModal(true)}
//           >
//             <Add />
//           </Button>
//         </Box>

//         {/* <CustomField style={{marginBottom:'1rem'}}
//             label="Member Id"
//             type="number"
//             handleChange={newFormik.handleChange}
//             value={newFormik.values.payerInfoMemberId}
//             name="payerInfoMemberId"
//             handleBlur={newFormik.handleBlur}

//           />

//           <CustomField
//             label="Group Id"
//             type="number"
//             handleChange={newFormik.handleChange}
//             value={newFormik.values.payerInfoGroupId}
//             name="payerInfoGroupId"
//             handleBlur={newFormik.handleBlur}
//           /> */}
//         {/* </Box> */}

//         <Box
//           display="grid"
//           gap="30px"
//           sx={{
//             gridTemplateColumns: {
//               xs: "repeat(1, minmax(0, 1fr))",
//               sm: "repeat(2, minmax(0, 1fr))",
//               md: "repeat(2, minmax(0, 1fr))",
//             },
//           }}
//         >
//           <div>
//           <CustomDatePicker
//                   formik={newFormik}
//                   dateLabel="Effective Date"
//                   dateValue={newFormik.values?.payerInfoEffectiveDate}
//                   handleDateChange={newFormik.handleChange}
//                   handleDateBlur={newFormik.handleBlur}
//                   name="payerInfoEffectiveDate"
//                   // isRequired={true}
//                 />
//           </div>

//           <div>
//           <CustomDatePicker
//                   formik={newFormik}
//                   dateLabel="Termination Date"
//                   dateValue={newFormik.values?.payerInfoTerminationDate}
//                   handleDateChange={newFormik.handleChange}
//                   handleDateBlur={newFormik.handleBlur}
//                   name="payerInfoTerminationDate"

//                 />
//           </div>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default PayerInfo;

import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelectBox2 from "../../components/CustomSelectBox2";
import CustomSearchField from "../../components/CustomSearchField";
import { Add } from "@mui/icons-material";
import CustomField from "../../components/CustomField";
import CustomDatePicker from "../../components/CustomDatePicker";
import path from "../../config/apiUrl";
import { getData } from "../../config/axiosFunctions";
import CustomModal from "../../components/CustomModal";
import CustomPreview from "../../layout/CustomPreview";
import {
  getPayerAction,
  getPayerBySearchTermAction,
} from "../../features/actions/payerAction";
import { useSelector } from "react-redux";
import { insurancePayer } from "../../utils/gridCols";
import CustomDialog from "../../components/CustomDialog";

const PayerInfo = ({ insuranceFormik, formik }) => {
  const initPayerDet = insuranceFormik.values.payerDetailVals;
  const [priorityOpt, setPriorityOpt] = useState([]);
  const [openPayerList, setOpenPayerList] = useState(false);
  const [pollicyOpt, setPolicyOpt] = useState([]);
  const [openExistPayer, setOpenExistPayer] = useState(false);
  const [existingPayerName, setExistingPayerName] = useState("");
  // use selectors
  const { getAllPayer } = useSelector((state) => state.payer);
  const payerLoading = useSelector((state) => state.payer.loading);
  const dataFetchUrl = {
    priorityType: "/ct-priorityType",
    polityType: "/ct-policyType",
  };

  const fetchData = async (endPoint, setState) => {
    try {
      const response = await getData(`${path}${endPoint}`);
      setState(response.result);
    } catch (error) {
      console.log("error while fetch data", error);
    }
  };
  useEffect(() => {
    fetchData(dataFetchUrl.priorityType, setPriorityOpt);
    fetchData(dataFetchUrl.polityType, setPolicyOpt);
  }, []);

  // handle select payer
  const handleSelectPayer = (param) => {
    console.log(param, "params");
    const payerExist = formik?.values?.insuredPayerDto?.some((insuredPayer) =>
      insuredPayer?.insurancePoliciesDtos?.some(
        (policy) => policy?.payerId === param?.payerId
      )
    );
    if (payerExist) {
      setExistingPayerName(`${param?.payerName} (${param?.payerSequenceNo})`);
      setOpenPayerList(false);
      setOpenExistPayer(true);
      return;
    } else {
      insuranceFormik.setFieldValue(
        "payerDetailVals.payerInfoPayerName",
        param?.payerName
      );
      insuranceFormik.setFieldValue(
        "payerDetailVals.payerInfoSequenceNumber",
        param?.payerSequenceNo
      );
      insuranceFormik.setFieldValue("payerDetailVals.payerId", param?.payerId);
      setOpenPayerList(false);
    }
  };

  return (
    <>
   
      <Box>
        <Grid container spacing={2}>
          <Grid item md={5}>
            <CustomSelectBox2
              dropdownOptions={priorityOpt?.map((opt) => ({
                id: opt?.priorityTypeId,
                value: opt?.priorityType,
              }))}
              label="Priority"
              showDefaultOpt={true}
              value={initPayerDet.payerInfoPriorityId}
              name="payerDetailVals.payerInfoPriorityId"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              formik={insuranceFormik}
            />
          </Grid>
          <Grid item md={7}>
            <CustomSelectBox2
              dropdownOptions={pollicyOpt?.map((opt) => ({
                id: opt?.policyTypeId,
                value: opt?.policyType,
              }))}
              label="Policy type"
              value={initPayerDet.payerInfoPolicyId}
              name="payerDetailVals.payerInfoPolicyId"
              handleBlur={insuranceFormik.handleBlur}
              handleChange={insuranceFormik.handleChange}
              showDefaultOpt={true}
              formik={insuranceFormik}
            />
          </Grid>

          <Grid item md={12}>
            <Stack direction="row" alignItems="center">
              <FormControl
                sx={{
                  width: { xs: "100%", sm: "100%" },
                  marginTop: "8px",
                  fontSize: "1rem",
                }}
              >
                {console.log(insuranceFormik.values, "checkInsurancePayValues")}
                <CustomSearchField
                isNested = {true}
                isRequired={true}
                  type="text"
                  label="Payer Name"
                  formik={insuranceFormik}
                  // disable={fieldDisable}
                  fieldVal={
                    initPayerDet.payerInfoPayerName &&
                    `${initPayerDet.payerInfoPayerName} (${initPayerDet.payerInfoSequenceNumber})`
                  }
                  name="payerDetailVals.payerInfoPayerName"
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  handleModalOpen={() => setOpenPayerList(true)}

                  // error={newFormik.errors}
                  // touched={newFormik.touched}
                  
                />
              </FormControl>
              <Button
                sx={{
                  marginTop: "38px",
                  marginRight: "40px",
                  marginLeft: "10px",
                  marginBottom: "4px",
                  color: "#216FED",
                }}
                
                // disabled={fieldDisable}
                variant="outlined"
                // onClick={() => setOpenNewPayerModal(true)}
              >
                <Add />
              </Button>
            </Stack>
          </Grid>

          <Grid item md={6}>
            <CustomField
              type="text"
              label="Member ID"
              value={initPayerDet.payerInfoMemberId}
              name={"payerDetailVals.payerInfoMemberId"}
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              isRequired={true}
              error={insuranceFormik.errors}
              touched={insuranceFormik.touched}
              isOutlined={false}
              isNested={true}
              
            />
          </Grid>
          <Grid item md={6}>
            <CustomField
              type="text"
              label="Group ID"
              value={initPayerDet.payerInfoGroupId}
              name="payerDetailVals.payerInfoGroupId"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="number"
              label="Copay"
              value={initPayerDet.payerInfoCopayAmount}
              name="payerDetailVals.payerInfoCopayAmount"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              isDecimal={true}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="number"
              label="Co-Insurance %"
              value={initPayerDet.payerInfoCoInsurancePercent}
              name="payerDetailVals.payerInfoCoInsurancePercent"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="number"
              label="Deductible"
              value={initPayerDet.payerInfoDeductibleAmount}
              name="payerDetailVals.payerInfoDeductibleAmount"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              isDecimal={true}
            />
          </Grid>
          <Grid item md={3}>
            <CustomField
              type="number"
              label="Out of Pocket Max"
              value={initPayerDet.payerInfoOutOfPocketMax}
              name="payerDetailVals.payerInfoOutOfPocketMax"
              handleChange={insuranceFormik.handleChange}
              handleBlur={insuranceFormik.handleBlur}
              isDecimal={true}
            />
          </Grid>
          <Grid item md={6}>
            <CustomDatePicker
              dateLabel="Effective Date"
              dateValue={initPayerDet.payerInfoEffectiveDate}
              name="payerDetailVals.payerInfoEffectiveDate"
              handleDateBlur={insuranceFormik.handleBlur}
              handleDateChange={insuranceFormik.handleChange}
              formik={insuranceFormik}
            />
          </Grid>
          <Grid item md={6}>
            <CustomDatePicker
              dateLabel="Termination Date"
              dateValue={initPayerDet.payerInfoTerminationDate}
              name="payerDetailVals.payerInfoTerminationDate"
              handleDateBlur={insuranceFormik.handleBlur}
              handleDateChange={insuranceFormik.handleChange}
              formik={insuranceFormik}
            />
          </Grid>
        </Grid>
      </Box>

      {/* modals here */}
      <CustomModal
        open={openPayerList}
        handleClose={() => setOpenPayerList(false)}
        height={"20px"}
        heading="Payer List"
      >
        <CustomPreview
          isModal={true}
          handleCell={(param) => handleSelectPayer(param)}
          handleGetAll={getPayerAction}
          handleBySearch={getPayerBySearchTermAction}
          rows={
            getAllPayer && getAllPayer?.result?.payers?.length > 0
              ? getAllPayer?.result?.payers?.map((el) => ({
                  id: el?.payerId,
                  ...el,
                }))
              : []
          }
          loading={payerLoading}
          // columns={payerCol}
          columns={insurancePayer}
          searchLabel="Search by name or address #"
          paginationDetail={getAllPayer}
        />
      </CustomModal>

      <CustomDialog
        open={openExistPayer}
        isWarning={true}
        handleClose={() => setOpenExistPayer(false)}
        isAction={false}
      >
        <Typography component="h3">
          The selected payer ({existingPayerName}) is already set on this
          patient.
        </Typography>
      </CustomDialog>
    </>
  );
};

export default PayerInfo;
