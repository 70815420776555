import { ExpandMore } from '@mui/icons-material'
import {useTheme } from "@mui/material";
import React from 'react'

const CustomExpandIcon = () => {
  const theme = useTheme(); // Access the current theme
  const mode = theme.palette.mode; // Extract the mode from the theme
  const colors = theme.palette; // Extract the colors from the themes
  return (
    <ExpandMore
    sx={{
      fontSize: "25px",
      // color: "#216FED",
      // border: "1px solid #216FED",
      color: theme.palette.accordiansIcon.primary,
      border: `1px solid ${theme.palette.accordiansIcon.primary}`,
      borderRadius: "50px",
    }}
  />
  )
}

export default CustomExpandIcon;
