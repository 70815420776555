import { Box, Link, Stack, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../../../components/CustomButton";
import { DataGrid } from "@mui/x-data-grid";
import { eraDetailCols } from "../../../utils/gridCols";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const EraDetail = () => {
  const navigate = useNavigate();
  const { eraDetailData } = useSelector((state) => state.era);
  console.log(eraDetailData, "checkEraDetail");
  const rows =
    eraDetailData && eraDetailData.nestedPaymentsDetails.length > 0
      ? eraDetailData?.nestedPaymentsDetails?.map((row, index) => ({
          id: index,
          ...row,
        }))
      : [];
  return (
    <Box margin="20px">
      <Stack alignItems="center" direction="row" gap={2}>
        <CustomButton>View EOB Report</CustomButton>
        <CustomButton>Print</CustomButton>
        <CustomButton handleClick={() => navigate(`/payment/era/search`)}>
          Close
        </CustomButton>
      </Stack>

      <Stack margin="25px 0" gap={2}>
        <Typography component="h4">
          Payment - EFT from <Link>SBI</Link> received on 03/26/2024
        </Typography>
        <Stack alignItems="center" gap={2} direction="row">
          <Typography component="h4">
            <strong>Amount:</strong> ${eraDetailData?.amount}
          </Typography>
          <Typography component="h4">
            <strong>Applied:</strong> ${eraDetailData?.applied}
          </Typography>
          <Typography component="h4">
            <strong>Unapplied:</strong> ${eraDetailData?.unapplied}
          </Typography>
        </Stack>

        <Stack alignItems="center" gap={2} direction="row">
          <Typography component="h4">
            <strong>Unresolved Errors:</strong>
            {eraDetailData?.unresolvedErrors}
          </Typography>
          <Typography component="h4">
            <strong>Warnings:</strong> {eraDetailData?.warnings}
          </Typography>
        </Stack>
      </Stack>
      <Box>
        <DataGrid
          rows={rows}
          columns={eraDetailCols}
          sx={{
            "& .header-bg": {
              // background: theme.palette.dataGridHeading.primary,
              // color: theme.palette.textMainHeading.primary,
              fontSize: "16px",
            },
            "& .cell-content": {
              fontSize: "1.2rem",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-actions": {
              "& svg": {
                fontSize: "1.8rem",
                //   color: theme.palette.textMainHeading.primary,
                marginBottom: "8px",
              },
            },
          }}
          autoHeight
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSize={20}
          components={{
            NoRowsOverlay: () => (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {rows.length === 0 && "No Data Is Added"}
              </div>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default EraDetail;
