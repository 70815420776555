import React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomModal from "./CustomModal";
import { useTheme } from "@mui/material";

import './Custom-styling/CustomSelectBox.css';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Input } from "@mui/material";
const CustomSelectBox = ({
  value,
  handleChange,
  handleBlur,
  label,
  dropdownOptions,
  formik,
  name,
}) => {
  const theme = useTheme(); 
  const hasError = formik?.touched[name] && formik?.errors[name];
  return (
    <div>
    <FormControl fullWidth>
      <label className="customLabel"   
       style={{ color: theme.palette.Labeltext.primary }}
      htmlFor="">{label}</label>
      <Select className="customSelectBox"
           style={{background:theme.palette.customFieldsBackground.primary,
            // border: `1px solid ${theme.palette.fieldsBorder.primary}`,
         border: hasError ? '2px solid red' : `1px solid ${theme.palette.fieldsBorder.primary}`,

            color: theme.palette.fieldsText.primary,

          }}
        value={value}
        name={name}
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        native
        id="dropdowns"
        defaultValue=""
        
      >
        <option value="0" style={{ color: "lightgray !important"}}>
          Select
        </option>
        {dropdownOptions.map((opt) => (
          <>
            <option key={opt.id} value={opt.id} style={{background:theme.palette.fieldsDDown.primary,}}>
              {opt.value}
            </option>
          </>
        ))}
      </Select>
    </FormControl>
    {/* {hasError && (
        <div
          style={{
            color: "red",
            border: "1px solid red",
            fontSize: "1.3rem",
            marginTop: "8px",
            display: "block",
          }}
        >
          {formik.errors[name]}
        </div>
      )} */}
    </div>
  );
};
export default CustomSelectBox;
