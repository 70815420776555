import React from "react";
import { AccordionDetails, Box, FormControl, useTheme } from "@mui/material";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomField from "../../../components/CustomField";
import { useState } from "react";
import CustomModal from "../../../components/CustomModal";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomAccordion from "../../../components/CustomAccordion";
import TypeOfBill from "./TypeOfBill";
import CustomPreview from "../../../layout/CustomPreview";
import {
  facilityCols,
  patientClaimDetailCols,
  payerInfoCols,
  renderingProvider,
  billingProviderCols,
  primaryInsPayerCol,
  providerCols,
} from "../../../utils/gridCols";
import {
  getPatientClaimBySearchTerm,
  getPatientClaimsDetailAction,
} from "../../../features/actions/claimAction";
import {
  getFacilityAction,
  getFacilityBySearchTermAction,
} from "../../../features/actions/facilityAction";
import { getPayerInfoAction } from "../../../features/actions/payerInfoAction";

import { getAllRenderingProviderAction } from "../../../features/actions/renderigProviderAction";
import { getAllBillingProviderAction } from "../../../features/actions/billingProviderAction";
import { getPayerAction } from "../../../features/actions/payerAction";
import {
  getProviderAction,
  getProviderBySearchTermAction,
} from "../../../features/actions/providerAction";
import {
  setPrimaryPayer,
  setSecondaryPayer,
  setTertiaryPayer,
} from "../../../features/slice/claimSlice";
import { payerPolicyTypeInClaim } from "../../../utils/formikInitValues";
import ClaimPayer from "./ClaimPayer";
import Loading from "../../../components/Loading";

const ClaimInfo = ({ formik, location }) => {
  const dispatch = useDispatch();
  console.log(location, "checkLocation");
  let patientAccNo = location?.state?.accountNo;
  const theme = useTheme();
  const renderingLoading = useSelector(
    (state) => state.renderingProvider.loading
  );
  const { getProviders } = useSelector((state) => state.provider);
  const providerLoading = useSelector((state) => state.provider.loading);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [openPatientModal, setOpenPatientModal] = useState(false);
  const [openTypeOfBillModal, setOpenTypeOfBillModal] = useState(false);
  const [openFacilityModal, setOpenFacilityModal] = useState(false);
  const [openInsuredParty, setOpenInsuredParty] = useState({
    openModal: false,
    payerPriorityId: null,
  });
  const [openBillingProviderMod, setOpenBillingProviderMod] = useState(false);
  const [openRenderingProviderMod, setOpenRenderingProviderMod] =
    useState(false);
  const [frequencyOpt, setFrequencyOpt] = useState([]);
  const [policyTypeOpt, setPolicyTypeOpt] = useState([]);
  const [priorityType, setPriorityType] = useState([]);
  // use selectors
  const { patientClaimsData, getPatientClaimDetailLoad, loading } = useSelector(
    (state) => state.claim
  );
  const { getFacilityData } = useSelector((state) => state.facility);
  const facilityLoading = useSelector((state) => state.facility.loading);

  const handleAccordionChange = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const dataFetchUrls = {
    frequencyType: `${path}/ct-claimFrequency`,
    policyType: `${path}/ct-policyType`,
    priorityType: `${path}/ct-priorityType`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataOptions(dataFetchUrls.frequencyType, setFrequencyOpt);
    fetchDataOptions(dataFetchUrls.policyType, setPolicyTypeOpt);
    fetchDataOptions(dataFetchUrls.priorityType, setPriorityType);
  }, [dispatch]);
  // handle add patient
  const handleAddPatientClaim = (val) => {
    console.log("val", val);
    ///  payer get and set to redux
    let primaryPayer =
      val.primaryPayerPolicyDto !== null
        ? val.primaryPayerPolicyDto.map((el, ind) => {
            return {
              ...payerPolicyTypeInClaim,
              ...el,
              payerId: el?.payerId,
              copayAmount: el?.payerInfoCopayDue,
              groupId: el?.payerInfoGroupId,
              memberId: el?.payerInfoMemberId,
              payerName: el?.payerInfoName,
              policyTypeId: el?.payerInfoPolicyTypeId,
              PolicyTypeName: el?.payerInfoPolicyType,
              priorityId: el?.payerInfoPriorityTypeId,
              priorityName: el?.payerInfoPriorityType,
              payerSequenceNo: el?.payerInfoSequenceNo,
            };
          })
        : [];
    let secondaryPayer =
      val.secondaryPayerPolicyDto !== null
        ? val.secondaryPayerPolicyDto.map((el, ind) => {
            return {
              ...payerPolicyTypeInClaim,
              ...el,
              payerId: el?.payerId,
              copayAmount: el?.payerInfoCopayDue,
              groupId: el?.payerInfoGroupId,
              memberId: el?.payerInfoMemberId,
              payerName: el?.payerInfoName,
              policyTypeId: el?.payerInfoPolicyTypeId,
              PolicyTypeName: el?.payerInfoPolicyType,
              priorityId: el?.payerInfoPriorityTypeId,
              priorityName: el?.payerInfoPriorityType,
              payerSequenceNo: el?.payerInfoSequenceNo,
            };
          })
        : [];
    let tertiaryPayer =
      val.tertiaryPayerPolicyDto !== null
        ? val.tertiaryPayerPolicyDto.map((el, ind) => {
            return {
              ...payerPolicyTypeInClaim,
              ...el,
              payerId: el?.payerId,
              copayAmount: el?.payerInfoCopayDue,
              groupId: el?.payerInfoGroupId,
              memberId: el?.payerInfoMemberId,
              payerName: el?.payerInfoName,
              policyTypeId: el?.payerInfoPolicyTypeId,
              PolicyTypeName: el?.payerInfoPolicyType,
              priorityId: el?.payerInfoPriorityTypeId,
              priorityName: el?.payerInfoPriorityType,
              payerSequenceNo: el?.payerInfoSequenceNo,
            };
          })
        : [];
    dispatch(setPrimaryPayer(primaryPayer));
    dispatch(setSecondaryPayer(secondaryPayer));
    dispatch(setTertiaryPayer(tertiaryPayer));
    /// setPayer values in array
    let payers = [];
    primaryPayer[0] && payers.push(primaryPayer[0]);
    secondaryPayer[0] && payers.push(secondaryPayer[0]);
    tertiaryPayer[0] && payers.push(tertiaryPayer[0]);
    formik.setValues((preVals) => ({
      ...preVals,
      patientFirstName: val?.patientFirstName,
      patientLastName: val?.patientLastName,
      patientAccountNo: val?.patientAccountNo,
      patientId: val?.patientId,
      renderingProviderId: val?.renderingProviderId,
      renderingProviderFirstName: val?.renderingProviderFirstName,
      renderingProviderLastName: val?.renderingProviderLastName,
      renderingProviderSequence: val?.renderingProviderSequence,
      billingProviderFirstName: val?.billingProviderFirstName,
      billingProviderLastName: val?.billingProviderLastName,
      billingProviderSequence: val?.billingProviderSequence,
      billingProviderId: val?.billingProviderId,
      providerId: val?.providerId,
      practiceAddress: val?.practiceAddress,
      practiceId: val?.practiceId,
      practiceSequenceNo: val?.practiceSequenceNo,
      practiceName: val?.practiceName,
      policyTypeDto: payers,
      claimSummaryDto: {
        ...preVals.claimSummaryDto,
        firstName: val?.patientFirstName,
        lastName: val?.patientLastName,
        accountNo: val?.patientAccountNo,
        dateOfBirth: val?.dateOfBirth,
        patientId: val?.patientId,
      },
    }));
    setOpenPatientModal(false);
  };

  useEffect(() => {
    if (!formik.values?.typeOfBill) {
      formik.setFieldValue(
        "typeOfBill",
        `${formik.values?.facilityTypeId}${formik.values?.careTypeId}${formik.values?.frequencyTypeId}`
      );
    }
  }, []);

  // set claim values directly from patient
  useEffect(() => {
    if (patientAccNo !== undefined) {
      try {
        dispatch(getPatientClaimsDetailAction()).then(() => {
          const findPatient = patientClaimsData?.find(
            (item) => item?.patientAccountNo === Number(patientAccNo)
          );
          formik.setValues((preVals) => ({
            ...preVals,
            claimTypeId: location?.state?.claimType || 1,
            patientFirstName: findPatient?.patientFirstName,
            patientLastName: findPatient?.patientLastName,
            patientAccountNo: findPatient?.patientAccountNo,
            patientId: findPatient?.patientId,
            renderingProviderId: findPatient?.renderingProviderId,
            renderingProviderFirstName: findPatient?.renderingProviderFirstName,
            renderingProviderLastName: findPatient?.renderingProviderLastName,
            renderingProviderSequence: findPatient?.renderingProviderSequence,
            billingProviderFirstName: findPatient?.billingProviderFirstName,
            billingProviderLastName: findPatient?.billingProviderLastName,
            billingProviderSequence: findPatient?.billingProviderSequence,
            billingProviderId: findPatient?.billingProviderId,
            providerId: findPatient?.providerId,
            payerSequenceNo:
              findPatient?.claimInsuredDetailDtos[0]?.payerInfoSequenceNo,
            primaryPayerInsuranceName: `${findPatient?.claimInsuredDetailDtos[0]?.payerInfoName}`,
            payerInfoId: findPatient.claimInsuredDetailDtos[0]?.payerInfoId,
            primaryPayerMemberId:
              findPatient.claimInsuredDetailDtos[0]?.payerInfoMemberId,
            primaryPayerPolicyId:
              findPatient.claimInsuredDetailDtos[0]?.payerInfoPolicyTypeId,
            practiceAddress: findPatient?.practiceAddress,
            practiceId: findPatient?.practiceId,
            practiceSequenceNo: findPatient?.practiceSequenceNo,
            practiceName: findPatient?.practiceName,
          }));
        });
      } catch (e) {
        console.log(e, ": fetching patient calim detail in claim");
        alert("Something went wrong");
      }
    }
  }, [dispatch, formik, location, patientAccNo, patientClaimsData]);

  const handlePayer = (params, isNew) => {
    let allPayer = formik.values.policyTypeDto;
    let payer = {
      ...payerPolicyTypeInClaim,
      ...params,
      payerId: params?.payerId,
      payerName: params?.payerName,
      payerSequenceNo: params?.payerSequenceNo,
      priorityId: openInsuredParty.payerPriorityId,
    };
    let findPayer = formik.values?.policyTypeDto?.findIndex(
      (e) => e?.priorityId === openInsuredParty.payerPriorityId
    );
    if (findPayer !== -1) {
      allPayer[findPayer] = payer;
    } else {
      allPayer.splice(openInsuredParty.payerPriorityId - 1, 0, payer);
    }
    formik.setValues((preVals) => ({
      ...preVals,
      policyTypeDto: allPayer,
    }));
    setOpenInsuredParty({
      openModal: false,
      payerPriorityId: null,
    });
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Box
          style={{
            borderRadius: "20px",
            background: theme.palette.innerBox.primary,
          }}
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            sx={{
              width: { xs: "95%", sm: "75%", md: "90%" },
              marginLeft: "40px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            flexDirection="column"
            gap={"20px"}
          >
            <Box
              display="grid"
              gap="30px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(3, minmax(0, 1fr))",
                  md: "repeat(3, minmax(0, 1fr))",
                },
                alignItems: "flex-end",
                width: { xs: "90%", sm: "100%", md: "100%" },
              }}
            >
              <FormControl>
                <CustomField
                  value={formik.values.claimNumber || null}
                  label="Claim#"
                  name="claimNumber"
                  disable={true}
                  // handleChange={formik.handleChange}
                  // handleBlur={formik.handleBlur}
                />
              </FormControl>

              <CustomField
                type="text"
                value={formik.values.referenceNumber || ""}
                handleChange={formik.handleChange}
                name="referenceNumber"
                handleBlur={formik.handleBlur}
                label="Reference #"
              />
              {formik.values.claimTypeId === 1 ? (
                <CustomSelectBox2
                  showDefaultOpt={true}
                  value={formik.values.claimFrequencyId}
                  name="claimFrequencyId"
                  dropdownOptions={frequencyOpt?.map((opt) => ({
                    value: opt.claimFrequencyName,
                    id: opt.claimFrequencyId,
                  }))}
                  label="Frequncy Type"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  formik={formik}
                  isRequired={true}
                />
              ) : (
                <CustomSearchField
                  label="Type of Bill"
                  type="text"
                  name="typeOfBill"
                  handleModalOpen={() => setOpenTypeOfBillModal(true)}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  fieldVal={
                    formik.values.typeOfBill ? formik.values.typeOfBill : ""
                  }
                  resetField={{
                    facilityTypeId: null,
                    careTypeId: null,
                    frequencyTypeId: null,
                    typeOfBill: "",
                  }}
                  formik={formik}
                  isRequired={true}
                />
              )}
            </Box>

            <Box
              display="grid"
              gap="30px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 1fr))",
                },
                width: { xs: "90%", sm: "100%", md: "100%" },
              }}
            >
              <CustomSearchField
                label="Patient"
                name="patientFirstName"
                isRequired={true}
                fieldVal={
                  formik.values.patientFirstName &&
                  `${formik.values.patientFirstName} ${formik.values.patientLastName} (${formik.values.patientAccountNo})`
                }
                // error={formik.errors}
                // touched={formik.touched}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                handleModalOpen={() => setOpenPatientModal(true)}
                resetField={{
                  patientFirstName: "",
                  patientLastName: "",
                  patientAccountNo: null,
                  patientId: null,
                }}
                formik={formik}
              />
              <CustomSearchField
                label={
                  formik.values.claimTypeId === 1
                    ? "Biling Provider"
                    : "Attending Provider"
                }
                isRequired={true}
                type="text"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                fieldVal={
                  formik.values.billingProviderFirstName &&
                  `${formik?.values?.billingProviderFirstName} ${formik?.values?.billingProviderLastName} (${formik?.values?.billingProviderSequence})`
                }
                name="billingProviderFirstName"
                resetField={{
                  billingProviderFirstName: "",
                  billingProviderLastName: "",
                  billingProviderSequence: null,
                  billingProviderId: null,
                }}
                handleModalOpen={() => setOpenBillingProviderMod(true)}
                formik={formik}
              />
            </Box>
            <Box
              display="grid"
              gap="30px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 1fr))",
                },
                width: { xs: "90%", sm: "100%", md: "100%" },
              }}
            >
              <CustomSearchField
                label="Rendering Provider"
                type="text"
                isRequired={true}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                fieldVal={
                  formik.values.renderingProviderFirstName &&
                  `${formik.values?.renderingProviderFirstName} ${formik.values?.renderingProviderLastName} (${formik.values?.renderingProviderSequence})`
                }
                name="renderingProviderFirstName"
                resetField={{
                  renderingProviderId: null,
                  renderingProviderFirstName: "",
                  renderingProviderLastName: "",
                  renderingProviderSequence: null,
                }}
                handleModalOpen={() => setOpenRenderingProviderMod(true)}
                formik={formik}
              />
              <CustomSearchField
                label="Facility"
                type="text"
                isRequired={true}
                formik={formik}
                handleModalOpen={() => setOpenFacilityModal(true)}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                fieldVal={
                  formik.values.facilityName &&
                  `${formik.values.facilityName} (${formik.values.facilitySequence})`
                }
                resetField={{
                  facilityId: null,
                  facilityName: "",
                  facilitySequence: null,
                }}
                name="facilityName"
              />

              <div style={{ marginTop: "4px" }}>
                <CustomField
                  label="Office Location"
                  type="text"
                  disable={true}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values.practiceAddress}
                  name="practiceAddress"
                />
              </div>
            </Box>

            <Box
              display="grid"
              gap="30px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(1, minmax(0, 1fr))",
                },
                width: { xs: "90%", sm: "100%", md: "100%" },
              }}
            >
              {priorityType.map((v, i) => {
                return (
                  <>
                    <CustomSearchField
                      label={`${v ? v?.priorityType : ""} Insurance`}
                      type="text"
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      fieldVal={
                        formik.values?.policyTypeDto?.find(
                          (val) => val?.priorityId === v?.priorityTypeId
                        )?.payerName
                          ? `${
                              formik.values?.policyTypeDto.find(
                                (val) => val?.priorityId === v?.priorityTypeId
                              )?.payerName
                            } (${
                              formik.values?.policyTypeDto.find(
                                (val) => val?.priorityId === v?.priorityTypeId
                              )?.payerSequenceNo
                            })`
                          : ""
                      }
                      name="primaryPayerInsuranceName"
                      handleModalOpen={() =>
                        setOpenInsuredParty({
                          openModal: true,
                          payerPriorityId: v?.priorityTypeId,
                        })
                      }
                      resetField={() => {
                        let updatedPayer = formik.values?.policyTypeDto?.filter(
                          (el) => el?.priorityId !== v?.priorityTypeId
                        );
                        return {
                          policyTypeDto: updatedPayer,
                        };
                      }}
                      formik={formik}
                    />

                    {formik.values?.policyTypeDto &&
                      formik.values?.policyTypeDto.find(
                        (val, ind) => val.priorityId === v.priorityTypeId
                      ) && (
                        <CustomAccordion
                          defaultExpanded={false}
                          onChange={handleAccordionChange}
                          heading={`${v ? v?.priorityType : ""} Insurance`}
                        >
                          <AccordionDetails>
                            <Box
                              display="grid"
                              gap="30px"
                              sx={{
                                gridTemplateColumns: {
                                  xs: "repeat(1, minmax(0, 1fr))",
                                  sm: "repeat(3, minmax(0, 1fr))",
                                  md: "repeat(3, minmax(0, 1fr))",
                                },
                              }}
                            >
                              <CustomField
                                type="text"
                                label="Member ID"
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                value={
                                  formik.values?.policyTypeDto[i]?.memberId ||
                                  ""
                                }
                                name={`policyTypeDto[${i}].memberId`}
                              />
                              <CustomSelectBox2
                                value={
                                  formik.values?.policyTypeDto[i]
                                    ?.policyTypeId || null
                                }
                                // name="primaryPayerPolicyId"
                                name={`policyTypeDto[${i}].policyTypeId`}
                                dropdownOptions={policyTypeOpt?.map((opt) => ({
                                  value: opt.policyType,
                                  id: opt.policyTypeId,
                                }))}
                                label="Policy Type"
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                formik={formik}
                              />
                              <CustomField
                                type="number"
                                label="Copay Due"
                                isDecimal={true}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                value={
                                  formik.values?.policyTypeDto[i]
                                    ?.copayAmount || 0
                                }
                                name={`policyTypeDto[${i}].copayAmount`}
                                // name="primaryPayerCopayDue"
                              />
                            </Box>
                            <Box
                              display="grid"
                              marginTop={"30px"}
                              gap="30px"
                              sx={{
                                gridTemplateColumns: {
                                  xs: "repeat(1, minmax(0, 1fr))",
                                  sm: "repeat(3, minmax(0, 1fr))",
                                  md: "repeat(3, minmax(0, 1fr))",
                                },
                              }}
                            >
                              <CustomField
                                type="text"
                                label="Group Number"
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                value={
                                  formik.values?.policyTypeDto[i]?.groupId || ""
                                }
                                // name="primaryPayerGroupId"
                                name={`policyTypeDto[${i}].groupId`}
                              />
                            </Box>
                          </AccordionDetails>
                        </CustomAccordion>
                      )}
                  </>
                );
              })}
            </Box>
            {/* {formik.values?.policyTypeDto && formik.values?.policyTypeDto.map((v, i) => {
          // let currentIndex = formik.values?.policyTypeDto?.findIndex((val) => val?.priorityId === v?.priorityTypeId)
          // if (currentIndex !== undefined) {
          return (
            <CustomAccordion
              defaultExpanded={false}
              onChange={handleAccordionChange}
              heading={`Show ${v?.priorityId === 1 ? "Primary" : v?.priorityId === 2 ? "Secondary" : "Tertiary"}  Policy Details`}
            >
              <AccordionDetails>
                <Box
                  display="grid"
                  gap="30px"
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(3, minmax(0, 1fr))",
                      md: "repeat(3, minmax(0, 1fr))",
                    },
                  }}
                >
                  <CustomField
                    type="number"
                    label="Member ID"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values?.policyTypeDto[i]?.memberId || ''}
                    name={`policyTypeDto[${i}].memberId`}

                  />

                  <CustomSelectBox2
                    value={formik.values?.policyTypeDto[i]?.policyTypeId || null}
                    // name="primaryPayerPolicyId"
                    name={`policyTypeDto[${i}].policyTypeId`}
                    dropdownOptions={policyTypeOpt?.map((opt) => ({
                      value: opt.policyType,
                      id: opt.policyTypeId,
                    }))}
                    label="Policy Type"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    formik={formik}
                  />
                  <CustomField
                    type="number"
                    label="Copay Due"
                    isDecimal={true}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values?.policyTypeDto[i]?.copayAmount || 0}
                    name={`policyTypeDto[${i}].copayAmount`}
                  // name="primaryPayerCopayDue"
                  />
                </Box>
                <Box
                  display="grid"
                  marginTop={"30px"}
                  gap="30px"
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(3, minmax(0, 1fr))",
                      md: "repeat(3, minmax(0, 1fr))",
                    },
                  }}
                >
                  <CustomField
                    type="number"
                    label="Group Number"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values?.policyTypeDto[i]?.groupId || ''}
                    // name="primaryPayerGroupId"
                    name={`policyTypeDto[${i}].groupId`}
                  />
                </Box>
              </AccordionDetails>
            </CustomAccordion>
          )
          // }
        })} */}
          </Box>
        </Box>
      )}

      <CustomModal
        open={openPatientModal}
        handleClose={() => setOpenPatientModal(false)}
        height={"20px"}
        heading="Patients"
      >
        <CustomPreview
          searchLabel="Search by Claim Id,Patient Name,Dos"
          rows={
            patientClaimsData &&
            patientClaimsData?.result?.patientClaim?.length > 0
              ? patientClaimsData?.result?.patientClaim?.map((row) => ({
                  id: row?.patientId,
                  ...row,
                }))
              : []
          }
          columns={patientClaimDetailCols}
          loading={getPatientClaimDetailLoad}
          handleGetAll={getPatientClaimsDetailAction}
          handleBySearch={getPatientClaimBySearchTerm}
          paginationDetail={patientClaimsData}
          handleCell={handleAddPatientClaim}
          isModal={true}
        />
      </CustomModal>
      <CustomModal
        open={openTypeOfBillModal}
        handleClose={() => setOpenTypeOfBillModal(false)}
        heading={"Type Of Bill"}
      >
        <TypeOfBill
          handleClose={() => setOpenTypeOfBillModal(false)}
          setOpenTypeOfBillModal={setOpenTypeOfBillModal}
          formik={formik}
        />
      </CustomModal>
      <CustomModal
        open={openFacilityModal}
        handleClose={() => setOpenFacilityModal(false)}
        height={"20px"}
        heading={"Facility"}
      >
        <CustomPreview
          isModal={true}
          rows={
            getFacilityData?.result &&
            getFacilityData.result?.facility?.length > 0
              ? getFacilityData.result?.facility?.map((el) => ({
                  id: el.facilityId,
                  facilityName: el.facilityName,
                  phoneNumber: el.phoneNumber,
                  sequenceNo: el.sequenceNo,
                  taxonomySpecialty: el.taxonomySpecialty,
                }))
              : []
          }
          columns={facilityCols}
          loading={facilityLoading}
          handleGetAll={getFacilityAction}
          paginationDetail={getFacilityData}
          handleBySearch={getFacilityBySearchTermAction}
          searchLabel="Search by First Name, Address, NPI, Phone ,or Sequence Number"
          handleCell={(params) => {
            formik.setFieldValue("facilityId", params.id);
            formik.setFieldValue("facilityName", params.facilityName);
            formik.setFieldValue("facilitySequence", params.sequenceNo);
            setOpenFacilityModal(false);
          }}
        />
      </CustomModal>

      <CustomModal
        open={openBillingProviderMod}
        handleClose={() => setOpenBillingProviderMod(false)}
        heading={"Billing Provider"}
        height="200px"
      >
        <CustomPreview
          rows={
            getProviders?.result?.providers.length > 1
              ? getProviders?.result?.providers?.map((el) => ({
                  id: el?.providerId,
                  ...el,
                }))
              : []
          }
          columns={providerCols}
          handleGetAll={getProviderAction}
          paginationDetail={getProviders}
          handleBySearch={getProviderBySearchTermAction}
          isModal={true}
          loading={providerLoading}
          searchLabel="Search By First Name and Sequence No"
          moduleHeading={"Billing Provider"}
          handleCell={(params) => {
            console.log("params", params);
            formik.setValues((preVals) => ({
              ...preVals,
              billingProviderFirstName: params?.providerFirstName,
              billingProviderLastName: params?.providerLastName,
              billingProviderSequence: params?.providerSequenceNo,
              billingProviderId: params?.id,
            }));
            setOpenBillingProviderMod(false);
          }}
        />
      </CustomModal>

      <CustomModal
        open={openRenderingProviderMod}
        handleClose={() => setOpenRenderingProviderMod(false)}
        heading={"Rendering Provider"}
      >
        {/* <RenderingProvider
          handleClose={() => setOpenRenderingProviderMod(false)}
          setValues={formik.setValues}
        /> */}
        <CustomPreview
          rows={
            getProviders?.result?.providers.length > 1
              ? getProviders?.result?.providers?.map((el) => ({
                  id: el?.providerId,
                  ...el,
                }))
              : []
          }
          columns={providerCols}
          handleGetAll={getProviderAction}
          paginationDetail={getProviders}
          handleBySearch={getProviderBySearchTermAction}
          isModal={true}
          loading={renderingLoading}
          searchLabel="Search By First Name and Sequence No"
          handleCell={(params) => {
            formik.setValues((preVals) => ({
              ...preVals,
              renderingProviderId: params?.id,
              renderingProviderFirstName: params?.providerFirstName,
              renderingProviderLastName: params?.providerLastName,
              renderingProviderSequence: params?.providerSequenceNo,
            }));
            setOpenRenderingProviderMod(false);
          }}
        />
      </CustomModal>

      <CustomModal
        open={openInsuredParty.openModal}
        handleClose={() =>
          setOpenInsuredParty({
            openModal: false,
            payerPriorityId: null,
          })
        }
        height="70%"
        heading={`${
          openInsuredParty.payerPriorityId === 1
            ? "Primary"
            : openInsuredParty.payerPriorityId === 2
            ? "Secondary"
            : "Tertiary"
        } Insurance`}
      >
        <ClaimPayer
          openInsuredParty={openInsuredParty}
          formik={formik}
          handlePayer={handlePayer}
        />
        {/* <CustomPreview
          rows={
            // getAllPayerInfo
            //   ? getAllPayerInfo?.map((el) => ({
            //       id: el?.payerInfoId,
            //       ...el,
            //     }))
            //   : []
            getAllPayer?.result && getAllPayer?.result?.length > 0
              ? getAllPayer.result.map((row) => ({
                ...row,
                id: row.payerId,
              }))
              : []
          }
          isModal={true}
          moduleHeading={"Payer Info"}
          columns={primaryInsPayerCol}
          handleGetAll={getPayerAction}
          loading={payerInfoLoading}
          searchLabel={"Search by Payer ID and Sequence #"}
          handleCell={(params) => {
            let payer = {
              ...payerPolicyTypeInClaim,
              payerId: params?.payerId,
              payerName: params?.payerName,
              payerSequenceNo: params?.payerSequenceNo,
              priorityId: openInsuredParty.payerPriorityId,
            }
            let findPayer = formik.values?.policyTypeDto?.findIndex((e) => e?.priorityId === openInsuredParty.payerPriorityId)
            let allPayer = formik.values.policyTypeDto
            if (findPayer !== -1) {
              allPayer[findPayer] = payer
            } else {
              allPayer.splice(openInsuredParty.payerPriorityId - 1, 0, payer)
            }
            formik.setValues((preVals) => ({
              ...preVals,
              policyTypeDto: allPayer,
            }));
            setOpenInsuredParty(false);
          }}
        /> */}
      </CustomModal>
    </>
  );
};

export default ClaimInfo;
