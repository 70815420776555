import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { newIcdProcedureAction } from "../actions/icdProcedureAction";
import { getICDProcedureCodeAction } from "../actions/icdProcedureAction";
import { updateICDProcedureAction } from "../actions/icdProcedureAction";

const initialState = {
  getIcdProcedureData: [],
  createIcdProcedure: {},
  error: null,
  loading: false,
};
const icdProcedureSlice = createSlice({
  name: "icdProcedure",
  initialState: initialState,
  extraReducers: {
    [newIcdProcedureAction.pending]: (state) => {
        state.loading = true;
      },
      [newIcdProcedureAction.fulfilled]: (state, action) => {
        state.loading = false;
        state.createFacilityData = action.payload;
        toast.success("ICD Procedure Code has been created successfully!");
      },
      [newIcdProcedureAction.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error("ICD Procedure Code Creation Failed.");
      },



      [getICDProcedureCodeAction.pending]: (state) => {
        state.loading = true;
      },
      [getICDProcedureCodeAction.fulfilled]: (state, action) => {
        state.loading = false;
        state.getIcdProcedureData = action.payload.result;
        console.log(action.paylaod, "icd procedure slice get payload");
      },
      [getICDProcedureCodeAction.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
      [updateICDProcedureAction.pending]: (state) => {
        state.loading = true;
      },
      [updateICDProcedureAction.fulfilled]: (state, action) => {
        state.loading = false;
        toast.success("ICD Procedure Updated Successfully!");
      },
      [updateICDProcedureAction.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        console.log(state.error, "facilityErr");
        toast.error("ICD Procedure Updation Failed.");
      },
  }}) 

  export default icdProcedureSlice.reducer;