import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "../../../components/Loading";
import DoneIcon from "@mui/icons-material/Done";
import CustomButton from "../../../components/CustomButton";
import { postData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { toast } from "react-toastify";

const icdProcedureColForMultiple = [
  {
    field: "icdCode",
    headerName: "ICD Code",
    minWidth: 160,
    headerAlign: "center",
    filterable: true,
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    flex: 3,
    align: "center",
  },
  {
    field: "isAdded",
    headerName: "isAdded",
    minWidth: 160,
    flex: 3,
    headerAlign: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    filterable: true,
    align: "center",
    renderCell: (params) => <span>{params.value ? <DoneIcon /> : ""}</span>,
  },
  {
    field: "icdCodeType",
    headerName: "ICD Code Type",
    minWidth: 160,
    headerAlign: "center",
    filterable: true,
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    flex: 3,
    align: "center",
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 160,
    flex: 3,
    headerAlign: "center",
    headerClassName: "header-bg",
    cellClassName: "cell-content",
    filterable: true,
    align: "center",
  },
];
export const ICDCodeMasterList = ({
  loading,
  multiICDs,
  setOpenMultipleICDTable,
  handleClose,
}) => {
  const reducedCellHeight = 35;
  const theme = useTheme();
  const [selectedIDC, setSelectedICD] = useState([]);

  const handleAddMultiICD = async () => {
    const selectedAllICDs = selectedIDC?.map((id) =>
      multiICDs?.find((el) => el.id === id)
    );
    console.log("selectedAllICDs", selectedAllICDs);
    const iCDUpdateValue = selectedAllICDs.map((val) => ({
      icdCode: val.icdCode,
      icdCodeTypeId: val.icDcodetypeId,
      icdCodeType: val.icDcodetype,
      description: val.description,
    }));
    try {
      const response = await postData(
        `${path}/ICDProcedureCode`,
        iCDUpdateValue
      );
      if (response) {
        console.log(response, "check posting remark response");
        toast.success("ICD code added successfully");
        setOpenMultipleICDTable(false);
        handleClose();
        return response;
      }
    } catch (err) {
      console.log(err, ": Error inposting remittance remark");
      setOpenMultipleICDTable(false);
      toast.error(err.response.data.message);
    }
  };

  return (
    <Box>
      {loading ? (
        <Loading />
      ) : (
        <DataGrid
          rows={multiICDs}
          columns={icdProcedureColForMultiple}
          sx={{
            "& .header-bg": {
              background: theme.palette.dataGridHeading.primary,
              color: theme.palette.textMainHeading.primary,
              fontSize: "16px",
            },
            "& .cell-content": {
              fontSize: "1.2rem",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-actions": {
              "& svg": {
                fontSize: "1.8rem",
                color: theme.palette.textMainHeading.primary,
                marginBottom: "8px",
              },
            },
          }}
          autoHeight
          rowHeight={reducedCellHeight}
          checkboxSelection
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSize={20}
          onSelectionModelChange={(id) => {
            setSelectedICD(id);
          }}
          components={{
            NoRowsOverlay: () => (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "16px",
                }}
              >
                {multiICDs.length === 0 && "No Data Is Added"}
              </div>
            ),
          }}
        />
      )}

      <div
        style={{
          position: "sticky",
          bottom: "0",
          borderTop: ".5px solid grey",
          width: "100%",
          background: "#fff",
          padding: "10px",
        }}
      >
        <CustomButton
          isBlue={true}
          isAdd={true}
          handleClick={handleAddMultiICD}
        >
          Add
        </CustomButton>
      </div>
    </Box>
  );
};
