import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAdjustmentAction,
  newAdjustmentAction,
  updateAdjustmentAction,
} from "../actions/adjustmentAction";

const initialState = {
  createAdjustmentCode: {},
  getAdjustmentCodes: [],
  loading: false,
  error: null,
};
const adjustmentSlice = createSlice({
  name: "adjustmentCodes",
  initialState: initialState,
  extraReducers: {
    [getAdjustmentAction.pending]: (state) => {
      state.loading = true;
    },
    [getAdjustmentAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getAdjustmentCodes = action.payload;
      console.log(action.payload, "adjustment slice get payload");
    },
    [getAdjustmentAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [newAdjustmentAction.pending]: (state) => {
      state.loading = true;
    },
    [newAdjustmentAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createAdjustmentCode = action.payload;
      toast.success("Adjustment has been created successfully!");
    },
    [newAdjustmentAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Adjustment creation failed!");
    },
    [updateAdjustmentAction.pending]: (state) => {
      state.loading = true;
    },
    [updateAdjustmentAction.fulfilled]: (state, action) => {
      state.loading = false;
      const findAdjustmentIndex = state.getAdjustmentCodes.result?.findIndex(
        (val) => val.adjustmentCodeId === action.payload.adjustmentCodeId
      );
      if (findAdjustmentIndex !== -1) {
        state.getAdjustmentCodes.result[findAdjustmentIndex] = {
          ...state.getAdjustmentCodes.result[findAdjustmentIndex],
          ...action.payload,
        };
      }
      toast.success("Adjustment has been updated successfully!");
    },
    [updateAdjustmentAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Adjustment updation failed!");
    },
  },
});

export default adjustmentSlice.reducer;
