import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getPracticeAction,
  getPracticeBySearchTermAction,
} from "../../../features/actions/practiceAction";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton";
import CustomPreview from "../../../layout/CustomPreview";
import { practiceCols } from "../../../utils/gridCols";

const Practice = () => {
  const navigate = useNavigate();
  const { getPractices, loading } = useSelector((state) => state.practices);
  console.log(getPractices, "getPractices");

  const rows =
    getPractices && getPractices?.result?.practice?.length > 0
      ? getPractices?.result?.practice?.map((el) => ({
          id: el.practiceId,
          ...el,
        }))
      : [];

  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={practiceCols}
        paginationDetail={getPractices}
        searchLabel="Search for practices by name, phone #, sequence #"
        loading={loading}
        handleCell={(params) =>
          navigate(`/practice/update/${params?.sequenceNo}`)
        }
        moduleHeading="All Practices"
        handleGetAll={getPracticeAction}
        handleBySearch={getPracticeBySearchTermAction}
      >
        <Stack direction="row" alignItems="center">
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => navigate("/practice/new")}
          >
            Add New
          </CustomButton>
        </Stack>
      </CustomPreview>
    </Box>
  );
};

export default Practice;
