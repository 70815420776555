import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  TextField,

  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomField from "../../../components/CustomField";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomModal from "../../../components/CustomModal";
import TaxonomyCategories from "./taxonomy/TaxonomyCategories";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import SearchNpi from "./npi/SearchNpi";
import { ExpandMore } from "@mui/icons-material";
import CustomButton from "../../../components/CustomButton";
import CustomAccordion from "../../../components/CustomAccordion";
import TextArea from "../../../components/TextArea";

const PracticeInfo = ({ formik }) => {

  const theme = useTheme();
  const mode = theme.palette.mode; // Extract the mode from the theme
  const colors = theme.palette; // Extract the colors from the theme

  const [taxonomyListModal, setTaxonomyListModal] = useState(false);
  const [searchNpiModal, setSearchNpiModal] = useState(false);
  const [organizationType, setOrganizationType] = useState([]);
  const [primaryCityOpt, setPrimaryCityOpt] = useState([]);
  const [payToCityOpt, setPayToCityOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [taxonomyName, setTaxonomyName] = useState("");

  // Define data fetching URLs
  const dataFetchUrls = {
    organizationType: `${path}/ct-organizationType`,
    cityType: `${path}/city`,
    stateType: `${path}/state`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.organizationType, setOrganizationType);
    fetchDataOptions(dataFetchUrls.stateType, setStateOpt);
  }, []);

  useEffect(() => {
    if (formik.values.primaryStateId) {
      fetchDataOptions(
        `${path}/city?stateId=${formik.values.primaryStateId}`,
        setPrimaryCityOpt
      );
    }
    if (formik.values.payToStateId) {
      fetchDataOptions(
        `${path}/city?stateId=${formik.values.payToStateId}`,
        setPayToCityOpt
      );
    }
  }, [formik.values.primaryStateId, formik.values.payToStateId]);

  const handleSelectTaxonomy = (taxonomyCode) => {
    console.log(taxonomyCode, "taxCode");
    formik.setFieldValue("taxonomySpecialty", taxonomyCode);
  };

  const handleSelectNpi = (npiNum) => {
    formik.setFieldValue("practiceNpiId", npiNum.id);
    formik.setFieldValue("practiceNpi", npiNum.npiNo);
  };

  //   handle checkbox change
  const handleCheckboxChange = () => {
    formik.setFieldValue(
      "isPayToAddressSame",
      !formik.values.isPayToAddressSame
    );

    if (formik.values.isPayToAddressSame) {
      formik.setFieldValue("payToAddress", "");
      formik.setFieldValue("payToCityId", null);
      formik.setFieldValue("payToStateId", null);
      formik.setFieldValue("payToZipCode", "");
    } else {
      formik.setFieldValue("payToAddress", formik.values.primaryAddress);
      formik.setFieldValue("payToCityId", formik.values.primaryCityId);
      formik.setFieldValue("payToStateId", formik.values.primaryStateId);
      formik.setFieldValue("payToZipCode", formik.values.primaryZipCode);
    }
  };
  return (
    <>
      <CustomModal
        open={taxonomyListModal}
        handleClose={() => setTaxonomyListModal(false)}
        width={'50%'}
       height={'20px'}
      >
        <TaxonomyCategories
          handleSelectTaxonomy={handleSelectTaxonomy}
          setTaxonomyName={setTaxonomyName}
          handleClose={() => setTaxonomyListModal(false)}
        />
      </CustomModal>
      {/* npi modal */}
      <CustomModal
        open={searchNpiModal}
        handleClose={() => setSearchNpiModal(false)}
        width={'50%'}
        minHeight={'70%'}
      >
        <SearchNpi
          handleSelectNpi={handleSelectNpi}
          handleClose={() => setSearchNpiModal(false)}
          setSearchNpiModal={setSearchNpiModal}
        />
      </CustomModal>
      <Box sx={{background:theme.palette.accordiansBodyBackground.primary,padding:"15px",margin:"0 0 20px",border:"1px solid #D3D3D3",borderRadius:"4px"}}>
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(1, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          label="Name"
          type="text"
          value={formik.values.practiceName}
          isRequired={true}
          touched={formik.touched}
          error={formik.errors}
          name="practiceName"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
      </Box>
      <Box
        display="grid"
        margin={"20px 0"}
        gap="30px"
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(2, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
        <CustomSearchField
          type="number"
          fieldVal={formik.values.practiceNpi}
          isRequired={true}
          formik={formik}
          resetField={{
            practiceNpi:null
          }}
          name="practiceNpi"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          label="NPI"
          handleModalOpen={() => setSearchNpiModal(true)}
        />
        <div style={{ marginTop: "4px" }}>
          <CustomSelectBox2
            value={formik.values.organizationTypeId}
            name="organizationTypeId"
            dropdownOptions={organizationType?.map((opt) => ({
              value: opt.organizationTypeName,
              id: opt.organizationTypeId,
            }))}
            label="Organization Type"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
          />
        </div>
      </Box>

      <Box
        display="grid"
        margin={"20px 0"}
        gap="30px"
        alignItems={"center"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(2, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
        {console.log(formik.values)}
        <CustomSearchField
          fieldVal={formik.values.taxonomySpecialty}
          isRequired={true}
          formik={formik}
          resetField={{
            taxonomySpecialty:""
          }}
          name="taxonomySpecialty"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          label="Taxonomy"
          handleModalOpen={() => setTaxonomyListModal(true)}
        />
        {/* <CustomSearchField
              type="text"
              size="small"
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "100%" },
                fontSize: "1rem",
              }}
              value={values.taxonomySpeciality}
              name="taxonomySpeciality"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Taxonomy"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={() => setTaxonomyListModal(true)}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
        {taxonomyName && (
          <Typography variant="h4" component="h4">
            {taxonomyName}
          </Typography>
        )}
      </Box>
      <Box marginTop={"30px"}>
        <Typography variant="h3" fontWeight={"500"}>
          Sequence
        </Typography>
      </Box>

      <Box
        display="grid"
        margin={"20px 0"}
        gap="10px"
        alignItems="flex-end"
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(2, minmax(0, 1fr))",
            md: "repeat(4, minmax(0, 1fr))",
          },
        }}
      >
        {/* <TextField
              size="small"
              fullWidth
              variant="filled"
              type="number"
              placeholder="#New"
              disabled
              value={values.sequenceNo}
              name="sequenceNo"
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ gridColumn: "span 1" }}
            /> */}
        {/* <FormControl>
          <label
            style={{
             
   color: theme.palette.Labeltext.primary, 
              fontFamily: "Poppins",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            New#
          </label>
          <TextField
            size="small"
            type="text"
            fullWidth
            variant="filled"
            disabled
            style={{  background:theme.palette.customFieldsBackground.primary,  fontSize: "1.5rem" }}
          />
          
        </FormControl> */}
        <CustomField
          disable={true}
          type="text"
          label={formik.values.sequenceNo ? "" : "New #"}
          value={formik.values.sequenceNo || null}
          // name="referenceNo"
          // handleChange={formik.handleChange}
          // handleBlur={formik.handleBlur}
        />
        <CustomField
          label="Refrence#"
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          type="number"
          value={formik.values.referenceNo}
          name="referenceNo"
        />

        <CustomField
          type="text"
          label="TCN Prefix"
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          value={formik.values.tcnPrefix}
          name="tcnPrefix"
        />
        <CustomField
          type="text"
          label="Code"
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          value={formik.values.code}
          name="code"
        />
      </Box>
      </Box>
      <CustomAccordion defaultExpanded={true} heading={"Primary Office"}>
          <Box
            display="grid"
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(1, minmax(0, 1fr))",
              },
            }}
          >
            <TextArea
              placeholder="Address"
              value={formik.values.primaryAddress}
              isRequired={true}
              formik={formik}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              name="primaryAddress"
              minRows={"5"} 
            />
          </Box>

          <Box
            display="grid"
            margin={"10px 0"}
            gap="10px"
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(2, minmax(0, 1fr))",
                md: "repeat(3, minmax(0, 1fr))",
              },
            }}
          >
          
          <CustomSelectBox2
              value={formik.values.primaryStateId}
              name="primaryStateId"
              dropdownOptions={stateOpt?.map((opt) => ({
                value: opt.stateName,
                id: opt.stateId,
              }))}
              label="State"
              handleChange={()=>{
                formik.setFieldValue("primaryCityId" , null)
              }}
              handleBlur={formik.handleBlur}
              formik={formik}
            />

            <CustomSelectBox2
              value={formik.values.primaryCityId}
              disable={!formik.values.primaryStateId}
              name="primaryCityId"
              dropdownOptions={primaryCityOpt?.map((opt) => ({
                value: opt.cityName,
                id: opt.cityId,
              }))}
              label="City"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
            />
       

            <CustomField
              type="text"
              label="Zipcode"
              handleBlur={formik.handleBlur}
              isRequired={true}
              error={formik.errors}
              touched={formik.touched}
              handleChange={formik.handleChange}
              value={formik.values.primaryZipCode}
              name="primaryZipCode"
            />
          </Box>
          <Box
            display="grid"
            margin={"20px 0"}
            gap="10px"
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(2, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              type="number"
              label="Phone"
              handleBlur={formik.handleBlur}
              isRequired={true}
              error={formik.errors}
              touched={formik.touched}
              handleChange={formik.handleChange}
              value={formik.values.primaryPhone}
              name="primaryPhone"
            />

            <CustomField
              type="text"
              label="Fax"
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              value={formik.values.primaryFax}
              name="primaryFax"
            />
          </Box>
      </CustomAccordion>


      <Box marginTop={"20px"}>
        <label style={{ fontSize: "1.4rem" }}>
          <input
            type="checkbox"
            name="isPayToAddressSame"
            checked={formik.values.isPayToAddressSame}
            onChange={handleCheckboxChange}
          />{" "}
          Pay-To address is the same as the primary office address
        </label>
      </Box>
      {!formik.values.isPayToAddressSame && (
        <CustomAccordion defaultExpanded={true} heading={"Pay-To Address"}>
   
            <Box
              display="grid"
              gap="10px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(1, minmax(0, 1fr))",
                },
              }}
            >
              <TextArea
                placeholder="Address"
                value={formik.values.payToAddress}
               handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                name="payToAddress"
                minRows={"5"}
              />
            </Box>

            <Box
              display="grid"
              margin={"20px 0"}
              gap="10px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(3, minmax(0, 1fr))",
                },
              }}
            >
              {/* state */}
              <CustomSelectBox2
                value={formik.values.payToStateId}
                name="payToStateId"
                dropdownOptions={stateOpt?.map((opt) => ({
                  value: opt.stateName,
                  id: opt.stateId,
                }))}
                label="State"
                handleChange={()=>{
                  formik.setFieldValue("payToCityId" , null)
                }}
                handleBlur={formik.handleBlur}
                formik={formik}
              />
              {/* city */}
              <CustomSelectBox2
                value={formik.values.payToCityId}
                disable={!formik.values.payToStateId}
                name="payToCityId"
                dropdownOptions={payToCityOpt?.map((opt) => ({
                  value: opt.cityName,
                  id: opt.cityId,
                }))}
                label="City"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                formik={formik}
              />
              

              <CustomField
                type="text"
                label="ZipCode"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                value={formik.values.payToZipCode}
                name="payToZipCode"
              />
            </Box>
        </CustomAccordion>
      )}
    </>
  );
};

export default PracticeInfo;
