import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

export const createNewPayerAction = createAsyncThunk(
  "createNewPayer",
  async (data) => {
    console.log(data, "payerData")
    try {
      const response = await postData(`${path}/payer`, data);
      if (response) {
        return response;
      } 
    } catch (error) {

      throw new Error(error);
    }
  }
);

export const updatePayerAction = createAsyncThunk(
  "updatePayer",
  async (data) => {
    try {
      const response = await updateData(`${path}/payer/${data.payerId}`, data);
      console.log(response, "action update payer response");
    } catch (error) {
      console.log(error, "Error in updating payer");
    }
  }
);

// export const getPayerAction = createAsyncThunk("getPayer", async (page) => {
//   try {
//     const response = await getData(`${path}/payer?page=${page}&pageSize=5`);
//     if (response) {
//       return response;
//     } else {
//       throw new Error(response.error);
//     }
//   } catch (error) {
//     throw new Error(error);
//   }
// });

export const getPayerAction = createAsyncThunk("getPayer", async (page) => {
  console.log(page, "checkPayerPage")
  try {
    const response = await getData(`${path}/payer?page=${page}&pageSize=20`);
    console.log(response, "Fetched Data");
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});


// search
export const getPayerBySearchTermAction = createAsyncThunk(
  "getPayer",
  async (searchTerm) => {
    try {
      const response = await getData(
        `${path}/payer/SearchPayer?searchValue=${searchTerm?.searchTerm}`
      );
      console.log(response, "action Payer by search term");
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
