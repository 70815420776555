import React, { useEffect,useState } from "react";
import { Box,Typography } from "@mui/material";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector   , useDispatch} from "react-redux";
import UserForm from "./UserForm";
import { useFormik } from "formik";
import { updateUserAction } from "../../../features/actions/userAction";
import { updateUserSechema } from "../../../schemas/userSchema";
import CustomDialog from "../../../components/CustomDialog";

const UpdateUser = () => {
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  // const handleCancel = () => {
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/users");
  //   }
  // };
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/users");
  };
  const params = useParams();
  const dispatch = useDispatch()
  const { allUsers, loading } = useSelector((state) => state.users);
  let updateUser = allUsers?.result?.find((v) => v?.user?.id === params?.id);
  const formik = useFormik({
    initialValues: {
      username: updateUser?.user?.userName,
      roleName: updateUser?.role,
      email: updateUser?.user?.email,
    },
    validationSchema : updateUserSechema,
    onSubmit: () => {
     if (formik.values.username && formik.values.email && formik.values.roleName) {
       dispatch(updateUserAction({...formik.values , userId : params?.id}))
       navigate('/users')
     }else{
      alert("Please fill required field")
     }
    },
  });
  console.log("updateUser", updateUser);
  useEffect(() => {}, []);
  return (
    <>
    <Box margin="20px">
       <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
    
        <MainHeading mainheading={"Update Users"} />
        </Box>
    
      <form onSubmit={formik.handleSubmit}>
        <Box
          marginTop="30px"
          marginBottom="10px"
          width={{ xs: "100%", sm: "65%", md: "100%" }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "row", md: "row", sm: "column" },
          }}
        >
          <Box   display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}>
            <CustomButton
              isBlue={true}
              type="submit"
              isEdit={true}
            >
              Update
            </CustomButton>
            <CustomButton isBlue={false} isCancel={true} handleClick={handleCancel}>
           Cancel
            </CustomButton>
          </Box>
        </Box>
        <UserForm formik={formik} />
      </form>
    </Box>
      <CustomDialog
      open={openCancelDialog}
      isWarning={true}
      handleClose={() => setOpenCancelDialog(false)}
      handleConfirm={handleConfirmCancel}
      handleCancel={() => setOpenCancelDialog(false)}
      confirmButtonTitle="OK"
      cancelButtonTitle="Cancel"
     >
      <Typography component="h3">Are you sure you want to cancel?</Typography>
     </CustomDialog>
   </>
  );
};

export default UpdateUser;
