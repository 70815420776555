import React from "react";
import CustomField from "../../../components/CustomField";
import CustomButton from "../../../components/CustomButton";
import { Grid } from "@mui/material";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { useState, useEffect } from "react";
import { getData, postData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomModal from "../../../components/CustomModal";
import { useFormik } from "formik";
import { ICDCodeMasterList } from "./ICDCodeMasterList";


const SearchICDCodeMaster = ({ handleClose }) => {
  const [codeType, setCodeType] = useState([]);
  const [orderBy, setOrderBy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMultipleICDTable, setOpenMultipleICDTable] = useState(false);
  const [multiICDs, setMuiltICDs] = useState([]);

  const formik = useFormik({
    initialValues: {
      icdCodeId: 1,
      searchValue: "",
      orderby: 1,
    },
    onSubmit: async (val, act) => {
      try {
        setLoading(true);
        const response = await postData(
          `${path}/MasterICDProcedureCode/SearchMasterICTCodeByCategory`,
          val
        );
        let rowsData =
          response.result.length > 0
            ? response.result.map((val, ind) => {
                return {
                  id: val.masterICTProcedureCodeId,
                  icdCode: val.code,
                  icdCodeType: val.icDcodetype,
                  ...val,
                };
              })
            : [];
        setMuiltICDs(rowsData);
        setOpenMultipleICDTable(true);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
      }
    },
  });

  const fetchData = async (endPoint, setState) => {
    try {
      let response = await getData(`${path}${endPoint}`);
      setState(response.result);
    } catch (error) {
      console.log("error", error);
    }
  };

  const dataSource = {
    icdCodeType: "/CTICDcodetype",
    orderByList: "/ct-masterListOrderBy",
  };


  useEffect(() => {
    fetchData(dataSource.icdCodeType, setCodeType);
    fetchData(dataSource.orderByList, setOrderBy);
  }, []);
  return (
    <>
      <CustomModal
        open={openMultipleICDTable}
        handleClose={() => setOpenMultipleICDTable(false)}
        height={"20%"}
        heading={"ICD Procedure Master List"}
      >
        <ICDCodeMasterList loading={loading} multiICDs={multiICDs} setOpenMultipleICDTable={setOpenMultipleICDTable} handleClose={handleClose} />
      </CustomModal>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} alignItems="end" padding="18px">
          <Grid item md={12}>
            <CustomSelectBox2
              value={formik.values.icdCodeId}
              showDefaultOpt={true}
              name="icdCodeId"
              dropdownOptions={codeType?.map((opt) => ({
                value: opt?.cticDcode,
                id: opt?.cticDcodetypeId,
              }))}
              label="Select ICD Version to Display"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
            />
          </Grid>
          <Grid item md={12}>
            <CustomSelectBox2
              value={formik.values.orderby}
              showDefaultOpt={true}
              name="orderby"
              dropdownOptions={orderBy?.map((opt) => ({
                value: opt?.masterListOrderByName,
                id: opt?.masterListOrderById,
              }))}
              label="Order Results By"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
            />
          </Grid>
          <Grid item md={12}>
            <CustomField
              type="text"
              label="Enter Code to Search For"
              value={formik.values.searchValue}
              name="searchValue"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
          </Grid>
          <hr style={{ color: "grey" }} />
          <Grid item lg={3}>
            <CustomButton
              type="submit"
              isBlue={true}
              isSearch={true}
              //   handleClick={formik.handleSubmit}
            >
              Search
            </CustomButton>
          </Grid>
        </Grid>
      </form>
      {/* modals */}
      {/* <CustomModal
        open={openMasterMod}
        handleClose={() => setOpenMasterMod(false)}
        showAddButton={true}
        height={"20%"}
      >
        <MasterTable masterListData={masterListData} />
      </CustomModal> */}
    </>
  );
};

export default SearchICDCodeMaster;
