// import { useState, useEffect } from "react";
// import { getData } from "../../config/axiosFunctions";
// import path from "../../config/apiUrl";
// import {
//   Box,
//   FormControl,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   Typography,
//   Stack,
// } from "@mui/material";
// import CustomButton from "../../components/CustomButton";

// const LinkFamilyOpt= ({
//   formik,
//   handleLinkRelation,
//   insuredData,
//   setOpenLinkOptModal,
// }) => {
//   const [familyLinkOpt, setFamilyLinkOpt] = useState([]);

//   const handleGetFamilyLinks = async () => {
//     try {
//       const response = await getData(`${path}/ct-linkFamilyRelation`);
//       console.log(response, "responsedLinks");
//       if (response.status === "Success") {
//         setFamilyLinkOpt(response.result);
//       } else {
//         setFamilyLinkOpt([]);
//       }
//     } catch (error) {
//       console.log(error, "fething family links");
//     }
//   };



//   useEffect(() => {
//     handleGetFamilyLinks();
//   }, [setFamilyLinkOpt]);

//   return (
//     <Box padding="20px">
//       {console.log("formik.values3322", formik.values)}
//       <h5 style={{ fontWeight: "600" }}>
//         What is this patient's relationship to the insured?
//       </h5>
//       <FormControl sx={{ fontSize: "1.5rem" }}>
//         <RadioGroup
//           defaultValue="outlined"
//           name="radio-buttons-group"
//           sx={{ fontSize: "20px !important" }}
//         >
//           {familyLinkOpt?.map((linkItem) => (
//             <>
//               <FormControlLabel
//                 key={linkItem?.linkFamilyRelationId}
//                 value={linkItem?.linkFamilyRelationId}
//                 control={
//                   <Radio
//                     key={linkItem?.linkFamilyRelationId}
//                     variant="outlined"
//                     checked={
//                       formik.values.linkFamilyRelationId ===
//                       linkItem?.linkFamilyRelationId
//                     }
//                     onChange={() => {
//                       formik.setFieldValue(
//                         "linkFamilyRelationId",
//                         linkItem?.linkFamilyRelationId
//                       );
//                       formik.setFieldValue(
//                         "linkFamilyRelation",
//                         linkItem?.linkFamilyRelation
//                       );
//                     }}
//                   />
//                 }
//                 label={
//                   <Typography fontSize={"1.3rem"} fontWeight="600">
//                     {linkItem?.linkFamilyRelation}
//                   </Typography>
//                 }
//               />
//             </>
//           ))}
//         </RadioGroup>
//       </FormControl>

//       <Stack
//         direction="row"
//         alignItems="center"
//         justifyContent="flex-end"
//         marginTop="15px"
//       >

//           <CustomButton
//             handleClick={() => handleLinkRelation(insuredData)}
//             isBlue={true}
//             padding="6px 12px"
//           >
//             Link
//           </CustomButton>
//         <CustomButton
//           isBlue={false}
//           isCancel={true}
//           handleClick={() => setOpenLinkOptModal(false)}
//           padding="6px 12px"
//           margin="0 10px"
//         >
//           Cancel
//         </CustomButton>
//       </Stack>
//     </Box>
//   );
// };

// export default LinkFamilyOpt;



import React, { useState, useEffect } from "react";
import { getData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";
import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Stack,
} from "@mui/material";
import CustomButton from "../../components/CustomButton";

const LinkFamilyOpt = ({ formik, handleLinkRelation, handleClose }) => {

  const [familyLinkOpt, setFamilyLinkOpt] = useState([]);

  const handleGetFamilyLinks = async () => {
    try {
      const response = await getData(`${path}/ct-linkFamilyRelation`);
      console.log(response, "responsedLinks");
      if (response.status === "Success") {
        setFamilyLinkOpt(response.result);
      } else {
        setFamilyLinkOpt([]);
      }
    } catch (error) {
      console.log(error, "fetching family links");
    }
  };

  useEffect(() => {
    handleGetFamilyLinks();
  }, []);

  return (
    <Box padding="20px">
      <h5 style={{ fontWeight: "600" }}>
        What is this patient's relationship to the insured?
      </h5>
      <FormControl sx={{ fontSize: "1.5rem" }}>
        <RadioGroup
          defaultValue="outlined"
          name="radio-buttons-group"
          sx={{ fontSize: "20px !important" }}
        >
          {familyLinkOpt?.map((linkItem) => (
            <FormControlLabel
              key={linkItem?.linkFamilyRelationId}
              value={linkItem?.linkFamilyRelationId}
              control={
                <Radio
                  key={linkItem?.linkFamilyRelationId}
                  variant="outlined"
                  checked={
                    formik.values.linkFamilyRelationId ===
                    linkItem?.linkFamilyRelationId
                  }
                  onChange={() => {
                    formik.setFieldValue(
                      "linkFamilyRelationId",
                      linkItem?.linkFamilyRelationId
                    );
                    formik.setFieldValue(
                      "linkFamilyRelation",
                      linkItem?.linkFamilyRelation
                    );
                  }}
                />
              }
              label={
                <Typography fontSize={"1.3rem"} fontWeight="600">
                  {linkItem?.linkFamilyRelation}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        marginTop="15px"
      >
        <CustomButton
          handleClick={handleLinkRelation}
          isBlue={true}
          padding="6px 12px"
        >
          Link
        </CustomButton>
        <CustomButton
          isBlue={false}
          isCancel={true}
          handleClick={handleClose}
          padding="6px 12px"
          margin="0 10px"
        >
          Cancel
        </CustomButton>
      </Stack>
    </Box>
  );
};

export default LinkFamilyOpt;



// import React, { useState, useEffect } from "react";
// import { getData } from "../../config/axiosFunctions";
// import path from "../../config/apiUrl";
// import { Box, FormControl, RadioGroup, FormControlLabel, Radio, Typography, Stack } from "@mui/material";
// import CustomButton from "../../components/CustomButton";

// const LinkFamilyOpt = ({ formik, handleLinkRelation }) => {
//   const [familyLinkOpt, setFamilyLinkOpt] = useState([]);

//   const handleGetFamilyLinks = async () => {
//     try {
//       const response = await getData(`${path}/ct-linkFamilyRelation`);
//       if (response.status === "Success") {
//         setFamilyLinkOpt(response.result);
//       } else {
//         setFamilyLinkOpt([]);
//       }
//     } catch (error) {
//       console.log(error, "fetching family links");
//     }
//   };

//   useEffect(() => {
//     handleGetFamilyLinks();
//   }, []);

//   return (
//     <Box padding="20px">
//       <h5 style={{ fontWeight: "600" }}>What is this patient's relationship to the insured?</h5>
//       <FormControl sx={{ fontSize: "1.5rem" }}>
//         <RadioGroup
//           defaultValue={formik.values.linkFamilyRelationId}
//           name="radio-buttons-group"
//           sx={{ fontSize: "20px !important" }}
//           onChange={(event) => {
//             const selectedRelation = familyLinkOpt.find(opt => opt.linkFamilyRelationId === parseInt(event.target.value));
//             if (selectedRelation) {
//               formik.setFieldValue("linkFamilyRelationId", selectedRelation.linkFamilyRelationId);
//               formik.setFieldValue("linkFamilyRelation", selectedRelation.linkFamilyRelation);
//             }
//           }}
//         >
//           {familyLinkOpt?.map((linkItem) => (
//             <FormControlLabel
//               key={linkItem?.linkFamilyRelationId}
//               value={linkItem?.linkFamilyRelationId}
//               control={<Radio variant="outlined" />}
//               label={
//                 <Typography fontSize={"1.3rem"} fontWeight="600">
//                   {linkItem?.linkFamilyRelation}
//                 </Typography>
//               }
//             />
//           ))}
//         </RadioGroup>
//       </FormControl>

//       <Stack direction="row" alignItems="center" justifyContent="flex-end" marginTop="15px">
//         <CustomButton handleClick={handleLinkRelation} isBlue={true} padding="6px 12px">
//           Link
//         </CustomButton>
//         <CustomButton isBlue={false} isCancel={true} padding="6px 12px" margin="0 10px">
//           Cancel
//         </CustomButton>
//       </Stack>
//     </Box>
//   );
// };

// export default LinkFamilyOpt;
