import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

export const newPracticeAction = createAsyncThunk(
  "createPractice",
  async (data) => {
    try {
      const response = await postData(`${path}/practice`, data);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getPracticeAction = createAsyncThunk(
  "getPractice",
  async (page) => {
    try {
      const response = await getData(
        `${path}/practice?page=${page}&pageSize=4`
      );
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getPracticeByIdAction = createAsyncThunk(
  "getPracticeById",
  async (seqNo) => {
    try {
      const response = await getData(`${path}/practice/${seqNo}`);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

// search
export const getPracticeBySearchTermAction = createAsyncThunk(
  "getPractice",
  async ({searchTerm,page}) => {
    try {
      const response = await getData(
        // `${path}/practice/SearchPractice?searchTerm=${searchTerm?.searchTerm}`
      `${path}/practice/SearchPractice?searchTerm=${searchTerm}&page=${page}&pageSize=4`
      );
      console.log(response, "action practice by search term");
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updatePracticeAction = createAsyncThunk(
  "updatePractice",
  async (data) => {
    console.log(data, "data1")
    try {
      const response = await updateData(
        `${path}/practice/${data.sequenceNo}`,
        data
      );
      console.log(response, "update prac action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);
