import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useFormik } from "formik";
import {
  getAdjustmentAction,
  updateAdjustmentAction,
} from "../../../features/actions/adjustmentAction";
import { adjustmentInitVals } from "../../../utils/formikInitValues";
import AdjustmentInfo from "./AdjustmentInfo";
import { adjustmentSchema } from "../../../schemas/patientSchema";

const UpdateAdjustment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getAdjustmentCodes, loading } = useSelector(
    (state) => state.adjustments
  );
  const findAdjustment = getAdjustmentCodes.result.find(
    (adjustment) => adjustment.adjustmentCodeId === Number(id)
  );

  const formik = useFormik({
    initialValues: findAdjustment || adjustmentInitVals,
    validationSchema : adjustmentSchema,
    onSubmit: (values) => {
      console.log(values, "checkUpdatedVals");
      try {
        dispatch(
          updateAdjustmentAction({
            adjustmentCodeId: findAdjustment?.adjustmentCodeId,
            ...values,
          })
        );
      } catch (err) {
        console.log(err, "create adjustment error");
        throw err;
      }
      navigate("/adjustment");
    },
  });

  // useEffect(() => {
  //   getAdjustmentAction();
  // }, []);

  useEffect(() => {
    try {
      dispatch(getAdjustmentAction());
    } catch (error) {
      throw error;
    }
  }, [dispatch]);

  // handle cancel
  const handleCancel = () => {
    const conform = window.confirm("Are you sure you want to cancel?");
    if (conform) {
      formik.resetForm();
      navigate("/adjustment");
    }
  };
  return (
    <>
      <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
        <div style={{ padding: "10px", marginLeft: "-30px" }}>
          <MainHeading mainheading={"Update Adjustment Code"} />
        </div>

        <Stack direction="row" gap="20px">
          <CustomButton
            type="submit"
            formId="adjustmentInfo"
            isBlue={true}
            isEdit={true}
          >
            {loading ? "Updating..." : "Update"}
            Update
          </CustomButton>
          <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>
        </Stack>

        <form id="adjustmentInfo" onSubmit={formik.handleSubmit}>
          <AdjustmentInfo formik={formik} />
        </form>
      </Box>
    </>
  );
};

export default UpdateAdjustment;
