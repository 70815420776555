import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomField from "../../../components/CustomField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CheckBox from "../../../components/CheckBox";
import CustomSearchField from "../../../components/CustomSearchField";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import TextArea from "../../../components/TextArea";

const RemittanceInfo = ({ formik }) => {
  const [remittanceTypeOpt, setRemittanceTypeOpt] = useState([]);
  const [informationLevelOpt, setInformationLevelOpt] = useState([]);
  const [detailCheckOpt, setDetailCheckOpt] = useState([]);
  // Define data fetching URLs
  const dataFetchUrls = {
    remittanceType: `${path}/ct-remittanceType`,
    informationLevel: `${path}/ct-remittanceInfoLevel`,
    detailChecks: `${path}/ct-remittanceCodeDetail`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.remittanceType, setRemittanceTypeOpt);
    fetchDataOptions(dataFetchUrls.informationLevel, setInformationLevelOpt);
    fetchDataOptions(dataFetchUrls.detailChecks, setDetailCheckOpt);
  }, []);
  // handle checkbox
  const handleCheckboxChange = (checkVal) => {
    const previosDetCodes = [...formik.values?.remittanceCodeDetailDtos];
    const findVal = previosDetCodes?.findIndex(
      (val) => val.remittanceCodeDetailId === checkVal.remittanceCodeDetailId
    );
    if (findVal !== -1) {
      previosDetCodes.splice(findVal, 1);
    } else {
      previosDetCodes.push(checkVal);
    }

    formik.setFieldValue("remittanceCodeDetailDtos", previosDetCodes);
  };
  return (
    <>
      <Box>
        <Grid container spacing={2} rowGap={2}>
          <Grid item md={12}>
            <CustomField
              type="text"
              label="Code"
              value={formik.values.remittanceCodeName}
              name="remittanceCodeName"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              isRequired={true}
              error={formik.errors}
              touched={formik.touched}
            />
          </Grid>
          <Grid item md={6}>
            <CustomSelectBox2
              value={formik.values.remittanceTypeId}
              name="remittanceTypeId"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              dropdownOptions={remittanceTypeOpt.map((opt) => ({
                value: opt.remittanceType,
                id: opt.remittanceTypeId,
              }))}
              label="Type"
            />
          </Grid>
          <Grid item md={6}>
            <CustomSelectBox2
              value={formik.values.remittanceInfoLevelId}
              name="remittanceInfoLevelId"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              dropdownOptions={informationLevelOpt.map((opt) => ({
                value: opt.remittanceInfoLevel,
                id: opt.remittenceInfoLevelId,
              }))}
              label="Information Level"
            />
          </Grid>
          <Grid item md={12}>
            {detailCheckOpt.map((opt) => (
              <>
                <CheckBox
                  label={opt.remittanceCodeDetail}
                  key={opt.remittanceCodeDetailId}
                  name="remittanceCodeDetailId"
                  CheckedValue={formik.values?.remittanceCodeDetailDtos.some(
                    (v) =>
                      v?.remittanceCodeDetailId === opt?.remittanceCodeDetailId
                  )}
                  handleChange={() => handleCheckboxChange(opt)}
                />
              </>
            ))}
          </Grid>
          <Grid item md={6}>
            <TextArea
             placeholder="Report Description"
             style={{ fontSize: "1.3rem" }}
             minRows={5}
             value={formik.values.reportDescription}
             name="reportDescription"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}/>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth>
              <TextArea
                placeholder="Long Description"
                style={{ fontSize: "1.3rem" }}
                minRows={5}
                value={formik.values.longDescription}
                name="longDescription"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </FormControl>
          </Grid>

          <Typography component="h3">Memoline</Typography>
          <Grid item md={12}>
            <CheckBox
              CheckedValue={formik.values.isMemoline === true}
              label="Use Memoline on Activity and Statements"
              name="isMemoline"
              handleChange={() =>
                formik.setFieldValue("isMemoline", !formik.values.isMemoline)
              }
            />
            <CustomSearchField />
            <CheckBox
              label="Include the amount in the memoline"
              CheckedValue={formik.values.isMemolineAmount === true}
              name="isMemolineAmount"
              handleChange={() =>
                formik.setFieldValue(
                  "isMemolineAmount",
                  !formik.values.isMemolineAmount
                )
              }
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RemittanceInfo;
