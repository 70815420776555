import React from "react";
import {
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  useTheme,
  FormControl,
} from "@mui/material";
import CustomField from "../../../components/CustomField";
import TextArea from "../../../components/TextArea";
import CheckBox from "../../../components/CheckBox";

const RevenueInfo = ({ formik }) => {
  console.log(formik.values, "formikValues");
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <CustomField
            type="text"
            label="Code"
            value={formik.values.revenueCode}
            isRequired={true}
            error={formik.errors}
            touched={formik.touched}
            name="revenueCode"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item md={12}>
          <CustomField
            type="number"
            name="revenueCodePrice"
            label="Price"
            value={formik.values.revenueCodePrice}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item md={6}>
          <CheckBox
            label="Exclude this code from duplicate service checks"
            CheckedValue={formik.values.isDuplicateService === true}
            name="isDuplicateService"
            handleChange={() =>
              formik.setFieldValue(
                "isDuplicateService",
                !formik.values.isDuplicateService
              )
            }
          />
        </Grid>

        {/* //         <CheckBox
//               label="Exclude this code from dublicate service checks"
             
//             /> */}

        <Grid item md={12} margin={"10px 0"}>
          <TextArea
            name="revenueCodeDescription"
            placeholder="Description"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.revenueCodeDescription}
            minRows={4}
          />
          {formik.errors["revenueCodeDescription"] &&
            formik.touched["revenueCodeDescription"] && (
              <div
                style={{
                  color: theme.palette.errorFieldsBorder.primary,
                  border:  `2px solid ${theme.palette.errorFieldsBorder.primary}`,
                  fontSize: "1.3rem",
                  marginTop: "8px",
                  display: "block",
                }}
              >
                {formik.errors["revenueCodeDescription"]}
              </div>
            )}
        </Grid>

        <Grid item md={12} sx={{ marginTop: "30px" }}>
          <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px" }}>
            <Box
              sx={{
                background: theme.palette.revenueStatement.primary,
                height: "33px",
              }}
            >
              <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
                Statement Options
              </Typography>
            </Box>

            <FormControl fullWidth sx={{ padding: "10px" }}>
              <CustomField
                type="text"
                name="statementDescription"
                label="Statement Description"
                value={formik.values.statementDescription}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RevenueInfo;
