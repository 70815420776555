import { Box, Stack } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getRemittanceAction,
  getRemittanceBySearchTermAction,
} from "../../../features/actions/remittanceAction";
import CustomModal from "./../../../components/CustomModal";
import RemarkTable from "./RemarkTable";
import AdjTable from "./AdjTable";
import CustomPreview from "../../../layout/CustomPreview";
import { useState } from "react";
import { remittanceCols } from "../../../utils/gridCols";

const Remittance = () => {
  const navigate = useNavigate();
  const [openRemarkTable, setOpenRemarkTable] = useState(false);
  const [openAdjTableModal, setOpenAdjTableModal] = useState(false);
  const { getRemittance, loading } = useSelector((state) => state.remittance);

  const rows = getRemittance
    ? getRemittance.map((item) => ({
        id: item.remittanceCodeId,
        ...item,
      }))
    : [];

  const handleCell = (params) => {
    navigate(`/remittance/update/${params.id}`);
  };

  return (
    <>
      <Box>
        <CustomPreview
          rows={rows}
          columns={remittanceCols}
          moduleHeading="All Remittance"
          searchLabel="Search by Code, or Description"
          handleGetAll={getRemittanceAction}
          handleBySearch={getRemittanceBySearchTermAction}
          loading={loading}
          handleCell={handleCell}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <CustomButton
              isBlue={true}
              isAdd={true}
              handleClick={() => navigate("/remittance/new")}
            >
              Add Remittance
            </CustomButton>
            <CustomButton
              isBlue={false}
              isAdd={true}
              handleClick={() => setOpenRemarkTable(true)}
            >
              Add Remark
            </CustomButton>
            <CustomButton
              isBlue={false}
              isAdd={true}
              handleClick={() => setOpenAdjTableModal(true)}
            >
              Add Adj Reason
            </CustomButton>
          </Stack>
        </CustomPreview>
      </Box>

      {/* custom modals here */}
      <CustomModal
        open={openRemarkTable}
        handleClose={() => setOpenRemarkTable(false)}
        height={"20%"}
      >
        <RemarkTable handleClose={() => setOpenRemarkTable(false)} />
      </CustomModal>

      <CustomModal
        open={openAdjTableModal}
        handleClose={() => setOpenAdjTableModal(false)}
        height={"20%"}
      >
        <AdjTable handleClose={() => setOpenAdjTableModal(false)} />
      </CustomModal>
    </>
  );
};

export default Remittance;
