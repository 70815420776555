// import React from 'react';
// import { Box,Stack,Typography,Radio,FormControlLabel } from '@mui/material';
// import { useState,useEffect } from 'react';
// import path from '../../../config/apiUrl';
// import { getData } from '../../../config/axiosFunctions';
// const CustomNewFeeScheduleFunction = ({formik}) => {
//   const [feeScheduleCtList, setFeeScheduleCtList] =useState([]);
//   const dataFetchUrls = {
//     // extra
//     feeSchedule: `${path}/CTFeesSchedule`,
//   };

//   const fetchDataOptions = async (url, setter) => {
//     try {
//       const response = await getData(url);
//       setter(response.result);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     fetchDataOptions(dataFetchUrls.feeSchedule, setFeeScheduleCtList);
//   }, []);



//   return (
//     <>
    
//       <Stack sx={{width: { sx: "95%", md: "95%", lg: "60%" }}}>
//                {/* <Box
//           sx={{
//             display: "flex",
//             justifyContent: "right",
//             alignItems: "right",
//           }}
//         >
      
//         </Box> */}
//             <Box>
//               <Stack
//                 flexDirection={"column"}
//                 alignItems={"left"}
//                 marginLeft={"20px"}
//               >
//                 {feeScheduleCtList.map((value) => {
//                   return (
//                     <FormControlLabel
//                       label={value.ctFeesScheduleOption}
//                       control={
//                         <Radio
//                           value={value.ctFeesScheduleOption}
//                           variant="soft"
//                           name="radio-buttons"
//                           checked={formik.values.ctFeesScheduleId}
//                         />
//                       }
//                     />
//                   );
//                 })}
//               </Stack>
//             </Box>
//           </Stack>
//     </>
//   )
// }

// export default CustomNewFeeScheduleFunction



//


// import React, { useState, useEffect } from 'react';
// import { Box, Stack, Radio, FormControlLabel, Typography } from '@mui/material';
// import path from '../../../config/apiUrl';
// import { getData } from '../../../config/axiosFunctions';

// const CustomNewFeeScheduleFunction = ({ formik }) => {
//   const [feeScheduleCtList, setFeeScheduleCtList] = useState([]);
//   const [selectedOption, setSelectedOption] = useState('');

//   const dataFetchUrls = {
//     feeSchedule: `${path}/CTFeesSchedule`,
//   };

//   const fetchDataOptions = async (url, setter) => {
//     try {
//       const response = await getData(url);
//       setter(response.result);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     fetchDataOptions(dataFetchUrls.feeSchedule, setFeeScheduleCtList);
//   }, []);

//   const handleRadioChange = (value) => {
//     formik.setFieldValue('ctFeesScheduleId', value);
//     setSelectedOption(value);
//     switch (selectedOption) {
//       case 1:
//         return <Typography variant="body1">rad 1</Typography>;
//       case 2:
//         return <Typography variant="body1">rad 2</Typography>;
   
//       default:
//         return null;
//     }
//   };

//   // const renderSelectedOption = () => {
//   //   switch (selectedOption) {
//   //     case 1:
//   //       return <Typography variant="body1">You have selected: Create an empty fee schedule</Typography>;
//   //     case 2:
//   //       return <Typography variant="body1">You have selected: Set prices based on another fee schedule</Typography>;
   
//   //     default:
//   //       return null;
//   //   }
//   // };

//   return (
//     <Stack sx={{ width: { sx: '95%', md: '95%', lg: '60%' } }}>
//       <Box>
//         <Stack flexDirection="column" alignItems="left" marginLeft="20px">
//           {feeScheduleCtList.map((value) => (
//             <FormControlLabel
//               key={value.ctFeesScheduleId}
//               label={value.ctFeesScheduleOption}
//               control={
//                 <Radio
//                   value={value.ctFeesScheduleId}
//                   checked={formik.values.ctFeesScheduleId === value.ctFeesScheduleId}
//                   onChange={() => handleRadioChange(value.ctFeesScheduleId)}
//                   name="radio-buttons"
//                 />
//               }
//             />
//           ))}
//         </Stack>
//         {/* <Box marginTop={2}>
//           {renderSelectedOption()}
//         </Box> */}
//         <Box>{handleRadioChange()}</Box>
//       </Box>
//     </Stack>
//   );
// };

// export default CustomNewFeeScheduleFunction;





// import React, { useState, useEffect } from 'react';
// import { Box, Stack, Radio, FormControlLabel, Typography, Grid } from '@mui/material';
// import path from '../../../config/apiUrl';
// import { getData } from '../../../config/axiosFunctions';
// import AnotherFeeSchedule from './new-fee-schedule/AnotherFeeSchedule';
// import MedicareFeeSchedule from './new-fee-schedule/MedicareFeeSchedule';
// import ContractBasedPrices from './new-fee-schedule/ContractBasedPrices';
// import ChargesEntered from './new-fee-schedule/ChargesEntered';

// const CustomNewFeeScheduleFunction = ({ formik }) => {
//   const [feeScheduleCtList, setFeeScheduleCtList] = useState([]);
//   const [selectedOption, setSelectedOption] = useState('');
//   const [selectedIndex, setSelectedIndex] = useState(null);

//   const dataFetchUrls = {
//     feeSchedule: `${path}/CTFeesSchedule`,
//   };

//   const fetchDataOptions = async (url, setter) => {
//     try {
//       const response = await getData(url);
//       setter(response.result);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     fetchDataOptions(dataFetchUrls.feeSchedule, setFeeScheduleCtList);
//   }, []);

//   const handleRadioChange = (value, index) => {
//     formik.setFieldValue('ctFeesScheduleId', value);
//     setSelectedOption(value);
//     setSelectedIndex(index);
//   };

//   const renderSelectedOption = (index) => {
//     if (selectedIndex !== index) return null;
//     switch (selectedOption) {
//       case 1:
//       return <Typography variant="body1">You have selected: Create an empty fee schedule</Typography>;
//       case 2:
//         return <AnotherFeeSchedule/>;  
//       case 3:
//           return <MedicareFeeSchedule/>;
//       case 4:
//           return <ContractBasedPrices/>;
//       case 5:
//           return <ChargesEntered/>;
//       default:
//         return null;
//     }
//   };

//   return (
//     <Stack 
//     // sx={{ width: { sx: '95%', md: '95%', lg: '60%' } }}
//     >
//       <Box>
//         <Stack flexDirection="column" alignItems="left" marginLeft="20px">
//           {feeScheduleCtList.map((value, index) => (
//             <Box key={value.ctFeesScheduleId}>
//               <FormControlLabel
//                 label={value.ctFeesScheduleOption}
//                 control={
//                   <Radio
//                     value={value.ctFeesScheduleId}
//                     checked={formik.values.ctFeesScheduleId === value.ctFeesScheduleId}
//                     onChange={() => handleRadioChange(value.ctFeesScheduleId, index)}
//                     name="radio-buttons"
//                   />
//                 }
//               />
//               {renderSelectedOption(index)}
//             </Box>
//           ))}
//         </Stack>
//       </Box>
//     </Stack>
//   );
// };

// export default CustomNewFeeScheduleFunction;



import React, { useState, useEffect } from 'react';
import { Box, Stack, Radio, FormControlLabel, Typography } from '@mui/material';
import path from '../../../config/apiUrl';
import { getData } from '../../../config/axiosFunctions';
import AnotherFeeSchedule from './new-fee-schedule/AnotherFeeSchedule';
import MedicareFeeSchedule from './new-fee-schedule/MedicareFeeSchedule';
import ContractBasedPrices from './new-fee-schedule/ContractBasedPrices';
import ChargesEntered from './new-fee-schedule/ChargesEntered';

const CustomNewFeeScheduleFunction = ({ formik }) => {
  const [feeScheduleCtList, setFeeScheduleCtList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(formik.values.ctFeesScheduleId);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const dataFetchUrls = {
    feeSchedule: `${path}/CTFeesSchedule`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.feeSchedule, setFeeScheduleCtList);
  }, []);

  useEffect(() => {
    const selectedIndex = feeScheduleCtList.findIndex(item => item.ctFeesScheduleId === formik.values.ctFeesScheduleId);
    setSelectedOption(formik.values.ctFeesScheduleId);
    setSelectedIndex(selectedIndex);
  }, [formik.values.ctFeesScheduleId, feeScheduleCtList]);

  const handleRadioChange = (value, index) => {
    formik.setFieldValue('ctFeesScheduleId', value);
    setSelectedOption(value);
    setSelectedIndex(index);
  };

  const renderSelectedOption = (index) => {
    if (selectedIndex !== index) return null;
    switch (selectedOption) {
      case 1:
        return <Typography variant="body1">You have selected: Create an empty fee schedule</Typography>;
      case 2:
        return <AnotherFeeSchedule />;
      case 3:
        return <MedicareFeeSchedule />;
      case 4:
        return <ContractBasedPrices />;
      case 5:
        return <ChargesEntered />;
      default:
        return null;
    }
  };

  return (
    <Stack>
      <Box>
        <Stack flexDirection="column" alignItems="left" marginLeft="20px">
          {feeScheduleCtList.map((value, index) => (
            <Box key={value.ctFeesScheduleId}>
              <FormControlLabel
                label={value.ctFeesScheduleOption}
                control={
                  <Radio
                    value={value.ctFeesScheduleId}
                    checked={formik.values.ctFeesScheduleId === value.ctFeesScheduleId}
                    onChange={() => handleRadioChange(value.ctFeesScheduleId, index)}
                    name="radio-buttons"
                  />
                }
              />
              {renderSelectedOption(index)}
            </Box>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

export default CustomNewFeeScheduleFunction;

