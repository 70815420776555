import React from "react";
import { useTheme } from "@mui/material";
import "./Custom-styling/CustomDatePicker.css";

const CustomDatePicker = ({
  dateValue,
  handleDateChange,
  handleDateBlur,
  disable,
  dateLabel,
  formik,
  name,
  isNested =false
}) => {
  const theme = useTheme();

// adding variable for nasted form field
const fieldName = isNested ? name.split('.').shift() : name;
console.log(fieldName,"danish");
  const hasError = formik && formik.touched[fieldName] && formik.errors[fieldName];

  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      <div>
        <label
          className="customLabel"
          htmlFor={name}
          style={{ color: theme.palette.Labeltext.primary }}
        >
          {dateLabel}
        </label>

        <input
          style={{
            background: theme.palette.customFieldsBackground.primary,
            border: hasError
              ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
              : `1px solid ${theme.palette.fieldsBorder.primary}`,
            color: theme.palette.fieldsText.primary,
          }}
          type="date"
          className="customDatePicker"
          name={name}
          id={name}
          disabled={disable}
          value={dateValue || ""}
          max={today}
          onChange={(e) => {
            formik.setFieldValue(
              name,
              e.target.value === "" ? null : e.target.value
            );
          }}
          onBlur={handleDateBlur}
        />
      </div>
    </>
  );
};

export default CustomDatePicker;
