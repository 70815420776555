import React, { useEffect } from "react";
import { payerSchema } from "../../../schemas/patientSchema";
import { payerIntialVal } from "../../../utils/formikInitValues";
import { useFormik } from "formik";
import MainHeading from "../../../components/MainHeading";
import { Box,Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import PayerForm from "./PayerForm";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";
import {
  createNewPayerAction,
  getPayerAction,
  updatePayerAction,
} from "../../../features/actions/payerAction";
const UpdatePayer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { getAllPayer, loading } = useSelector((state) => state.payer);
  console.log("getAllPayer", getAllPayer);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const payerToEdit = getAllPayer?.result?.payers?.find(
    (item) => item?.payerId === Number(id)
  );
  console.log("payerToEdit", payerToEdit);
  const formik = useFormik({
    initialValues: payerToEdit,
    validationSchema: payerSchema,
    onSubmit: (values, action) => {
      console.log(values, "check payer Val");
      dispatch(updatePayerAction(values));
      action.resetForm();
      navigate("/payer");
    },
  });

  useEffect(() => {
    dispatch(getPayerAction());
  }, []);
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/payer");
  };
  return (
    <>
      <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
        <MainHeading mainheading={"Update Payer"} />
        <form onSubmit={formik.handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 150px))",
                sm: "repeat(1, minmax(0, 150px))",
                md: "repeat(2, minmax(0, 150px))",
              },
            }}
          >
            <CustomButton type="submit" isBlue={true} isSave={true}>
              Update
            </CustomButton>
            {/* <CustomButton
              type="submit"
              isCancel={true}
              handleClick={() => {
                let confirm = window.confirm(
                  "Are you sure you want to cancel?"
                );
                if (confirm) {
                  formik.resetForm();
                  navigate("/payer");
                }
              }}
            >
              Cancel
            </CustomButton> */}
               <CustomButton isCancel={true} handleClick={handleCancel}>
         Cancel
       </CustomButton>
          </Box>
          <PayerForm formik={formik} />
        </form>
      </Box>
      <CustomDialog
    open={openCancelDialog}
    isWarning={true}
    handleClose={() => setOpenCancelDialog(false)}
    handleConfirm={handleConfirmCancel}
    handleCancel={() => setOpenCancelDialog(false)}
    confirmButtonTitle="OK"
    cancelButtonTitle="Cancel"
   >
    <Typography component="h3">Are you sure you want to cancel?</Typography>
   </CustomDialog>
    </>
  );
};

export default UpdatePayer;
