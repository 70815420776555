import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import NotFoundAnim from "../404NotFound/NotFoundAnim.json";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "90%",
      }}
    >
      <Lottie animationData={NotFoundAnim} />
      <Typography variant="h1" fontWeight={"700"} sx={{ color: "black" }}>
        404-Page Not Found
      </Typography>
      <Typography
        sx={{
          color: "black",
          width: "40%",
          textAlign: "center",
          fontFamily: "Radio Canada",
          marginTop: "10px",
        }}
        variant="h4"
      >
        The page you are looking for might have been removed had its name
        changed or is temporarily unavailable
      </Typography>
      {/* button color:1768C2 */}
      <CustomButton
        borderRadius={"30px"}
        padding={"10px"}
        margin={"10px 0"}
        isBlue={true}
        handleClick={() => navigate("/dashboard")}
      >
        GO TO HOMEPAGE{" "}
      </CustomButton>
    </Box>
  );
};
export default NotFound;
