import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import CustomDataGrid from "../components/CustomDataGrid";
import MainHeading from "../components/MainHeading";
import CustomField from "../components/CustomField";
import CustomButton from "../components/CustomButton";
import { useDispatch } from "react-redux";
import Loading from "../components/Loading";

const CustomPreview = ({
  children,
  paginationDetail,
  rows,
  columns,
  handleCell,
  moduleHeading,
  searchLabel,
  handleBySearch,
  handleGetAll,
  isModal = false,
  loading,
}) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(null);
  const pageSize = 20;
  const [page, setPage] = useState(0);

  /// for page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Handler for search button click
  const handleSearchChange = () => {
    try {
      if (searchTerm) {
        dispatch(handleBySearch({ searchTerm: searchTerm, page: page + 1 }));
      } else {
        dispatch(handleGetAll(page + 1));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Perform search or get all when searchTerm or page changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!searchTerm) {
          dispatch(handleGetAll(page + 1));
        } else {
        }
      } catch (err) {
        console.log("err", err);
      }
    };

    fetchData();
  }, [searchTerm, page]);

  // Perform initial fetch on component mount
  useEffect(() => {
    dispatch(handleGetAll(page + 1));
  }, [page]);

  return (
    <>
      <Box margin="15px">
        <Grid container rowSpacing={2}>
          {!isModal && (
            <Grid item md={12} sm={12} xs={12}>
              <MainHeading mainheading={moduleHeading} />
            </Grid>
          )}

          {children && (
            <Grid item md={12}>
              {children}
            </Grid>
          )}

          <Grid item md={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                margin: "3px",
              }}
            >
              <CustomField
                type="text"
                size="small"
                label={searchLabel}
                value={searchTerm}
                name="searchTerm"
                handleChange={(e) => setSearchTerm(e.target.value)}
              />
              <CustomButton
                type="button"
                handleClick={handleSearchChange}
                isBlue={true}
                padding="5px 30px"
                margin={"0 10px 0"}
                isSearch={true}
              >
                Search
              </CustomButton>
            </Box>
          </Grid>

          <Grid item md={12} xs={12} sm={12}>
            {loading ? (
              <Loading />
            ) : (
              <CustomDataGrid
                columns={columns}
                paginationDetail={paginationDetail}
                rows={rows}
                pageSize={pageSize}
                handleCellClick={handleCell}
                page={page}
                handlePageChange={handlePageChange}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CustomPreview;
