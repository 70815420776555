// import axios from "axios";
// import React, { useEffect, useState ,navigate  } from "react";
// import path from "../../../config/apiUrl";
// import { Box, CircularProgress, Stack, Typography } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import CustomDataGrid from "../../../components/CustomDataGrid";
// import MainHeading from "../../../components/MainHeading";
// import CustomButton from "../../../components/CustomButton";
// import { useNavigate } from "react-router-dom";



// const ReferringProvider = ({ handleClose, setValues, setClaimIds, handleCellClick, isUpdate = true}) => {
//   const [renderingProviderInfo, setRenderingProviderInfo] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const navigate = useNavigate();

//   const fetchRenderingProvider = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get(`${path}/renderingProvider`);
//       console.log("apiResponse",response)
//       if (response.status === 200) {
//         const data = await response.data;
       
//         setRenderingProviderInfo(data.result);
//       } else {
//         setRenderingProviderInfo([]);
//       }
//     } catch (error) {
//       throw new Error(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const rows = renderingProviderInfo.map((el) => ({
//     id: el.renderingProviderId,
//     renderingProviderFirstName: el.renderingProviderFirstName,
//     renderingProviderLastName: el.renderingProviderLastName,
//     renderingProviderNPINo: el.renderingProviderNPINo,
//     renderingProviderSequenceNo: el.renderingProviderSequenceNo,
//   }));

//   const columns = [
//     {
//       field: "name",
//       headerName: "Name",
//       minWidth: 250,
//       flex: 1,
//       headerAlign: "center",
//       align: "center",
//       headerClassName: "header-bg",
//       cellClassName: "cell-content",
//       valueGetter: (params) =>
//         `${params.row.renderingProviderFirstName} ${params.row.renderingProviderLastName}`,
//     },
//     {
//       field: "renderingProviderNPINo",
//       headerName: "NPI",
//       minWidth: 100,
//       flex: 1,
//       headerAlign: "center",
//       align: "center",
//       headerClassName: "header-bg",
//       cellClassName: "cell-content"
//     },
//     {
//       field: "renderingProviderSequenceNo",
//       headerName: "Seq #",
//       minWidth: 100,
//       flex: 1,
//       headerAlign: "center",
//       align: "center",
//       headerClassName: "header-bg",
//       cellClassName: "cell-content"
//     },
//   ];

//   useEffect(() => {
//     fetchRenderingProvider();
//   }, []);

//   const handleRenderingProvider = (val) => {
//     console.log(val, "billing vals");
//     setClaimIds((prevVals) => ({
//       ...prevVals,
//       renderingProviderId: val.id,
//     }));
//     setValues((prevVals) => ({
//       ...prevVals,
//       renderingProviderName: `${val.renderingProviderFirstName} ${val.renderingProviderLastName} (${val.renderingProviderSequenceNo})`,
//       renderingProviderId: val.id,
//     }));
//     handleClose();
//   };
//   return (
//     <>
//     <Box margin="15px">

//       <Box
//         marginTop="30px"
//         marginBottom="10px"
//         width={{ xs: "100%", sm: "65%", md: "65%" }}
//       >
//         <CustomButton
//           isBlue={true}
//           isAdd={true}
//           handleClick={() => navigate("/referringProvider/new")}
//         >
//           New Referring
//         </CustomButton>

//       </Box>

//       {/* <DataGrid
//             rows={rows}
//             columns={columns}
//             sx={{
//               "& .header-bg": {
//                 backgroundColor: "lightgrey",
//                 color: 'black',
//                 fontSize: '14px',
//               },
//               "& .cell-content": {
//                 fontSize: '12.5px', 
//               }
//             }}
//             autoHeight
//             components={{
//               NoRowsOverlay: () => (
//                 <div
//                   style={{
//                     width: "100%",
//                     textAlign: "center",
//                     padding: "16px",
//                   }}
//                 >
//                   {renderingProviderInfo.length === 0 && "No Data Is Added"}
//                 </div>
//               ),
//             }}
//             onCellClick={(params) => handleRenderingProvider(params.row)}
//           /> */}

//           <CustomDataGrid 
//           rows={rows}
//           columns={columns}
//           handleCellClick={(params) => isUpdate ? navigate(`/referringProvider/update/${params.id}`) : (params) => handleRenderingProvider(params)}
//           />
//       </Box>

//     {/* <Box sx={{ width: "100%" }}>
//       {isLoading ? (
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//           }}
//         >
//           <CircularProgress />
//         </Box>
//       ) : (
//         <>
//           <Stack
//             sx={{
//               borderBottom: "1px solid grey",
//               padding: "10px 20px 15px",
//             }}
//           >
//             <Typography variant="h3" component={"h3"}>
//               Referring Provider
//             </Typography>
//           </Stack>
         
//           <DataGrid
//             rows={rows}
//             columns={columns}
//             sx={{
//               "& .header-bg": {
//                 backgroundColor: "lightgrey",
//                 color: 'black',
//                 fontSize: '14px',
//               },
//               "& .cell-content": {
//                 fontSize: '12.5px', 
//               }
//             }}
//             autoHeight
//             components={{
//               NoRowsOverlay: () => (
//                 <div
//                   style={{
//                     width: "100%",
//                     textAlign: "center",
//                     padding: "16px",
//                   }}
//                 >
//                   {renderingProviderInfo.length === 0 && "No Data Is Added"}
//                 </div>
//               ),
//             }}
//             onCellClick={(params) => handleRenderingProvider(params.row)}
//           />
//         </>
//       )}
//     </Box> */}
//     </>
//   );
// };

// export default ReferringProvider;






import axios from "axios";
import React, { useEffect, useState ,navigate  } from "react";
import path from "../../../config/apiUrl";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomDataGrid from "../../../components/CustomDataGrid";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { refProvider } from "../../../utils/gridCols";
import CustomPreview from "../../../layout/CustomPreview";



const ReferringProvider = ({ handleClose, setValues, setClaimIds, handleCellClick, isUpdate = true}) => {
  const [renderingProviderInfo, setRenderingProviderInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchRenderingProvider = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${path}/renderingProvider`);
      console.log("apiResponse",response)
      if (response.status === 200) {
        const data = await response.data;
       
        setRenderingProviderInfo(data.result);
      } else {
        setRenderingProviderInfo([]);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const rows = renderingProviderInfo.map((el) => ({
    id: el.renderingProviderId,
    renderingProviderFirstName: el.renderingProviderFirstName,
    renderingProviderLastName: el.renderingProviderLastName,
    renderingProviderNPINo: el.renderingProviderNPINo,
    renderingProviderSequenceNo: el.renderingProviderSequenceNo,
  }));

 

  useEffect(() => {
    fetchRenderingProvider();
  }, []);

  // const handleRenderingProvider = (val) => {
  //   console.log(val, "billing vals");
  //   setClaimIds((prevVals) => ({
  //     ...prevVals,
  //     renderingProviderId: val.id,
  //   }));
  //   setValues((prevVals) => ({
  //     ...prevVals,
  //     renderingProviderName: `${val.renderingProviderFirstName} ${val.renderingProviderLastName} (${val.renderingProviderSequenceNo})`,
  //     renderingProviderId: val.id,
  //   }));
  //   handleClose();
  // };
  return (
    <>
    

      <Box>
      <CustomPreview
        rows={rows}
        columns={refProvider}
        handleGetAll={fetchRenderingProvider}
        isModal={true}
      >
        <Stack direction="row" alignItems="center">
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => navigate("/referringProvider/new")}
          >
            New Referring
          </CustomButton>
        </Stack>
      </CustomPreview>
    </Box>

    </>
  );
};

export default ReferringProvider;