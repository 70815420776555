import { Box,Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { newProviderAction } from "../../../features/actions/providerAction";
import MainHeading from "../../../components/MainHeading";
import ProviderInfo from "./ProviderInfo";
import { providerInitValues } from "../../../utils/formikInitValues";
import CustomButton from "../../../components/CustomButton";
import {useNavigate} from "react-router-dom"
import { providerSchema } from "../../../schemas/patientSchema";
import { handleCancel } from "../../../utils/customFunctions";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";

const NewProvider = () => {
  const dispatch = useDispatch();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: providerInitValues,
    validationSchema : providerSchema,
    onSubmit: (values, action) => {
      try {
        dispatch(newProviderAction(values));
      } catch (error) {
        throw new Error(error);
      }
      action.resetForm();
      navigate("/provider")
    },
  });
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/provider");
  };

  return (
    <>
      <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
        <MainHeading mainheading={"New Provider"} />

        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >
          <CustomButton
            formId="provider-form"
            isBlue={true}
            isSave={true}
            type="submit"
          >
            Save
          </CustomButton>
          {/* <CustomButton
            
            type="submit"
            isCancel={true}
            handleClick={() => {
              handleCancel(
                formik,
                 "/provider", navigate);
            }}
          >
            Cancel
          </CustomButton> */}
           <CustomButton   type="submit" isCancel={true} handleClick={handleCancel}>
         Cancel
       </CustomButton>
        </Box>

        <form id="provider-form" onSubmit={formik.handleSubmit}>
          <ProviderInfo formik={formik} />
        </form>
      </Box>
      <CustomDialog
     open={openCancelDialog}
     isWarning={true}
     handleClose={() => setOpenCancelDialog(false)}
     handleConfirm={handleConfirmCancel}
     handleCancel={() => setOpenCancelDialog(false)}
     confirmButtonTitle="OK"
     cancelButtonTitle="Cancel"
    >
     <Typography component="h3">Are you sure you want to cancel?</Typography>
    </CustomDialog>
    </>
  );
};

export default NewProvider;
