// import React, { useState } from "react";
import { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPayerAction } from "../../features/actions/payerAction";
import { DataGrid } from "@mui/x-data-grid";
import CustomDataGrid from "../../components/CustomDataGrid";

const PayerList = ({ handleSelectPayer, handlePaymentBy, modalFor }) => {
  const dispatch = useDispatch();
  const { getAllPayer, loading } = useSelector((state) => state.payer);

  const rows = getAllPayer.result?.map((el) => ({
    id: el.payerId,
    payerName: el.payerName,
    planName: el.planName,
    payerAddress: el.payerAddress,
    payerSequenceNo: el.payerSequenceNo,
  }));

  useEffect(() => {
    dispatch(getPayerAction());
  }, []);

  const columns = [
    {
      field: "payerName",
      headerName: "Payer Name",
      flex: 2,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "planName",
      headerName: "Plan Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "payerAddress",
      headerName: "Payer Address",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];

  return (
    <Box>
      {/* <Typography
        padding={"10px"}
        variant="h5"
        component={"h1"}
        fontSize={"1.6rem"}
        fontWeight={"600"}
      >
        Payer List
        <Box component={"hr"} />
      </Typography> */}

      <Box height={400} marginTop={4}>
        {loading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : rows && rows.length > 0 ? (
          <CustomDataGrid
            rows={rows}
            columns={columns}
            scrollbarSize={5}
            handleCellClick={(params) =>
              modalFor === "payment"
                ? handlePaymentBy(params)
                : handleSelectPayer(params)
            }
          />
        ) : (
          // <DataGrid
          //   rows={rows}
          //   columns={columns}
          //   scrollbarSize={5}
          //   disableSelectionOnClick
          //   onCellClick={(param) =>
          //     modalFor === "payment"
          //       ? handlePaymentBy(param.row)
          //       : handleSelectPayer(param.row)
          //   }
          // />
          <Typography variant="body1">No data available.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default PayerList;
