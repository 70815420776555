import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const  getAllTosCodesActios = createAsyncThunk("getTos" , async()=>{
    try {
        const response = await getData(`${path}/ct-tosCode`);

    if (response) {
      return response;
    } 
    } catch (error) {
        console.log(error, "action error");
        throw new Error(error);
    }
})