import { Grid,  FormControl, useTheme} from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomField from '../../../components/CustomField'
import CustomSelectBox2 from '../../../components/CustomSelectBox2'
import TextArea from '../../../components/TextArea'
import { getData } from '../../../config/axiosFunctions'
import path from '../../../config/apiUrl'


const ICDProcedureCodesInfo = ({formik}) => {
  const [codeTypeOpt, setOpenTypeOpt] = useState([]);
  const theme=useTheme();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getData(`${path}/CTICDcodetype`);
        if (response) {
          setOpenTypeOpt(response.result);
        }
      } catch (err) {
        console.error(err, ": err in getting code type options");
      }
    };
  
    fetchData();
  }, [setOpenTypeOpt]); 
  
  return (
    <>
     <Grid container spacing={2}>
      <Grid item lg={12}>
    
        <CustomField
               type="text"
               label="Codes"
               value={formik.values.icdCode}
               name="icdCode"
               handleChange={formik.handleChange}
               handleBlur={formik.handleBlur}
               isRequired={true}
               error={formik.errors}
               touched={formik.touched}
              />
      </Grid>
      <Grid item lg={12}>
      <FormControl fullWidth>
                  <CustomSelectBox2
                    value={formik.values.icdCodeTypeId}
                    name="icdCodeTypeId"
                    dropdownOptions={codeTypeOpt?.map((opt) => ({
                      value: opt.cticDcode,
                      id: opt.cticDcodetypeId,
                    }))}
                    label="Code Type"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    formik={formik}
                    showDefaultOpt={true}
                  />
                </FormControl>
      </Grid>

      <Grid item lg={12}>
                <TextArea
              placeholder="Description"
              minRows={4}
              value={formik.values.description}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              name="description"

            />
             {formik.errors["description"] && formik.touched["description"] && (
          <div
            style={{
              color:theme.palette.errorFieldsBorder.primary,
            border: `2px solid ${theme.palette.errorFieldsBorder.primary}`,
              fontSize: "1.3rem",
              marginTop: "8px",
              display: "block",
            }}
          >
            {formik.errors["description"]}
          </div>
        )}
                </Grid>

</Grid>
</>
   
  )
}

export default ICDProcedureCodesInfo