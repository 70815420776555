import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { postData } from "../../config/axiosFunctions";

// search statement batch action creator
export const searchStatementPrintAction = createAsyncThunk(
    "searchStatementPrint",
    async (data) => {
      try {
        const response = await postData(`${path}/StatementBatchPrint`, data);
        if (response) {
          return response;
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        console.log("error", error?.response);
        throw error;
      }
    }
  );