import { createSlice } from "@reduxjs/toolkit";
import { getClaimBatchPrint } from "../actions/claimBatchPrintAction";

const claimBatchPrintSlice = createSlice({
  name: "claimBatchPrint",
  initialState: {
    claimBatchPrintData: [],
    dataExits: false,
    loading: false,
    error: null,
  },
  reducers: {
    handleArrowChange: (state, action) => {
      state.claimBatchPrintData = action.payload;
    },
    handleCancel: (state, action) => {
      state.claimBatchPrintData = [];
      state.dataExits = false;
    },
  },
  extraReducers: {
    [getClaimBatchPrint.pending]: (state, action) => {
      state.loading = true;
      state.dataExits = false;
    },
    [getClaimBatchPrint.fulfilled]: (state, action) => {
      state.loading = false;
      state.claimBatchPrintData = action?.payload?.claimBatchPrintList || [];
      state.dataExits =
        action.payload?.claimBatchPrintList.length > 0 ? true : false;
    },
    [getClaimBatchPrint.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.dataExits = false;
    },
  },
});

export const { handleArrowChange, handleCancel } = claimBatchPrintSlice.actions;
export default claimBatchPrintSlice.reducer;
