import React from 'react';
import CustomButton from "../../../components/CustomButton";
import MainHeading from "../../../components/MainHeading";
import ReferringProviderInfo from './ReferringProviderInfo';
import { useFormik } from "formik";
import { Box } from "@mui/material";
import {useNavigate} from "react-router-dom"

const NewReferringProvider=()=> {
  const navigate  = useNavigate()
  const initialValues = {
    // providerFirstName: "",
    // providerLastName: "",
    // providerMI: "",
    // providerCredential: "",
    // providerOrganization: "",
    // providerNPIId: null,
    // providerNPINo: null,
    // providerTaxonomySpecialtyCode: "",
    // providerReferenceNo: null,
    // providerCode: "",
    // practiceName: "",
    // billingProvider: "",
    // billingProviderFirstName: "",
    // billingProviderLastName: "",
    // billingProviderId: null,
    // billingProviderSeqNo: null,
    // eligibilityProvider: "",
    // eligibilityProviderId: null,
    // eligibilityProviderFirstName: "",
    // eligibilityProviderLastName: "",
    // eligibilityProviderSeqNo: null,
    // practiceSequenceNo: null,
    // practiceId: null,
    // practiceAddress: "",
    // id_NoId: null,
    // id_NoType: "",
    // id_NoValue: null,
    // billAsId: null,
    // billAsType: "",
    // claimModeId: null,
    // claimModeType: "",
    // claimBillingId: null,
    // claimBillingType: "",
    // homePhone: null,
    
    
    // taxId:"",
    // bcbsId:"",
    // medicareId:"",
    // medicaidId:"",
    // champusId:"",
    // marketer:"",
    // sequenceNo:"",
    // referenceNo:null,
    // address:"",
    // cellPhone: null,
    // city:"",
    // state:"",
    // zipCode:null,
    // taxonomySpecialty:"",


    // faxNo: null,
    // email: "",
    // specialtyLicenseNo: "",
    // stateLicenseNo: "",
    // anesthesiaLicenseNo: "",
    // upinNo: "",
    // blueCrossNo: "",
    // tricareNo: "",
    // revCodeId: null,
    // revCode: "",
    // isIndividual: true,

    renderingProviderId:null,
    renderingProviderFirstName: "",
    renderingProviderLastName: "",
    renderingProviderMI: "",
    renderingProviderCredential: "",
    renderingProviderNPINo:"",
    renderingProviderTaxonomy: "",
    renderingProviderSequenceNo: null,
    renderingProviderReferenceNo: null,
    renderingProviderCode: "",
    isDeleted: "",
    deletedBy: null,
    createdBy: null ,
    createdOn: "",
    updatedBy: null,
    updatedOn: "",
    isActive:"",
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      console.log(values, "checkRefProviderVals");
     
    },
  });
  return (
    <>
     <Box margin="20px">
     <div style={{ padding: "10px", marginLeft: "-30px" }}>
        <MainHeading mainheading={"New Referring"} />
      </div>

      <form onSubmit={formik.handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          padding={'15px'}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >
          <CustomButton type="submit" isBlue={true} isSave={true}>
            Save
          </CustomButton>
          <CustomButton type="submit" isBlue={false} isCancel={true}  handleClick={()=>{
              formik.resetForm()
              navigate('/referringProvider')
            }}
          >
           Cancel
          </CustomButton>
        </Box>
        <ReferringProviderInfo formik={formik}/>
      </form>
      </Box>
      </>
  );
};

export default NewReferringProvider;