
import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Typography,
  AccordionDetails,
  TextField,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import MainHeading from "../../../components/MainHeading";
import CustomField from "../../../components/CustomField";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomAccordion from "../../../components/CustomAccordion";
import { useFormik } from "formik";
import { facilityInitVals } from "../../../utils/formikInitValues";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomModal from "../../../components/CustomModal";
import TaxonomyCategories from "../practices/taxonomy/TaxonomyCategories";
import SearchNpi from "../practices/npi/SearchNpi";
import PosCodes from "../pos-codes/PosCodes";
import CustomPreview from "../../../layout/CustomPreview";
import { posCodesCols } from "../../../utils/gridCols";
import { getProcedureCodesBySearchTermAction } from "../../../features/actions/procedureCodeAction";
import { getAllPosCodesActios } from "../../../features/actions/posCodeAction";

const FacilityInfo = ({ formik }) => {
  const [cityOpt, setCityOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [openTaxonomyListModal, setOpenTaxonomyListModal] = useState(false);
  const [openPosModal, setOpenPosModal] = useState(false);
  const [searchNpiModal, setSearchNpiModal] = useState(false);

  const {getAllPos} = useSelector(state => state.posCodes)
  const posLoading = useSelector(state => state.posCodes.loading)
  

  // Define data fetching URLs
  const dataFetchUrls = {
    cityType: `${path}/city`,
    stateType: `${path}/state`,
  };

  const theme = useTheme();

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.cityType, setCityOpt);
    fetchDataOptions(dataFetchUrls.stateType, setStateOpt);
  }, []);

  useEffect(() => {
    if (formik.values.facilityStateId) {
      fetchDataOptions(
        `${path}/city?stateId=${formik.values.facilityStateId}`,
        setCityOpt
      );
    }
  }, [formik.values.facilityStateId]);

  // taxonomy
  const handleSelectTaxonomy = (taxonomyCode) => {
    formik.setFieldValue("taxonomySpecialty", taxonomyCode);
  };
  // handle npi
  const handleSelectNpi = (npiNum) => {
    formik.setFieldValue("facilityNpiId", npiNum.id);
    formik.setFieldValue("facilityNpiNo", npiNum.npiNo);
  };
  // handle pos code
  const handlePosCodes = (val) => {
    console.log(val, ":posCode");
    formik.setFieldValue("posCodeId", val.id);
    formik.setFieldValue("posCode", val.posCode);
    setOpenPosModal(false);
  };
  return (
    <>
      {/* taxonomy modal */}
      <CustomModal
        open={openTaxonomyListModal}
        handleClose={() => setOpenTaxonomyListModal(false)}
        height={'20px'}
      >
        <TaxonomyCategories
          handleSelectTaxonomy={handleSelectTaxonomy}
          handleClose={() => setOpenTaxonomyListModal(false)}
         
        />
      </CustomModal>
      {/* npi modal */}
      <CustomModal
        open={searchNpiModal}
        handleClose={() => setSearchNpiModal(false)}
        heading={'NPI Registry'}
        height={'20px'}
      >
        <SearchNpi
          handleSelectNpi={handleSelectNpi}
          handleClose={() => setSearchNpiModal(false)}
          setSearchNpiModal={setSearchNpiModal}
        />
      </CustomModal>
      {/* POS modal */}
      <CustomModal
        open={openPosModal}
        handleClose={() => setOpenPosModal(false)}
        height={'20px'}
        heading={'Place Of Service'}
      >
        {/* <PosCodes
          handleClose={() => setOpenPosModal(false)}
          handleCellClick={handlePosCodes}
        /> */}
    
          <CustomPreview isModal={true} 
          rows={
            getAllPos ? getAllPos.map(el => ({
              id: el?.posCodeId,
              ...el
            }))
            :[]
          }
          columns={posCodesCols}  handleGetAll={getAllPosCodesActios} 
          handleCell={handlePosCodes} 
          searchLabel="Search by Pos Code" />
      </CustomModal>


<Box sx={{background:theme.palette.accordiansBodyBackground.primary,padding:"15px",margin:"0 0 20px",border:"1px solid #D3D3D3",borderRadius:"4px"}}>
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(1, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          type="text"
          label="Name"
          isRequired={true}
          error={formik.errors}
          touched={formik.touched}
          value={formik.values.facilityName}
          name="facilityName"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
      </Box>

      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
        <CustomSearchField
          label="NPI"
          fieldVal={formik.values.facilityNpiNo}
          name="facilityNpiNo"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          handleModalOpen={() => setSearchNpiModal(true)}
          formik={formik}
          resetField={{
            facilityNpiNo:null
          }}
        />
        <CustomSearchField
          label="Taxonomy Speciality"
          fieldVal={formik.values.taxonomySpecialty}
          name="taxonomySpecialty"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          handleModalOpen={() => setOpenTaxonomyListModal(true)}
          formik={formik}
          resetField={{
            taxonomySpecialty:""
          }}
        />
      </Box>
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
       
        <CustomField
          disable={true}
          type="text"
          label="Sequence #"
          value={formik.values.sequenceNo || null}
          // name="referenceNo"
          // handleChange={formik.handleChange}
          // handleBlur={formik.handleBlur}
        />
        <CustomField
          type="text"
          label="Reference #"
          value={formik.values.referenceNo}
          name="referenceNo"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
      </Box>
      </Box>
      <CustomAccordion heading="Contact Information" maxHeight="300px">
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(1, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              type="text"
              isRequired={true}
              error={formik.errors}
              touched={formik.touched}
              label="Address"
              value={formik.values.facilityAddress}
              name="facilityAddress"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
          </Box>

          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(3, minmax(0, 1fr))",
              },
            }}
          >
            <CustomSelectBox2
              value={formik.values.facilityStateId}
              name="facilityStateId"
              label="State"
              handleChange={() => {
                formik.setFieldValue("facilityCityId", null);
              }}
              handleBlur={formik.handleBlur}
              formik={formik}
              dropdownOptions={stateOpt.map((opt) => ({
                value: opt.stateName,
                id: opt.stateId,
              }))}
            />
            <CustomSelectBox2
              value={formik.values.facilityCityId}
              disable={!formik.values.facilityStateId}
              name="facilityCityId"
              label="City"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              dropdownOptions={cityOpt.map((opt) => ({
                value: opt.cityName,
                id: opt.cityId,
              }))}
            />

            <CustomField
              type="text"
              label="ZIP Code"
              value={formik.values.facilityZipCode}
              isRequired={true}
              error={formik.errors}
              touched={formik.touched}
              name="facilityZipCode"
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </Box>

          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(3, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              type="number"
              label="Phone"
              value={formik.values.phoneNumber}
              name="phoneNumber"
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
            <CustomField
              type="number"
              label="Fax"
              value={formik.values.faxNumber}
              name="faxNumber"
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </Box>

          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(1, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              type="text"
              label="Email"
              value={formik.values.email}
              name="email"
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </Box>

          {/* ID Numbers */}
          <div>
            <Typography
              variant="h2"
              component="h3"
              sx={{
                // bgcolor: "#DBE8FC",
                background: theme.palette.accordInnerHeading.primary,
                padding: "10px",
                marginBottom: "20px",
              }}
            >
              ID Numbers
            </Typography>
            <Box
              display="grid"
              gap="30px"
              margin={"20px 0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 1fr))",
                },
              }}
            >
              <CustomField
                type="number"
                label="Tax ID"
                value={formik.values.taxId}
                name="taxId"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
              <CustomField
                type="text"
                label="CLIA ID"
                value={formik.values.cliaId}
                name="cliaId"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
              <CustomField
                type="text"
                label="Location Provider ID"
                value={formik.values.locationProviderId}
                name="locationProviderId"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
              <CustomField
                type="text"
                label="Site ID"
                value={formik.values.siteId}
                name="siteId"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />

              <CustomField
                type="text"
                label="BlueCross ID"
                value={formik.values.blueCrossId}
                name="blueCrossId"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
              <CustomField
                type="text"
                label="BlueShield ID"
                value={formik.values.blueShieldId}
                name="blueShieldId"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
              <CustomField
                type="text"
                label="Medicare ID"
                value={formik.values.medicareId}
                name="medicareId"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
              <CustomField
                type="text"
                label="Medicaid ID"
                value={formik.values.medicaidId}
                name="medicaidId"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
              <CustomField
                type="text"
                label="Locator Code"
                value={formik.values.locatorCode}
                name="locatorCode"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </Box>
          </div>

          {/* claim default */}
          <div>
            <Typography
              variant="h2"
              component="h3"
              sx={{
                // bgcolor: "#DBE8FC",
                background: theme.palette.accordInnerHeading.primary,
                padding: "10px",
                marginBottom: "20px",
              }}
            >
              Claim Defaults
            </Typography>
          </div>

          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
              },
            }}
          >
            <CustomSearchField
              label="Place of Service"
              fieldVal={formik.values.posCode}
              name="posCode"
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              handleModalOpen={() => setOpenPosModal(true)}
              formik={formik}
              resetField={{
                posCode:null
              }}
            />
          </Box>
      </CustomAccordion>
      {/* </form> */}
    </>
  );
};

export default FacilityInfo;