// // import React from 'react';
// // import CustomButton from "../../../components/CustomButton";
// // import MainHeading from "../../../components/MainHeading";
// // import { Box } from "@mui/material";
// // import { useFormik } from "formik";
// // import ReferringProviderInfo from './ReferringProviderInfo';
// // import { useNavigate, useParams } from "react-router-dom";
// // import { useDispatch, useSelector } from "react-redux";
// // import initialValues from "../referring-provider/NewReferringProvider"

// // const UpdateRefferingProvider = () => {
// //   // const navigate = useNavigate();
// //   // const { id } = useParams();
// //   // const {getRefProviderData, loading } = useSelector((state) => state.facility);
// //   // console.log(getRefProviderData, "getRefProviderData");
// //   // const refProviderDataToEdit = getRefProviderData.result?.find(
// //   //   (item) => item.renderingProviderId === Number(id)
// //   // );
// //   // const formik = useFormik({
// //   //   initialValues: refProviderDataToEdit || initialValues,
// //   //   onSubmit: (values, action) => {
// //   //     console.log(values, "checkRefVals");
// //   //   },
// //   // });
// //   // navigate("/referringProvider");
// //   // console.log(refProviderDataToEdit, "refProviderDataToEdit")
// //   return (
// //     <>
// //     <div style={{ padding: "20px 30px 0", marginLeft: "-30px" }}>
// //         <MainHeading mainheading={"Update Referring Provider"} />
// //       </div>

// //       <form 
// //       // onSubmit={formik.handleSubmit}
// //        >
// //         <Box
// //           display="grid"
// //           gap="30px"
// //           margin={"20px 20px 0"}
// //           sx={{
// //             gridTemplateColumns: {
// //               xs: "repeat(1, minmax(0, 150px))",
// //               sm: "repeat(1, minmax(0, 150px))",
// //               md: "repeat(2, minmax(0, 150px))",
// //             },
// //           }}
// //         >
// //           <CustomButton type="submit" isBlue={true} isEdit={true} gap={'7px'} >
// //             Update
// //           </CustomButton>
// //         </Box>
// //         <ReferringProviderInfo 
// //         // formik={formik}
// //          />
// //       </form>
// //       </>
    
// //   );
// // };

// // export default UpdateRefferingProvider;


// import React from 'react';
// import CustomButton from "../../../components/CustomButton";
// import MainHeading from "../../../components/MainHeading";
// import { useFormik } from 'formik';
// import { Box } from "@mui/material";
// import { useNavigate, useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import initialValues from "../referring-provider/NewReferringProvider"
// import ReferringProviderInfo from './ReferringProviderInfo';
// import axios from "axios";
// import path from "../../../config/apiUrl";

// const UpdateRefferingProvider = () => {


//   const [renderingProviderInfo, setRenderingProviderInfo] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const fetchRenderingProvider = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get(`${path}/renderingProvider`);
//       console.log("apiResponse",response)
//       if (response.status === 200) {
//         const data = await response.data;
       
//         setRenderingProviderInfo(data.result);
//       } else {
//         setRenderingProviderInfo([]);
//       }
//     } catch (error) {
//       throw new Error(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };
 
//   const refProviderDataToEdit = fetchRenderingProvider.result?.find(
//     (item) => item.renderingProviderId === Number(id)
//   );
//   console.log(refProviderDataToEdit, "refProviderDataToEdit")
//   const formik = useFormik({
//     initialValues: refProviderDataToEdit ||  initialValues ,
//     onSubmit: (values, action) => {
//       console.log(values, "checkFacilityVals");
    
//       // navigate("/referringProvider");
//     },
//   });

//   useEffect(() => {
//     fetchRenderingProvider();
//   }, []);
//       // const formik = useFormik({
//       //   initialValues: initialValues,
//       //   onSubmit: (values, action) => {
//       //     console.log(values, "checkRefProviderVals");
         
//       //   },
//       // });

//   return (
//     <>
//       <div style={{ padding: "20px 30px 0", marginLeft: "-30px" }}>
//         <MainHeading mainheading={"Update Referring Provider"} />
//       </div>

//       <form >
//         <Box
//           display="grid"
//           gap="30px"
//           margin={"20px 20px 0"}
//           sx={{
//             gridTemplateColumns: {
//               xs: "repeat(1, minmax(0, 150px))",
//               sm: "repeat(1, minmax(0, 150px))",
//               md: "repeat(2, minmax(0, 150px))",
//             },
//           }}
//         >
//           <CustomButton type="submit" isBlue={true} isEdit={true} gap={'7px'} >
//             Update
//           </CustomButton>
//         </Box>
      
//        <ReferringProviderInfo formik={formik}/>
//       </form>
//     </>
//   );
// };

// export default UpdateRefferingProvider;
