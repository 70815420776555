import { Search, Close } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";
import React from "react";

const CustomSearchField = ({
  label,
  fieldVal,
  handleChange,
  handleBlur,
  name,
  isRequired,
  resetField,
  // error,
  // touched,
  formik,
  disable,
  // setFieldValue,
  handleModalOpen,
  isNested = false
}) => {
  console.log(resetField, "resetField")
  
  // adding variable for nasted form field
  const fieldName = isNested ? name.split('.').shift() : name;

  const hasError = isRequired && formik?.errors[fieldName] && formik?.touched[fieldName];
  const theme = useTheme();

  const handleClear = () => {
    if (typeof resetField === "function") {
      const updatedFields = resetField();
      formik.setValues({
        ...formik.values,
        ...updatedFields,
      });
    } else if (typeof resetField === "object") {
      formik.setValues({
        ...formik.values,
        ...resetField,
      });
    }
  };

  return (
    <div>
      <label
        className="customLabel"
        style={{ color: theme.palette.Labeltext.primary }}
        htmlFor={name}
      >
        {label}
      </label>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          pointerEvents: disable ? "none" : "auto",
          opacity: disable ? 0.6 : 1,
          border: hasError
            ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
            : `1px solid ${theme.palette.fieldsBorder.primary}`,
          background: theme.palette.customFieldsBackground.primary,
          color: theme.palette.btnCancelText.primary,
        }}
      >
        <input
          style={{
            background: "transparent",
            border: "none",
            outline: "none",
            color: "inherit",
            flex: 1,
            fontSize: "1.5rem",
            padding: "0 10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            letterSpacing: "-0.05em",
            cursor: "pointer",
          }}
          className="customSearchField"
          type="text"
          autoComplete="off"
          value={fieldVal !== null ? fieldVal : ""}
          name={name}
          // onChange={handleChange}
          onBlur={handleBlur}
          spellCheck={false}
          disabled={disable}
          onClick={handleModalOpen}
        />
        <div style={{ display: "flex" }}>
          {(Array.isArray(fieldVal) && fieldVal.length > 0) ||
          (fieldVal && !Array.isArray(fieldVal)) ? (
            <IconButton
              onClick={handleClear}
              aria-label="clear input"
              edge="end"
            >
              <Close />
            </IconButton>
          ) : null}

          <IconButton onClick={handleModalOpen}>
            <Search />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default CustomSearchField;
