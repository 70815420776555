import { Box, FormControl, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomField from "../../../components/CustomField";
import CustomModal from "../../../components/CustomModal";
import Diagnosis from "../../custom-setup/diagnosis/Diagnosis";
import OtherProcdure from "../../custom-setup/other-procedure/OtherProcedure";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import "../../claim-dir/claim/claim-styling/InformationCodes.css";
import CustomDatePicker from "../../../components/CustomDatePicker";
import DeleteIcon from "@mui/icons-material/Delete";

const InformationCodes = ({ formik }) => {
  const [openDiagnosisModal, setOpenDiagnosisModal] = useState(false);
  const [openProcedureModal, setOpenProcedureModal] = useState(false);
  const [openPrincipalDaignoseModal, setOpenPrincipalDaignoseModal] =
    useState(false);
  const [openAdmitDaignoseModal, setOpenAdmitDaignoseModal] = useState(false);

  const types = [
    {
      id: 1,
      type: "One",
    },
    {
      id: 2,
      type: "Two",
    },
    {
      id: 3,
      type: "Three",
    },
  ];
  const [diagnoseTable, setDiagnoseTable] = useState(null);
  const [poa, setPoa] = useState([]);
  let claimInfoCodeName = [
    {
      id: 1,
      name: "Injury Caues",
    },
    {
      id: 2,
      name: "Patient Visit Reason",
    },
    {
      id: 3,
      name: "Other Diagnosis",
    },
    {
      id: 4,
      name: "Other Procedure",
    },
    {
      id: 5,
      name: "Occurrence Span",
    },
    {
      id: 6,
      name: "Occurrence",
    },
    {
      id: 7,
      name: "Value",
    },
    {
      id: 8,
      name: "Condition",
    },
  ];

  //   handle diagnosis
  const handleInsDiagnosis = (value, daignoseTableId) => {
    formik.values.insClaimInfoDetailDto.push({
      insClaimInfoCTId: value?.id,
      insClaimInfoCodeId: daignoseTableId,
      codeType: value?.codeType,
      description: value?.diagnosisDescription,
      from: null,
      to: null,
      valueAmount: 0,
      poaId: null,
    });
    setOpenDiagnosisModal(false);
    setOpenProcedureModal(false);
  };

  const clickClaimInfoSearch = (claimId) => {
    if (claimId === 1 || claimId === 2 || claimId === 3) {
      console.log("daignose model");
      setDiagnoseTable(claimId);
      setOpenDiagnosisModal(true);
    } else {
      console.log("procedure model");
      setDiagnoseTable(claimId);
      setOpenProcedureModal(true);
    }
  };

  const handleValueChange = (parentId, childId, type, e) => {
    /// store insClaimInfoDetailDto value into insCalimDto Val
    let insClaimDto = formik?.values?.insClaimInfoDetailDto;
    // findIndex of current Child
    let found = insClaimDto.findIndex(
      (val) =>
        val?.insClaimInfoCTId === childId &&
        val?.insClaimInfoCodeId === parentId
    );
    // replace values if integer than convert into integer
    if (found !== -1) {
      let currentObj = insClaimDto[found];
      if (type === "valueAmount") {
        currentObj[type] = parseFloat(e.target.value);
      } else {
        currentObj[type] = e.target.value;
      }
      formik.setFieldValue("insClaimInfoDetailDto", insClaimDto);
    }
  };

  const handleDelete = (parentId, childId) => {
    console.log("parentId , childId ", parentId, childId);
    let insClaimDto = formik?.values?.insClaimInfoDetailDto;
    // findIndex of current Child
    let found = insClaimDto.findIndex(
      (val) =>
        val?.insClaimInfoCTId === childId &&
        val?.insClaimInfoCodeId === parentId
    );
    // replace values if integer than convert into integer
    if (found !== -1) {
      insClaimDto.splice(found, 1);
    }
    formik.setFieldValue("insClaimInfoDetailDto", insClaimDto);
  };

  // Define data fetching URLs
  const dataFetchUrls = {
    poa: `${path}/ct-poa`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.poa, setPoa);
  }, []);

  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = theme.palette;
  return (
    <>
      {/* modals */}
      {diagnoseTable === 1 || diagnoseTable === 2 || diagnoseTable === 3 ? (
        <CustomModal
          open={openDiagnosisModal}
          handleClose={() => setOpenDiagnosisModal(false)}
        >
          <Diagnosis
            handleClose={() => setOpenDiagnosisModal(false)}
            formik={formik}
            diagnoseTable={diagnoseTable}
            //   icdIdentifier={icdIdentifier}
            handleCellClick={handleInsDiagnosis}
          />
        </CustomModal>
      ) : (
        <CustomModal
          open={openProcedureModal}
          handleClose={() => setOpenProcedureModal(false)}
        >
          <OtherProcdure
            handleClose={() => setOpenProcedureModal(false)}
            formik={formik}
            diagnoseTable={diagnoseTable}
            //   icdIdentifier={icdIdentifier}
            handleCellClick={handleInsDiagnosis}
          />
        </CustomModal>
      )}
      {/* openPrincipalDaignoseModal */}
      <CustomModal
        open={openPrincipalDaignoseModal}
        handleClose={() => setOpenPrincipalDaignoseModal(false)}
      >
        <Diagnosis
          handleClose={() => setOpenPrincipalDaignoseModal(false)}
          formik={formik}
          diagnoseTable={diagnoseTable}
          //   icdIdentifier={icdIdentifier}
          handleCellClick={(v, i) => {
            formik.setFieldValue("principalDiagnosisId", v?.id);
            setOpenPrincipalDaignoseModal(false);
          }}
        />
      </CustomModal>
      {/* openAdmitDaignoseModal */}
      <CustomModal
        open={openAdmitDaignoseModal}
        handleClose={() => setOpenAdmitDaignoseModal(false)}
      >
        <Diagnosis
          handleClose={() => setOpenAdmitDaignoseModal(false)}
          formik={formik}
          diagnoseTable={diagnoseTable}
          //   icdIdentifier={icdIdentifier}
          handleCellClick={(v, i) => {
            formik.setFieldValue("admittingDiagnosisId", v?.id);
            setOpenAdmitDaignoseModal(false);
          }}
        />
      </CustomModal>
      {/* modals end */}

      <Box
        display="grid"
        gap="20px"
        sx={{
          marginTop: "20px",
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 450px))",
          },
        }}
      >
        <Stack
          className="infoCodeContainer"
          sx={{
            flexDirection: { xs: "column", sm: "row", md: "row" },
          }}
        >
          <FormControl fullWidth>
            <CustomSearchField
              type="text"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              isRequired={true}
              fieldVal={
                formik.values.principalDiagnosisId ??
                formik.values.principalDiagnosisId
              }
              resetField={{
                principalDiagnosisId: null,
              }}
              name="principalDiagnosisId"
              handleModalOpen={() => setOpenPrincipalDaignoseModal(true)}
              label="Principal Diagnosis"
              formik={formik}
            />
          </FormControl>
          {/* <Typography
            variant="h6"
            component="span"
            className="ft_content"
            width={{ xs: "100%", sm: "30%", md: "38%" }}
            display={formik.values.formType === "1" && "none"}
          >
            {formik.values.formType === "2"
                    ? "2300 REF~P4"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
          </Typography> */}
        </Stack>
        {/* POA */}
        <Stack
          className="infoCodeContainer"
          sx={{
            flexDirection: { xs: "column", sm: "row", md: "row" },
          }}
        >
          <FormControl fullWidth>
            <CustomSelectBox2
              name="poaId"
              formik={formik}
              value={formik.values.poaId}
              dropdownOptions={poa?.map((opt) => ({
                value: opt.poaType,
                id: opt.poaId,
              }))}
              label="POA"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
          </FormControl>
          {/* <Typography
            variant="h6"
            component="span"
            className="ft_content"
            width={{ xs: "100%", sm: "30%", md: "38%" }}
            display={formik.values.formType === "1" && "none"}
          >
            {formik.values.formType === "2"
                    ? "2300 CLM-20"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
          </Typography> */}
        </Stack>
        {/* Admitting Diagnosis */}
        <Stack
          className="infoCodeContainer"
          sx={{
            flexDirection: { xs: "column", sm: "row", md: "row" },
          }}
        >
          <FormControl fullWidth>
            <CustomSearchField
              type="text"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              fieldVal={
                formik.values.admittingDiagnosisId ??
                formik.values.admittingDiagnosisId
              }
              name="admittingDiagnosisId"
              handleModalOpen={() => setOpenAdmitDaignoseModal(true)}
              resetField={{ admittingDiagnosisId: null }}
              label="Admitting Diagnosis"
              isRequired={true}
              formik={formik}
            />
          </FormControl>

          {/* <Typography
            variant="h6"
            component="span"
            className="ft_content"
            width={{ xs: "100%", sm: "30%", md: "38%" }}
            display={formik.values.formType === "1" && "none"}
          >
            {formik.values.formType === "2"
                    ? "2300 REF~P4"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
          </Typography> */}
        </Stack>
        {/* Principal Procedure */}
        <Stack
          className="infoCodeContainer"
          sx={{
            flexDirection: { xs: "column", sm: "row", md: "row" },
          }}
        >
          <FormControl fullWidth>
            <CustomSearchField label="Principal Procedure" />
          </FormControl>
        </Stack>
        {/* Date */}
        <Stack
          className="infoCodeContainer"
          sx={{
            flexDirection: { xs: "column", sm: "row", md: "row" },
          }}
        >
          <FormControl fullWidth>
            <CustomDatePicker
              formik={formik}
              dateLabel="Procedure Date"
              name="principalProcedureDate"
              dateValue={formik.values.principalProcedureDate}
              handleDateChange={formik.handleChange}
              handleDateBlur={formik.handleBlur}
            />
          </FormControl>
        </Stack>
      </Box>
      {claimInfoCodeName?.map((val, index) => {
        return (
          <Box>
            <Typography
              sx={{ marginTop: "10px", fontSize: "1.5rem", fontWeight: "600" }}
            >
              {val?.name}
            </Typography>
            <Box
              display="grid"
              gap="20px"
              sx={{
                marginTop: "10px",
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(1, minmax(0, 920px))",
                },
              }}
            >
              <table id="table">
                <thead
                  style={{
                    background: theme.palette.infocodesHeader.primary,
                    color: theme.palette.sidebarDDownText.primary,
                  }}
                  id="t-header"
                >
                  <tr>
                    <th className="th">Code</th>
                    {(val.id === 4 || val.id === 5 || val.id === 6) && (
                      <th th className="th">
                        {val?.id === 4 || val?.id === 6 ? "Date" : "From"}
                      </th>
                    )}
                    {val.id === 5 && (
                      <th th className="th">
                        To
                      </th>
                    )}
                    {val.id === 7 && (
                      <th th className="th">
                        Amount
                      </th>
                    )}
                    <th th className="th">
                      Description
                    </th>
                    {val.id === 3 && (
                      <th th className="th">
                        POA
                      </th>
                    )}
                    <th className="th"></th>
                  </tr>
                </thead>
                {formik?.values?.insClaimInfoDetailDto?.map((el, id) => {
                  return (
                    el?.insClaimInfoCodeId === val?.id && (
                      <tbody>
                        <tr>
                          <td
                            className="td-date"
                            onClick={() => clickClaimInfoSearch(val?.id)}
                          >
                            <div style={{ display: "flex", width: "240px" }}>
                              <div className="code-data">{el?.codeType}</div>
                              <div className="code-data"></div>
                            </div>
                          </td>
                          {(val.id === 4 || val.id === 5 || val.id === 6) && (
                            <td className="td-date">
                              {" "}
                              {/* <input
                                type="date"
                                style={{ padding: "3px", width: "100%" }}
                                value={el?.from}
                                onChange={(e) =>
                                  handleValueChange(
                                    val?.id,
                                    el?.insClaimInfoCTId,
                                    "from",
                                    e
                                  )
                                }
                              /> */}
                              <CustomDatePicker
                                formik={formik}
                                dateValue={el?.from}
                                handleDateChange={(e) =>
                                  handleValueChange(
                                    val?.id,
                                    el?.insClaimInfoCTId,
                                    "from",
                                    e
                                  )
                                }
                              />
                            </td>
                          )}
                          {val.id === 5 && (
                            <td style={{ border: "1px solid black" }}>
                              {/* <input
                                type="date"
                                value={el?.to}
                                style={{ padding: "3px", width: "100%" }}
                                onChange={(e) =>
                                  handleValueChange(
                                    val?.id,
                                    el?.insClaimInfoCTId,
                                    "to",
                                    e
                                  )
                                }
                              /> */}
                              <CustomDatePicker
                                formik={formik}
                                dateValue={el?.to}
                                handleDateChange={(e) =>
                                  handleValueChange(
                                    val?.id,
                                    el?.insClaimInfoCTId,
                                    "to",
                                    e
                                  )
                                }
                              />
                            </td>
                          )}
                          {val.id === 7 && (
                            <td style={{ border: "1px solid black" }}>
                              {/* <input
                                style={{ padding: "3px", width: "100%" }}
                                type="number"
                                value={el?.valueAmount}
                                onChange={(e) =>
                                  handleValueChange(
                                    val?.id,
                                    el?.insClaimInfoCTId,
                                    "valueAmount",
                                    e
                                  )
                                }
                              ></input> */}
                              <CustomField
                                type="number"
                                formattedValue={el?.valueAmount}
                                handleChange={(e) =>
                                  handleValueChange(
                                    val?.id,
                                    el?.insClaimInfoCTId,
                                    "valueAmount",
                                    e
                                  )
                                }
                              />
                            </td>
                          )}
                          <td id="description">{el?.description}</td>
                          {val.id === 3 && (
                            <td>
                              <select
                                value={el?.poaId}
                                id="select-one"
                                onChange={(e) =>
                                  handleValueChange(
                                    val?.id,
                                    el?.insClaimInfoCTId,
                                    "poaId",
                                    e
                                  )
                                }
                              >
                                <option className="selections">Select</option>
                                <option className="selections">A</option>
                                <option className="selections">B</option>
                                <option className="selections">C</option>
                              </select>
                            </td>
                          )}
                          <td
                            style={{
                              cursor: "pointer",
                              border: "1px solid black",
                              padding: "5px",
                            }}
                            onClick={() =>
                              handleDelete(val?.id, el?.insClaimInfoCTId)
                            }
                          >
                            <DeleteIcon />
                          </td>
                        </tr>
                      </tbody>
                    )
                  );
                })}
                <tbody>
                  <tr>
                    <td
                      //  className="td"
                      onClick={() => clickClaimInfoSearch(val?.id)}
                    >
                      {/* <div>
                        <div></div>
                        <div style={{ fontSize: "1.3rem " }}>
                          <SearchIcon />
                        </div>
                      </div> */}
                      <CustomSearchField
                        type="text"
                        handleModalOpen={() => clickClaimInfoSearch(val?.id)}
                        // handleChange={formik.handleChange}
                        // handleBlur={formik.handleBlur}
                        // fieldVal={
                        //   formik.values?.icD_DiagnosisDetailDto[i]
                        //     ?.diagnosisDescription
                        // }
                        // name={`icd_Description_${v}`}
                      />
                    </td>
                    {(val.id === 4 || val.id === 5 || val.id === 6) && (
                      <td className="td-date">
                        {" "}
                        {/* <input
                          type="date"
                          disabled
                          style={{ padding: "3px", width: "100%" }}
                          // value={selectedDate}
                          // onChange={handleDateChange}
                        /> */}
                        <CustomDatePicker formik={formik} disable={true} />
                      </td>
                    )}
                    {val.id === 5 && (
                      <td className="td-date">
                        {/* <input
                          type="date"
                          disabled
                          style={{ padding: "3px", width: "100%" }}
                          // value={selectedDate}
                          // onChange={handleDateChange}
                        /> */}

                        <CustomDatePicker formik={formik} disable={true} />
                      </td>
                    )}
                    {val.id === 7 && (
                      <td className="td-date">
                        {/* <input
                          disabled
                          style={{ padding: "3px", width: "100%" }}
                          type="number"
                        ></input> */}
                        <CustomField disable={true} type="number" />
                      </td>
                    )}
                    <td
                      style={{ border: "1px solid black", padding: "2px" }}
                    ></td>
                    {val.id === 3 && (
                      <td className="td-date">
                        <select disabled id="select">
                          <option className="selections">Select</option>
                          <option className="selections">A</option>
                          <option className="selections">B</option>
                          <option className="selections">C</option>
                        </select>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default InformationCodes;
