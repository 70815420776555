import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

export const newProcedureCodeAction = createAsyncThunk(
  "createProcedureCode",
  async (data) => {
    try {
      const response = await postData(`${path}/ct-procedureCodes`, data);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateProcedureCodeAction = createAsyncThunk(
  "updateProcedureCode",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/ct-procedureCodes/${data?.procedureCodeId}`,
        data
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getProcedureCodeAction = createAsyncThunk(
  "getProcedureCodes",
  async () => {
    try {
      const response = await getData(`${path}/ct-procedureCodes`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

// search
export const getProcedureCodesBySearchTermAction = createAsyncThunk(
  "getProcedureCodes",
  async ({searchTerm}) => {
    try {
      const response = await getData(
        `${path}/ct-procedureCodes/SearchProcedureCodes?SearchValue=${searchTerm}`
      );
      console.log(response, "action procedure codes by search term");
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);


