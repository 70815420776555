// import React, { useEffect, useState } from 'react';
// import {Typography,Stack,Box,useTheme} from '@mui/material';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';


// const OldPatient = ({ data }) => {
//   const theme = useTheme();
//   const totalOldPatient = data
//     ? data.reduce((acc, item) => acc + item.oldPatientCount, 0)
//     : 0;
//   const totalPatient = data
//     ? data.reduce(
//         (acc, item) => acc + item.oldPatientCount + item.newPatientCount,
//         0
//       )
//     : 0;
//   const percentage = ((totalPatient / totalOldPatient) * 100).toFixed(2);

//   return (
//     <>
//       <Card
//         className="newPatient"
//         sx={{
//           width: "50%",
//           background: theme.palette.announcementCard.primary,
//         }}
//       >
//         <CardContent>
//           <Box>
//             <Typography
//               sx={{
//                 marginLeft: {
//                   md: "40%",
//                   sm: "40%",
//                   xs: "40%",
//                   lg: "20%",
//                   xl: "40%",
//                 },
//               }}
//               className="oldPatientHeading"
//             >
//               Old Patient
//             </Typography>
//             <Box className="totalPatient">
//               <Stack spacing={"5px"} direction="row">
//                 <Typography
//                   sx={{
//                     color: theme.palette.globalText,
//                     fontFamily: "Radio Canada",
//                     fontSize: "2rem",
//                     fontWeight: "700",
//                     //  marginLeft:{xl:'27%',md:'70%',lg:'3%',xs:'100%'}
//                   }}
//                 >
//                   {totalOldPatient}
//                 </Typography>

//                 <Box>
//                   <Typography
//                     sx={{
//                       color: "#ED1010",
//                       fontSize: "1.2rem",
//                       //  marginLeft:{xl:'27%',md:'60%',lg:'2%'}
//                     }}
//                   >
//                     {percentage}%
//                   </Typography>
//                 </Box>
//               </Stack>
//             </Box>
//           </Box>
//         </CardContent>
//       </Card>
//     </>
//   );
// };

// export default OldPatient;



import React from 'react';
import { Typography, Stack, Box, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const OldPatient = ({ data }) => {
  const totalOldPatient = data ? data.reduce((acc, item) => acc + item.oldPatientCount, 0) : 0;
  const totalPatient = data ? data.reduce((acc, item) => acc + item.oldPatientCount + item.newPatientCount, 0) : 0;
  const percentage = totalOldPatient > 0 ? ((totalPatient / totalOldPatient) * 100).toFixed(2) : 0;

  const theme = useTheme();
  return (
    <Card sx={{ width:'100%',background: theme.palette.announcementCard.primary }}>
      <CardContent>
        <Box>
          <Typography   sx={{ textAlign: 'center', marginBottom: '16px' }}className='oldPatientHeading'>
            Old Patient
          </Typography>
          <Box className='totalPatient'>
            <Stack spacing={'5px'} direction='row'>
              <Typography sx={{ color: theme.palette.globalText, fontFamily: 'Radio Canada', fontSize: '2rem', fontWeight: '700' }}>
                {totalOldPatient}
              </Typography>
              <Box>
                <Typography sx={{ color: '#ED1010', fontSize: '1.2rem' }}>
                  {percentage}%
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OldPatient;
