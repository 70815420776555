import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getAdjustmentAction = createAsyncThunk(
  "getAdjustments",
  async () => {
    try {
      const response = await getData(`${path}/AdjustmentCode`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const newAdjustmentAction = createAsyncThunk(
  "createAdjustment",
  async (data) => {
    try {
      const response = await postData(`${path}/AdjustmentCode`, data);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

// search

export const getAdjustmentBySearchTermAction = createAsyncThunk("getAdjustments", async (val) => {
  try {
    const response = await getData(`${path}/AdjustmentCode/SearchAdjustmentCode?searchValue=${val}`);
    console.log(response, "action adjustment by search term");
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});

export const updateAdjustmentAction = createAsyncThunk(
  "updateAdjustment",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/AdjustmentCode/${data.adjustmentCodeId}`,
        data
      );
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);
