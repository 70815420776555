import { useState } from "react";
import CustomButton from "../CustomButton";
import { Typography, ListItem, ListItemText, List } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
const FileUpload = ({ multiple, handleMultipleSubmit , files , setFiles }) => {
  

  function handleMultipleChange(event) {
    setFiles([...files, ...event.target.files]);
  }

  return (
    <div>
      <form
        onSubmit={handleMultipleSubmit}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <label
          style={{ display: "flex", cursor: "pointer" }}
          onChange={(e) => handleMultipleChange(e, files)}
          htmlFor="formId"
        >
          <input
            name=""
            type="file"
            multiple={multiple}
            accept=".pdf,.doc,.docx"
            id="formId"
            hidden
          />
          <Typography> Add </Typography> <FileUploadIcon />
        </label>
        <CustomButton
          type="submit"
          isBlue={true}
          variant="contained"
          margin="30px 5px 10px"
        >
          Upload
        </CustomButton>
      </form>

      <List sx={{ display: "flex", flexWrap: "wrap" }} dense={false}>
        {files.map((val, ind) => {
          return (
            <ListItem>
              <ListItemText
                primary={
                  val?.name.length > 10
                    ? `${val?.name.substring(0, 10)}...`
                    : val.name
                }
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default FileUpload;
