import React, { useState } from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import CustomField from "../../../components/CustomField";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomModal from "../../../components/CustomModal";
import ProcedureClaim from "../../claim-dir/claim/ProcedureClaim";
import TextArea from "../../../components/TextArea";
import CustomExpandIcon from "../../../components/CustomExpandIcon";
import { CheckBox } from "@mui/icons-material";
import ProcedureModal from "./ProcedureModal";
import CustomPreview from "../../../layout/CustomPreview";
import { getProcedureCodeAction, getProcedureCodesBySearchTermAction } from "../../../features/actions/procedureCodeAction";
import { procedureModal } from "../../../utils/gridCols";
const InventoryCodeForm = ({ formik }) => {

  const { getProcedureCodes } = useSelector((state) => state.procedureCodes);
  const procedureLoading = useSelector((state) => state.procedureCodes.loading);

  const [openProcedureModal, setOpenProcedureModal] = useState(false);
  const handleProcedureCode = (e) => {
    formik.setValues((prev) => ({
      ...prev,
      procedureCodeId: e?.id,
      procedureCode: e?.procedureCode,
    }));
    setOpenProcedureModal(false);
  };
  return (
    <>
      <CustomModal
        open={openProcedureModal}
        handleClose={() => setOpenProcedureModal(false)}
        heading={'All Procedure'}
        height={'20%'}
      >
        {/* <ProcedureModal handleCellClick={handleProcedureCode} /> */}
        <CustomPreview
        isModal={true}
        rows={
          getProcedureCodes
              ?  getProcedureCodes.map((el) => ({
                  id: el?.procedureCodeId,
                  ...el,
                }))
              : []
        }
        columns={procedureModal}
        handleGetAll={getProcedureCodeAction}
        handleCell={handleProcedureCode}
        handleBySearch={getProcedureCodesBySearchTermAction}
        searchLabel='Search By Procedure Code and Procedure Description'
        />
      </CustomModal>
      <Box 
      // sx={{ display: "flex", gap: "10px", margin: "13px 0px" }}
      display="grid"
      gap="30px"
      margin={"20px 0"}
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, minmax(0, 1fr))",
          sm: "repeat(1, minmax(0, 1fr))",
          md: "repeat(1, minmax(0, 1fr))",
        },
      }}
      >
        <CustomField
          error={formik.errors}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          isRequired={true}
          label={"Code"}
          name={"code"}
          value={formik.values.code}
          type={"text"}
          touched={formik.touched}
        />
        <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          label={"Qunatity"}
          name={"quantity"}
          value={formik.values.quantity}
          type={"number"}
        />
      </Box>
      <Box 
      // sx={{ width: { md: "35%", xs: "80%" }, margin: "13px 0px" }}
      display="grid"
      gap="30px"
      margin={"20px 0"}
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, minmax(0, 1fr))",
          sm: "repeat(1, minmax(0, 1fr))",
          md: "repeat(1, minmax(0, 1fr))",
        },
      }}
      >
        <CustomSearchField
          fieldVal={formik.values.procedureCode}
          label={"Procedure Code"}
          name={"procedureCodeId"}
          type="text"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          handleModalOpen={() => setOpenProcedureModal(true)}
          formik={formik}
          resetField={{
            procedureCode:null
          }}
        />
      </Box>
      <Box 
      // sx={{ width: { md: "35%", xs: "80%" }, margin: "13px 0px" }}
      display="grid"
      gap="30px"
      margin={"20px 0"}
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, minmax(0, 1fr))",
          sm: "repeat(1, minmax(0, 1fr))",
          md: "repeat(1, minmax(0, 1fr))",
        },
      }}>
        <TextArea
          type="text"
          value={formik.values.codeDescription}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          name={"codeDescription"}
          minRows={3}
          placeholder="Code Description"
        />
      </Box>
      <Box 
      // sx={{ width: { md: "35%", xs: "80%" }, margin: "13px 0px" }}
      display="grid"
      gap="30px"
      margin={"20px 0"}
      sx={{
        gridTemplateColumns: {
          xs: "repeat(1, minmax(0, 1fr))",
          sm: "repeat(1, minmax(0, 1fr))",
          md: "repeat(1, minmax(0, 1fr))",
        },
      }}
      >
        <TextArea
          type="text"
          value={formik.values.billingDescription}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          name={"billingDescription"}
          minRows={3}
          placeholder="Billing Description"
        />
      </Box>
      <Box sx={{ width: { md: "35%", xs: "80%" }, margin: "13px 0px" }}>
        {/* <CheckBox onChange={(e)=> console.log(e) } /> */}
        <FormControlLabel
          control={
            <Checkbox
              value={formik.values.alert}
              onChange={(e) =>
                formik.setFieldValue("alert", !formik.values.alert)
              }
            />
          }
          label="Use Alert"
        />
      </Box>
      {formik.values.alert && (
        <>
          <Box sx={{ width: { md: "35%", xs: "80%" }, margin: "13px 0px" }}>
            <TextArea
              type="text"
              value={formik.values.alertDescription}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              name={"alertDescription"}
              isRequired={formik.values.alert}
              formik={formik}
              minRows={3}
              placeholder="Alert Description"
            />
          </Box>
          <Box 
          sx={{ display: "flex", gap: "10px", margin: "13px 0px" }}
          >
            <CustomField
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              label={"Display alert when quantity falls below"}
              name={"alertQuantity"}
              value={formik.values.alertQuantity}
              type={"number"}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default InventoryCodeForm;
