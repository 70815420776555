// import { Box, Stack, useTheme } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import React, { useEffect, useState } from "react";
// import CustomButton from "../../../components/CustomButton";
// import ChargeDetails from "./ChargeDetails";
// import MultiButton from "../../../components/MultiButton";
// import { getData } from "../../../config/axiosFunctions";
// import path from "../../../config/apiUrl";

// const SearchedControls = ({ searchedStatusData, handleClose, moduleName,formik }) => {
//   const [claimStatusOpt, setClaimStatusOpt] = useState([]);
//   const [priorityTypeOpt, setPriorityTypeOpt] = useState([]);
//   const [selectedRow, setSelectedRows] = useState([]);
//   const [selectionModel, setSelectionModel] = useState([]);
//   const [showChargeDetail, setShowChargeDetail] = useState(false);
//   const theme = useTheme();

//   console.log(claimStatusOpt, "claimStatusOpt");

//   const columns = [
//     {
//       field: "claimNumber",
//       headerName: "Claim #",
//       flex: 2,
//       minWidth: 150,
//       headerAlign: "center",
//       filterable: true,
//       align: "center",
//       headerClassName: "header-bg",
//       cellClassName: "cell-content",
//     },
//     {
//       field: "dateOfService",
//       headerName: "DOS",
//       flex: 2,
//       minWidth: 150,
//       headerAlign: "center",
//       filterable: true,
//       align: "center",
//       headerClassName: "header-bg",
//       cellClassName: "cell-content",
//     },
//     {
//       field: "name",
//       headerName: "Patient",
//       flex: 2,
//       minWidth: 250,
//       headerAlign: "center",
//       filterable: true,
//       align: "center",
//       headerClassName: "header-bg",
//       cellClassName: "cell-content",
//       valueGetter: (params) => {
//         const { firstName, lastName } = params.row;
//         return `${firstName} ${lastName}`;
//       },
//     },
//     {
//       field: "chargeAmount",
//       headerName: "Charge Amount",
//       flex: 2,
//       minWidth: 150,
//       headerAlign: "center",
//       filterable: true,
//       align: "center",
//       headerClassName: "header-bg",
//       cellClassName: "cell-content",
//     },
//     {
//       field: "chargeBalance",
//       headerName: "Balance",
//       flex: 2,
//       minWidth: 150,
//       headerAlign: "center",
//       filterable: true,
//       align: "center",
//       headerClassName: "header-bg",
//       cellClassName: "cell-content",
//     },
//   ];

//   const rows =
//     searchedStatusData?.length > 0
//       ? searchedStatusData?.map((el, index) => ({
//           id: index,
//           ...el,
//         }))
//       : [];

//   // row click
//   const handleRowClick = (params, event) => {
//     const isCheckboxClick = event.target.tagName === "INPUT";
//     if (!isCheckboxClick) {
//       setSelectionModel([params.id]);
//     }
//   };

//   // fetch cts here
//   const dataFetchUrl = {
//     claimStatus: `/ct-claimStatusModules/GetClaimStatusModulesByModuleName?ModuleName=${moduleName}`,
//     ctPriorityType: `/ct-priorityType`

//   };

//   const fetchData = async (endPoint, setState) => {
//     try {
//       const response = await getData(`${path}${endPoint}`);
//       setState(response.result);
//     } catch (error) {
//       console.log("error while fetch data", error);
//     }
//   };
//   useEffect(() => {
//     fetchData(dataFetchUrl.claimStatus, setClaimStatusOpt);
//     fetchData(dataFetchUrl.ctPriorityType, setPriorityTypeOpt);
//   }, []);
//   //   handle modal change
//   const handleSelectionModalChange = (newSelection) => {
//     console.log(newSelection, "newSelection");
//     setSelectionModel(newSelection);
//     const lastSeletedRow = searchedStatusData?.find(
//       (row, ind) => ind === newSelection[newSelection.length - 1]
//     );
//     setSelectedRows(lastSeletedRow);
//   };
//   return (
//     <Box
//       minHeight="200px"
//       height="70vh"
//       sx={{
//         display: "flex",
//         gap: 2,
//       }}
//     >
//       <Stack width={showChargeDetail ? "70%" : "100%"}>
//         <Stack direction="row" alignItems="center" gap={2} margin="15px 0">
//           <CustomButton isCancel={true} handleClick={handleClose}>
//             Close
//           </CustomButton>
//           <CustomButton
//             isBlue={true}
//             handleClick={() => setShowChargeDetail(!showChargeDetail)}
//           >
//             {showChargeDetail ? "Hide" : "Show"} Charge Details
//           </CustomButton>
//           {/* <MultiButton
//             title="Update Status"
//             multiSection={priorityTypeOpt?.map((opt) => ({
//               ...opt,
//               id: opt?.priorityTypeId,
//               value: opt?.priorityType,
//             }))}

//             multiOpts={claimStatusOpt?.map((opt) => ({
//               ...opt,
//               id: opt?.claimStatusId,
//               value: opt?.claimStatusName,
//             }))}
//           /> */}
// <MultiButton
//             title="Update Status"
//             multiOpts={claimStatusOpt.map((opt) => ({
//               id: opt.claimStatusId,
//               value: opt.claimStatusName,
//             }))}
//             multiSection={priorityTypeOpt.map((opt) => ({
//               id: opt.priorityTypeId,
//               value: opt.priorityType,
//             }))}
//             radioGroupName="priorityTypeId"
//             selectedValue={formik.values.priorityTypeId}
//             onRadioChange={(event) => formik.setFieldValue('priorityTypeId', event.target.value)}
//           />
//         </Stack>
//         <DataGrid
//           sx={{
//             "& .header-bg": {
//               background: theme.palette.dataGridHeading.primary,
//               color: theme.palette.textMainHeading.primary,
//               fontSize: "16px",
//             },
//             "& .cell-content": {
//               fontSize: "1.2rem",
//             },
//             "& .MuiTablePagination-displayedRows": {
//               fontSize: "1.3rem",
//             },
//             "& .MuiTablePagination-actions": {
//               "& svg": {
//                 fontSize: "1.8rem",
//                 color: theme.palette.textMainHeading.primary,
//                 marginBottom: "8px",
//               },
//               "& .MuiDataGrid-row": {
//                 backgroundColor: theme.palette.textMainHeading.primary,
//               },
//             },
//           }}
//           columns={columns}
//           rows={rows}
//           // autoHeight
//           rowHeight={35}
//           showColumnRightBorder
//           showCellRightBorder
//           checkboxSelection
//           onSelectionModelChange={handleSelectionModalChange}
//           onRowClick={handleRowClick}
//           selectionModel={selectionModel}
//         />
//       </Stack>

//       {showChargeDetail && (
//         <Box
//           sx={{
//             height: "100%",
//             width: `30%`,
//             border: ".5px solid grey",
//           }}
//         >
//           <ChargeDetails chargeDetailData={selectedRow?.statusControlDetails} />
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default SearchedControls;


import {
  Box,
  Stack,
  useTheme,
  Button,
  Menu,
  MenuItem,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useConfirm } from 'material-ui-confirm';
import ChargeDetails from "./ChargeDetails";
import { getData, updateData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import KeyboardArrowDownSharp from "@mui/icons-material/KeyboardArrowDownSharp";
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { statusControlAction } from "../../../features/actions/statusControlAction";
import { useDispatch } from "react-redux";

const SearchedControls = ({ searchedStatusData, handleClose, moduleName , payload, setSearchedStatusData}) => {
  console.log(payload, "checkPayload1")
  const confirm = useConfirm();
  const dispatch = useDispatch()
  const [claimStatusOpt, setClaimStatusOpt] = useState([]);
  const [priorityTypeOpt, setPriorityTypeOpt] = useState([]);
  const [selectedRow, setSelectedRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [showChargeDetail, setShowChargeDetail] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const controlUpdateFormik = useFormik({
    initialValues: {
      claimStatusId: 0,
      priorityTypeId: 1,
      claimNo: [],
    },
    onSubmit: async (values) => {
      if (selectionModel.length === 0) {
        confirm({
          confirmationText:"Ok",
          title:"No Claims selected. Please select at least one Claim"
        });
        return;
      }
      const body = {
        claimStatusId: values.claimStatusId,
        priorityTypeId: values.priorityTypeId,
        claimNo: selectionModel.map((id) => rows[id].claimNumber),
      };
      if (!body.claimStatusId) {
        confirm({
          confirmationText:"Ok",
          title:"Please Select a Claim Status"
        });
        return;
      } else if (!body.priorityTypeId) {
        confirm({
          confirmationText:"Ok",
          title:"Please Select a Priority Type"
        });
        return;
      } else if (body.claimNo.length === 0) {
        confirm({
          confirmationText:"Ok",
          title:"Please select a claim No"
        });
        return;
      }
      if (body.claimStatusId && body.priorityTypeId && body.claimNo.length > 0) {
        confirm({
          confirmationText:"Ok",
          title: (`Are you sure you would like to Update Charges on the Selected Claim to ${claimStatusOpt.find(opt => opt.claimStatusId === body.claimStatusId)?.claimStatusName}?`)
        });
      }
      try {
        const response = await updateData(`${path}/statusControl/UpdateStatusControl`, body).then(() => {
          dispatch(statusControlAction(payload)).then(({payload}) => {
            setSearchedStatusData(payload?.result?.statusControlMain?.statusControl)
          })
        });
        toast.success('Status Control Updated successfully');
        console.log('Update successful', response);
      } catch (error) {
        toast.error("Status Control failed");
        console.error('Error in updating status control', error);
      }
      setAnchorEl(null);
    },
  });

  const handleMenuItemClick = (opt) => {
    controlUpdateFormik.setFieldValue("claimStatusId", opt.claimStatusId);
    controlUpdateFormik.submitForm();
  };

  const columns = [
    {
      field: "claimNumber",
      headerName: "Claim #",
      flex: 2,
      minWidth: 150,
      headerAlign: "center",
      filterable: true,
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "dateOfService",
      headerName: "DOS",
      flex: 2,
      minWidth: 150,
      headerAlign: "center",
      filterable: true,
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "name",
      headerName: "Patient",
      flex: 2,
      minWidth: 250,
      headerAlign: "center",
      filterable: true,
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      valueGetter: (params) => {
        const { firstName, lastName } = params.row;
        return `${firstName} ${lastName}`;
      },
    },
    {
      field: "chargeAmount",
      headerName: "Charge Amount",
      flex: 2,
      minWidth: 150,
      headerAlign: "center",
      filterable: true,
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "chargeBalance",
      headerName: "Balance",
      flex: 2,
      minWidth: 150,
      headerAlign: "center",
      filterable: true,
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];

  const rows =
    searchedStatusData?.length > 0
      ? searchedStatusData?.map((el, index) => ({
          id: index,
          ...el,
        }))
      : [];

  const handleRowClick = (params, event) => {
    const isCheckboxClick = event.target.tagName === "INPUT";
    if (!isCheckboxClick) {
      setSelectionModel([params.id]);
    }
  };

  const dataFetchUrl = {
    claimStatus: `/ct-claimStatusModules/GetClaimStatusModulesByModuleName?ModuleName=${moduleName}`,
    ctPriorityType: `/ct-priorityType`,
  };

  const fetchData = async (endPoint, setState) => {
    try {
      const response = await getData(`${path}${endPoint}`);
      setState(response.result);
    } catch (error) {
      console.log("error while fetch data", error);
    }
  };

  useEffect(() => {
    fetchData(dataFetchUrl.claimStatus, setClaimStatusOpt);
    fetchData(dataFetchUrl.ctPriorityType, setPriorityTypeOpt);
  }, []);

  const handleSelectionModalChange = (newSelection) => {
    console.log(newSelection, "newSelection");
    setSelectionModel(newSelection);
    const lastSelectedRow = searchedStatusData?.find(
      (row, ind) => ind === newSelection[newSelection.length - 1]
    );
    setSelectedRows(lastSelectedRow);
  };

  const handlePriorityChange = (event) => {
    controlUpdateFormik.setFieldValue("priorityTypeId", event.target.value);
  };

  return (
    <Box
      minHeight="200px"
      height="70vh"
      sx={{
        display: "flex",
        gap: 2,
      }}
    >
      <Stack width={showChargeDetail ? "70%" : "100%"}>
        <Stack direction="row" alignItems="center" gap={2} margin="15px 0">
          <CustomButton isCancel={true} handleClick={handleClose}>
            Close
          </CustomButton>
          <CustomButton
            isBlue={true}
            handleClick={() => setShowChargeDetail(!showChargeDetail)}
          >
            {showChargeDetail ? "Hide" : "Show"} Charge Details
          </CustomButton>

          <Button
            id="customized-multi-btn"
            aria-controls={open ? "multiBtn" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownSharp />}
            sx={{
              backgroundColor: "#1976d2",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#2fa8ee",
              },
              textTransform: "none",
              padding: "5px 9px",
              fontSize: "14px",
            }}
          >
            Update Status
          </Button>
          <Menu
            elevation={3}
            id="multiBtn"
            MenuListProps={{
              "aria-labelledby": "customized-multi-btn",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            sx={{
              "& .MuiPaper-root": {
                margin:'6px 0',
                borderRadius: "8px",
                minWidth: "150px",
                maxHeight:"50%",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.2) 0px 2px 10px",
              },
              "& .MuiMenuItem-root": {
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "#f1f1f1",
                },
              },
            }}
          >
            {claimStatusOpt?.map((opt) => (
              <MenuItem
                onClick={() => handleMenuItemClick(opt)}
                key={opt.claimStatusId}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#e3f2fd",
                  },
                }}
              >
                {opt.claimStatusName}
              </MenuItem>
            ))}

            <Box sx={{ padding: "8px 16px" }}>
              <RadioGroup>
                {priorityTypeOpt?.map((opt) => (
                  <FormControlLabel
                    label={opt.priorityType}
                    control={
                      <Radio
                        value={opt.priorityType}
                        variant="soft"
                        name="radio-buttons"
                        checked={
                          controlUpdateFormik.values.priorityTypeId ===
                          opt?.priorityTypeId
                        }
                        onChange={() =>
                          controlUpdateFormik.setFieldValue(
                            "priorityTypeId",
                            opt?.priorityTypeId
                          )
                        }
                      />
                    }
                  />
                ))}
              </RadioGroup>
            </Box>
          </Menu>
        </Stack>
        <DataGrid
          sx={{
            "& .header-bg": {
              background: theme.palette.dataGridHeading.primary,
              color: theme.palette.textMainHeading.primary,
              fontSize: "16px",
            },
            "& .cell-content": {
              fontSize: "1.2rem",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-actions": {
              "& svg": {
                fontSize: "1.8rem",
                color: theme.palette.textMainHeading.primary,
                marginBottom: "8px",
              },
              "& .MuiDataGrid-row": {
                backgroundColor: theme.palette.textMainHeading.primary,
              },
            },
          }}
          columns={columns}
          rows={rows}
          rowHeight={35}
          showColumnRightBorder
          showCellRightBorder
          checkboxSelection
          onSelectionModelChange={handleSelectionModalChange}
          onRowClick={handleRowClick}
          selectionModel={selectionModel}
        />
      </Stack>

      {showChargeDetail && (
        <Box
          sx={{
            height: "100%",
            width: `30%`,
            border: ".5px solid grey",
          }}
        >
          <ChargeDetails chargeDetailData={selectedRow?.statusControlDetails} />
        </Box>
      )}
    </Box>
  );
};

export default SearchedControls;





