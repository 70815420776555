import React, { useCallback } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./Custom-styling/CustomSelectBox.css";
import { useTheme } from "@mui/material";

const CustomSelectBox2 = ({
  value,
  handleChange,
  handleBlur,
  label,
  dropdownOptions,
  disable,
  name,
  formik,
  showDefaultOpt = false,
  isSetName= false,
  setValName,
  isNested =false
}) => {

  const handleSelectChange = useCallback(
    (e) => {
      const selectedValue = e.target.value;
      const numericValue = selectedValue === "" ? null : Number(selectedValue);
      formik.setFieldValue(name, numericValue);
      if(isSetName){
        const findValName = dropdownOptions?.find(el => el.id === numericValue)
        formik.setFieldValue(setValName, findValName?.value)
      }
      handleChange();
    },
    [formik, name, dropdownOptions]
  );


  
// adding variable for nasted form field
let fieldName = isNested ? name.split('.').shift() : name;
console.log(fieldName,"test");
  const hasError = formik && formik.touched[fieldName] && formik.errors[fieldName];
  const theme = useTheme();
  return (
    <div>
      <FormControl fullWidth>
        <label
          className="customLabel"
          style={{color: theme.palette.Labeltext.primary}}
          htmlFor=""
        >
          {label}
        </label>
        <Select
          sx={{
            "& fieldset": {
              border: "yellow",
            },
            "& .MuiNativeSelect-icon": {
              fontSize: "35px !important",
            },
          }}
          className="customSelectBox"
          style={{
            background: theme.palette.customFieldsBackground.primary,
            border: hasError
              ? `2px solid ${theme.palette.errorFieldsBorder.primary}`
              : `1px solid ${theme.palette.fieldsBorder.primary}`,
            color: theme.palette.fieldsText.primary,
          }}
          disabled={disable}
          value={value}
          name={name}
          onChange={handleSelectChange}
          onBlur={handleBlur}
          native
          id="dropdowns"
        >
          {!showDefaultOpt && (
            <option
              key={0}
              value={null}
              style={{ color: "lightgray !important" }}
            >
              Select
            </option>
          )}
          {dropdownOptions.map((opt) => {
            return (
                <option
                  style={{background: theme.palette.fieldsDDown.primary}}
                  itemType="number"
                  key={opt.id}
                  value={opt.id}
                >
                  {opt.value}
                </option>        
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomSelectBox2;



// import React, { useCallback } from "react";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import Checkbox from "@mui/material/Checkbox";
// import ListItemText from "@mui/material/ListItemText";
// import { useTheme } from "@mui/material";

// const CustomSelectBox2 = ({
//   value,
//   handleChange,
//   handleBlur,
//   label,
//   dropdownOptions,
//   disable = false,
//   name,
//   formik,
//   showDefaultOpt = false,
//   isMulti = false,
// }) => {
//   const theme = useTheme();

//   const handleSelectChange = useCallback(
//     (event) => {
//       const selectedValue = event.target.value;
//       if (isMulti && selectedValue.includes("selectAll")) {
//         if (value.length === dropdownOptions.length) {
//           formik.setFieldValue(name, []);
//           handleChange([]);
//         } else 
//         {
//           const allValues = dropdownOptions.map((opt) => opt.id);
//           formik.setFieldValue(name, allValues);
//           handleChange(allValues);
//         }
//       } else {
//         formik.setFieldValue(name, selectedValue);
//         handleChange(selectedValue);
//       }
//     },
//     [formik, name, handleChange, dropdownOptions, value, isMulti]
//   );

//   const hasError = formik && formik.touched[name] && formik.errors[name];

//   return (
//     <div>
//       <FormControl fullWidth>
//         <label
//           className="customLabel"
//           style={{ color: theme.palette.Labeltext.primary }}
//           htmlFor={name}
//         >
//           {label}
//         </label>
//         <Select
//           multiple={isMulti}
//           value={value}
//           name={name}
//           onChange={handleSelectChange}
//           onBlur={handleBlur}
//           disabled={disable}
//           renderValue={(selected) =>
//             isMulti
//               ? selected.length === dropdownOptions.length
//                 ? "All Selected"
//                 : selected.map((id) => dropdownOptions.find((opt) => opt.id === id)?.value).join(", ")
//               : dropdownOptions.find((opt) => opt.id === selected)?.value
//           }
//           sx={{
//             "& fieldset": {
//               border: "none",
//             },
//             "& .MuiNativeSelect-icon": {
//               fontSize: "35px !important",
//             },
//           }}
//           className="customSelectBox"
//           style={{
//             background: theme.palette.customFieldsBackground.primary,
//             border: hasError
//               ? "2px solid red"
//               : `1px solid ${theme.palette.fieldsBorder.primary}`,
//             color: theme.palette.fieldsText.primary,
//           }}
//         >
//           {!showDefaultOpt && !isMulti && (
//             <MenuItem
//               key={0}
//               value=""
//               style={{ color: "lightgray !important" }}
//             >
//               Select
//             </MenuItem>
//           )}
//           {isMulti && (
//             <MenuItem value="selectAll">
//               <Checkbox
//                 checked={value.length === dropdownOptions.length}
//                 indeterminate={value.length > 0 && value.length < dropdownOptions.length}
//               />
//               <ListItemText primary="Select All" />
//             </MenuItem>
//           )}
//           {dropdownOptions.map((opt) => (
//             <MenuItem key={opt.id} value={opt.id}>
//               {isMulti && <Checkbox checked={value.indexOf(opt.id) > -1} />}
//               <ListItemText primary={opt.value} />
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </div>
//   );
// };

// export default CustomSelectBox2;



