import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatientAction,getPatientBySearchTermAction } from "../../../features/actions/createPatientAction";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomPreview from "../../../layout/CustomPreview";
import { patientCols } from "../../../utils/gridCols";
const PaymentPlans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAllPatients, loading } = useSelector((state) => state.patient);

  // rows
  const rows =
    getAllPatients && getAllPatients?.result?.patients?.length > 0
      ? getAllPatients.result?.patients?.map((el) => ({
          id: el.accountNo,
          insuredName: el?.getInsuredParty?.insuredName,
          ...el,
        }))
      : [];

  return (
    <>
      <Box>
        <CustomPreview
          rows={rows}
          columns={patientCols}
          paginationDetail={getAllPatients}
          searchLabel="Search by Account #, First Name, Last Name, Date of Birth, Gender, Account Type"
          moduleHeading="Payment Plans"
          loading={loading}
          handleCell={(params) =>
            navigate(`/patients/payment-plans/${params.accountNo}`)
          }
          handleGetAll={getPatientAction}
          handleBySearch={getPatientBySearchTermAction}
        />
      </Box>
    </>
  );
};

export default PaymentPlans;
