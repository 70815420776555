import React, { useEffect } from "react";
import { Box } from "@mui/material";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import UserForm from "./UserForm";
import { useFormik } from "formik";
import {
  updateUserAction,
  updateUserPasswordAction,
} from "../../../features/actions/userAction";
import CustomField from "../../../components/CustomField";
import { changePasswordSchema } from "../../../schemas/userSchema";
const ChangePassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
    },
    validationSchema: changePasswordSchema,
    onSubmit: () => {
      if (formik.values.newPassword === formik.values.confirmPassword) {
        dispatch(
          updateUserPasswordAction({ ...formik.values, userId: params?.id })
        );
        navigate("/users");
      } else {
        alert("Password are not same");
      }
    },
  });

  return (
    <Box margin="20px">
      <div style={{ padding: "10px", marginLeft: "-28px" }}>
        <MainHeading mainheading={"Password Update"} />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Box
          marginTop="30px"
          marginBottom="10px"
          width={{ xs: "100%", sm: "65%", md: "100%" }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "row", md: "row", sm: "column" },
          }}
        >
          <Box>
            <CustomButton isBlue={true} type="submit" isEdit={true}>
              Update Password
            </CustomButton>
          </Box>
        </Box>
        <Box
          margin={"20px"}
          sx={{ width: { xs: "30%", sm: "70%", md: "30%" } }}
        >
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(1, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              label={"Old Password"}
              type={"text"}
              isRequired={true}
              touched={formik.touched}
              error={formik.errors}
              value={formik.values.oldPassword}
              name="oldPassword"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
          </Box>{" "}
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(1, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              label={"New Password"}
              type={"text"}
              value={formik.values.newPassword}
              isRequired={true}
              touched={formik.touched}
              error={formik.errors}
              name="newPassword"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
          </Box>{" "}
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(1, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              label={"Confirm Password"}
              type={"text"}
              value={formik.values.confirmPassword}
              isRequired={true}
              touched={formik.touched}
              error={formik.errors}
              name="confirmPassword"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ChangePassword;
