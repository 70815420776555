import { Box, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProviderAction } from "../../../features/actions/providerAction";
import CustomButton from "../../../components/CustomButton";
import { getProviderBySearchTermAction } from "../../../features/actions/providerAction";
import CustomPreview from "../../../layout/CustomPreview";
import { providerCols } from "../../../utils/gridCols";

const Provider = () => {
  const navigate = useNavigate();
  const { getProviders, loading } = useSelector((state) => state.provider);
  console.log(getProviders, "checking provider");
  const rows =
    getProviders && getProviders.result?.providers?.length > 0
      ? getProviders?.result?.providers?.map((el) => ({
          id: el.providerId,
          ...el,
        }))
      : [];

  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={providerCols}
        loading={loading}
        paginationDetail={getProviders}
        handleGetAll={getProviderAction}
        handleBySearch={getProviderBySearchTermAction}
        searchLabel="Search by Provider Name and Sequence No"
        moduleHeading="All Providers"
        handleCell={(params) =>
          navigate(`/provider/update/${params.providerSequenceNo}`)
        }
      >
        <Stack direction="row" alignItems="center">
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => navigate("/provider/new")}
          >
            Add Provider
          </CustomButton>
        </Stack>
      </CustomPreview>
    </Box>
  );
};

export default Provider;
