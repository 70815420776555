import { ExpandMore, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomField from "../../../components/CustomField";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomSelectBox from "../../../components/CustomSelectBox";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
// import CheckBox from "../../components/CheckBox";
import CheckBox from "../../../components/CheckBox";
import CustomAccordion from "../../../components/CustomAccordion";
import TextArea from "../../../components/TextArea";
let certificationFieldDetailList = [
  {
    certificationFieldCodeId: 1,
    certificationFieldType: "Patient was admitted to a hospital",
  },
  {
    certificationFieldCodeId: 2,
    certificationFieldType: "Patient was unconcious or in shock",
  },
  {
    certificationFieldCodeId: 3,
    certificationFieldType: "Patient had to be physically restrained",
  },
  {
    certificationFieldCodeId: 4,
    certificationFieldType: "Ambulance service was medically necessary",
  },
  {
    certificationFieldCodeId: 5,
    certificationFieldType: "Patient was moved by stretcher",
  },

  {
    certificationFieldCodeId: 6,
    certificationFieldType: "Patient was transported in an emergency situation",
  },

  {
    certificationFieldCodeId: 7,
    certificationFieldType: "Patient had visible hemorrhaging",
  },
  {
    certificationFieldCodeId: 8,
    certificationFieldType: "Patient was confined to a bed or chair",
  },
];
const AmbulanceInfo = ({ formik }) => {
  const [transportReason, setTransportReason] = useState([]);
  const [pickUpCityOpt, setPickUpCityOpt] = useState([]);
  const [dropOffCityOpt, setDropOffCityOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const dataFetchUrls = {
    transportReason: `${path}/ct-transportReason`,
    cities: `${path}/city`,
    states: `${path}/state`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.transportReason, setTransportReason);
    fetchDataOptions(dataFetchUrls.states, setStateOpt);
  }, []);

  useEffect(() => {
    if (formik.values.pickupStateId) {
      fetchDataOptions(
        `${path}/city?stateId=${formik.values.pickupStateId}`,
        setPickUpCityOpt
      );
    }
    if (formik.values.dropOffStateId) {
      fetchDataOptions(
        `${path}/city?stateId=${formik.values.dropOffStateId}`,
        setDropOffCityOpt
      );
    }
  }, [formik.values.pickupStateId, formik.values.dropOffStateId]);

  const handleChangeCertificationField = (e) => {
    let val = [...formik.values.certificationFieldDetailDto];
    let findVal = val.findIndex(
      (v) => v?.certificationFieldCodeId === e?.certificationFieldCodeId
    );
    if (findVal !== -1) {
      val.splice(findVal, 1);
    } else {
      if (val.length < 5) {
        val.push(e);
      } else {
        val.pop();
        val.push(e);
      }
    }
    formik.setFieldValue("certificationFieldDetailDto", val);
  };
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = theme.palette;
  return (
    <Box >
      <Stack direction="row" alignItems="center" marginTop="30px">
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          sx={{ width: "150px", fontSize: "1.5rem", fontWeight: "600" }}
        >
          Ambulamce Claim
        </FormLabel>
        <RadioGroup
          sx={{
            marginLeft: "20px",
          }}
          row
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="Yes"
            control={
              <Radio
                checked={formik.values.ambulanceClaimId === 1}
                onChange={() => formik.setFieldValue("ambulanceClaimId", 1)}
                style={{ color: theme.palette.radioButtons.primary }}
              />
            }
            label={
              <Typography variant="h5" fontWeight="600">
                Yes
              </Typography>
            }
          />
          <FormControlLabel
            value="No"
            control={
              <Radio
                checked={formik.values.ambulanceClaimId === 2}
                onChange={() => formik.setFieldValue("ambulanceClaimId", 2)}
                style={{ color: theme.palette.radioButtons.primary }}
              />
            }
            label={
              <Typography variant="h5" fontWeight="600">
                No
              </Typography>
            }
          />
        </RadioGroup>
      </Stack>

      <Box
        display="grid"
        gap="20px"
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(1, minmax(0, 620px))",
          },
        }}
      >
        <CustomSelectBox2
          value={formik.values.transportReasonId}
          name="transportReasonId"
          dropdownOptions={transportReason?.map((opt) => ({
            value: opt.transportReasonType,
            id: opt.transportReasonId,
          }))}
          label="Transport Reason"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          formik={formik}
        />
        <CustomField
          type="text"
          value={formik.values.roundTripReason}
          name="roundTripReason"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          label="Round Trip Reason"
        />
        <CustomField
          label="Stretcher Reason"
          type="text"
          value={formik.values.stretcherReason}
          name="stretcherReason"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
      </Box>
      <Box
        display="grid"
        gap="20px"
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 300px))",
          },
        }}
        marginTop="20px"
      >
        <CustomField
          label="Transport Miles"
          type="number"
          value={formik.values.transportMiles}
          name="transportMiles"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          isDecimal={true}
        />
        <CustomField
          label="Patient Weight"
          type="number"
          value={formik.values.ambPatientWeight}
          name="ambPatientWeight"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
      </Box>
      <br />
      <br />
      <br />

      {/* pickup address */}
      <CustomAccordion defaultExpanded={false} heading={"Pickup Address"}>
        <AccordionDetails>
          <Box
            display="grid"
            gap="20px"
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(1, minmax(0, 620px))",
              },
            }}
          >
            <TextArea
              placeholder="Address"
              minRows={4}
              value={formik.values.pickupAddress}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              name="pickupAddress"
            />
          </Box>
          <Box
            display="grid"
            gap="20px"
            marginTop="20px"
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(3, minmax(0, 200px))",
              },
            }}
          >
            <CustomSelectBox2
              label="State"
              value={formik.values.pickupStateId}
              name="pickupStateId"
              formik={formik}
              handleChange={() => {
                formik.setFieldValue("pickupCityId", null);
              }}
              handleBlur={formik.handleBlur}
              dropdownOptions={stateOpt.map((option) => ({
                value: option.stateName,
                id: option.stateId,
              }))}
            />
            <CustomSelectBox2
              label="City"
              value={formik.values.pickupCityId}
              disable={!formik.values.pickupStateId}
              name="pickupCityId"
              formik={formik}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              dropdownOptions={pickUpCityOpt.map((option) => ({
                value: option.cityName,
                id: option.cityId,
              }))}
            />

            {/* <CustomField
              label="State"
              type="text"
              value={formik.values.pickupState}
              name="pickupState"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            /> */}
            <CustomField
              label="Zip Code"
              type="text"
              value={formik.values.pickupZipCode}
              name="pickupZipCode"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
          </Box>
          <div style={{ marginTop: "10px" }}>
            <CheckBox label={"Institutional"} />
            {/* <FormGroup >
            <FormControlLabel  control={<CheckBox />} label={<Typography sx={{fontSize:'1.3rem'}} fontWeight="600">
            Institutional
          </Typography>} />
          </FormGroup> */}
          </div>
        </AccordionDetails>
      </CustomAccordion>
      {/* dropoff address */}
      <CustomAccordion defaultExpanded={false} heading={"Dropoff Address"}>
        <AccordionDetails>
          <Box
            display="grid"
            gap="20px"
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(1, minmax(0, 620px))",
              },
            }}
          >
            <CustomField
              label="Name"
              type="text"
              value={formik.values.dropOffName}
              name="dropOffName"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
            <TextArea
              placeholder="Address"
              value={formik.values.dropOffAddress}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              name="dropOffAddress"
              minRows={"4"}
            />
          </Box>
          <Box
            display="grid"
            gap="20px"
            marginTop="20px"
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(3, minmax(0, 200px))",
              },
            }}
          >
            <CustomSelectBox2
              label="State"
              value={formik.values.dropOffStateId}
              name="dropOffStateId"
              formik={formik}
              handleChange={() => {
                formik.setFieldValue("dropOffCityId", null);
              }}
              handleBlur={formik.handleBlur}
              dropdownOptions={stateOpt.map((option) => ({
                value: option.stateName,
                id: option.stateId,
              }))}
            />
            <CustomSelectBox2
              label="City"
              value={formik.values.dropOffCityId}
              disable={!formik.values.dropOffStateId}
              name="dropOffCityId"
              formik={formik}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              dropdownOptions={dropOffCityOpt.map((option) => ({
                value: option.cityName,
                id: option.cityId,
              }))}
            />

            <CustomField
              label="Zip Code"
              type="text"
              value={formik.values.dropOffZipCode}
              name="dropOffZipCode"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
            />
          </Box>
        </AccordionDetails>
      </CustomAccordion>
      {/* certification fields */}
      <CustomAccordion defaultExpanded={false} heading={"Certification Fields"}>
        <AccordionDetails>
          <Typography
            variant="h5"
            style={{
              marginTop: "-20px",
              marginBottom: "10px",
              fontWeight: "600",
            }}
            fontWeight={"500"}
          >
            Select up to 5
          </Typography>
          <Box
            display="grid"
            gap="10px"
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 300px))",
              },
            }}
          >
            {certificationFieldDetailList.map((val, ind) => {
              return (
                <CheckBox
                  key={ind}
                  val={val}
                  handleChange={() => handleChangeCertificationField(val)}
                  label={val?.certificationFieldType}
                  CheckedValue={formik.values?.certificationFieldDetailDto.some(
                    (v) =>
                      v?.certificationFieldCodeId ===
                      val?.certificationFieldCodeId
                  )}
                />
              );
            })}
            {/* <FormGroup>
              <FormControlLabel
                control={<CheckBox />}
                label={
                  <Typography sx={{ fontSize: "1rem" }} fontWeight="600">
                    Patient was moved by stretcher
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<CheckBox />}
                label={
                  <Typography sx={{ fontSize: "1rem" }} fontWeight="600">
                    Patient was unconcious or in shock
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<CheckBox />}
                label={
                  <Typography sx={{ fontSize: "1rem" }} fontWeight="600">
                    Patient was transported in an emergency situation
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<CheckBox />}
                label={
                  <Typography sx={{ fontSize: "1rem" }} fontWeight="600">
                    Patient had to be physically restrained
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<CheckBox />}
                label={
                  <Typography sx={{ fontSize: "1rem" }} fontWeight="600">
                    Patient had visible hemorrhaging
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<CheckBox />}
                label={
                  <Typography sx={{ fontSize: "1rem" }} fontWeight="600">
                    Ambulance service was medically necessary
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={<CheckBox />}
                label={
                  <Typography sx={{ fontSize: "1rem" }} fontWeight="600">
                    Patient was confined to a bed or chair
                  </Typography>
                }
              />
            </FormGroup> */}
          </Box>
        </AccordionDetails>
      </CustomAccordion>
    </Box>
  );
};

export default AmbulanceInfo;
