// import { createAsyncThunk } from "@reduxjs/toolkit";
// import path from "../../config/apiUrl";
// import { postData } from "../../config/axiosFunctions";


// export const searchFollowUpDataAction = createAsyncThunk(
//     "searchFollowUp",
//     async (data) => {
//       try {
//         const response = await postData(`${path}/FollowUp/Followup`, data);
//         if (response) {
//           return response;
//         } else {
//           throw new Error(response.error);
//         }
//       } catch (error) {
//         console.log("error", error?.response);
//         throw error;
//       }
//     }
//   );


// actions/followUpManagementAction.js


// actions/followUpManagementAction.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { postData } from "../../config/axiosFunctions";

export const searchFollowUpDataAction = createAsyncThunk(
  "searchFollowUp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await postData(`${path}/FollowUp/Followup`, data);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log("error", error?.response);
      return rejectWithValue(error.response.data);
    }
  }
);



// import { createAsyncThunk } from "@reduxjs/toolkit";
// import path from "../../config/apiUrl";
// import { postData } from "../../config/axiosFunctions";

// export const searchFollowUpDataAction = createAsyncThunk(
//   "searchFollowUp",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await postData(`${path}/FollowUp/Followup`, data);
//       if (response) {
//         return response;
//       } else {
//         throw new Error(response.error);
//       }
//     } catch (error) {
//       console.log("error", error?.response);
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
