import React from "react";
import { Box, FormControl, useTheme } from "@mui/material";
import CustomField from "../../../components/CustomField";
import CustomDatePicker from "../../../components/CustomDatePicker";
import TextArea from "../../../components/TextArea";

const DiagnosisForm = ({ formik }) => {
  const theme = useTheme();
  // const mode = theme.palette.mode;
  // const colors = theme.palette;
  return (
    <>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <CustomField
          type="text"
          label="Code"
          value={formik.values.diagnosisCode}
          isRequired={true}
          error={formik.errors}
          touched={formik.touched}
          name="diagnosisCode"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
        <CustomField
          type="text"
          label="Code Type"
          value={formik.values.codeType}
          name="codeType"
          disable={true}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: " 60%",
          margin: "8px 0px",
        }}
      >
        <TextArea
          type="text"
          value={formik.values.diagnosisDescription}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          name={"diagnosisDescription"}
          minRows={3}
          placeholder="Description"
        />
        {formik.errors["diagnosisDescription"] &&
          formik.touched["diagnosisDescription"] && (
            <div
              style={{
                color: theme.palette.errorFieldsBorder.primary,
                border:`2px solid ${theme.palette.errorFieldsBorder.primary}`,
                fontSize: "1.3rem",
                marginTop: "8px",
                display: "block",
              }}
            >
              {formik.errors["diagnosisDescription"]}
            </div>
          )}
      </Box>
      <Box sx={{ display: "flex", gap: "10px", width: " 60%" }}>
        <FormControl fullWidth>
          <CustomDatePicker
            formik={formik}
            dateLabel="Effective Date"
            name="effectiveDate"
            dateValue={formik.values.effectiveDate}
            handleDateChange={formik.handleChange}
            handleDateBlur={formik.handleBlur}
          />
        </FormControl>
        <FormControl fullWidth>
          <CustomDatePicker
            formik={formik}
            dateLabel="Termination Date"
            name="terminationDate"
            dateValue={formik.values.terminationDate}
            handleDateChange={formik.handleChange}
            handleDateBlur={formik.handleBlur}
          />
        </FormControl>
      </Box>
    </>
  );
};

export default DiagnosisForm;
