import React from 'react';
import {Typography,Stack,Box,useTheme} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import BlueDot from '../../../assets/bluedot.png'
import LightBlueDot from '../../../assets/lightblue.svg';
import ShadedEllipse from '../../../assets/shadedellipse.svg';

const Ratio = () => {
  const theme=useTheme();
  return (
    <>
    <Card sx={{ width:'100%', minHeight:'180px',background:theme.palette.announcementCard.primary,}}>
    <CardContent>
    <Stack direction='row'>

<Box sx={{marginLeft:{sm:'40%',md:'30%',lg:'2%',xs:'40%',xl:'27%'} }} >
<img  style={{marginTop:'44%' }} 
src={ShadedEllipse} alt="adminImg"/>

</Box>
   <Stack spacing={'5px'} direction='column'>
    <Box 
  >
   <img style={{marginTop:'44px',marginLeft:'100%'}} src={BlueDot} alt="adminImg"/>
   </Box>
   <Box>
   <img style={{marginTop:'18px',marginLeft:'100%'}} src={LightBlueDot} alt="adminImg"/>
   </Box>
   </Stack>
   <Stack spacing={'5px'} direction='column'>
    <Box style={{marginTop:'35%',marginLeft:'40px'}}>
   <Typography sx={{color:theme.palette.globalText.primary,fontSize:'1.2rem'}}>Professional</Typography>
   </Box>
   <Box style={{margin:'23px',marginLeft:'40px'}}>
   <Typography sx={{color:theme.palette.globalText.primary,fontSize:'1.2rem'}}>Interrogator</Typography>
   </Box>
   </Stack>
   </Stack>
   
    </CardContent>
  </Card>
    </>
  )
}

export default Ratio