import { Box, Stack, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomField from "../../../components/CustomField";
import CustomButton from "../../../components/CustomButton";
import Loading from "../../../components/Loading";
import { DataGrid } from "@mui/x-data-grid";
import { payerCol } from "../../../utils/gridCols";
import { useDispatch, useSelector } from "react-redux";
import { getPayerAction } from "../../../features/actions/payerAction";
import CustomModal from "./../../../components/CustomModal";

const StMultiPayer = ({ handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const { searchterm, setSearchTerm } = useState("");
  const { getAllPayer, loading } = useSelector((state) => state.payer);
  console.log(getAllPayer, "getAllPayer");
  const rows = getAllPayer?.result && getAllPayer?.result?.length ? getAllPayer?.result?.map(item => ({
    id: item.payerId,
    ...item,

  })) : []
  useEffect(() => {
    try {
      dispatch(getPayerAction());
    } catch (e) {
      console.log(e, "Error in fetching payers");
    }
  }, []);
  return (
    <>
      <Box minHeight={"200px"} margin="15px">
        <Stack direction="row" alignItems="flex-end" marginBottom="15px">
          <CustomField
            label="Search"
            value={searchterm}
            name="searchTerm"
            handleChange={(e) => setSearchTerm(e.target.value)}
          />
          <CustomButton
            type="button"
            // handleClick={handleSearchChange}
            isBlue={true}
            padding="5px 30px"
            margin={"0 10px 0"}
            isSearch={true}
          >
            Search
          </CustomButton>
        </Stack>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Box>
              <DataGrid
                columns={payerCol}
                rows={[]}
                autoHeight
                rowHeight={35}
                onSelectionModelChange={(newRow) => setSelectedRows(newRow)}
                disableSelectionOnClick

                sx={{
                  "& .header-bg": {
                    background: theme.palette.dataGridHeading.primary,
                    color: theme.palette.textMainHeading.primary,
                    fontSize: "16px",
                  },
                  "& .cell-content": {
                    fontSize: "1.2rem",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    fontSize: "1.3rem",
                  },
                  "& .MuiTablePagination-actions": {
                    "& svg": {
                      fontSize: "1.8rem",
                      color: theme.palette.textMainHeading.primary,
                      marginBottom: "8px",
                    },
                  },
                }}
                components={{
                  NoRowsOverlay: () => (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        padding: "16px",
                      }}
                    >
                      {/* {rows?.length === 0 && "No Data Is Added"} */}
                      {[]}
                    </div>
                  ),
                }}
              />

              <CustomButton
                // handleClick={handleAddRows}
                type="button"
                isAdd={true}
              >
                Add
              </CustomButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default StMultiPayer;
