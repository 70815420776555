import { createSlice } from "@reduxjs/toolkit";
import { getAllRenderingProviderAction } from "../actions/renderigProviderAction";

const initialState = {
    getAllRenederingProvider: [],
    error: null,
    loading: false,
  };

  const renderingProviderSlice = createSlice({
    name: "renderingProvider",
    initialState: initialState,
  
    extraReducers: {
      [getAllRenderingProviderAction.pending]: (state) => {
        state.loading = true;
      },
      [getAllRenderingProviderAction.fulfilled]: (state, action) => {
        state.loading = false;
        state.getAllRenederingProvider = action.payload.result;
      },
      [getAllRenderingProviderAction.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        console.log(state.error, "user error");
      },
     
    }})

    export default renderingProviderSlice.reducer