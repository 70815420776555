import React from "react";
import MainHeading from "../../../components/MainHeading";
import { Box,Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { newPracticeAction } from "../../../features/actions/practiceAction";
import CustomButton from "../../../components/CustomButton";
import PracticeInfo from "./PracticeInfo";
import { practiceInitVals } from "../../../utils/formikInitValues";
import { practiceSchema } from "../../../schemas/patientSchema";
import { handleCancel } from "../../../utils/customFunctions";
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";

const NewPractice = () => {
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: practiceInitVals,
    validationSchema :practiceSchema,
    onSubmit: (values, action) => {
      console.log(values, "practice data");
      try {
        dispatch(newPracticeAction(values));
      } catch (error) {
        console.error("Error creating patient:", error);
      }
      action.resetForm();
      navigate('/practice')
    },
  });
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/practice");
  };
  return (
    <>
      <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
       
          <MainHeading mainheading={"Add New Practice"} />
  

        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >

          <CustomButton
            isBlue={true}
            type="submit"
            formId="practice-form"
            isSave={true}
          >
            Save
          </CustomButton>
          {/* <CustomButton
            
            type="submit"
            isCancel={true}
            handleClick={() => {
              handleCancel(
                formik,
                 "/practice", navigate);
            }}
          >
            Cancel
          </CustomButton> */}
          <CustomButton   type="submit" isCancel={true} handleClick={handleCancel}>
         Cancel
       </CustomButton>
        </Box>

        <form id="practice-form" onSubmit={formik.handleSubmit}>
          <PracticeInfo formik={formik}/>
        </form>
      </Box>
      <CustomDialog
    open={openCancelDialog}
    isWarning={true}
    handleClose={() => setOpenCancelDialog(false)}
    handleConfirm={handleConfirmCancel}
    handleCancel={() => setOpenCancelDialog(false)}
    confirmButtonTitle="OK"
    cancelButtonTitle="Cancel"
   >
    <Typography component="h3">Are you sure you want to cancel?</Typography>
   </CustomDialog>
    </>
  );
};

export default NewPractice;
