import { ExpandMore } from '@mui/icons-material'
import React from 'react'

const SummaryIcon = () => {
  return (
    <ExpandMore
    sx={{
      fontSize: "25px",
      color: "white",
      border: "1px solid white",
      borderRadius: "50px",
    }}
  />
  )
}

export default SummaryIcon;
