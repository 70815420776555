import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { postData } from "../../config/axiosFunctions";

export const getClaimTracker = createAsyncThunk(
  "getClaimTracker",
  async (data) => {
    try {
      const response = await postData(`${path}/claimTracker`, data);
      if (response) {
        console.log(response, "success 4343");
        return response?.result;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  }
);
