import { Box,Typography } from "@mui/material";
import { useState } from "react";
import React, { useEffect } from "react";
import MainHeading from "../../../components/MainHeading";
import RemittanceInfo from "./RemittanceInfo";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getRemittanceAction,
  updateRemittanceAction,
} from "../../../features/actions/remittanceAction";
import { useFormik } from "formik";
import { remittanceValues } from "../../../utils/formikInitValues";
import { remittanceSchema } from "../../../schemas/patientSchema";
import { toast } from "react-toastify";
import CustomDialog from "../../../components/CustomDialog";

const UpdateRemittance = () => {
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  // const handleCancel = () => {
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/remittance");
  //   }
  // };
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/remittance");
  };
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getRemittance, loading } = useSelector((state) => state.remittance);

  const findRemittance = getRemittance?.find(
    (remittance) => remittance.remittanceCodeId === Number(id)
  );

  const formik = useFormik({
    initialValues: findRemittance || remittanceValues,
    // enableReinitialize: true,
    validationSchema: remittanceSchema,
    onSubmit: (values) => {
    try {
        dispatch(
          updateRemittanceAction({
            remittanceCodeId: findRemittance?.remittanceCodeId,
            ...values,
          })
        );
        toast.success("Remittance Updated Successfully!")
      } catch (err) {
        console.error("Error updating remittance:", err);
        throw err;
      }
      navigate("/remittance");
    },
  });
  useEffect(() => {
    try {
      dispatch(getRemittanceAction());
    } catch (e) {
      console.log(e, "Error in gettig remittance");
    }
  }, [dispatch]);

  return (
    <>
      <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
          <MainHeading mainheading={"Update Remittance"} />
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >
          <CustomButton
            type="submit"
            formId="remittanceForm"
            isBlue={true}
            isEdit={true}
          >
            Update
          </CustomButton>
          {/* <CustomButton isCancel={true} handleClick={handleCancel}>Cancel</CustomButton> */}
          <CustomButton isCancel={true} handleClick={handleCancel}>
            Cancel
          </CustomButton>
        </Box>
        <form id="remittanceForm" onSubmit={formik.handleSubmit}>
          <RemittanceInfo formik={formik} />
        </form>
      </Box>
      <CustomDialog
     open={openCancelDialog}
     isWarning={true}
     handleClose={() => setOpenCancelDialog(false)}
     handleConfirm={handleConfirmCancel}
     handleCancel={() => setOpenCancelDialog(false)}
     confirmButtonTitle="OK"
     cancelButtonTitle="Cancel"
    >
     <Typography component="h3">Are you sure you want to cancel?</Typography>
    </CustomDialog>
    </>
  );
};

export default UpdateRemittance;
