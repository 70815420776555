
import React, { useEffect,useState} from 'react';
import {Typography,Stack,Box,useTheme} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom"
const TotalClaim = () => {
  const [apiData,setApiData]=useState([])
  const theme=useTheme();


useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch('http://192.168.3.73:86/api/dashboard');
      const data = await response.json();
      
      console.log('API Data:', data); 

      setApiData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchData();
}, []);


  return (
    <>
          <Card sx={{ width: '100%', 
           minHeight: '150px', 
          background:theme.palette.announcementCard.primary,}}>
        <CardContent>
    <Typography sx={{color:theme.palette.globalText.primary,fontFamily: "Radio Canada",fontWeight: '700',
    marginLeft:{md:'42%',lg:'21%',xl:'32%',sm:'42%',xs:'42%'},
    fontSize:{md:'2.5rem',lg:'2.1rem',sm:'2.3rem',xs:'2rem'}
}}>Todays Claims</Typography>

       <Stack direction='row' sx={{display:'flex',justifyContent:'space-around'}}>

       {apiData.result && apiData.result.length > 0 ? (
            <Typography
              sx={{
                color: '#1B70FA',
                fontFamily: 'Radio Canada',
                fontWeight: '700',
                fontSize: '2.5rem',
                color: theme.palette.totalClaim.primary,
              }}
            >
              {apiData.result[0].todaysClaimCount}
            </Typography>
          ) : (
            <p>Loading...</p>
          )}
       <Box 
        className='prevRecord'>
       <Typography 
       component={Link}
       to='/claims'
       sx={{color: theme.palette.preRecords.primary,fontSize:'1.2rem',
       marginLeft:'10px',textDecoration:'none'
       }}>Previous Records</Typography> 
       
       </Box>
       </Stack>
        </CardContent>
      </Card>
    </>
  )
}

export default TotalClaim







