import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelectBox2 from "../../components/CustomSelectBox2";
import CustomField from "../../components/CustomField";
import CustomDatePicker from "../../components/CustomDatePicker";
import { getData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";
import CheckBox from "../../components/CheckBox";
import CustomSelectBox from "../../components/CustomSelectBox";
import { insuranceInfoValues } from "../../utils/formikInitValues";

const InsuranceInfo = ({ insuranceFormik, formik }) => {
  // console.log(insuranceFormik,formik,"danish");
  const theme = useTheme();
  const initVals = insuranceFormik.values.insuredDetail;
  
  
  const {
    firstName,
    lastName,
    genderIdentityStatusId,
    email,
    homePhone,
    cellPhone,
    workPhone,
    address,
    ext,
    ssn,
    zipCode,
    dateOfBirth,
    countryId,
    cityId,
    stateId,
    isSamePatient,
    isPatientInsured,
  } = formik.values;

  const [patRelationOpt, setPatRelationOpt] = useState([]);
  const [genderOpt, setGenderOpt] = useState([]);
  const [countryOpt, setCountryOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [empStateOpt, setEmpStateOpt] = useState([]);
  const [empCityOpt, setEmpCityOpt] = useState([]);
  const [empStatusOpt, setEmpStatusOpt] = useState([]);

  const [currentInsuredParty, setCurrentInsuredParty] = useState("");
  const dataFetchUrl = {
    patientRelation: "/ct-relationToPatient",
    gender: "/ct-genderIdentity",
    city: `/city`,
    country: "/country",
    state: `/state`,
    empState: `/state`,
    employeStatus: "/ct-employmentStatus",
  };

  const fetchData = async (endPoint, setState) => {
    try {
      const response = await getData(`${path}${endPoint}`);
      setState(response.result);
    } catch (error) {
      console.log("error while fetch data", error);
    }
  };

  useEffect(() => {
    fetchData(dataFetchUrl.patientRelation, setPatRelationOpt);
    fetchData(dataFetchUrl.gender, setGenderOpt);
    fetchData(dataFetchUrl.city, setCityOpt);
    fetchData(dataFetchUrl.country, setCountryOpt);
    fetchData(dataFetchUrl.state, setStateOpt);
    fetchData(dataFetchUrl.empState, setEmpStateOpt);
    fetchData(dataFetchUrl.employeStatus, setEmpStatusOpt);
  }, []);

  useEffect(() => {
    if (initVals?.insuredCountryId) {
      fetchData(
        `/state${
          initVals?.insuredCountryId
            ? `?countryId=${initVals?.insuredCountryId}`
            : ""
        }`,
        setStateOpt
      );
    }

    if (initVals?.insuredStateId) {
      fetchData(
        `/city${
          initVals?.insuredStateId ? `?stateId=${initVals?.insuredStateId}` : ""
        }`,
        setCityOpt
      );
    }

    if (initVals?.empStateId) {
      fetchData(
        `/city${
          initVals?.empStateId ? `?stateId=${initVals?.empStateId}` : ""
        }`,
        setEmpCityOpt
      );
    }
  }, [
    initVals?.insuredCountryId,
    initVals?.insuredStateId,
    initVals?.empStateId,
  ]);

  //if same patient
  useEffect(() => {
    if (isSamePatient) {
      insuranceFormik.setValues((preVals) => ({
        ...preVals,
        insuredDetail: {
          ...preVals.insuredDetail,
          insuredFirstName: firstName,
          insuredLastName: lastName,
          insuredEmail: email,
          insuredCellPhone: cellPhone,
          insuredAddress: address,
          insuredHomePhone: homePhone,
          insuredWorkPhone: workPhone,
          insuredSSN: ssn,
          insuredExt: ext,
          insuredZipCode: zipCode,
          insuredDateOfBirth: dateOfBirth,
          insuredGenderIdentityId: genderIdentityStatusId,
          insuredCountryId: countryId,
          insuredStateId: stateId,
          insuredCityId: cityId,
        },
      }));
    }
  }, [isSamePatient, isPatientInsured]);

  useEffect(() => {
    if(formik.values?.insuredPayerDto?.length > 0) {
      formik.setFieldValue("isPatientInsured", false);
      formik.setFieldValue("isSamePatient", false);
      insuranceFormik.setFieldValue(
        "insuredDetail.insuredRelationShipToPatientId",
        0
      );
    }
  }, [formik.values.insuredPayerDto])

  // handle existsting insureance
  const handleExisInsChange = (event) => {
    setCurrentInsuredParty(event.target.value);
    const insuredPayerInd = formik.values.insuredPayerDto?.findIndex(
      (v) => v.insuredFirstName === event.target.value
    );
    if (insuredPayerInd !== -1) {
      insuranceFormik.setValues((preVals) => ({
        ...preVals,
        insuredDetail: {
          ...preVals.insuredDetail,
          ...formik.values.insuredPayerDto[insuredPayerInd],
        },
      }));
    }
  };


  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={6}>
            {firstName &&
              lastName &&
              genderIdentityStatusId &&
              formik.values?.insuredPayerDto.length === 0 && (
                <>
                  <FormControl>
                    <FormLabel sx={{ fontSize: "1.7rem" }}>
                      Patient is insured?
                    </FormLabel>
                    <RadioGroup row>
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            checked={isPatientInsured}
                            onChange={() => {
                              formik.setFieldValue("isPatientInsured", true);
                              formik.setFieldValue("isSamePatient", true);
                              insuranceFormik.setFieldValue(
                                "insuredDetail.insuredRelationShipToPatientId",
                                8
                              );
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={
                          <Radio
                            checked={!isPatientInsured}
                            onChange={() => {
                              formik.setFieldValue("isPatientInsured", false);
                              formik.setFieldValue("isSamePatient", false);
                              insuranceFormik.setFieldValue(
                                "insuredDetail.insuredRelationShipToPatientId",
                                0
                              );
                            }}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </>
              )}
          </Grid>
          <Grid item md={6} alignSelf={"end"}>
            {formik.values.isPatientInsured && formik.values?.insuredPayerDto.length === 0 && (
              <FormControl>
                <CheckBox
                  CheckedValue={isSamePatient === true}
                  label="Same as patient contact information"
                  name="isSamePatient"
                  handleChange={() => {
                    formik.setFieldValue("isSamePatient", !isSamePatient);
                  }}
                />
              </FormControl>
            )}
          </Grid>
          {formik?.values?.insuredPayerDto?.length > 0 && (
            <>
              <Grid item md={12}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          checked={insuranceFormik.values.existingInsuredParty}
                          onChange={() => {
                            insuranceFormik.setFieldValue("existingInsuredParty", true);
                            setCurrentInsuredParty("");
                          }}
                        />
                      }
                      label="Use an existing insured party on the patient record"
                    />
                    {insuranceFormik.values.existingInsuredParty &&
                      formik?.values?.insuredPayerDto?.length > 0 && (
                        <Grid item md={12}>
                          <Select
                            sx={{
                              "& fieldset": {
                                border: "none",
                              },
                              "& .MuiNativeSelect-icon": {
                                fontSize: "35px !important",
                              },
                              background:
                                theme.palette.accordiansBackground.primary,
                              color: theme.palette.textMainHeading.primary,
                            }}
                            className="customSelectBox"
                            value={currentInsuredParty}
                            type="text"
                            onChange={handleExisInsChange}
                            native
                          >
                            <option
                              value="0"
                              style={{ color: "lightgray !important" }}
                            >
                              Select
                            </option>
                            {formik.values?.insuredPayerDto.map((opt) => (
                              <>
                                <option
                                  style={{
                                    background:
                                      theme.palette.fieldsDDown.primary,
                                  }}
                                  key={opt.insuredFirstName}
                                  value={opt.insuredFirstName}
                                >
                                  {opt.insuredFirstName}
                                </option>
                              </>
                            ))}
                          </Select>
                        </Grid>
                      )}
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          checked={!insuranceFormik.values.existingInsuredParty}
                          onChange={() => {
                            insuranceFormik.setFieldValue("existingInsuredParty", false);
                            setCurrentInsuredParty("");
                            insuranceFormik.resetForm()
                          }}
                        />
                      }
                      label="create new insured party"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}

          {!insuranceFormik.values.existingInsuredParty && (
            <>
              <Grid item md={12}>
                <CustomSelectBox2
                  label="Patient Relation to Insured"
                  dropdownOptions={patRelationOpt?.map((opt) => ({
                    id: opt?.relationShipToPatientId,
                    value: opt?.relationShipToPatientName,
                  }))}
                  value={initVals.insuredRelationShipToPatientId}
                  name="insuredDetail.insuredRelationShipToPatientId"
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  formik={insuranceFormik}
                  disable={isPatientInsured}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  
                  type="text"
                  label="First Name"
                  value={initVals.insuredFirstName}
                  name={"insuredDetail.insuredFirstName"}
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  disable={isSamePatient}
                  isRequired={true}
                  error={insuranceFormik.errors}
                  touched={insuranceFormik.touched}
                  isOutlined={false}
                  isNested={true}
                  />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  label="Last Name"
                  value={initVals.insuredLastName}
                  name="insuredDetail.insuredLastName"
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  disable={isSamePatient}
                  isRequired={true}
                  error={insuranceFormik.errors}
                  touched={insuranceFormik.touched}
                  isOutlined={false}
                  isNested={true}
                  
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="email"
                  label="Email"
                  value={initVals.insuredEmail}
                  name="insuredDetail.insuredEmail"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                  disable={isSamePatient}
                />
              </Grid>
              <Grid item md={3}>
                <CustomDatePicker
                  dateLabel="Date of Birth"
                  dateValue={initVals.insuredDateOfBirth}
                  name="insuredDetail.insuredDateOfBirth"
                  handleDateChange={insuranceFormik.handleChange}
                  handleDateBlur={insuranceFormik.handleBlur}
                  disable={isSamePatient}
                  formik={insuranceFormik}
                  isNested={true}
                />
              </Grid>
              <Grid item md={3}>
                <CustomSelectBox2
                  label="Gender"
                  dropdownOptions={genderOpt?.map((opt) => ({
                    id: opt?.genderIdentityId,
                    value: opt?.genderIdentityName,
                  }))}
                  name="insuredDetail.insuredGenderIdentityId"
                  value={initVals?.insuredGenderIdentityId}
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  formik={insuranceFormik}
                  disable={isSamePatient}
                  isNested = {true}
                
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="number"
                  label="Cell Phone"
                  value={initVals.insuredCellPhone}
                  name="insuredDetail.insuredCellPhone"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                  disable={isSamePatient}
                  isRequired={true}
                  error={insuranceFormik.errors}
                  touched={insuranceFormik.touched}
                  isOutlined={false}
                  isNested={true}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="number"
                  label="Home Phone"
                  value={initVals.insuredHomePhone}
                  name="insuredDetail.insuredHomePhone"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                  disable={isSamePatient}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="number"
                  label="Work Phone"
                  value={initVals.insuredWorkPhone}
                  name="insuredDetail.insuredWorkPhone"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                  disable={isSamePatient}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="number"
                  label="Ext"
                  value={initVals.insuredExt}
                  name="insuredDetail.insuredExt"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                  disable={isSamePatient}
                />
              </Grid>
              <Grid item md={3}>
            <CustomField
              type="number"
              label="SSN"
              value={initVals.insuredSSN}
              name="insuredDetail.insuredSSN"
              handleBlur={insuranceFormik.handleBlur}
              handleChange={insuranceFormik.handleChange}
              disable={isSamePatient}
            />
          </Grid>
       
              <Grid item md={3}>
                <CustomSelectBox2
                  label="Country"
                  dropdownOptions={countryOpt?.map((opt) => ({
                    id: opt.countryId,
                    value: opt.countryName,
                  }))}
                  value={initVals?.insuredCountryId}
                  name="insuredDetail.insuredCountryId"
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  formik={insuranceFormik}
                  disable={isSamePatient}
                  isSetName={true}
                  setValName={"insuredDetail.insuredCountryName"}
                  isNested= {true}
                />
              </Grid>
              <Grid item md={3}>
                <CustomSelectBox2
                  label="State"
                  dropdownOptions={stateOpt?.map((opt) => ({
                    id: opt?.stateId,
                    value: opt?.stateName,
                  }))}
                  name="insuredDetail.insuredStateId"
                  value={initVals?.insuredStateId}
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  formik={insuranceFormik}
                  disable={isSamePatient}
                  isSetName={true}
                  setValName={"insuredDetail.insuredStateName"}
                  isNested={true}
                />
              </Grid>
              <Grid item md={3}>
                <CustomSelectBox2
                  label="City"
                  dropdownOptions={cityOpt?.map((opt) => ({
                    id: opt?.cityId,
                    value: opt?.cityName,
                  }))}
                  name="insuredDetail.insuredCityId"
                  value={initVals?.insuredCityId}
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  formik={insuranceFormik}
                  disable={isSamePatient}
                  isSetName={true}
                  setValName={"insuredDetail.insuredCityName"}
                  isNested= {true}
                />
              </Grid>
              <Grid item md={3}>
                <CustomField
                  type="text"
                  label="Zipcode"
                  value={initVals.insuredZipCode}
                  name="insuredDetail.insuredZipCode"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                  disable={isSamePatient}
                  isRequired={true}
                  error={insuranceFormik.errors}
                  touched={insuranceFormik.touched}
                  isOutlined={false}
                  isNested={true}

                />
              </Grid>

              <Grid item md={12}>
                <CustomField
                  type="text"
                  label="Address"
                  value={initVals.insuredAddress}
                  name="insuredDetail.insuredAddress"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                  disable={isSamePatient}
                />
              </Grid>

              <Grid item md={12}>
                <Typography component="h3">Employee Details:</Typography>
              </Grid>

              <Grid item md={6}>
                <CustomField
                  type="text"
                  label="Employee Name"
                  value={initVals.employeeName}
                  name="insuredDetail.employeeName"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                />
              </Grid>
              <Grid item md={6}>
                <CustomField
                  type="text"
                  label="Address"
                  value={initVals.empAddress}
                  name="insuredDetail.empAddress"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                />
              </Grid>
              <Grid item md={6}>
                <CustomSelectBox2
                  label="State"
                  dropdownOptions={empStateOpt?.map((opt) => ({
                    id: opt?.stateId,
                    value: opt?.stateName,
                  }))}
                  value={initVals.empStateId}
                  name="insuredDetail.empStateId"
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  formik={insuranceFormik}
                />
              </Grid>
              <Grid item md={6}>
                <CustomSelectBox2
                  label="City"
                  dropdownOptions={empCityOpt?.map((opt) => ({
                    id: opt?.cityId,
                    value: opt?.cityName,
                  }))}
                  value={initVals.empCityId}
                  name="insuredDetail.empCityId"
                  handleChange={insuranceFormik.handleChange}
                  handleBlur={insuranceFormik.handleBlur}
                  formik={insuranceFormik}
                />
              </Grid>
              <Grid item md={6}>
                <CustomField
                  type="text"
                  label="Zipcode"
                  value={initVals.empZipCode}
                  name="insuredDetail.empZipCode"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                />
              </Grid>
              <Grid item md={6}>
                <CustomSelectBox2
                  label="Employment Status"
                  dropdownOptions={empStatusOpt?.map((opt) => ({
                    id: opt?.employmentStatusId,
                    value: opt?.employmentStatusName,
                  }))}
                  value={initVals?.empEmploymentStatusId}
                  name="insuredDetail.empEmploymentStatusId"
                  handleBlur={insuranceFormik.handleBlur}
                  handleChange={insuranceFormik.handleChange}
                  formik={insuranceFormik}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default InsuranceInfo;
