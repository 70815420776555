// import { Close, Warning } from "@mui/icons-material";
// import {
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   Stack,
// } from "@mui/material";
// import React from "react";
// import CustomButton from "./CustomButton";

// const CustomDialog = ({
//   open,
//   handleClose,
//   title,
//   children,
//   isAction = false,
//   isWarning = false,
// }) => {
//   return (
//     <Dialog
//       onClose={handleClose}
//       aria-labelledby="custom-dialog"
//       open={open}
//       hideBackdrop
//       sx={{
//         position: "absolute",
//         transform: "translate(-50%, -50%)",
//         top: "50%",
//         left: "50%",
//         zIndex: 4445,
//       }}
//     >
//       {title && (
//         <DialogTitle sx={{ m: 0, p: 2 }} id="custom-dialog">
//           {title}
//         </DialogTitle>
//       )}
//       <IconButton
//         aria-label="close"
//         onClick={handleClose}
//         sx={{
//           position: "absolute",
//           right: 8,
//           top: 8,
//         }}
//       >
//         <Close />
//       </IconButton>
//       <DialogContent dividers>
//         <Box marginTop="35px">
//         <Stack direction="row" alignItems={"flex-start"} columnGap={"20px"}>
//           <div>{isWarning ? <Warning color="warning" fontSize="large" /> : null}</div>
//           {children}
//         </Stack>
//         </Box>
//       </DialogContent>
//       {isAction && (
//         <DialogActions>
//           <CustomButton isBlue={true} handleClick={handleClose}>
//             Save changes
//           </CustomButton>
//           <CustomButton handleClick={handleClose}>Save D</CustomButton>
//         </DialogActions>
//       )}
//     </Dialog>
//   );
// };

// export default CustomDialog;


import { Close, Warning } from "@mui/icons-material";
import CustomButton from "./CustomButton";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import React from "react";

const CustomDialog = ({
  open,
  handleClose,
  title,
  children,
  isWarning = false,
  handleConfirm,
  handleCancel,
  confirmButtonTitle,
  cancelButtonTitle,
}) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="custom-dialog"
      open={open}
      hideBackdrop
      sx={{
        '& .MuiDialog-container': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& .MuiPaper-root': {
          position: 'relative',
          margin: 0,
        },
      }}
    >
      {title && (
        <DialogTitle sx={{ m: 0, p: 2 }} id="custom-dialog">
          {title}
        </DialogTitle>
      )}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <Box marginTop="35px">
          <Stack direction="row" alignItems={"flex-start"} columnGap={"20px"}>
            {isWarning && <Warning color="warning" fontSize="large" />}
            {children}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        {handleConfirm && (
          <CustomButton handleClick={handleConfirm} isBlue={true}>
            {confirmButtonTitle}
          </CustomButton>
        )}
        {handleCancel && (
          <CustomButton handleClick={handleCancel} isCancel={true}>
            {cancelButtonTitle}
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;



