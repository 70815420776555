import React from "react";
import { Box, Stack, useTheme } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { DataGrid } from "@mui/x-data-grid";
import Loading from "../../../components/Loading";
import { payerCol } from "../../../utils/gridCols";
import CustomField from "../../../components/CustomField";
import { useDispatch, useSelector } from "react-redux";

const MultiPayer = () => {
  const theme = useTheme();
  const { getAllPayer, loading } = useSelector((state) => state.payer);
  const rows =
  getAllPayer && getAllPayer?.result?.payers?.length > 0
              ? getAllPayer?.result?.payers?.map((el) => ({
                  id: el?.payerId,
                  ...el,
                }))
              : []
  return (
    <>
      <Box minHeight={"200px"} margin="15px">
        <Stack direction="row" alignItems="flex-end" marginBottom="15px">
          <CustomField label="Search" />
          <CustomButton
            type="button"
            isBlue={true}
            padding="5px 30px"
            margin={"0 10px 0"}
            isSearch={true}
          >
            Search
          </CustomButton>
        </Stack>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Box>
              <DataGrid
                columns={payerCol}
                rows={rows}
                autoHeight
                rowHeight={35}
                checkboxSelection
                disableSelectionOnClick
                sx={{
                  "& .header-bg": {
                    background: theme.palette.dataGridHeading.primary,
                    color: theme.palette.textMainHeading.primary,
                    fontSize: "16px",
                  },
                  "& .cell-content": {
                    fontSize: "1.2rem",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    fontSize: "1.3rem",
                  },
                  "& .MuiTablePagination-actions": {
                    "& svg": {
                      fontSize: "1.8rem",
                      color: theme.palette.textMainHeading.primary,
                      marginBottom: "8px",
                    },
                  },
                }}
                components={{
                  NoRowsOverlay: () => (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        padding: "16px",
                      }}
                    >
                      {rows?.length === 0 && "No Data Is Added"}
                    </div>
                  ),
                }}
              />

              <CustomButton
                type="button"
                isBlue={true}
                isAdd={true}
                margin={"10px"}
              >
                Add
              </CustomButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default MultiPayer;
