import { Box,Stack } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import React from "react";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomPreview from "../../../layout/CustomPreview";
import CustomModal from "../../../components/CustomModal";
import SearchICDCodeMaster from "./SearchICDCodeMaster";
import { icdProcedureCol } from "../../../utils/gridCols";

import { getICDProcedureCodeAction,getICDProcedureCodesBySearchTermAction } from "../../../features/actions/icdProcedureAction";
const  ICDProcedure = () => {
  const {getIcdProcedureData, loading } = useSelector((state) => state.icdProcedure);
  console.log("icd",getIcdProcedureData)
    const navigate = useNavigate();
    const [openICDProcedureModal,setOpenICDProcedureModal ] = useState(false);
  return (
    <>
        <Box>
        <CustomPreview
          rows={
            getIcdProcedureData &&  getIcdProcedureData.length > 0
              ?  getIcdProcedureData?.map((row) => ({
                  id: row?.icdProcedureCodesId,
                  ...row,
                }))
              : []
          }
          columns={icdProcedureCol}
          moduleHeading={'ICD Procedure Codes'}
          searchLabel="Search by ICD Code and Description"
          handleGetAll={getICDProcedureCodeAction}
          handleBySearch={getICDProcedureCodesBySearchTermAction}
          loading={loading}
        //   handleCell={handleCell}
        handleCell={(params) => navigate(`/icd-procedures/update/${params?.id}`)}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <CustomButton
              isBlue={true}
              isAdd={true}
              handleClick={() => navigate("/icd-procedures/new")}
            >
              ICD Procedure Codes
            </CustomButton> 
            <CustomButton
              isAdd={true}
              handleClick={() => setOpenICDProcedureModal(true)}
            >
           Add From Master List
            </CustomButton> 
          </Stack>
          </CustomPreview>

          {/* Custom Modal */}
          <CustomModal
        open={openICDProcedureModal}
        handleClose={() => setOpenICDProcedureModal(false)}
        height={"20%"}
      >
        <SearchICDCodeMaster handleClose={() => setOpenICDProcedureModal(false)}/>
      </CustomModal>
      </Box>
   
    </>
  );
};

export default ICDProcedure;
