import { Box, FormControl, Grid, Select, Stack, useTheme } from "@mui/material";
// import { makeStyles } from "@mui/styles";

import CustomButton from "../../../../components/CustomButton";
import CustomSelectBox2 from "../../../../components/CustomSelectBox2";
import { getData } from "../../../../config/axiosFunctions";
import { useEffect, useState } from "react";
import path from "../../../../config/apiUrl";
import CustomDatePicker from "../../../../components/CustomDatePicker";
import CustomModal from "../../../../components/CustomModal";
import MultiSelectCustomPreview from "../../../../layout/MultiSelectCustomPreview";
import { useSelector } from "react-redux";
import {
  facilityCols,
  patientClaimDetailCols,
  payerCol,
  practiceCols,
  providerCols,
} from "../../../../utils/gridCols";
import {
  getPracticeAction,
  getPracticeBySearchTermAction,
} from "../../../../features/actions/practiceAction";
import CustomSearchField from "../../../../components/CustomSearchField";
import {
  getProviderAction,
  getProviderBySearchTermAction,
} from "../../../../features/actions/providerAction";
import {
  getFacilityAction,
  getFacilityBySearchTermAction,
} from "../../../../features/actions/facilityAction";
import {
  getPayerAction,
  getPayerBySearchTermAction,
} from "../../../../features/actions/payerAction";
import CustomPreview from "../../../../layout/CustomPreview";
import {
  getPatientClaimBySearchTerm,
  getPatientClaimsDetailAction,
} from "../../../../features/actions/claimAction";
import { MultiSelect } from "react-multi-select-component";
import CustomMultiSelect from "../../../../components/CustomMultiSelect/CustomMultiSelect";

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const dateOption = {
  1: {
    fromDate: null,
    toDate: null,
  },
  2: {
    fromDate: null,
    toDate: null,
  },
  3: {
    fromDate: formatDate(new Date()),
    toDate: formatDate(new Date()),
  },
  4: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      )
    ),
    toDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      )
    ),
  },
  5: {
    fromDate: (function () {
      let date = new Date();
      let diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return formatDate(new Date(date.setDate(diff)));
    })(),
    toDate: formatDate(new Date()),
  },
  6: {
    fromDate: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    toDate: formatDate(new Date()),
  },
  7: {
    fromDate: formatDate(new Date(new Date().getFullYear(), 0, 1)),
    toDate: formatDate(new Date()),
  },
  8: {
    fromDate: (function () {
      let date = new Date();
      let diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return formatDate(new Date(date.setDate(diff - 7)));
    })(),
    toDate: (function () {
      let date = new Date();
      let diff =
        date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return formatDate(new Date(date.setDate(diff - 1)));
    })(),
  },
  9: {
    fromDate: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
    ),
    toDate: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 0)
    ),
  },
  10: {
    fromDate: formatDate(new Date(new Date().getFullYear() - 1, 0, 1)),
    toDate: formatDate(new Date(new Date().getFullYear() - 1, 12, 0)),
  },
  11: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      )
    ),
    toDate: formatDate(new Date()),
  },
  12: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 30
      )
    ),
    toDate: formatDate(new Date()),
  },
  13: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 60
      )
    ),
    toDate: formatDate(new Date()),
  },
  14: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 90
      )
    ),
    toDate: formatDate(new Date()),
  },
  15: {
    fromDate: formatDate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 12,
        new Date().getDate()
      )
    ),
    toDate: formatDate(new Date()),
  },
};

const ClaimBatchPrintForm = ({ formik }) => {
  const theme = useTheme();

  const { getProviders } = useSelector((state) => state.provider);
  const providerLoading = useSelector((state) => state.provider.loading);
  const { getPractices } = useSelector((state) => state.practices);
  const practiceLoading = useSelector((state) => state.practices.loading);
  const { getFacilityData } = useSelector((state) => state.facility);
  const facilityLoading = useSelector((state) => state.facility.loading);
  const { getAllPayer } = useSelector((state) => state.payer);
  const payerLoading = useSelector((state) => state.payer.loading);
  const { patientClaimsData, getPatientClaimDetailLoad } = useSelector(
    (state) => state.claim
  );

  const [practiceSelectedRow, setPracticeSelectedRow] = useState([]);
  const [providerSelectedRow, setProviderSelectedRow] = useState([]);
  const [facilitySelectedRow, setFacilitySelectedRow] = useState([]);
  const [officeLocationSelectedRow, setOfficeLocationSelectedRow] = useState(
    []
  );
  const [payerSelectedRow, setPayerSelectedRow] = useState([]);

  const [dateFilter, setDateFilter] = useState([]);
  const [claimFormType, setClaimFormType] = useState([]);
  const [payerPriorityType, setPayerPriorityType] = useState([]);
  const [claimAmountType, setClaimAmountType] = useState([]);

  const [openPracticeMod, setOpenPracticeMod] = useState(false);
  const [openProviderMod, setOpenProviderMod] = useState(false);
  const [openFacilityMod, setOpenFacilityMod] = useState(false);
  const [openOfficeLocationMod, setOpenOfficeLocationMod] = useState(false);
  const [openPayerMod, setOpenPayerMod] = useState(false);
  const [openPatientMod, setOpenPatientMod] = useState(false);

  const fetchData = async (url, setState) => {
    try {
      const { result } = await getData(url);
      setState(result);
    } catch (error) {
      console.log(error);
    }
  };

  const dataSourceOption = {
    dateFilter: `${path}/ct-dOS`,
    claimForm: `${path}/ct-claimForm`,
    priorityType: `${path}/ct-priorityType`,
    claimAmountType: `${path}/ct-ComparisionFilters/ComparisionFilters?ModuleName=Claim_Batch_Print&ContentName=Claim_Amount`,
  };
  useEffect(() => {
    fetchData(dataSourceOption.dateFilter, setDateFilter);
    fetchData(dataSourceOption.claimForm, setClaimFormType);
    fetchData(dataSourceOption.priorityType, setPayerPriorityType);
    fetchData(dataSourceOption.claimAmountType, setClaimAmountType);
  }, []);
  const handleDosChange = (e, type) => {
    let { fromDate, toDate } = dateOption[e.target.value];
    let valueKey = type === "ct" ? "ctDosId" : "claimEnterdId";
    formik.setValues((value) => ({
      ...value,
      [valueKey]: Number(e.target.value),
      [`${type}FromDate`]: fromDate,
      [`${type}ToDate`]: toDate,
    }));
  };
  return (
    <>
      {/* practice */}
      <CustomModal
        height="20px"
        heading="Practices"
        open={openPracticeMod}
        handleClose={() => setOpenPracticeMod(false)}
      >
        <MultiSelectCustomPreview
          rows={
            getPractices?.result?.practice.length > 0
              ? getPractices?.result?.practice?.map((el) => ({
                  id: el?.practiceId,
                  ...el,
                }))
              : []
          }
          columns={practiceCols}
          isModal={true}
          loading={practiceLoading}
          paginationDetail={getPractices}
          setState={setPracticeSelectedRow}
          handleGetAll={getPracticeAction}
          handleBySearch={getPracticeBySearchTermAction}
          searchLabel="Search by Practice Name and Sequence No"
          state={practiceSelectedRow}
          handleCell={(selectedRows) => {
            let setRowData = selectedRows?.map((el) => ({
              value: el?.sequenceNo,
              label: el?.practiceName,
            }));
            formik.setValues((preVals) => ({
              ...preVals,
              practiceSeqNums: [...setRowData],
            }));
            setOpenPracticeMod(false);
          }}
        />
      </CustomModal>
      {/* provider */}
      <CustomModal
        height="20px"
        heading="Providers"
        open={openProviderMod}
        handleClose={() => setOpenProviderMod(false)}
      >
        <MultiSelectCustomPreview
          rows={
            getProviders?.result?.providers.length > 1
              ? getProviders?.result?.providers?.map((el) => ({
                  id: el?.providerId,
                  ...el,
                }))
              : []
          }
          columns={providerCols}
          handleGetAll={getProviderAction}
          handleBySearch={getProviderBySearchTermAction}
          paginationDetail={getProviders}
          isModal={true}
          loading={providerLoading}
          setState={setProviderSelectedRow}
          searchLabel="Search by Provider Name and Sequence No"
          state={providerSelectedRow}
          handleCell={(selectedRows) => {
            let setRowData = selectedRows?.map((el) => ({
              value: el?.providerSequenceNo,
              label: `${el?.providerFirstName} ${el?.providerLastName || ""}`,
            }));
            formik.setValues((preVals) => ({
              ...preVals,
              providerSeqNums: [...setRowData],
            }));
            setOpenProviderMod(false);
          }}
        />
      </CustomModal>

      {/* facility */}
      <CustomModal
        open={openFacilityMod}
        handleClose={() => setOpenFacilityMod(false)}
        height={"20px"}
        heading={"Facility"}
      >
        <MultiSelectCustomPreview
          isModal={true}
          rows={
            getFacilityData?.result &&
            getFacilityData.result?.facility?.length > 0
              ? getFacilityData.result?.facility?.map((el) => ({
                  id: el.facilityId,
                  facilityName: el.facilityName,
                  phoneNumber: el.phoneNumber,
                  sequenceNo: el.sequenceNo,
                  taxonomySpecialty: el.taxonomySpecialty,
                }))
              : []
          }
          columns={facilityCols}
          loading={facilityLoading}
          state={facilitySelectedRow}
          setState={setFacilitySelectedRow}
          handleGetAll={getFacilityAction}
          paginationDetail={getFacilityData}
          handleBySearch={getFacilityBySearchTermAction}
          searchLabel="Search by First Name, Address, NPI, Phone ,or Sequence Number"
          handleCell={(selectedRows) => {
            let setRowData = selectedRows?.map((el) => ({
              value: el?.sequenceNo,
              label: el?.facilityName,
            }));
            formik.setValues((preVals) => ({
              ...preVals,
              facilitySeqNums: [...setRowData],
            }));
            setOpenFacilityMod(false);
          }}
        />
      </CustomModal>

      {/* office location */}
      <CustomModal
        height="20px"
        heading="Office Location"
        open={openOfficeLocationMod}
        handleClose={() => setOpenOfficeLocationMod(false)}
      >
        <MultiSelectCustomPreview
          rows={
            getPractices?.result?.practice.length > 0
              ? getPractices?.result?.practice?.map((el) => ({
                  id: el?.practiceId,
                  ...el,
                }))
              : []
          }
          columns={practiceCols}
          isModal={true}
          loading={practiceLoading}
          paginationDetail={getPractices}
          setState={setOfficeLocationSelectedRow}
          handleGetAll={getPracticeAction}
          handleBySearch={getPracticeBySearchTermAction}
          searchLabel="Search by Practice Name and Sequence No"
          state={officeLocationSelectedRow}
          handleCell={(selectedRows) => {
            let setRowData = selectedRows?.map((el) => ({
              value: el?.sequenceNo,
              label: el?.primaryAddress,
            }));
            formik.setValues((preVals) => ({
              ...preVals,
              officeLocation: [...setRowData],
            }));
            setOpenOfficeLocationMod(false);
          }}
        />
      </CustomModal>

      {/* payer modal */}
      <CustomModal
        open={openPayerMod}
        handleClose={() => setOpenPayerMod(false)}
        height={"20px"}
        heading="Payers"
      >
        <MultiSelectCustomPreview
          rows={
            getAllPayer && getAllPayer?.result?.payers?.length > 0
            ? getAllPayer?.result?.payers?.map((el) => ({
                id: el?.payerId,
                ...el,
              }))
            : []
          }
          columns={payerCol}
          loading={payerLoading}
          state={payerSelectedRow}
          setState={setPayerSelectedRow}
          handleGetAll={getPayerAction}
          handleBySearch={getPayerBySearchTermAction}
          isModal={true}
          handleCell={(selectedRows) => {
            let setRowData = selectedRows?.map((el) => ({
              value: el?.payerSequenceNo,
              label: el?.payerName,
            }));
            formik.setValues((preVals) => ({
              ...preVals,
              payerSeqNums: [...setRowData],
            }));
            setOpenPayerMod(false);
          }}
          searchLabel="Search by name or address #"
        />
      </CustomModal>

      {/* patient modal */}
      <CustomModal
        open={openPatientMod}
        handleClose={() => setOpenPatientMod(false)}
        height={"20px"}
        heading="Patients"
      >
        <CustomPreview
          searchLabel="Search by Claim Id,Patient Name,Dos"
          rows={
            patientClaimsData &&
            patientClaimsData?.result?.patientClaim?.length > 0
              ? patientClaimsData?.result?.patientClaim?.map((row) => ({
                  id: row?.patientId,
                  ...row,
                }))
              : []
          }
          columns={patientClaimDetailCols}
          loading={getPatientClaimDetailLoad}
          handleGetAll={getPatientClaimsDetailAction}
          handleBySearch={getPatientClaimBySearchTerm}
          paginationDetail={patientClaimsData}
          handleCell={(e) => {
            formik.setFieldValue("patientAccountNo", e?.patientAccountNo);
            setOpenPatientMod(false);
          }}
          isModal={true}
        />
      </CustomModal>

      <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
            padding: "0px 0px 20px 20px",
            borderBottom: "1px solid black",
          }}
        >
          <CustomButton
            type="submit"
            children={"Search"}
            isSearch={true}
            isBlue={true}
          />
        </Box>
        <Grid container>
          <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "10px" }}>
            <CustomSelectBox2
              value={formik.values.claimFormId}
              name="claimFormId"
              dropdownOptions={claimFormType?.map((opt) => ({
                value: opt.claimFormName,
                id: opt.claimFormId,
              }))}
              label="Claim Form"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </Grid>
          <Grid container spacing={1} item xs={12} sx={{ marginTop: "10px" }}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <label
                  className="customLabel"
                  style={{ color: theme.palette.Labeltext.primary }}
                  htmlFor=""
                >
                  Claim Date Entered
                </label>
                <select
                  className={"customSelectBox"}
                  style={{
                    background: theme.palette.customFieldsBackground.primary,
                    border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                    color: theme.palette.fieldsText.primary,
                  }}
                  value={formik.values.claimEnterdId}
                  onChange={(e) => handleDosChange(e, "claimEnterd")}
                  id="dropdowns"
                >
                  {dateFilter.map((opt) => {
                    return (
                      <>
                        <option
                          style={{
                            background: theme.palette.fieldsDDown.primary,
                          }}
                          itemType="number"
                          key={opt.dosId}
                          value={opt.dosId}
                        >
                          {opt.dos}
                        </option>
                      </>
                    );
                  })}
                </select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomDatePicker
                formik={formik}
                disable={formik.values.claimEnterdId !== 2}
                dateLabel="From"
                name="claimEnterdFromDate"
                dateValue={formik.values.claimEnterdFromDate}
                handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomDatePicker
                formik={formik}
                dateLabel="To"
                name="claimEnterdToDate"
                disable={formik.values.claimEnterdId !== 2}
                dateValue={formik.values.claimEnterdToDate}
                handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} item xs={12} sx={{ marginTop: "10px" }}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <label
                  className="customLabel"
                  style={{ color: theme.palette.Labeltext.primary }}
                  htmlFor=""
                >
                  Claim Date of Service{" "}
                </label>
                <select
                  className={"customSelectBox"}
                  style={{
                    background: theme.palette.customFieldsBackground.primary,
                    border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                    color: theme.palette.fieldsText.primary,
                  }}
                  value={formik.values.ctDosId}
                  onChange={(e) => handleDosChange(e, "ct")}
                  id="dropdowns"
                >
                  {dateFilter.map((opt) => {
                    return (
                      <>
                        <option
                          style={{
                            background: theme.palette.fieldsDDown.primary,
                          }}
                          itemType="number"
                          key={opt.dosId}
                          value={opt.dosId}
                        >
                          {opt.dos}
                        </option>
                      </>
                    );
                  })}
                </select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomDatePicker
                formik={formik}
                disable={formik.values.ctDosId !== 2}
                dateLabel="From"
                name="ctFromDate"
                dateValue={formik.values.ctFromDate}
                handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomDatePicker
                formik={formik}
                dateLabel="To"
                name="ctToDate"
                disable={formik.values.ctDosId !== 2}
                dateValue={formik.values.ctToDate}
                handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "10px" }}>
            <CustomSearchField
              label="Practices"
              fieldVal={formik.values.practiceSeqNums?.map((el) => el?.label)}
              handleModalOpen={() => setOpenPracticeMod(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              isRequired={true}
              disable={false}
              name="practiceSeqNums"
              formik={formik}
              resetField={{
                practiceSeqNums: [],
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "10px" }}>
            <CustomSearchField
              label="Provider"
              fieldVal={formik.values?.providerSeqNums?.map((el) => el?.label)}
              handleModalOpen={() => setOpenProviderMod(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              isRequired={true}
              formik={formik}
              resetField={{
                providerSeqNums: [],
              }}
              name="providerSeqNums"
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "10px" }}>
            <CustomSearchField
              label="Facilities"
              fieldVal={formik.values?.facilitySeqNums?.map((el) => el?.label)}
              handleModalOpen={() => setOpenFacilityMod(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
              isRequired={true}
              formik={formik}
              resetField={{
                facilitySeqNums: [],
              }}
              name="facilitySeqNums"
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "10px" }}>
            <CustomSearchField
              label="OfficeLocation"
              fieldVal={formik.values?.officeLocation?.map((el) => el?.label)}
              handleModalOpen={() => setOpenOfficeLocationMod(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
              isRequired={true}
              formik={formik}
              resetField={{
                officeLocation: [],
              }}
              name="officeLocation"
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "10px" }}>
            <CustomSearchField
              label="Payers"
              fieldVal={formik.values?.payerSeqNums?.map((el) => el?.label)}
              handleModalOpen={() => setOpenPayerMod(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
              isRequired={true}
              formik={formik}
              resetField={{
                payerSeqNums: [],
              }}
              name="payerSeqNums"
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "10px" }}>
            <CustomSearchField
              label="Patient"
              fieldVal={formik.values.patientAccountNo}
              handleModalOpen={() => setOpenPatientMod(true)}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
              isRequired={true}
              formik={formik}
              resetField={{
                patientAccountNo: null,
              }}
              name="patientAccountNo"
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "10px" }}>
            <label
              className="customLabel"
              style={{
                color: theme.palette.Labeltext.primary,
              }}
            >
              Payer Priority
            </label>
            <CustomMultiSelect
              options={
                payerPriorityType.length > 0
                  ? payerPriorityType?.map((el) => ({
                      label: el?.priorityType,
                      value: el?.priorityTypeId,
                    }))
                  : []
              }
              value={formik.values.payerPriority}
              handleChange={(selectedOption) => {
                formik.setFieldValue("payerPriority", selectedOption);
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "10px" }}>
            <Stack direction="row" alignItems="flex-end" gap="15px">
              <FormControl sx={{ width: "300px" }}>
                <label
                  className="customLabel"
                  style={{
                    color: theme.palette.Labeltext.primary,
                  }}
                >
                  Patient Balance
                  {/* {field.filterName} */}
                </label>
                <Select
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiNativeSelect-icon": {
                      fontSize: "35px !important",
                    },
                    background: theme.palette.accordiansBackground.primary,
                    color: theme.palette.textMainHeading.primary,
                  }}
                  className="customSelectBox"
                  value={formik.values?.claimAmountId || 1}
                  type="text"
                  onChange={(event) =>
                    formik.setFieldValue(
                      "claimAmountId",
                      Number(event.target.value)
                    )
                  }
                  native
                >
                  {claimAmountType.map((opt) => (
                    <>
                      <option
                        style={{
                          background: theme.palette.fieldsDDown.primary,
                        }}
                        key={opt.filterId}
                        value={opt.filterId}
                      >
                        {opt.filtersName}
                      </option>
                    </>
                  ))}
                </Select>
              </FormControl>
              {
                <Stack direction="row" alignItems="center" gap="15px">
                  {formik.values.claimAmountId === 2 ||
                  formik.values.claimAmountId === 3 ||
                  formik.values.claimAmountId === 4 ? (
                    <>
                      <input
                        type="number"
                        className="customDatePicker"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "claimAmountVal1",
                            Number(e.target.value)
                          )
                        }
                        min={0}
                        value={formik.values?.claimAmountVal1}
                      />
                    </>
                  ) : formik.values.claimAmountId === 5 ? (
                    <>
                      <input
                        type="number"
                        className="customDatePicker"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "claimAmountVal1",
                            Number(e.target.value)
                          )
                        }
                        min={0}
                        value={formik.values?.claimAmountVal1}
                      />
                      <input
                        type="number"
                        className="customDatePicker"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "claimAmountVal2",
                            Number(e.target.value)
                          )
                        }
                        min={0}
                        value={formik.values?.claimAmountVal2}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </Stack>
              }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default ClaimBatchPrintForm;
