import { Box, Typography,useTheme } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomField from "../../../components/CustomField";
import { useState } from "react";
import CustomModal from "../../../components/CustomModal";
import Diagnosis from "../../custom-setup/diagnosis/Diagnosis";
import ProcedureClaim from "./ProcedureClaim";
import ProcedureTable from "./ProcedureTable";
import InsClaimCharges from "./InsClaimCharges";
import CustomButton from "../../../components/CustomButton";
import CustomPreview from "../../../layout/CustomPreview";
import {
  getDiagnosisAction,
  getDiagnosisBySearchTermAction,
} from "./../../../features/actions/diagnosisAction";
import { useSelector } from "react-redux";
import { diagnosisCodesCols } from "../../../utils/gridCols";
import { useFormik } from "formik";
import {
  proceduresSchemaForClaim,
  proceduresSchemaForInsitutionalClaim,
  proceduresSchemaForProfessionalClaim,
} from "../../../schemas/patientSchema";

const ClaimCharges = ({
  // findClaim,
  formik,
  claimChargesDto,
  setClaimChargesDto,
}) => {
  const [openDiagnosisModal, setOpenDiagnosisModal] = useState(false);
  const [editProcedure, setEditProcedure] = useState(false);
  const [openProcedureModal, setOpenProcedureModal] = useState(false);
  const [icdIdentifier, setIcdIdentifier] = useState("");
  // use selector
  const { getDiagnosisData, loading } = useSelector((state) => state.diagnosis);
  const theme = useTheme();
  const handleOpenDiagnosisModal = (identifier) => {
    setOpenDiagnosisModal(true);
    setIcdIdentifier(identifier);
  };
  const updateTotalAmount = useCallback(() => {
    let claimChargesDto = formik.values.claimChargesDto
    let totalAmount = 0;
    if (claimChargesDto.length > 0) {
      for (let i = 0; i < claimChargesDto.length; i++) {
        let val = claimChargesDto[i]?.unitPrice * claimChargesDto[i]?.units
        totalAmount = totalAmount + val
      }
      formik.setValues((prev) => {
        return {
          ...prev,
          claimSummaryDto: {
            ...prev.claimSummaryDto,
            totalAmount: totalAmount,
            balance: Number(totalAmount - prev?.claimSummaryDto?.insPayments - prev?.claimSummaryDto?.patPayment - prev?.claimSummaryDto?.adjustments),
            dateOfService: claimChargesDto[0]?.fromDate
          }
        }
      })
    }

  }, [formik])


  useEffect(() => {
    updateTotalAmount()
  }, [formik.values.claimChargesDto])


  // select diagnosis
  const handleDiagnosis = (val, field) => {
    let find = formik.values?.dxpointer?.find(
      (value) => value === icdIdentifier
    );
    if (!find) {
      let prevDxPointer = formik.values?.dxpointer;
      let setValues = {
        dxpointer: [...prevDxPointer, icdIdentifier],
      };
      formik.setValues({
        ...formik.values,
        ...setValues,
      });
    }
    let formikVal = [...formik.values.icD_DiagnosisDetailDto];
    let findCode = formikVal.find((v) => v?.diagnosisCodesId === val?.id);
    if (!findCode) {
      let value = {
        diagnosisCodesId: val?.id,
        diagnosisCode: val?.diagnosisCode,
        diagnosisDescription: val?.diagnosisDescription,
        dxPointerLabel: icdIdentifier,
      };
      let finddxPointerLabel = formikVal.findIndex(
        (v) => v?.dxPointerLabel === icdIdentifier
      );
      if (finddxPointerLabel === -1) {
        formikVal.push(value);
      } else {
        formikVal.splice(finddxPointerLabel, 1);
        formikVal.push(value);
      }
      formik.setFieldValue("icD_DiagnosisDetailDto", formikVal);
    } else {
      window.alert("ICD Code already selected");
    }
    setOpenDiagnosisModal(false);
  };

  // formik work here
  const procedureFormik = useFormik({
    initialValues: {
      procedureCodeId: null,
      tosCodeId: null,
      posCodeId: null,
      procedureCode: "",
      toDate: null,
      fromDate: null,
      posCode: "",
      tosCode: "",
      unitPrice: 0,
      description: "",
      revCodeId: null,
      dxPointer: "",
      units: 0,
      claimStatus: "",
      claimStatusId: null,
      amountBilled: 0,
      amountPaid: 0,
      isDeleted: false,
      mod_Code1: "",
      mod_Code2: "",
      mod_Code3: "",
      mod_Code4: "",
      mod_1: null,
      mod_2: null,
      mod_3: null,
      mod_4: null,
    },
    validationSchema:
      formik.values.claimTypeId === 1
        ? proceduresSchemaForProfessionalClaim
        : proceduresSchemaForInsitutionalClaim,
    onSubmit: (values, action) => {
      if (!editProcedure) {
        formik.setFieldValue("claimChargesDto", [
          ...formik.values.claimChargesDto,
          values,
        ]);
        // formik.values.claimChargesDto?.push(values);
      } else {
        let claimChargesDto = formik.values?.claimChargesDto;
        let index = claimChargesDto.findIndex(
          (el) => el.procedureCodeId === values.procedureCodeId
        );
        if (index !== -1) {
          claimChargesDto = claimChargesDto.filter(
            (el) => el.procedureCodeId !== values.procedureCodeId
          );
        }
        formik.setFieldValue("claimChargesDto", [
          ...claimChargesDto,
          values,
        ]);
        setClaimChargesDto((prevVals) => [...prevVals, procedureFormik.values]);
        // action.resetForm();
      }
      setOpenProcedureModal(false);
      setEditProcedure(false);
    },
  });

  const handleAddProcedure = () => {
    setOpenProcedureModal(true);
    procedureFormik.resetForm();
  };

  const handleEditProcedure = (e) => {
    let claimChargesDto = formik.values?.claimChargesDto;
    let index = claimChargesDto.findIndex(
      (el) => el.procedureCodeId === e.row.id
    );
    if (index !== -1) {
      procedureFormik.setValues((prevVals) => ({
        ...prevVals,
        ...claimChargesDto[index],
      }));
      setEditProcedure(true);
      setOpenProcedureModal(true);
    }
  };
  return (
    <>
      <div>
        {formik.values.claimTypeId === 1 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              height: "300px",
              overflowY: "scroll",
              border: "1px solid lightgrey",
              padding: "12px",
              width: { xs: "95%", sm: "75%", md: "100%" },
              borderRadius: "20px",
            }}
          >
            <Box>
              <Typography variant="h2" fontWeight="600">
                ICD Codes
              </Typography>
            </Box>
            {["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"].map(
              (v, i) => {
                return (
                  <Box
                    key={v}
                    display="grid"
                    gap="30px"
                    sx={{
                      gridTemplateColumns: {
                        xs: "repeat(1, minmax(0, 1fr))",
                        sm: "repeat(3, minmax(0, 1fr))",
                        md: "repeat(4, minmax(0, 1fr))",
                      },
                    }}
                  >
                    <Box>
                      <CustomField
                        type="text"
                        label={`ICD ${v}`}
                        handleBlur={formik.handleBlur}
                        handleChange={formik.handleChange}
                        formik={formik}
                        resetField={() => {
                          let icD_DiagnosisDetailDto =
                            formik.values?.icD_DiagnosisDetailDto || [];
                          let findIndex = icD_DiagnosisDetailDto.findIndex(
                            (val) => val.dxPointerLabel === v
                          );
                          if (findIndex !== -1) {
                            icD_DiagnosisDetailDto.splice(findIndex, 1);
                            return {
                              icD_DiagnosisDetailDto: icD_DiagnosisDetailDto,
                            };
                          }
                          return {};
                        }}
                        value={
                          formik.values?.icD_DiagnosisDetailDto.find(
                            (val) => val?.dxPointerLabel === v
                          )?.diagnosisCode || ""
                        }
                        name={`icD_Code_${v}`}
                      />
                      {v === "A" &&
                        formik.values.icD_DiagnosisDetailDto.length <= 0 && (
                          <div
                            style={{
                              color: theme.palette.errorFieldsBorder.primary,
                              border: `2px solid ${theme.palette.errorFieldsBorder.primary}`,
                              fontSize: "1.3rem",
                              marginTop: "8px",
                              display: "block",
                            }}
                          >
                            ICD Code is Required
                          </div>
                        )}
                    </Box>
                    <Box
                      sx={{
                        gridColumn: {
                          xs: "span 1",
                          sm: "span 2",
                          md: "span 3",
                        },
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <CustomSearchField
                          type="text"
                          handleModalOpen={() => handleOpenDiagnosisModal(v)}
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                          fieldVal={
                            formik.values?.icD_DiagnosisDetailDto?.find(
                              (val) => val?.dxPointerLabel === v
                            )?.diagnosisDescription || ""
                          }
                          formik={formik}
                          resetField={() => {
                            let icD_DiagnosisDetailDto =
                              formik.values?.icD_DiagnosisDetailDto || [];
                            let findIndex = icD_DiagnosisDetailDto.findIndex(
                              (val) => val.dxPointerLabel === v
                            );
                            if (findIndex !== -1) {
                              icD_DiagnosisDetailDto.splice(findIndex, 1);
                              return {
                                icD_DiagnosisDetailDto: icD_DiagnosisDetailDto,
                              };
                            }
                            return {};
                          }}
                          name={`icd_Description_${v}`}
                        />
                      </div>
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
        ) : (
          <Box>
            <InsClaimCharges formik={formik} />
          </Box>
        )}

        {/* Procedure table */}
        <Box>
          <CustomButton
            handleClick={handleAddProcedure}
            variant="contained"
            isBlue={true}
            margin="30px 5px 10px"
            isAdd={true}
          >
            Add Procedures
          </CustomButton>

          <ProcedureTable
            formik={formik}
            handleEditProcedure={handleEditProcedure}
            claimChargesDto={claimChargesDto}
            setClaimChargesDto={setClaimChargesDto}
            // findClaim={findClaim}
          />
        </Box>
      </div>

      {/* modals here */}
      <CustomModal
        open={openDiagnosisModal}
        handleClose={() => {
          setIcdIdentifier(null);
          setOpenDiagnosisModal(false);
        }}
        height="60%"
        heading="Search Diagnosis"
      >
        <CustomPreview
          columns={diagnosisCodesCols}
          rows={
            getDiagnosisData && getDiagnosisData?.result
              ? getDiagnosisData?.result?.map((el) => ({
                  id: el?.diagnosisCodeId,
                  ...el,
                }))
              : []
          }
          loading={loading}
          handleGetAll={getDiagnosisAction}
          searchLabel="Search by Code or Description"
          handleBySearch={getDiagnosisBySearchTermAction}
          handleCell={handleDiagnosis}
          isModal={true}
        />
      </CustomModal>
      <CustomModal
        open={openProcedureModal}
        handleClose={() => {
          setOpenProcedureModal(false);
          setEditProcedure(false);
        }}
        height={"20%"}
        showButton={true}
        heading={editProcedure ? "Edit Procedure" : "Add Procedure"}
        btnLabel={editProcedure ? "Update" : "Add"}
        handleClick={procedureFormik.handleSubmit}
      >
        <ProcedureClaim
          editProcedure={editProcedure}
          formik={formik}
          setClaimChargesDto={setClaimChargesDto}
          handleClose={() => setOpenProcedureModal(false)}
          procedureFormik={procedureFormik}
        />
      </CustomModal>
    </>
  );
};

export default ClaimCharges;
