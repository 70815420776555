import React, { useEffect } from "react";
import CustomModal from "../../components/CustomModal";
import { useState } from "react";
import ClaimTable from "../claim-dir/claim/ClaimTable";
import PayerList from "../patient/PayerList";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomField from "../../components/CustomField";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomSearchField from "../../components/CustomSearchField";
import CustomButton from "../../components/CustomButton";
import { useFormik } from "formik";
import { paymentInitVal3 } from "../../utils/formikInitValues";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedClaim,
  setPaymentDataForApi,
} from "../../features/slice/PaymentSlice";
import CustomSelectBox from "../../components/CustomSelectBox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PostPayment from "../payment/PostPayment";
import { createPaymentAction } from "../../features/actions/PaymentAction";
import { useParams } from "react-router-dom";
import { getData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";
import { DataObject } from "@mui/icons-material";

const EditPayment = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [openPayerModal, setOpenPayerModal] = useState(false);

  const [showPostPay, setShowPostPay] = useState(false);
  const [applyEob, setApplyEob] = useState(false);
  const [paymentDetailDto, setPaymentDetailDto] = useState(null);

  // get  paymentData For api value in redux
  let { paymentDataForApi } = useSelector((state) => state.payment);
  //   formik logic here
  const formik = useFormik({
    initialValues: paymentInitVal3,
    onSubmit: (values) => {
      const postValues = {
        ...values,
        paymentClaimDto: paymentDataForApi.paymentClaimDto,
      };
      console.log("paymentDataForApiabove", paymentDataForApi);
      console.log("paymentDataForApi3232", postValues);
      dispatch(createPaymentAction(postValues));
    },
  });

  //   handling payer type
  const handlePaymentBy = (selectedRow) => {
    console.log("selectedRow", selectedRow);
    if (formik.values.isClaim) {
      console.log("selected Rows", selectedRow);

      setOpenClaimModal(true);
      if (selectedRow.primaryPayerInsuranceName) {
        console.log(selectedRow, "selectedRow5667");
        dispatch(
          setPaymentDataForApi({
            ...paymentDataForApi,
            paymentBy: ` ${selectedRow.primaryPayerInsuranceName} (${selectedRow.payerSequenceNo})`,
            paymentFromName: selectedRow.primaryPayerInsuranceName,
            paymentFrom: selectedRow.payerId,
            payerId: selectedRow.payerId,
            payerSequenceNo: selectedRow.payerSequenceNo,
            paymentClaimDto: [
              {
                claimInfoId: selectedRow.claimChargesDto[0].claimInfoId,
                claimNumber: selectedRow.claimNumber,
                claimChargesDto: selectedRow.claimChargesDto,
                paymentDetailDto: [],
              },
            ],
          })
        );
        formik.setValues((prevValues) => ({
          ...prevValues,
          paymentBy: ` ${selectedRow.primaryPayerInsuranceName} (${selectedRow.payerSequenceNo})`,
          paymentFromName: selectedRow.primaryPayerInsuranceName,
          paymentFrom: selectedRow.payerId,
          payerId: selectedRow.payerId,
          payerSequenceNo: selectedRow.payerSequenceNo,
        }));
        dispatch(addSelectedClaim([selectedRow]));
        setOpenClaimModal(false);
      }
    } else if (!formik.values.isClaim && formik.values.paymentByTypeId === 1) {
      console.log("selected Rows for patient", selectedRow);
      setOpenClaimModal(true);
      if (selectedRow.patientFirstName) {
        dispatch(
          setPaymentDataForApi({
            ...paymentDataForApi,
            paymentBy: ` ${selectedRow.patientFirstName} (${selectedRow.patientAccountNo})`,
            paymentFromName: selectedRow.primaryPayerInsuranceName,
            paymentFrom: selectedRow.payerId,
            payerId: selectedRow.payerId,
            payerSequenceNo: selectedRow.payerSequenceNo,
            paymentClaimDto: [
              {
                claimInfoId: selectedRow.claimChargesDto[0].claimInfoId,
                claimNumber: selectedRow.claimNumber,
                claimChargesDto: selectedRow.claimChargesDto,
                paymentDetailDto: [],
              },
            ],
          })
        );
        formik.setValues((prevValues) => ({
          ...prevValues,
          paymentBy: ` ${selectedRow.patientFirstName} (${selectedRow.patientAccountNo})`,
          paymentFromName: selectedRow.primaryPayerInsuranceName,
          paymentFrom: selectedRow.payerId,
          payerId: selectedRow.payerId,
          payerSequenceNo: selectedRow.payerSequenceNo,
        }));
        dispatch(addSelectedClaim([selectedRow]));
        setOpenClaimModal(false);
      }
    } else if (!formik.values.isClaim) {
      setOpenPayerModal(true);
      if (selectedRow.payerName) {
        formik.setValues((prevValues) => ({
          ...prevValues,
          paymentBy: `${selectedRow.payerName} (${selectedRow.payerSequenceNo})`,
          paymentFromName: selectedRow.payerName,
          paymentFrom: selectedRow.id,
          payerId: selectedRow.id,
          payerSequenceNo: selectedRow.payerSequenceNo,
        }));
        dispatch(
          setPaymentDataForApi({
            ...paymentDataForApi,
            paymentBy: `${selectedRow.payerName} (${selectedRow.payerSequenceNo})`,
            paymentFromName: selectedRow.payerName,
            paymentFrom: selectedRow.id,
            payerId: selectedRow.id,
            payerSequenceNo: selectedRow.payerSequenceNo,
            paymentClaimDto: [
              {
                claimInfoId: selectedRow.claimChargesDto[0].claimInfoId,
                claimNumber: selectedRow.claimNumber,
                claimChargesDto: selectedRow.claimChargesDto,
                paymentDetailDto: [],
              },
            ],
          })
        );
        setOpenPayerModal(false);
      }
    }
  };

  const renderSourceOptions = () => {
    if (formik.values.paymentMethodId === 1) {
      return (
        <>
          <CustomField
            value={formik.values.checkNumber}
            type="text"
            label="Check/EFT #"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="checkNumber"
          />
        </>
      );
    } else if (formik.values.paymentMethodId === 3) {
      return (
        <>
          <CustomSelectBox
            value={formik.values.creditCardTypeId}
            name="creditCardTypeId"
            dropdownOptions={creditCardOpt?.map((opt) => ({
              value: opt.cardType,
              id: opt.id,
            }))}
            label="Credit Card Type"
            //  handleChange={formik.handleChange}
            handleChange={(e) => {
              formik.setFieldValue("creditCardTypeId", Number(e.target.value));
            }}
            handleBlur={formik.handleBlur}
          />
        </>
      );
    } else if (formik.values.paymentMethodId === 4) {
      return (
        <CustomField
          value={formik.values.checkNumber}
          type="text"
          label="Check/EFT #"
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          name="checkNumber"
        />
      );
    } else {
      return null;
    }
  };

  const creditCardOpt = [
    {
      id: 1,
      cardType: "Visa",
    },
    {
      id: 2,
      cardType: "Master",
    },
    {
      id: 3,
      cardType: "American Express",
    },
    {
      id: 4,
      cardType: "Discover",
    },
    {
      id: 5,
      cardType: "Other",
    },
  ];

  // apply eob handle
  const handleApplyEOB = () => {
    if (
      formik.values.paymentBy === "" ||
      formik.values.paymentBy.length === 0 ||
      formik.values.paymentBy === null
    ) {
      alert("Fill up the required fields");
    } else {
      console.log("formik.values", formik.values);
      console.log("paymentDataForApi", paymentDataForApi);
      console.log("formik.values.paymentBy", formik.values.paymentBy);
      const loadingBtn = setTimeout(() => {
        setShowPostPay(true);
      }, 1500);
      loadingBtn && setApplyEob(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getData(`${path}/Updatedpayment`);
        let data = await response?.result.find(
          (val) => val.paymentId === Number(id)
        );
        let ClaimIds = [];
        data.paymentDetailDto.forEach((v) => {
          let findI = ClaimIds.findIndex((val) => val === v?.claimInfoId);
          if (findI === -1) {
            ClaimIds.push(v?.claimInfoId);
          } else {
            ClaimIds.splice(findI, 1);
            ClaimIds.push(v?.claimInfoId);
          }
        });
        let PaymentClaimDto = [];
        ClaimIds.forEach((e) => {
          let allRecordOfEachClaimId = data["paymentDetailDto"].filter(
            (val) => val?.claimInfoId === e
          );
          PaymentClaimDto.push({
            claimInfoId: allRecordOfEachClaimId[0].claimInfoId,
            claimNumber: allRecordOfEachClaimId[0].claimNumber,
            claimChargesDto: allRecordOfEachClaimId,
            paymentDetailDto: allRecordOfEachClaimId,
          });
        });
        data = {
          ...data,
          paymentBy: ` ${data.payerName} (${data.payerSequenceNo})`,
          paymentFromName: data.payerName,
          paymentFrom: data.payerId,
          paymentAmount: data.amount,
          checkDate : new Date(data.checkDate),
          totalPaymentReceived: data.totalPaid,
          applied: data.totalAllowed,
          paymentClaimDto: PaymentClaimDto,
        };
        dispatch(setPaymentDataForApi(data));
        formik.setValues(data);
        dispatch(addSelectedClaim([data]));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {console.log("formik.values for paymenetDataForApi", paymentDataForApi)}
      {console.log("formik.values 3232", formik.values)}
      <Box className="backgroundpatient ">
        <CustomModal
          open={openClaimModal}
          handleClose={() => setOpenClaimModal(false)}

        >
          <ClaimTable
            handleClose={() => setOpenClaimModal(false)}
            onCellClick={handlePaymentBy}
            isModal={true}
          />
        </CustomModal>
        {/* payer modal */}
        <CustomModal
          open={openPayerModal}
          handleClose={() => setOpenPayerModal(false)}
          height={'20px'}
        >
          <PayerList
            handleClose={() => setOpenPayerModal(false)}
            modalFor="payment"
            handlePaymentBy={handlePaymentBy}
          />
        </CustomModal>

        {/* first window data */}
        <Box margin={"20px"}>
          {showPostPay ? (
            <PostPayment
              formik={formik}
              setPaymentDetailDto={setPaymentDetailDto}
            />
          ) : (
            <div>
              <Box>
                <CustomButton
                  variant="contained"
                  margin="0 0 15px"
                  handleClick={handleApplyEOB}
                >
                  {applyEob ? (
                    <CircularProgress />
                  ) : formik.values.paymentByTypeId === 2 ? (
                    "Apply As EOB"
                  ) : (
                    "Apply Manually"
                  )}
                </CustomButton>
              </Box>
              <Stack
                sx={{
                  width: { xs: "95%", sm: "75%", md: "50%" },
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  padding: "15px",
                }}
              >
                <Box
                  display="grid"
                  gap="30px"
                  margin={"20px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(1, minmax(0, 1fr))",
                      md: "repeat(1, minmax(0, 1fr))",
                    },
                  }}
                >
                  <CustomSearchField
                    type="text"
                    label="Payment By"
                    handleModalOpen={handlePaymentBy}
                    name="paymentBy"
                    fieldVal={formik.values.paymentBy}
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                  />

                  <Stack flexDirection={"row"} alignItems={"center"}>
                    <FormControlLabel
                      label="Claim"
                      control={
                        <Radio
                          value="claim"
                          variant="soft"
                          name="claim"
                          checked={
                            formik.values.isClaim &&
                            formik.values.paymentByTypeId === 2
                          }
                          onChange={() => {
                            formik.setFieldValue("paymentByTypeId", 2);
                            formik.setFieldValue("isClaim", true);
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Payer"
                      control={
                        <Radio
                          value="payer"
                          variant="soft"
                          name="payer"
                          checked={
                            !formik.values.isClaim &&
                            formik.values.paymentByTypeId === 2
                          }
                          onChange={() => {
                            formik.setFieldValue("isClaim", false);
                            formik.setFieldValue("paymentByTypeId", 2);
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Patient"
                      control={
                        <Radio
                          value="1"
                          variant="soft"
                          name="patient"
                          checked={
                            formik.values.paymentByTypeId === 1 &&
                            formik.values.isClaim === false
                          }
                          onChange={() => {
                            formik.setFieldValue("paymentByTypeId", 1);
                            formik.setFieldValue("isClaim", false);
                          }}
                        />
                      }
                    />
                  </Stack>
                </Box>
                <Box
                  display="grid"
                  gap="30px"
                  margin={"20px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(2, minmax(0, 1fr))",
                      md: "repeat(2, minmax(0, 1fr))",
                    },
                  }}
                >
                  {formik.values.paymentByTypeId === 2 && (
                    <CustomField
                      type="text"
                      label="Payment From"
                      value={formik.values.paymentFromName}
                      name="paymentFromName"
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                    />
                  )}
                  <CustomField
                    type="number"
                    label="Payment Amount"
                    value={formik.values.paymentAmount}
                    name="paymentAmount"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                  />
                </Box>
                <CustomDatePicker
formik={formik}
                  className="customDatePicker"
                  // label="Date of Death"
                  value={formik.values.checkDate}
                  onChange={(value) => formik.setFieldValue("checkDate", value)}
                  onBlur={() => formik.setFieldTouched("checkDate", true)}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="MM/DD/YYYY"
                />
                {formik.values.paymentByTypeId === 1 && (
                  <Box>
                    {" "}
                    <Stack flexDirection={"row"} alignItems={"center"}>
                      <Typography marginRight={"20px"}>Type:</Typography>
                      <FormControlLabel
                        label="Copay"
                        control={
                          <Radio
                            value="1"
                            variant="soft"
                            name="paymentTypeId"
                            checked={formik.values.paymentTypeId === 1}
                            // onChange={formik.handleChange}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "paymentTypeId",
                                Number(e.target.value)
                              );
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Payment"
                        control={
                          <Radio
                            value="2"
                            variant="soft"
                            name="paymentTypeId"
                            checked={formik.values.paymentTypeId === 2}
                            // onChange={formik.handleChange}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "paymentTypeId",
                                Number(e.target.value)
                              );
                            }}
                          />
                        }
                      />
                    </Stack>
                    {formik.values.paymentTypeId === 1 && (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        locale="en"
                      >
                        <DatePicker
                          label="Copay Date of Service"
                          value={formik.values.copayServiceDate}
                          onChange={(newDate) =>
                            formik.setFieldValue("copayServiceDate", newDate)
                          }
                          onBlur={() =>
                            formik.setFieldTouched("copayServiceDate", true)
                          }
                          renderInput={(params) => <TextField {...params} />}
                          inputFormat="MM/DD/YYYY"
                        />
                      </LocalizationProvider>
                    )}
                  </Box>
                )}
                <Stack flexDirection={"row"} alignItems={"center"}>
                  <Typography marginRight={"20px"}>Source:</Typography>
                  <FormControlLabel
                    label="Check"
                    control={
                      <Radio
                        value="1"
                        variant="soft"
                        name="paymentMethodId"
                        checked={formik.values.paymentMethodId === 1}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "paymentMethodId",
                            Number(e.target.value)
                          );
                        }}
                      />
                    }
                  />
                  {formik.values.paymentByTypeId === 1 && (
                    <FormControlLabel
                      label="Cash"
                      control={
                        <Radio
                          value="2"
                          variant="soft"
                          name="paymentMethodId"
                          checked={formik.values.paymentMethodId === 2}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "paymentMethodId",
                              Number(e.target.value)
                            );
                          }}
                        />
                      }
                    />
                  )}
                  <FormControlLabel
                    label="Credit Card"
                    control={
                      <Radio
                        value="3"
                        variant="soft"
                        name="paymentMethodId"
                        checked={formik.values.paymentMethodId === 3}
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "paymentMethodId",
                            Number(e.target.value)
                          );
                        }}
                      />
                    }
                  />
                  {formik.values.paymentByTypeId === 2 && (
                    <FormControlLabel
                      label="Electronic Fund Transfer"
                      control={
                        <Radio
                          value="4"
                          variant="soft"
                          name="paymentMethodId"
                          checked={formik.values.paymentMethodId === 4}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "paymentMethodId",
                              Number(e.target.value)
                            );
                          }}
                        />
                      }
                    />
                  )}
                </Stack>

                <Box
                  display="grid"
                  gap="30px"
                  margin={"20px 0"}
                  sx={{
                    gridTemplateColumns: {
                      xs: "repeat(1, minmax(0, 1fr))",
                      sm: "repeat(2, minmax(0, 1fr))",
                      md: "repeat(2, minmax(0, 1fr))",
                    },
                  }}
                >
                  {renderSourceOptions()}
                </Box>
              </Stack>
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default EditPayment;
