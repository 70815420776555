import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getDiagnosisAction = createAsyncThunk("getDiagnosis", async (searchCode) => {
  try {
    let search = searchCode ? `/${searchCode}` : ''
    const response = await getData(`${path}/ct-diagnosisCode${search}`);

    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error, "action error");
    throw new Error(error);
  }
});

export const newDiagnosisAction = createAsyncThunk(
  "createDiagnosis" ,
  async (data) => {
    try {
      const response = await postData(`${path}/ct-diagnosisCode`, data);
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      console.log(error, "check Error");
      throw new Error(error);
    }
  }
);

export const getDiagnosisBySearchTermAction = createAsyncThunk("getDiagnosis", async ({searchTerm}) => {
  try {
    const response = await getData(`${path}/ct-diagnosisCode/SearchDiagnosisCode?searchValue=${searchTerm}`);
    console.log(response, "action diagnosis by search term");
    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw new Error(error);
  }
});

export const updateDiagnosisAction = createAsyncThunk(
  "updateDiagnosis",
  async (data) => {
    console.log("data " , data)
    try {
      const response = await updateData(
        `${path}/ct-diagnosisCode/${data.diagnosisCodeId}`,
        data
      );
      console.log(response, "update Diagnosis action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);
