import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useEffect, useState } from "react";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { MultiSelect } from "react-multi-select-component";
import CustomField from "../../../components/CustomField";
import CustomSearchField from "../../../components/CustomSearchField";
import { useSelector } from "react-redux";
import CustomModal from "../../../components/CustomModal";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomPreview from "../../../layout/CustomPreview";
import '../../../index.css';
import {
  claimCols,
  facilityCols,
  patientClaimDetailCols,
  payerCol,
  providerCols,
} from "../../../utils/gridCols";
import {
  getClaimAction,
  getClaimBySearchTermAction,
  getPatientClaimBySearchTerm,
  getPatientClaimsDetailAction,
} from "../../../features/actions/claimAction";
import {
  getPayerAction,
  getPayerBySearchTermAction,
} from "../../../features/actions/payerAction";
import {
  getProviderAction,
  getProviderBySearchTermAction,
} from "../../../features/actions/providerAction";
import {
  getFacilityAction,
  getFacilityBySearchTermAction,
} from "../../../features/actions/facilityAction";

const ClaimTrackerForm = ({ formik, handleDosChange }) => {
  const { getClaims, loading } = useSelector((state) => state.claim);
  const { patientClaimsData, getPatientClaimDetailLoad } = useSelector(
    (state) => state.claim
  );
  // rows

  const { getAllPayer } = useSelector((state) => state.payer);
  const payerLoading = useSelector((state) => state.payer.loading);

  const { getProviders } = useSelector((state) => state.provider);
  const providerLoading = useSelector((state) => state.provider.loading);

  const { getFacilityData } = useSelector((state) => state.facility);
  const facilityLoading = useSelector((state) => state.facility.loading);

  const [openClaimMod, setOpenClaimMod] = useState(false);
  const [openPatientMod, setOpenPatientMod] = useState(false);
  const [openPayerMod, setOpenPayerMod] = useState(false);
  const [openBillingProviderMod, setOpenBillingProviderMod] = useState(false);
  const [openRenderingPrviderMod, setOpenRenderingProviderMod] =
    useState(false);
  const [openFacilityMod, setOpenFacilityMod] = useState(false);

  const theme = useTheme();
  const [dateFilter, setDateFilter] = useState([]);
  const [submissionType, setSubmissionType] = useState([]);
  const [processingModeList, setProcessingModeList] = useState([]);
  const [trackingStatusTypeList, setTrackingStatusTypeList] = useState([]);
  const [trackingStatusSourceList, setTrackingStatusSourceList] = useState([]);
  const [claimStatusList, setClaimStatusList] = useState([]);
  const [claimTypeList, setClaimTypeList] = useState([]);
  const [payerTypeList, setPayerTypeList] = useState([]);

  const fetchData = async (url, setState) => {
    try {
      const { result } = await getData(url);
      setState(result);
    } catch (error) {
      console.log(error);
    }
  };
  const dataSourceOption = {
    dateFilter: `${path}/ct-dOS`,
    submissionType: `${path}/ct-submissionType`,
    processingMode: `${path}/ct-processingMode`,
    trackingStatusType: `${path}/ct-trackingStatusType`,
    trackingStatusSource: `${path}/ct-trackingStatusSource`,
    claimStatus: `${path}/ct-claimStatus`,
    claimType: `${path}/ct-claimTypes`,
    payerType: `${path}/ct-payerType`,
  };
  useEffect(() => {
    fetchData(dataSourceOption.dateFilter, setDateFilter);
    fetchData(dataSourceOption.submissionType, setSubmissionType);
    fetchData(dataSourceOption.processingMode, setProcessingModeList);
    fetchData(dataSourceOption.trackingStatusType, setTrackingStatusTypeList);
    fetchData(dataSourceOption.claimStatus, setClaimStatusList);
    fetchData(dataSourceOption.claimType, setClaimTypeList);
    fetchData(
      dataSourceOption.trackingStatusSource,
      setTrackingStatusSourceList
    );
    fetchData(dataSourceOption.payerType, setPayerTypeList);
  }, []);


  // const customStyles = {
  //   dropdownContainer: {
  //     backgroundColor: theme.palette.Labeltext.primary,
  //     color: 'red !important',
  //   },
  //   option: {
  //     // color: theme.palette.Labeltext.primary,
  //     color: 'red',

  //   },
  // };

  return (
    <>

{/* <style>
        {`
          .custom-multi-select .dropdown-container {
            background-color: ${customStyles.dropdownContainer.backgroundColor} !important;
          }
          .custom-multi-select .dropdown-content .item {
            color: ${customStyles.dropdownContainer.color} !important;
          }
        `}
      </style> */}
      {/* <style>
      {`
          .custom-multi-select .dropdown-container {
            background-color: lightblue;
          }
          .custom-multi-select .dropdown-content {
            color: theme.palette.Labeltext.primary;
          }
          .custom-multi-select .dropdown-content .item {
            color: purple !important;
      }
          `}
      </style> */}
      

      {/* claim Modal */}
      <CustomModal
        open={openClaimMod}
        handleClose={() => setOpenClaimMod(false)}
        height={"20px"}
        heading={"All Claims"}
      >
        <CustomPreview
          rows={
            getClaims && getClaims.result?.claims.length > 0
              ? getClaims.result?.claims.map((el) => ({
                  id: el.claimNumber,
                  fromDate: el?.getClaimChargesDto?.fromDate,
                  ...el,
                }))
              : []
          }
          isModal={true}
          columns={claimCols}
          paginationDetail={getClaims}
          searchLabel="Search by Claim Id,Patient Name,Dos"
          moduleHeading="All Claims"
          loading={loading}
          handleCell={(params) => {
            formik.setFieldValue("claimNo", params?.claimNumber);
            setOpenClaimMod(false);
          }}
          handleGetAll={getClaimAction}
          handleBySearch={getClaimBySearchTermAction}
        />
      </CustomModal>

      {/* payer modal */}
      <CustomModal
        open={openPayerMod}
        handleClose={() => setOpenPayerMod(false)}
        height={"20px"}
        heading="Payers"
      >
        <CustomPreview
          rows={
            getAllPayer && getAllPayer?.result?.payers?.length > 0
            ? getAllPayer?.result?.payers?.map((el) => ({
                id: el?.payerId,
                ...el,
              }))
            : []
          }
          columns={payerCol}
          loading={payerLoading}
          handleGetAll={getPayerAction}
          handleBySearch={getPayerBySearchTermAction}
          paginationDetail={getAllPayer}
          moduleHeading={"Payer"}
          isModal={true}
          handleCell={(params) => {
            formik.setFieldValue("payer", [params]);
            setOpenPayerMod(false);
          }}
          searchLabel="Search by name or address #"
        />
      </CustomModal>

      {/* patient modal */}
      <CustomModal
        open={openPatientMod}
        handleClose={() => setOpenPatientMod(false)}
        height={"20px"}
        heading="Patients"
      >
        <CustomPreview
          searchLabel="Search by Claim Id,Patient Name,Dos"
          rows={
            patientClaimsData &&
            patientClaimsData?.result?.patientClaim?.length > 0
              ? patientClaimsData?.result?.patientClaim?.map((row) => ({
                  id: row?.patientId,
                  ...row,
                }))
              : []
          }
          columns={patientClaimDetailCols}
          loading={getPatientClaimDetailLoad}
          handleGetAll={getPatientClaimsDetailAction}
          handleBySearch={getPatientClaimBySearchTerm}
          paginationDetail={patientClaimsData}
          handleCell={(e) => {
            formik.setFieldValue("patientAccountNo", e?.patientAccountNo);
            setOpenPatientMod(false);
          }}
          isModal={true}
        />
      </CustomModal>

      {/* billing provider modal */}
      <CustomModal
        open={openBillingProviderMod}
        handleClose={() => setOpenBillingProviderMod(false)}
        height={"20px"}
        heading="Billing Provider"
      >
        <CustomPreview
          rows={
            getProviders && getProviders.result?.providers?.length > 0
              ? getProviders?.result?.providers?.map((el) => ({
                  id: el.providerId,
                  ...el,
                }))
              : []
          }
          columns={providerCols}
          isModal={true}
          loading={providerLoading}
          paginationDetail={getProviders}
          handleGetAll={getProviderAction}
          handleBySearch={getProviderBySearchTermAction}
          searchLabel="Search by Provider Name and Sequence No"
          handleCell={(params) => {
            console.log("billingProvidersId", params);
            formik.setFieldValue("billingProvidersId", [params]);
            setOpenBillingProviderMod(false);
          }}
        />
      </CustomModal>

      {/* rendering provider */}
      <CustomModal
        open={openRenderingPrviderMod}
        handleClose={() => setOpenRenderingProviderMod(false)}
        height={"20px"}
        heading={"Rendering Provider"}
      >
        <CustomPreview
          rows={
            getProviders && getProviders.result?.providers?.length > 0
              ? getProviders?.result?.providers?.map((el) => ({
                  id: el.providerId,
                  ...el,
                }))
              : []
          }
          isModal={true}
          columns={providerCols}
          loading={providerLoading}
          paginationDetail={getProviders}
          handleGetAll={getProviderAction}
          handleBySearch={getProviderBySearchTermAction}
          searchLabel="Search by Provider Name and Sequence No"
          handleCell={(params) => {
            formik.setFieldValue("renderingProviderId", [params]);
            setOpenRenderingProviderMod(false);
          }}
        />
      </CustomModal>

      {/* facility modal */}
      <CustomModal
        open={openFacilityMod}
        handleClose={() => setOpenFacilityMod(false)}
        height={"20px"}
        heading={"Facility"}
      >
        <CustomPreview
          isModal={true}
          rows={
            getFacilityData?.result &&
            getFacilityData.result?.facility?.length > 0
              ? getFacilityData.result?.facility?.map((el) => ({
                  id: el.facilityId,
                  facilityName: el.facilityName,
                  phoneNumber: el.phoneNumber,
                  sequenceNo: el.sequenceNo,
                  taxonomySpecialty: el.taxonomySpecialty,
                }))
              : []
          }
          columns={facilityCols}
          loading={facilityLoading}
          handleGetAll={getFacilityAction}
          paginationDetail={getFacilityData}
          handleBySearch={getFacilityBySearchTermAction}
          searchLabel="Search by First Name, Address, NPI, Phone ,or Sequence Number"
          handleCell={(params) => {
            console.log("params", params);
            formik.setFieldValue("facilityId", [params]);
            setOpenFacilityMod(false);
          }}
        />
      </CustomModal>

      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 150px))",
            sm: "repeat(1, minmax(0, 150px))",
            md: "repeat(2, minmax(0, 150px))",
          },
        }}
      >
        <CustomButton type={"submit"} isBlue={true} isSearch={true}>
          Search
        </CustomButton>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "30px" }}>
          <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px" }}>
            <Box
              sx={{
                background: theme.palette.revenueStatement.primary,
                height: "33px",
              }}
            >
              <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
                Claim Tracking Date Range
              </Typography>
            </Box>
            <FormControl fullWidth sx={{ padding: "14px" }}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{
                      width: "100%",
                      fontSize: "1.4rem",
                      fontWeight: "600",
                      marginTop: "5px",
                    }}
                  >
                    Filter Search By:
                  </FormLabel>
                </Grid>

                <Grid item md={6}>
                  <RadioGroup
                    onChange={(e) => {
                      formik.setFieldValue(
                        "filterSearchId",
                        Number(e.target.value)
                      );
                    }}
                    value={formik.values.filterSearchId}
                    sx={{
                      marginLeft: "20px",
                    }}
                    row
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          style={{
                            color: theme.palette.radioButtons.primary,
                          }}
                        />
                      }
                      label={
                        <Typography fontSize={"1.3rem"} fontWeight="600">
                          By Date Submitted
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={2}
                      control={
                        <Radio
                          style={{
                            color: theme.palette.radioButtons.primary,
                          }}
                        />
                      }
                      label={
                        <Typography fontSize={"1.3rem"} fontWeight="600">
                          By DOS
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <label
                      className="customLabel"
                      style={{ color: theme.palette.Labeltext.primary }}
                      htmlFor=""
                    >
                      {formik.values.filterSearchId === 1
                        ? "Date Submitted"
                        : "DOS"}
                    </label>
                    <select
                      className={"customSelectBox"}
                      style={{
                        background:
                          theme.palette.customFieldsBackground.primary,
                        border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                        color: theme.palette.fieldsText.primary,
                      }}
                      value={formik.values.ctDosId}
                      onChange={(e) => handleDosChange(e, "ct")}
                      id="dropdowns"
                    >
                      {dateFilter.map((opt) => {
                        return (
                          <>
                            <option
                              style={{
                                background: theme.palette.fieldsDDown.primary,
                              }}
                              itemType="number"
                              key={opt.dosId}
                              value={opt.dosId}
                            >
                              {opt.dos}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomDatePicker
                    formik={formik}
                    disable={formik.values.ctDosId !== "2"}
                    dateLabel="From"
                    name="ctFromDate"
                    dateValue={formik.values.ctFromDate}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <CustomDatePicker
                    formik={formik}
                    dateLabel="To"
                    name="ctToDate"
                    disable={formik.values.ctDosId !== "2"}
                    dateValue={formik.values.ctToDate}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item md={4} xs={12} sm={12}>
                  <FormControl fullWidth>
                    <label
                      className="customLabel"
                      style={{ color: theme.palette.Labeltext.primary }}
                      htmlFor=""
                    >
                      Status Date
                    </label>
                    <select
                      className={"customSelectBox"}
                      style={{
                        background:
                          theme.palette.customFieldsBackground.primary,
                        border: `1px solid ${theme.palette.fieldsBorder.primary}`,
                        color: theme.palette.fieldsText.primary,
                      }}
                      value={formik.values.statusDateId}
                      onChange={(e) => handleDosChange(e, "sd")}
                      id="dropdowns"
                    >
                      {dateFilter.map((opt) => {
                        return (
                          <>
                            <option
                              style={{
                                background: theme.palette.fieldsDDown.primary,
                              }}
                              itemType="number"
                              key={opt.dosId}
                              value={opt.dosId}
                            >
                              {opt.dos}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12} sm={12}>
                  <CustomDatePicker
                    formik={formik}
                    disable={formik.values.statusDateId !== "2"}
                    dateLabel="From"
                    name="sdFromDate"
                    dateValue={formik.values.sdFromDate}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item md={4} xs={12} sm={12}>
                  <CustomDatePicker
                    formik={formik}
                    dateLabel="To"
                    name="sdToDate"
                    disable={formik.values.statusDateId !== "2"}
                    dateValue={formik.values.sdToDate}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </Grid>

        {/* 3rd row */}
        <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "30px" }}>
          <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px" }}>
            <Box
              sx={{
                background: theme.palette.revenueStatement.primary,
                height: "33px",
              }}
            >
              <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
                Claim Filters
              </Typography>
            </Box>

            <FormControl fullWidth sx={{ padding: "14px" }}>
              <label
                className="customLabel"
                style={{
                  color: theme.palette.Labeltext.primary,
                }}
              >
                Submission Type
              </label>
              <MultiSelect
                // className={multiSelectClasses}
                className="custom-multi-select"
                options={submissionType?.map((el) => ({
                  label: el?.submissionType,
                  value: el?.submissionTypeId,
                }))}
                value={formik.values.submissionTypeId}
                onChange={(selectedOption) => {
                  formik.setFieldValue("submissionTypeId", selectedOption);
                }}
                style={{
                  backgroundColor: "red",
                  control: (base) => ({
                    ...base,
                    backgroundColor: "red",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "blue",
                  }),
                }}
              />

              {/* <label
                className="customLabel"
                style={{
                  color: theme.palette.Labeltext.primary,
                }}
              >
                Processing Mode
              </label> */}
              {/* <MultiSelect
                options={processingModeList?.map((el) => ({
                  label: el?.processingMode,
                  value: el?.processingModeId,
                }))}
                value={formik.values.processingModeId}
                onChange={(selectedOption) => {
                  formik.setFieldValue("processingModeId", selectedOption);
                }}
                style={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "red",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "blue",
                  }),
                }}
              /> */}
              <CustomSelectBox2
                label="Processing Mode"
                dropdownOptions={processingModeList?.map((el) => ({
                  id: el?.processingModeId,
                  value: el?.processingMode,
                }))}
                value={formik.values.processingModeId}
                handleChange={(selectedOption) => {
                  formik.setFieldValue("processingModeId", selectedOption);
                }}
                handleBlur={formik.handleBlur}
                disable={false}
                name="processingModeId"
                formik={formik}
                isMulti={true}
                showDefaultOpt={false}
              />

              {/* <label
                className="customLabel"
                style={{
                  color: theme.palette.Labeltext.primary,
                }}
              >
                Tracking Status Type
              </label>
              <MultiSelect
                options={trackingStatusTypeList?.map((el) => ({
                  label: el?.trackingStatusType,
                  value: el?.trackingStatusTypeId,
                }))}
                value={formik.values.trackingStatusTypeId}
                onChange={(selectedOption) => {
                  formik.setFieldValue("trackingStatusTypeId", selectedOption);
                }}
                style={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "red !important",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "blue",
                  }),
                }}
              /> */}

              <label
                className="customLabel"
                style={{
                  color: theme.palette.Labeltext.primary,
                }}
              >
                Tracking Status Source
              </label>
              <MultiSelect
                className="custom-multi-select"
                options={trackingStatusSourceList?.map((el) => ({
                  label: el?.trackingStatusSource,
                  value: el?.trackingStatusSourceId,
                }))}
                value={formik.values.trackingStatusSourceId}
                onChange={(selectedOption) => {
                  formik.setFieldValue(
                    "trackingStatusSourceId",
                    selectedOption
                  );
                }}
              />

              <label className="customLabel"
        style={{color: theme.palette.Labeltext.primary}}>Tracking Status Type</label>
              <MultiSelect
                className="custom-multi-select"
                options={trackingStatusTypeList?.map((el) => ({
                  label: el?.trackingStatusType,
                  value: el?.trackingStatusTypeId,
                }))}
                value={formik.values.trackingStatusTypeId}
                onChange={(selectedOption) => {
                  formik.setFieldValue("trackingStatusTypeId", selectedOption);
                }}
              />

              <label
                className="customLabel"
                style={{
                  color: theme.palette.Labeltext.primary,
                }}
              >
                Claim Status
              </label>
              <MultiSelect
                options={claimStatusList?.map((el) => ({
                  label: el?.claimStatusType,
                  value: el?.claimStatusId,
                }))}
                value={formik.values.claimStatusId}
                onChange={(selectedOption) => {
                  formik.setFieldValue("claimStatusId", selectedOption);
                }}
               
              />
              <label
                className="customLabel"
                style={{
                  color: theme.palette.Labeltext.primary,
                }}
              >
                Claim Type
              </label>
              <MultiSelect
                className="custom-multi-select"
                options={claimTypeList?.map((el) => ({
                  label: el?.claimType,
                  value: el?.claimTypeId,
                }))}
                value={formik.values.claimTypeId}
                onChange={(selectedOption) => {
                  formik.setFieldValue("claimTypeId", selectedOption);
                }}
                style={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "red",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "blue",
                  }),
                }}
              />
            </FormControl>
          </Box>
        </Grid>

        <Grid item md={12} xs={12} sm={12}>
          <CustomField
            type="text"
            value={formik.values.tcn}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="tcn"
            label="Tracking Status(Contains)"
          />
        </Grid>

        <Grid item md={12} xs={12} sm={12}>
          <CustomField
            type="text"
            value={formik.values.insuranceCheckNumber}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="insuranceCheckNumber"
            label="Insurance Check #"
          />
        </Grid>

        {console.log(formik.values, "checkClaimTrackValues")}
        <Grid item xs={12} sm={12} md={12}>
          <CustomSearchField
            label="Claim #"
            fieldVal={formik.values.claimNo}
            handleModalOpen={() => setOpenClaimMod(true)}
            isRequired={true}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="claimNo"
            formik={formik}
            resetField={{
              claimNo: null,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <CustomSearchField
            label="Patient"
            fieldVal={formik.values.patientAccountNo}
            handleModalOpen={() => setOpenPatientMod(true)}
            isRequired={true}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            name="patientAccountNo"
            formik={formik}
            resetField={{
              patientAccountNo: null,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <CustomSearchField
            label="Payers"
            fieldVal={`${formik.values?.payer[0]?.payerName || ""} ${
              formik.values?.payer[0]?.payerSequenceNo || ""
            }`}
            handleModalOpen={() => setOpenPayerMod(true)}
            name="payer"
            isRequired={true}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
            resetField={{
              payer: [],
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <label
            className="customLabel"
            style={{
              color: theme.palette.Labeltext.primary,
            }}
          >
            Payer Type
          </label>
          <MultiSelect
            options={payerTypeList?.map((el) => ({
              label: el?.payerType,
              value: el?.payerTypeId,
            }))}
            value={formik.values.payerTypeId}
            onChange={(selectedOption) => {
              formik.setFieldValue("payerTypeId", selectedOption);
            }}
            style={{
              control: (base) => ({
                ...base,
                backgroundColor: "red",
              }),
              option: (provided) => ({
                ...provided,
                color: "blue",
              }),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <CustomSearchField
            label="Billing Providers"
            fieldVal={`${
              formik.values?.billingProvidersId[0]?.billingProviderFirstName ||
              ""
            } ${
              formik.values?.billingProvidersId[0]?.billingProviderSeqNo || ""
            }`}
            handleModalOpen={() => setOpenBillingProviderMod(true)}
            name="billingProvidersId"
            isRequired={true}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
            resetField={{
              billingProvidersId: [],
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <CustomSearchField
            label="Rendering Providers"
            fieldVal={`${
              formik.values?.renderingProviderId[0]?.billingProviderFirstName ||
              ""
            } ${
              formik.values?.renderingProviderId[0]?.billingProviderSeqNo || ""
            }`}
            handleModalOpen={() => setOpenRenderingProviderMod(true)}
            name="renderingProviderId"
            isRequired={true}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
            resetField={{
              renderingProviderId: [],
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <CustomSearchField
            label="Facilities"
            fieldVal={`${formik.values?.facilityId[0]?.facilityName || ""} ${
              formik.values?.facilityId[0]?.sequenceNo || ""
            }`}
            handleModalOpen={() => setOpenFacilityMod(true)}
            name="facilityId"
            isRequired={true}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
            resetField={{
              facilityId: [],
            }}
          />
        </Grid>

        <Grid item md={12}>
          <FormControl sx={{ width: "50%" }}>
            <FormControlLabel
              sx={{ marginTop: "12px" }}
              control={
                <Checkbox
                  sx={{
                    color: "black",
                  }}
                  value={formik.values.claimHistory}
                  onChange={() =>
                    formik.setFieldValue(
                      "claimHistory",
                      !formik.values.claimHistory
                    )
                  }
                />
              }
              label="Include Claim History"
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default ClaimTrackerForm;
