import React from "react";
import { useState } from "react";
import path from "../../../config/apiUrl";
import axios from "axios";
import { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import CustomDataGrid from "../../../components/CustomDataGrid";

const PosCodes = ({ handleCellClick }) => {
  const [posDetail, setPosDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPosCodes = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${path}/PosCode`);
      if (response.status === 200) {
        const data = await response.data;
        setPosDetail(data.result);
      } else {
        setPosDetail([]);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const rows = posDetail.map((el) => ({
    id: el.posCodeId,
    posCode: el.posCode,
    posDescription: el.posDescription,
  }));

  const columns = [
    {
      field: "posCode",
      headerName: "Codes",
      minWidth: 100,
      flex: 2,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "posDescription",
      headerName: "Description",
      minWidth: 100,
      flex: 3,
      headerAlign: "left",
      align: "left",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];

  useEffect(() => {
    fetchPosCodes();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {isLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <CustomDataGrid
          rows={rows}
          columns={columns}
          handleCellClick={(params) => handleCellClick(params)}
        />
      )}
    </Box>
  );
};

export default PosCodes;
