import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";

export const PaymentPlanStatement = ({ formik, accountType }) => {
  const theme = useTheme();
  const [arFilterOpt, setArFilterOpt] = useState([]);
  const [chargesToIncludeList, setChargesToIncludeList] = useState([]);
  const [seperateStatementsList, setSeperateStatementsList] = useState([]);

  const filterData = [
    {
      filterId: 1,
      filterName: "Payment Plan Balance",
      filterKey: "statementAmount",
    },
    {
      filterId: 2,
      filterName: "Electronic Statement Sent",
      filterKey: "electronicStatementSent",
    },
    {
      filterId: 3,
      filterName: "Paper Statement Sent",
      filterKey: "paperStatementSent",
    },
    {
      filterId: 4,
      filterName: "Total Statement Sent",
      filterKey: "totalStatementSent",
    },
    {
      filterId: 5,
      filterName: "Day Since Last Statement",
      filterKey: "daySinceLastStatement",
    },
    {
      filterId: 6,
      filterName: "Day Until Next Statement",
      filterKey: "daySinceLastStatement",
    },
  ];
  const dataFetchUrl = {
    arFilters: `${path}/ct-arFilters`,
    chargesToInclude: `${path}/ct-includedCharges`,
    seperateStatement: `${path}/ct-seperateStatement`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.log("Fetching Dropdown Option Error:", error);
      throw error;
    }
  };

  const handleSelectChange = () => {};
  const handleChangeValue = () => {};
  useEffect(() => {
    fetchDataOptions(dataFetchUrl.arFilters, setArFilterOpt);
    fetchDataOptions(dataFetchUrl.chargesToInclude, setChargesToIncludeList);
    fetchDataOptions(dataFetchUrl.seperateStatement, setSeperateStatementsList);
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "grid",
          marginTop: "10px",
          padding: "0px 0px 20px 20px",
          borderBottom: "1px solid black",
          gap: "30px",
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 300px))",
            sm: "repeat(1, minmax(0, 300px))",
            md: "repeat(1, minmax(0, 300px))",
          },
        }}
      >
        {filterData.map((field) => (
          <>
            <div key={field.filterId}>
              <FormControl fullWidth>
                <label
                  className="customLabel"
                  style={{
                    color: theme.palette.Labeltext.primary,
                  }}
                >
                  {field.filterName}
                </label>
                <Select
                  sx={{
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiNativeSelect-icon": {
                      fontSize: "35px !important",
                    },
                    background: theme.palette.accordiansBackground.primary,
                    color: theme.palette.textMainHeading.primary,
                  }}
                  className="customSelectBox"
                  // value={
                  //   formik.values?.otherFilters?.find(
                  //     (v) => v?.filterKey === field?.filterKey
                  //   )?.arFilterId || 1
                  // }
                  type="text"
                  onChange={(event) => handleSelectChange(event, field)}
                  // onBlur={handleBlur}
                  native
                >
                  {arFilterOpt.map((opt) => (
                    <>
                      <option
                        style={{
                          background: theme.palette.fieldsDDown.primary,
                        }}
                        key={opt.arFilterId}
                        value={opt.arFilterId}
                      >
                        {opt.arFilterName}
                      </option>
                    </>
                  ))}
                </Select>
              </FormControl>
              <Stack
                direction="row"
                alignItems="flex-end"
                gap="5px"
                marginTop={"9px"}
              >
                {/* {formik.values?.filterTypeId === 1 ? (
                formik.values?.otherFilters.find(
                  (v) => v?.filterKey === field?.filterKey
                )?.arFilterId === 2 ||
                formik.values?.otherFilters.find(
                  (v) => v?.filterKey === field?.filterKey
                )?.arFilterId === 3 ||
                formik.values?.otherFilters.find(
                  (v) => v?.filterKey === field?.filterKey
                )?.arFilterId === 4 ? (
                  <>
                    <input
                      type="number"
                      className="customDatePicker"
                      onChange={(e) => handleChangeValue(field, "value1", e)}
                      min={0}
                      value={
                        formik.values?.otherFilters?.find(
                          (v) => v?.filterKey === field?.filterKey
                        )?.value1
                      }
                    />
                  </>
                ) : formik.values.otherFilters.find(
                    (v) => v?.filterKey === field?.filterKey
                  )?.arFilterId === 5 ? (
                  <>
                    <input
                      type="number"
                      className="customDatePicker"
                      onChange={(e) => handleChangeValue(field, "value1", e)}
                      min={0}
                      value={
                        formik.values?.otherFilters?.find(
                          (v) => v?.filterKey === field?.filterKey
                        )?.value1
                      }
                    />
                    <input
                      type="number"
                      className="customDatePicker"
                      onChange={(e) => handleChangeValue(field, "value2", e)}
                      min={0}
                      value={
                        formik.values?.otherFilters?.find(
                          (v) => v?.filterKey === field?.filterKey
                        )?.value2
                      }
                    />
                  </>
                ) : (
                  ""
                )
              ) : formik.values?.otherFilters.find(
                  (v) => v?.filterKey === field?.filterKey
                )?.arFilterId === 2 ||
                formik.values?.otherFilters.find(
                  (v) => v?.filterKey === field?.filterKey
                )?.arFilterId === 3 ||
                formik.values?.otherFilters.find(
                  (v) => v?.filterKey === field?.filterKey
                )?.arFilterId === 4 ? (
                <>
                  <input
                    type="date"
                    className="customDatePicker"
                    onChange={(e) => handleChangeValue(field, "value1", e)}
                    value={
                      formik.values?.otherFilters?.find(
                        (v) => v?.filterKey === field?.filterKey
                      )?.value1
                    }
                  />
                </>
              ) : formik.values?.otherFilters.find(
                  (v) => v?.filterKey === field?.filterKey
                )?.arFilterId === 5 ? (
                <>
                  <input
                    type="date"
                    className="customDatePicker"
                    onChange={(e) => handleChangeValue(field, "value1", e)}
                    value={
                      formik.values?.otherFilters?.find(
                        (v) => v?.filterKey === field?.filterKey
                      )?.value1
                    }
                  />
                  <input
                    type="date"
                    className="customDatePicker"
                    onChange={(e) => handleChangeValue(field, "value2", e)}
                    value={
                      formik.values?.otherFilters?.find(
                        (v) => v?.filterKey === field?.filterKey
                      )?.value2
                    }
                  />
                </>
              ) : (
                ""
              )} */}
              </Stack>
            </div>
          </>
        ))}
      </Box>
      <Box>
        <Stack flexDirection={"column"} alignItems={"left"} marginLeft={"25px"}>
          <FormControlLabel
            label={"Include overdue payment plans"}
            control={
              <Radio
                value={"Include overdue payment plans"}
                variant="soft"
                name="radio-buttons"
                checked={formik.values.patientLastPayement}
                onChange={() =>
                  formik.setFieldValue(
                    "patientLastPayement",
                    !formik.values?.patientLastPayement
                  )
                }
              />
            }
          />
        </Stack>
      </Box>
    </>
  );
};
