import React, { useState } from "react";
import { postData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { toast } from "react-toastify";
import { Box, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { cptMasterCols } from "../../../utils/gridCols";
import CustomButton from "../../../components/CustomButton";

const CptMasterList = ({ cptMasterData, handleClose }) => {
  console.log(cptMasterData, "cptMasterData");
  const [selectedCode, setSelectedCode] = useState(null);
  const rows =
    cptMasterData && cptMasterData.length > 0
      ? cptMasterData?.map((el) => ({
          id: el?.cptCodeId,
          ...el,
        }))
      : [];

  const handleAddCpt = async () => {
    const selectedAllCpt = selectedCode?.map((code) => {
      const { cptCodeId, ...rest } = rows?.find((row) => row.id === code);
      return rest;
    });
    console.log(selectedAllCpt, "selectedAllCpt");
    const updatedCptStructure = selectedAllCpt?.map((el) => ({
      procedureCode: el?.cptCode,
      procedureSettingDetailDto: el?.cptProcedureSettingDetailDto,
      procedureModifierDetailDto: el?.cptProcedureModifierDetailDto,
      ...el,
    }));
    try {
      const response = await postData(
        `${path}/ct-procedureCodes`,
        updatedCptStructure
      );
      if (response) {
        console.log(response, "check hcpcs response");
        toast.success("CPT Code Added Successfully!");
        handleClose();
      }
    } catch (err) {
      console.log(err, "error in adding cpt code");
    }
  };

  return (
    <>
      <Box margin={'10px'}>
        <DataGrid
          rows={rows}
          columns={cptMasterCols}
          sx={{
            "& .header-bg": {
              backgroundColor: "lightgrey",
              color: "black",
              fontSize: "14px",
            },
            "& .cell-content": {
              fontSize: "12.5px",
            },
          }}
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSize={20}
          onSelectionModelChange={(newCode) => setSelectedCode(newCode)}
          components={{
            NoRowsOverlay: () => (
              <div
                style={{ width: "100%", textAlign: "center", padding: "16px" }}
              >
                {cptMasterData.length === 0 && "No Data Is Added"}
              </div>
            ),
          }}
        />

        <Stack direction="row" alignItems="center" padding="10px">
          <CustomButton isBlue={true} isAdd={true} handleClick={handleAddCpt}>
            Add
          </CustomButton>
        </Stack>
      </Box>
    </>
  );
};

export default CptMasterList;
