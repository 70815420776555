import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    // border: "0.5px solid black",
  },
  firstContainer: { marginTop: "8%" },
  secondContainer: { marginTop: "4%" },
  thirdContainer: { marginTop: "5%" },
  headerContentBox: {
    width: "49%",
  },
  childContainer: {
    width: "203%",
  },
  thirdchildContainer: {
    width: "100%",
  },

  secondaryText: {
    fontSize: "9pt",
    // color: "#808080",
    color: "#878080",
    wordSpacing: "1.5px",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
});
const data = [
  {
    id: 1,
    name: "PATIENT : DAHMS, ELIJAH ACCT# 56200548",
    prize1: 30,
    prize2: 13,
    prize3: 54,
  },
  {
    id: 2,
    name: "03-20-24 OFFICE O/P EST LOW 20-29 MIN",
    prize1: 25,
    prize2: 43,
    prize3: 12,
  },
  {
    id: 3,
    name: "04-19-24 PAYMENT FROM SELECTHEALTH",
    prize1: 40,
    prize2: 43,
    prize3: 23,
  },
  {
    id: 3,
    name: "04-19-24 ADJUSTMENT BY SELECTHEALTH",
    prize1: 40,
    prize2: 23,
    prize3: 12,
  },
];

const StatementBatchPdf = () => {
  return (
    <>
      <Document>
        <Page size="LETTER" style={{ padding: "12px" }}>
          <View style={[styles.body]}>
            <View style={styles.headerContentBox}>
              <View
                style={[
                  { marginLeft: "120%" },
                  styles.firstContainer,
                  styles.childContainer,
                ]}
              >
                <View>
                  <View style={[styles.flexRow]}>
                    <Text
                      style={[styles.secondaryText, { marginLeft: "40px" }]}
                    >
                      INVOICE# 1205527105
                    </Text>
                    <Text
                      style={[styles.secondaryText, { marginLeft: "20px" }]}
                    >
                      PAGE:1
                    </Text>
                  </View>

                  <Text style={[styles.secondaryText, { marginLeft: "20px" }]}>
                    ACCOUNT# OF GUARANTOR: 58868618
                  </Text>
                </View>
              </View>
              {/* 2nd row */}
              <View
                style={[
                  styles.flexRow,
                  { justifyContent: "space-between" },
                  styles.secondContainer,
                  styles.childContainer,
                ]}
              >
                <View>
                  <Text style={styles.secondaryText}>
                    PETTIT PEDIATRICS LLC
                  </Text>
                  <Text style={styles.secondaryText}>
                    1360{""} W{""} HIGHWAY{""} 40
                  </Text>
                  <Text style={styles.secondaryText}>
                    VERNAL, UT 84078-4203{" "}
                  </Text>
                </View>

                <View
                  style={{
                    marginRight: "5%",
                    width: "30%",
                  }}
                >
                  <Text style={[styles.secondaryText, { marginLeft: "50%" }]}>
                    DATE:{"   "} May-09-2024
                  </Text>
                  <Text
                    style={[
                      styles.secondaryText,
                      { marginLeft: "20px", marginTop: "7px" },
                    ]}
                  >
                    PATIENT ACCOUNT# 58868618
                  </Text>
                  <Text style={[styles.secondaryText, { marginLeft: "20px" }]}>
                    PATIENT: STEELE, CHELSEA
                  </Text>
                  <Text style={[styles.secondaryText, { marginLeft: "20px" }]}>
                    DATE OF SERVICE: 02-22-24
                  </Text>
                </View>
              </View>

              {/* 3nd row */}
              <View
                style={[
                  styles.thirdContainer,
                  styles.thirdchildContainer,
                  { marginLeft: "120%" },
                ]}
              >
                <View style={[styles.flexRow]}>
                  <Text style={styles.secondaryText}>Signature {"  "}</Text>
                  <Text
                    style={[
                      styles.secondaryText,
                      {
                        borderBottom: "1px solid #878080",
                        width: "70%",
                      },
                    ]}
                  ></Text>
                </View>
                <Text style={styles.secondaryText}>Exp.Date _ _/_ _</Text>

                <View style={[styles.flexRow]}>
                  <Text style={styles.secondaryText}>CC#</Text>
                  <Text
                    style={[
                      styles.secondaryText,
                      {
                        borderBottom: "1px solid #878080",
                        width: "63%",
                      },
                    ]}
                  ></Text>
                  <Text style={styles.secondaryText}>CID#</Text>
                  <Text
                    style={[
                      styles.secondaryText,
                      {
                        borderBottom: "1px solid #878080",
                        width: "10%",
                      },
                    ]}
                  ></Text>
                </View>

                <View style={[styles.flexRow, { width: "500%" }]}>
                  <Text
                    style={[
                      styles.secondaryText,
                      {
                        borderBottom: "1px solid #878080",
                        width: "1%",
                      },
                    ]}
                  ></Text>
                  <Text style={styles.secondaryText}>VISA</Text>
                  <Text
                    style={[
                      styles.secondaryText,
                      {
                        borderBottom: "1px solid #878080",
                        width: "1%",
                      },
                    ]}
                  ></Text>
                  <Text style={styles.secondaryText}>MC</Text>
                  <Text
                    style={[
                      styles.secondaryText,
                      {
                        borderBottom: "1px solid #878080",
                        width: "1%",
                      },
                    ]}
                  ></Text>
                  <Text style={styles.secondaryText}> DISC</Text>
                  <Text
                    style={[
                      styles.secondaryText,
                      {
                        borderBottom: "1px solid #878080",
                        width: "1%",
                      },
                    ]}
                  ></Text>
                  <Text style={styles.secondaryText}>AMEX</Text>

                  <Text
                    style={[
                      styles.secondaryText,
                      {
                        borderBottom: "1px solid #878080",
                        width: "1%",
                        marginLeft: "1.6%",
                      },
                    ]}
                  ></Text>
                  <Text style={styles.secondaryText}>CHECK #</Text>
                  <Text
                    style={[
                      styles.secondaryText,
                      {
                        borderBottom: "1px solid #878080",
                        width: "2%",
                      },
                    ]}
                  ></Text>
                </View>
              </View>

              {/* 4th row */}
              <View
                style={[
                  styles.flexRow,
                  { justifyContent: "space-between" },
                  styles.secondContainer,
                  styles.childContainer,
                ]}
              >
                <View>
                  <Text style={styles.secondaryText}>ELIJAH{""} DAHMS </Text>
                  <Text style={styles.secondaryText}>
                    3004{""} E{""} 5000{""} S
                  </Text>
                  <Text style={styles.secondaryText}>
                    VERNAL,{""} UT{""} 84078{" "}
                  </Text>
                </View>

                <View style={{ marginRight: "11%"}}>
                  <Text style={[styles.secondaryText, { marginRight: "30px" }]}>
                    PAY{""} TO: PETTIT{""} PEDIATRICS{""} LLC
                  </Text>
                  <Text style={[styles.secondaryText, { marginLeft: "40px" }]}>
                    1360{""} W{""} HIGHWAY{""} 40
                  </Text>
                  <Text style={[styles.secondaryText, { marginLeft: "40px" }]}>
                    VERNAL,{""} UT {""} 84078-4203
                  </Text>
                </View>
              </View>

              {/* 5TH Row */}
              <View
                style={[
                  styles.flexRow,
                  {
                    width: "200%",
                    justifyContent: "center",
                    marginTop: "30px",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.secondaryText,
                    {
                      borderBottom: "1px dotted #878080",
                      width: "33%",
                    },
                  ]}
                ></Text>
                <Text style={styles.secondaryText}>
                  Cut Here & Return Above Portion With Payment
                </Text>
                <Text
                  style={[
                    styles.secondaryText,
                    {
                      borderBottom: "1px dotted #878080",
                      width: "33%",
                    },
                  ]}
                ></Text>
              </View>

              {/* 6th Row */}
              <View
                style={[styles.flexRow, { width: "200%", marginTop: "10px" }]}
              >
                <Text style={[styles.secondaryText]}>DATE</Text>
                <Text style={[styles.secondaryText, { marginLeft: "20px" }]}>
                  DESCRIPTION
                </Text>
                <Text style={[styles.secondaryText, { marginLeft: "300px" }]}>
                  CHARGES
                </Text>
                {/* <Text
                    style={[
                      styles.secondaryText,
                      {marginLeft:'8px'}
                    ]}
                  >PAYMENTS/ADJUSTMENTS</Text> */}
                <View style={{ flexDirection: "column", marginLeft: "8px" }}>
                  <Text style={[styles.secondaryText, { marginLeft: "6px" }]}>
                    PAYMENTS/
                  </Text>
                  <Text style={[styles.secondaryText, { marginLeft: "8px" }]}>
                    ADJUSTMENTS
                  </Text>
                </View>

                <Text style={[styles.secondaryText, { marginLeft: "8px" }]}>
                  REPLAY
                </Text>
              </View>

              {/* 7th Row */}
              <View style={[{ width: "200%", marginTop: "3%" }]}>
                <Text
                  style={[
                    styles.secondaryText,
                    {
                      borderBottom: "1px solid #878080",
                      width: "99%",
                    },
                  ]}
                ></Text>
              </View>

              {/* 8TH ROW */}
              <View style={[styles.flexRow, { width: "200%" }]}>
                <Text style={[styles.secondaryText]}>
                  PATIENT :{""} DAHMS, {""}ELIJAH {""}ACCT#{""} 56200548
                </Text>
              </View>

              {/* 9TH Row */}
              <View
                style={[styles.flexRow, { width: "200%", marginTop: "4%" }]}
              >
                <Text style={[styles.secondaryText]}>
                  03-20-24{"  "} OFFICE O/P EST LOW 20-29 MIN
                </Text>
                <Text style={[styles.secondaryText, { marginLeft: "41%" }]}>
                  $155.00
                </Text>
                <Text style={[styles.secondaryText, { marginLeft: "17%" }]}>
                  $155.00
                </Text>
              </View>

              {/* 10th Row */}
              <View style={[styles.flexRow, { width: "200%" }]}>
                <Text style={[styles.secondaryText]}>
                  04-19-24 {"  "} PAYMENT FROM SELECTHEALTH
                </Text>
                <Text style={[styles.secondaryText, { marginLeft: "50%" }]}>
                  $0.00
                </Text>
                <Text style={[styles.secondaryText, { marginLeft: "8%" }]}>
                  $155.00
                </Text>
              </View>

              {/* 11th Row */}
              <View style={[styles.flexRow, { width: "200%" }]}>
                <Text style={[styles.secondaryText]}>
                  04-19-24 {"  "} ADJUSTMENT BY SELECTHEALTH
                </Text>
                <Text style={[styles.secondaryText, { marginLeft: "50%" }]}>
                  $0.00
                </Text>
                <Text style={[styles.secondaryText, { marginLeft: "8%" }]}>
                  $155.00
                </Text>
              </View>

              {/* 12th Row */}
              <View
                style={[styles.flexRow, { width: "200%", marginTop: "4%" }]}
              >
                <Text style={[styles.secondaryText]}>
                  04-19-24 {"  "} ACCOUNT {""}CREDIT{""} -{""} PATIENT{""} COPAY
                  {""} -{""}VISA
                </Text>
                <Text style={[styles.secondaryText, { marginLeft: "41%" }]}>
                  $0.00
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default StatementBatchPdf;