import React from "react";
import { useSelector } from "react-redux";
import { getAdjustmentAction } from "../../../features/actions/adjustmentAction";
import { Box, Stack } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router";
import { getAdjustmentBySearchTermAction } from "../../../features/actions/adjustmentAction";
import CustomPreview from "../../../layout/CustomPreview";
import { adjustmentCodeCols } from "./../../../utils/gridCols";

const Adjustments = () => {
  const navigate = useNavigate();
  const { getAdjustmentCodes, loading } = useSelector(
    (state) => state.adjustments
  );

  const rows =
    getAdjustmentCodes && getAdjustmentCodes.result
      ? getAdjustmentCodes.result.map((item) => ({
          id: item.adjustmentCodeId,
          ...item,
        }))
      : [];
  return (
    <>
      <Box>
        <CustomPreview
          rows={rows}
          columns={adjustmentCodeCols}
          loading={loading}
          handleGetAll={getAdjustmentAction}
          handleBySearch={getAdjustmentBySearchTermAction}
          searchLabel="Search by Code,or Description"
          moduleHeading="All Adjustment Codes"
          handleCell={(params) => navigate(`/adjustment/update/${params.id}`)}
        >
          <Stack direction="row" alignItems="center">
            <CustomButton
              isAdd={true}
              isBlue={true}
              handleClick={() => navigate("/adjustment/new")}
            >
              Add Adjustment
            </CustomButton>
          </Stack>
        </CustomPreview>
      </Box>
    </>
  );
};

export default Adjustments;
