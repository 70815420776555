import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

const ChargeDetails = ({ chargeDetailData }) => {
  console.log(chargeDetailData, "chargeDetailData2");
  const theme = useTheme();
  return (
    <Box>
      <Typography
        sx={{
          background: theme.palette.saveBtn.primary,
          color: theme.palette.btnSaveText.primary,
          padding: "5px",
        }}
        component="h2"
      >
        Charge Details
      </Typography>

      {chargeDetailData === undefined ? (
        <Typography>Select a charge to view</Typography>
      ) : (
        chargeDetailData?.map((item, i) => (
          <>
            <Card sx={{ minWidth: 275, marginBottom: "10px" }}>
              <CardContent>
                {Object.entries(item).map(([key, value]) => {
                  return (
                    <>
                      <Stack direction="row" alignItems="flex-start" gap={2}>
                        <Typography component="h4" fontWeight={700}>
                          {key.split(/(?=[A-Z])/).join(" ").toUpperCase()}:
                        </Typography>
                        {Array.isArray(value) ? (
                          <Typography component="h4">
                            {value
                              .map(
                                (nestedItem) =>
                                  nestedItem.diagnosesCode ||
                                  nestedItem.mod_Code
                              )
                              .join(", ")}
                          </Typography>
                        ) : (
                          <Typography component="h4">{value}</Typography>
                        )}
                      </Stack>
                    </>
                  );
                })}
              </CardContent>
            </Card>
          </>
        ))
      )}
    </Box>
  );
};

export default ChargeDetails;