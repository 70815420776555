import { PDFViewer } from "@react-pdf/renderer";
import React from "react";
import CmsForm15 from "./CmsForm15";
import CmsUB04 from "./CmsUB04";
import { useLocation } from "react-router-dom";
const PreviewPdf = () => {
  const location = useLocation();
  console.log("location.state.pdfType", location.state.pdfType);
  return (
    <>
      <PDFViewer width="100%" height="100%">
        {location.state.pdfType === "CmsForm15" ? (
          <CmsForm15 data={location.state.pdfData} />
        ) : (
          <CmsUB04 />
        )}
      </PDFViewer>
    </>
  );
};

export default PreviewPdf;
