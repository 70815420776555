import React, { useEffect, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import CustomDataGrid from "../components/CustomDataGrid";
import MainHeading from "../components/MainHeading";
import CustomField from "../components/CustomField";
import CustomButton from "../components/CustomButton";
import { useDispatch } from "react-redux";
import Loading from "../components/Loading";
import { DataGrid } from "@mui/x-data-grid";

const MultiSelectCustomPreview = ({
  children,
  paginationDetail,
  state,
  rows,
  columns,
  handleCell,
  moduleHeading,
  searchLabel,
  handleBySearch,
  handleGetAll,
  isModal = false,
  setState,
  loading,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const reducedCellHeight = 35;
  const [searchTerm, setSearchTerm] = useState(null);
  const pageSize = 20;
  const [page, setPage] = useState(0);

  /// for page change
  const handlePageChange = (newPage) => {
    console.log("state", state);
    setPage(newPage);
  };

  // Handler for search button click
  const handleSearchChange = () => {
    try {
      if (searchTerm) {
        dispatch(handleBySearch({ searchTerm: searchTerm, page: page + 1 }));
      } else {
        dispatch(handleGetAll(page + 1));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Perform search or get all when searchTerm or page changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!searchTerm) {
          dispatch(handleGetAll(page + 1));
        } else {
        }
      } catch (err) {
        console.log("err", err);
      }
    };

    fetchData();
  }, [searchTerm, page]);

  // Perform initial fetch on component mount
  useEffect(() => {
    dispatch(handleGetAll(page + 1));
  }, [page]);

  return (
    <>
      <Box margin="15px">
        <Grid container rowSpacing={2}>
          {!isModal && (
            <Grid item md={12} sm={12} xs={12}>
              <MainHeading mainheading={moduleHeading} />
            </Grid>
          )}

          {children && (
            <Grid item md={12}>
              {children}
            </Grid>
          )}

          <Grid item md={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                margin: "3px",
              }}
            >
              <CustomField
                type="text"
                size="small"
                label={searchLabel}
                value={searchTerm}
                name="searchTerm"
                handleChange={(e) => setSearchTerm(e.target.value)}
              />
              <CustomButton
                type="button"
                handleClick={handleSearchChange}
                isBlue={true}
                padding="5px 30px"
                margin={"0 10px 0"}
                isSearch={true}
              >
                Search
              </CustomButton>
            </Box>
          </Grid>

          <Grid item md={12} xs={12} sm={12}>
            {loading ? (
              <Loading />
            ) : (
              <Box>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  sx={{
                    "& .header-bg": {
                      background: theme.palette.dataGridHeading.primary,
                      color: theme.palette.textMainHeading.primary,
                      fontSize: "16px",
                    },
                    "& .cell-content": {
                      fontSize: "1.2rem",
                    },
                    "& .MuiTablePagination-displayedRows": {
                      fontSize: "1.3rem",
                    },
                    "& .MuiTablePagination-actions": {
                      "& svg": {
                        fontSize: "1.8rem",
                        color: theme.palette.textMainHeading.primary,
                        marginBottom: "8px",
                      },
                      "& .MuiDataGrid-row": {
                        backgroundColor: theme.palette.textMainHeading.primary,
                      },
                    },
                  }}
                  pagination
                  // keepNonExistentRowsSelected
                  pageSize={paginationDetail?.result?.pageSize}
                  paginationMode="server"
                  page={page}
                  rowCount={paginationDetail?.result?.totalCount}
                  onPageChange={handlePageChange}
                  checkboxSelection={true}
                  disableRowSelectionOnClick={true}
                  selectionModel={state}
                  showCellRightBorder={true}
                  autoHeight
                  rowHeight={reducedCellHeight}
                  disableSelectionOnClick
                  onSelectionModelChange={(newRow) => setState(newRow)}
                  components={{
                    NoRowsOverlay: () => (
                      <h3
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "16px",
                        }}
                      >
                        {rows?.length === 0 && "No Data Is Added"}
                      </h3>
                    ),
                  }}
                />
                <Box
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    background: theme.palette.multiCustomPreview.primary,
                    padding: "4px",
                    zIndex: 1,
                  }}
                >
                  <CustomButton
                    handleClick={() => {
                      const selectedRows = state?.map((rowId) =>
                        rows?.find((el) => el.id === rowId)
                      );
                      handleCell(selectedRows);
                    }}
                    type="button"
                    isBlue={true}
                    isAdd={true}
                  >
                    Add
                  </CustomButton>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MultiSelectCustomPreview;
