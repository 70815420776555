import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomPreview from "./../../../layout/CustomPreview";
import { feeSchedulesCols } from "../../../utils/gridCols";
import { getFeeScheduleAction } from "../../../features/actions/feeSchedulesAction";
import CustomModal from "../../../components/CustomModal";
import CustomNewFeeScheduleFunction from "./CustomNewFeeScheduleFunction";
import { feeScheduleVal } from "../../../utils/formikInitValues";
import { useFormik } from "formik";

const FeeSchedules = () => {
  const formik = useFormik({
    initialValues: feeScheduleVal,
    onSubmit: (values, action) => {
      console.log(values, "checkFeeSchedule");
    },
  });

    const [openNewFeeScheduleModal,setOpenNewFeeScheduleModal]=useState(false);
  const navigate = useNavigate();
  const {getFeeScheduleData, loading } = useSelector((state) => state.feeSchedule);
 console.log(getFeeScheduleData, "feeScheduleData")
  // rows
  const rows =
  getFeeScheduleData?.result && getFeeScheduleData.result?.length > 0
      ? getFeeScheduleData.result?.map((el) => ({
          id: el.feeSchedulesId,
          feeSchedulesName: el.feeSchedulesName,
          effectiveFrom: el.effectiveFrom,
          effectiveTo: el.effectiveTo,
          isActive: el.isActive,
        }))
      : [];
  return (
    <>
    <Box>
      <CustomPreview
        rows={rows}
        columns={feeSchedulesCols}
        handleGetAll={getFeeScheduleAction}
        // handleBySearch={getProcedureCodesBySearchTermAction}
        moduleHeading={'Fee Schedules'}
        // handleCell={(params) => navigate(`/facility/update/${params?.id}`)}
        searchLabel="Search by First Name, Phone ,or Sequence Number"
      >
        <Stack direction="row" alignItems="center">
          <CustomButton
            isAdd={true}
            isBlue={true}
            handleClick={() => setOpenNewFeeScheduleModal(true)}
          >
         New Fee Schedule
          </CustomButton>
        </Stack>
      </CustomPreview>
    </Box>

    {/* modals here */}
    <CustomModal open={openNewFeeScheduleModal} handleClose={() => setOpenNewFeeScheduleModal(false)} heading='New   Fee  Schedule' height='20px'>
     <CustomNewFeeScheduleFunction formik={formik} />
      </CustomModal>
    </>
  );
};

export default FeeSchedules;





