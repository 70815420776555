import { Box, FormControl, Stack, useTheme } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { pdf } from "@react-pdf/renderer";
import { DataGrid } from "@mui/x-data-grid";
import { batchStateSearchedCols } from "../../../utils/gridCols";
import StatementBatchPdf from "../../../components/pdfs/StatementBatchPdf";

const BatchSearchedData = ({ handleClose, searchedData }) => {
  const theme = useTheme();
  const [selectedRows, setSelectedRows] = useState([]);
  console.log(selectedRows, "checlSel78");
  const rows =
    searchedData && searchedData?.length > 0
      ? searchedData?.map((el, index) => ({
          id: index,
          ...el,
        }))
      : [];
const handlePrintClick = async ()=>{
  const pdfBlob = await pdf(
      // <CmsForm15 data={pdfData} />    
      <StatementBatchPdf/>
  ).toBlob();
  console.log(pdfBlob, "checkPdfBlob")
  const pdfUrl = URL.createObjectURL(pdfBlob);
  window.open(pdfUrl, "_blank"); 
}
  return (
    <Box margin="15px">
      <Stack direction="row" alignItems="center" gap={2} marginBottom="15px">
        <CustomButton
          children="Close"
          isCancel={true}
          handleClick={handleClose}
          type="button"
          isBlue={true}
        />
        <CustomButton children="Print" handleClick={handlePrintClick} />
      </Stack>

      <Box minHeight="200px" width="100%">
        <DataGrid
          rows={rows}
          columns={batchStateSearchedCols}
          autoHeight
          checkboxSelection
          onSelectionModelChange={(newRow) => setSelectedRows(newRow)}
          disableSelectionOnClick
          rowHeight={35}
          showCellRightBorder
          sx={{
            "& .header-bg": {
              background: theme.palette.dataGridHeading.primary,
              color: theme.palette.textMainHeading.primary,
              fontSize: "16px",
            },
            "& .cell-content": {
              fontSize: "1.2rem",
            },
            "& .MuiTablePagination-displayedRows": {
              fontSize: "1.3rem",
            },
            "& .MuiTablePagination-actions": {
              "& svg": {
                fontSize: "1.8rem",
                color: theme.palette.textMainHeading.primary,
                marginBottom: "8px",
              },
            },
          }}
          components={{
            NoRowsOverlay: () => (
              <div
                style={{ width: "100%", textAlign: "center", padding: "16px" }}
              >
                {rows?.length === 0 && "No Data Is Added"}
              </div>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default BatchSearchedData;
