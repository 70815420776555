
// import React from 'react';
// import { useState } from 'react';
// import { Typography } from '@mui/material';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';

// ChartJS.register(
//   CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement
// );
// const options = {
//   indexAxis: 'x',
//   elements: {
//     bar: {
//       borderWidth: 2,
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//         position:'left'
//     },
//     title: {
//       display: true,
    
//     },
//   },
// };
// const LineChart = () => {
//   const [data, setData] =useState({
//     labels: ['Sun', 'Mon', 'Tues', 'Wed', 'Thur'],
//     datasets:[{

//       label:"first data",
//       data:[10,20,30,40,50],
//       backgroundColor:'blue',
//       tension:0.4,
//       fill:true,
//     }
//     ]
//   })
//   return (
//     <>
//         <Card sx={{ width: '100%',height: 400 }}>
//         <CardContent>
//       <Typography>Claim Summary</Typography>
//      <Line data={data} options={options}>Line chart</Line>  
//      </CardContent>
//       </Card>
//     </>
//   );
// };

// export default LineChart;






// import React from 'react';
// import { useState } from 'react';
// import { Typography, useTheme } from '@mui/material';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';

// ChartJS.register(
//   CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement
// );
// const options = {
//   type: 'line',
//   indexAxis: 'x',
//   elements: {
//     bar: {
//       borderWidth: 2,
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//         position:'left'
//     },
//     title: {
//       display: true,
    
//     },
//   },
// };
// const LineChart = () => {
//   const theme=useTheme();
//   const [data, setData] =useState({
//     labels: ['Sun', 'Mon', 'Tues', 'Wed', 'Thur'],
//     datasets:[{

//       label:"first data",
//       data:[20,12,40,35,50],
//       backgroundColor:'blue',
//       tension:0.4,
//       fill:true,
//     }
//     ]
//   })
//   return (
//     <>
//         <Card sx={{ width: '100%',
//         height: 320,background:theme.palette.announcementCard.primary,
//          }}>
//         <CardContent>
//       <Typography sx={{color:theme.palette.globalText.primary}}>Claim Summary</Typography>
//      <Line  data={data} options={options}>Line chart</Line>  
//      </CardContent>
//       </Card>
//     </>
//   );
// };

// export default LineChart;





import React from 'react';
import { useState,useEffect } from 'react';
import { Typography, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement } from 'chart.js';

ChartJS.register(
  CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement
);
const getOptions=(theme)=>({
  type: 'line',
  indexAxis: 'x',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
        position:'left',
        labels: {
          color: theme.palette.patientsHeading.primary, 
        },
    },
    title: {
      display: true,
      text: 'Claim Summary',
      color: theme.palette.patientsHeading.primary, 
    },
  },

  scales: {
    x: { 
      ticks: {
        color: theme.palette.patientsHeading.primary, 

       
      },
      grid: {
        color: theme.palette.chartGrid.primary, 
        borderColor: theme.palette.chartGrid.primary, 
      },
      // title: {
      //   display: true,
      //   color: theme.palette.patientsHeading.primary, 
      // }
    },
    y: { 
      ticks: {
        color: theme.palette.patientsHeading.primary, 
 
      },
      grid: {
        color: theme.palette.chartGrid.primary, 
        borderColor: theme.palette.chartGrid.primary, 
      },
      // title: {
      //   display: true,
      //   color: theme.palette.patientsHeading.primary, 
      // }
    }
  },
});
const LineChart = ({data}) => {
  const theme=useTheme();
  const options= getOptions(theme);
  const [chartData, setChartData] =useState({
    labels: [],
    datasets:[{

      label:"Claims",
     
      data:[],

    
      backgroundColor: '#7b8be7',
      tension:0.4,
      fill:true,
    }
    ]
  })

  useEffect(() => {
    console.log("Data received in LineChart:", data);
    if (data && data.length > 0 && data[0].monthlyClaimSummaryDto) {
      const { monthNames, claimCount } = data[0].monthlyClaimSummaryDto;
      setChartData({
        labels: monthNames,
        datasets: [
          {
            label: 'Claims',
            data: claimCount,
        backgroundColor:'#7b8be7' 
          },
        ],
      });
    }
  }, [data]);

  return (
    <>
        <Card sx={{ width:'100%',
    height: { xs: '500px', sm: '500px', md: '500px',lg:'320px' },
    // height: '300px',
     background:theme.palette.announcementCard.primary,
         }}>
        <CardContent>
     <Line  data={chartData} options={options}>Line chart</Line>  
     </CardContent>
      </Card>
    </>
  );
};

export default LineChart;




