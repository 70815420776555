import {
  Box,
  FormControl,
  Grid,
  TextareaAutosize,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomField from "../../../components/CustomField";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomSelectBox2 from "./../../../components/CustomSelectBox2";
import CheckBox from "../../../components/CheckBox";
import CustomSearchField from "./../../../components/CustomSearchField";
import CustomDatePicker from "./../../../components/CustomDatePicker";
import CustomModal from "../../../components/CustomModal";
import Revenue from "./../revenue/Revenue";
import PosCodes from "./../pos-codes/PosCodes";
import TosCodes from "./../tos-codes/TosCodes";
import CustomPreview from "../../../layout/CustomPreview";
import { revenueCodesCols } from "../../../utils/gridCols";
import { getRevenueAction } from "../../../features/actions/revenueAction";
import { getRevenueCodeBySearchTermAction } from "../../../features/actions/revenueAction";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const ProcedureInfo = ({ formik }) => {
  const navigate = useNavigate();
  const theme=useTheme()
  const { getRevenueData, loading } = useSelector((state) => state.revenue);
  const [procedureTypeOpt, setProcedureTypeOpt] = useState([]);
  const [codeSettingsOpt, setCodeSettingsOpt] = useState([]);
  const [claimStatusOpt, setClaimStatusOpt] = useState([]);
  const [drugMeasureOpt, setDrugMeasureOpt] = useState([]);
  const [drugCodeFormatOpt, setDrugCodeFormat] = useState([]);
  const [globalPeriodOpt, setGlobalPeriodOp] = useState([]);
  const [openRevModal, setOpenRevModal] = useState(false);
  const [openPosModal, setOpenPosModal] = useState(false);
  const [openTosModal, setOpenTosModal] = useState(false);
  const [openModifierModal, setOpenModifierModal] = useState(false);

  const dataFetchUrls = {
    procedureTypes: `${path}/ct-procedureTypeCode`,
    codeSettings: `${path}/ct-procedureCodeSetting`,
    claimStatus: `${path}/ct-claimStatus`,
    drugMeasure: `${path}/ct-drugUnitMeasure`,
    drugFormat: `${path}/ct-drugCodeFormat`,
    globalPeriod: `${path}/ct-globalPeriod`,
  };

  // Define a reusable function to fetch data for a given URL
  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.procedureTypes, setProcedureTypeOpt);
    fetchDataOptions(dataFetchUrls.codeSettings, setCodeSettingsOpt);
    fetchDataOptions(dataFetchUrls.claimStatus, setClaimStatusOpt);
    fetchDataOptions(dataFetchUrls.drugMeasure, setDrugMeasureOpt);
    fetchDataOptions(dataFetchUrls.drugFormat, setDrugCodeFormat);
    fetchDataOptions(dataFetchUrls.globalPeriod, setGlobalPeriodOp);
  }, []);

  console.log(
    formik.values.procedureSettingDetailDto,
    "procedureSettingDetailDto"
  );
  const handleCodeSettingChange = (checkVal) => {
    const checkExistingValues = [...formik.values.procedureSettingDetailDto];
    const findCodeInd = checkExistingValues.findIndex(
      (v) => v?.procedureCodeSettingId === checkVal?.procedureCodeSettingId
    );
    if (findCodeInd !== -1) {
      checkExistingValues.splice(findCodeInd, 1);
    } else {
      if (checkExistingValues.length < 2) {
        checkExistingValues.push(checkVal);
      }
    }

    formik.setFieldValue("procedureSettingDetailDto", checkExistingValues);
  };

  // set revenues
  const handleRevenue = (params) => {
    formik.setFieldValue("revenueCodeId", params?.id);
    formik.setFieldValue("revenueCode", params?.revenueCode);
    setOpenRevModal(false);
  };
  // set pos codes
  const handleSelectPos = (params) => {
    formik.setFieldValue("posCodeId", params?.id);
    formik.setFieldValue("posCode", params?.posCode);
    setOpenPosModal(false);
  };
  // set tos codes
  const handleSelectTos = (params) => {
    console.log("tosCodes12 : ", params);
    formik.setFieldValue("tosCodeId", params?.id);
    formik.setFieldValue("tosCode", params?.tosCode);
    setOpenTosModal(false);
  };

  console.log(formik.values.procedureSettingDetailDto, "checkboxDto");
  const findCheckBox = formik.values.procedureSettingDetailDto?.find(
    (el) => el?.procedureCodeSettingId === 3
  );

  return (
    <>
    <Box sx={{background:theme.palette.accordiansBodyBackground.primary,borderRadius:"10px",padding:"15px",margin:"0 0 20px"}}>
      <Grid container spacing={2}>
        <Grid item md={4} sm={12} xs={12}>
          <CustomField
            type="text"
            label="Code"
            isRequired={true}
            error={formik.errors}
            touched={formik.touched}
            value={formik.values.procedureCode}
            name="procedureCode"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <CustomSelectBox2
            label="Type"
            value={formik.values.procedureCodeTypeId}
            name="procedureCodeTypeId"
            dropdownOptions={procedureTypeOpt.map((opt) => ({
              value: opt.procedureCodeType,
              id: opt.procedureCodeTypeId,
            }))}
            formik={formik}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <CustomField
            type="text"
            label="Dept"
            value={formik.values.procedureDepartment}
            name="procedureDepartment"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <FormControl fullWidth>
            <TextareaAutosize
              style={{ fontSize: "1.3rem", background:theme.palette.accordiansBodyBackground.primary,
              color:theme.palette.btnCancelText.primary}}
              minRows={4}
              value={formik.values.procedureDescription}
              name="procedureDescription"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Description"
            />
            {formik.errors["procedureDescription"] &&
              formik.touched["procedureDescription"] && (
                <div
                  style={{
                    color: theme.palette.errorFieldsBorder.primary,
                    border: `2px solid ${theme.palette.errorFieldsBorder.primary}`,
                    fontSize: "1.3rem",
                    marginTop: "8px",
                    display: "block",
                  }}
                >
                  {formik.errors["procedureDescription"]}
                </div>
              )}
          </FormControl>
        </Grid>
        <Grid md={12} sx={{ margin: "5px 20px 0" }}>
          <Typography component="h3">Claim Defaults</Typography>
          {/* <Grid md={12}> */}
          {codeSettingsOpt.map((opt) => (
            <CheckBox
              key={opt.procedureCodeSettingId}
              label={opt.procedureCodeSetting}
              handleChange={() => handleCodeSettingChange(opt)}
              CheckedValue={formik.values.procedureSettingDetailDto?.some(
                (v) => v?.procedureCodeSettingId === opt?.procedureCodeSettingId
              )}
            />
          ))}
        </Grid>

        {findCheckBox ? (
          <Grid item md={6} sm={12} xs={12}>
            <CustomField label="Default Percent" />
          </Grid>
        ) : (
          <>
            <Grid item md={3} sm={12} xs={12}>
              <CustomField
                type="number"
                label="Default Price"
                value={formik.values.price}
                name="price"
                isDecimal={true}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <CustomField
                type="number"
                label="Default Units"
                value={formik.values.units}
                name="units"
                isDecimal={true}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
          </>
        )}
        {/* <Grid item md={3}>
          <CustomField
            type="number"
            label="Default Price"
            value={formik.values.price}
            name="price"
            isDecimal={true}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={3}>
          <CustomField
            type="number"
            label="Default Units"
            value={formik.values.units}
            name="units"
            isDecimal={true}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid> */}

        <Grid item md={6} sm={12} xs={12}>
          <CustomSelectBox2
            label="Default Charge Status"
            value={formik.values.claimStatusId}
            name="claimStatusId"
            formik={formik}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            dropdownOptions={claimStatusOpt.map((opt) => ({
              value: opt.claimStatusType,
              id: opt.claimStatusId,
            }))}
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <CustomSearchField
            fieldVal={formik.values.revenueCode}
            name="revenueCode"
            label="Rev Code"
            handleModalOpen={() => setOpenRevModal(true)}
            formik={formik}
            resetField={{
              revenueCode:""
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <CustomSearchField
            label="Place of Service"
            handleModalOpen={() => setOpenPosModal(true)}
            fieldVal={formik.values.posCode}
            name="posCode"
            formik={formik}
            resetField={{
              posCode:""
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12} className="mt-2" >
          <CustomField
            label="CLIA Number"
            type="text"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.cliA_No}
            name="cliA_No"
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <CustomSearchField
            label="Type of Service"
            handleModalOpen={() => setOpenTosModal(true)}
            fieldVal={formik.values.tosCode}
            name="tosCode"
            formik={formik}
            resetField={{
              tosCode:""
            }}
          />
        </Grid>


        <Grid item md={12} sm={12} xs={12}>
          <CustomField
            label="Narratives Notes"
            type="text"
            value={formik.values.narrativeNotes}
            name="narrativeNotes"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
<Grid md={12}>
<Typography component="h3" sx={{ margin: "15px 20px 0" }} >
          Modifiers (Global & Situational)
        </Typography>
</Grid>
          {[1, 2, 3, 4].map((opt, ind) => (
            <>
              <Grid key={ind} item md={3} sm={12} xs={12}>
                <CustomSearchField
                  label={`Global ${opt}`}
                  handleModalOpen={() => setOpenModifierModal(true)}
                />
              </Grid>
            </>
          ))}
          <Grid md={12} sm={12} xs={12}>
          <Typography sx={{ margin: "14px 18px 0" }} component="h3">
            Drug Information
          </Typography>
          </Grid>

            <Grid item md={3} sm={12} xs={12}>
              <CustomField
                label="Drug Price"
                type="number"
                value={formik.values.drugPrice}
                name="drugPrice"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <CustomField
                label="Drug Units"
                type="number"
                value={formik.values.drugUnits}
                name="drugUnits"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <CustomSelectBox2
                label="Drug Units Measure"
                value={formik.values.drugUnitMeasureId}
                name="drugUnitMeasureId"
                formik={formik}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                dropdownOptions={drugMeasureOpt.map((opt) => ({
                  value: opt.drugUnit,
                  id: opt.drugUnitMeasureId,
                }))}
              />
            </Grid>

            <Grid item md={4} sm={12} xs={12}>
              <CustomField
                type="number"
                label="Drug Code"
                value={formik.values.drugCode}
                name="drugCode"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <CustomSelectBox2
                label="Drug Code Format"
                value={formik.values.drugUnitFormatId}
                name="drugUnitFormatId"
                formik={formik}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                dropdownOptions={drugCodeFormatOpt.map((opt) => ({
                  value: opt.drugCodeFormat,
                  id: opt.ctDrugCodeFormatId,
                }))}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <CustomField
                type="text"
                label="Additional Descripion (for non-specific procedure codes)"
                value={formik.values.additionalDescription}
                name="additionalDescription"
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <CustomSelectBox2
                label="Global Period"
                value={formik.values.globalPeriodId}
                name="globalPeriodId"
                formik={formik}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                dropdownOptions={globalPeriodOpt.map((opt) => ({
                  value: opt.globalPeriodDays,
                  id: opt.globalPeriodId,
                }))}
              />
            </Grid>
<Grid md={12} sm={12} xs={12}>
          <Typography sx={{ margin: "14px 18px 0" }} component="h3">
            Effective/Termination Dates
          </Typography>
          </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <CustomDatePicker
                formik={formik}
                dateValue={formik.values.effectiveDates}
                name="effectiveDates"
                dateLabel="Effective Date"
                handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <CustomDatePicker
                formik={formik}
                dateValue={formik.values.terminationDates}
                name="terminationDates"
                dateLabel="Termination Date"
                handleDateChange={formik.handleChange}
                handleDateBlur={formik.handleBlur}
              />
            </Grid>
        {/* superbill options  */}
        <Grid item md={12} sm={12} xs={12}>
          <Typography component="h3">Superbill Options</Typography>

          <FormControl fullWidth>
            <CheckBox
              label="Print this code on superbills"
              // handleChange={() =>
              //   formik.setFieldValue("isSuperBill", !formik.values.isSuperBill)
              // }

              handleChange={() =>
                formik.setFieldValue("isSuperBill", !formik.values.isSuperBill)
              }
              CheckedValue={formik.values.isSuperBill === true}
            />

            <CustomField
              value={formik.values.superBillCategory}
              name="superBillCategory"
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              label="Category"
              disable={true}
              type="text"
            />
            <CustomField
              value={formik.values.superBillDescription}
              name="superBillDescription"
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              label="Description"
              disable={true}
              type="text"
            />
          </FormControl>
        </Grid>

        <Grid item md={12} sm={12} xs={12} margin={"0 0 10px 0"}>
          <Typography component="h3">Statement Options</Typography>
          <CustomField
            value={formik.values.statementDescription}
            name="statementDescription"
            type="text"
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      {/* modals */}
      {/* revenue */}
      <CustomModal
        open={openRevModal}
        handleClose={() => setOpenRevModal(false)}
        heading={'All Revenues'}
        height={"20%"}
      >
        <Box width="100%">
          {/* <Revenue handleCellClick={handleRevenue} isUpdate={false} /> */}
          <CustomPreview
        rows={ getRevenueData && getRevenueData.result
          ? getRevenueData.result.map((item) => ({
              id: item.revenueCodeId,
              ...item,
            }))
          : []
        }
        columns={revenueCodesCols}
        searchLabel="Search by Claim Id,Patient Name,Dos"
        loading={loading}
        isModal={true}
        handleCell={handleRevenue}
        handleGetAll={getRevenueAction}
        handleBySearch={getRevenueCodeBySearchTermAction}
      />
        </Box>
      </CustomModal>
      {/* pos modal */}
      <CustomModal
        open={openPosModal}
        handleClose={() => setOpenPosModal(false)}
        height={"20%"}
      >
        <PosCodes handleCellClick={handleSelectPos} />
      </CustomModal>
      {/* tos modal */}
      <CustomModal
        open={openTosModal}
        handleClose={() => setOpenTosModal(false)}
        height={"20%"}
      >
        <TosCodes handleCellClick={handleSelectTos} />
      </CustomModal>
      {/* revenue */}
      {/* <CustomModal open={openRevModal} handleClose={() => setOpenRevModal(false)}>
        <Revenue />
      </CustomModal> */}
      </Box>
    </>
  );
};

export default ProcedureInfo;
