import React, { useEffect, useState } from "react";
import PatientInfo from "./PatientInfo";
import { useFormik } from "formik";
import { Box, Stack, Tab, Tabs,Typography } from "@mui/material";
import CustomDialog from "../../components/CustomDialog";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { newPatientAction } from "../../features/actions/createPatientAction";
import { Dropdown } from "react-bootstrap";
import "./createpatient.css";
import { patientInitValues } from "../../utils/formikInitValues";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import BillingInfo from "./BillingInfo";
import ClaimDefaults from "./ClaimDefaults";
import MainInsurance from "./MainInsurance";
import MainHeading from "../../components/MainHeading";
import { useTheme } from "@mui/material";
import { patientSchema } from "../../schemas/patientSchema";
import { getData } from "../../config/axiosFunctions";
import { handleCancel } from "../../utils/customFunctions";
import path from "../../config/apiUrl";

const CreatePatient = () => {
  const confirm = useConfirm();
  const { loading } = useSelector((state) => state.patient);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const handleTabChange = (event, newValue) => {
    setIsFormVisible(false);
    setTimeout(() => {
      setTabValue(newValue);
      setIsFormVisible(true);
    }, 400);
  };

  const handleFormSubmit = (formValues) => {
    try {
      console.log(formValues, "gettinFormVals");
      dispatch(newPatientAction(formValues));
    } catch (error) {
      console.error("Error creating patient:", error);
    }
  };

  const formik = useFormik({
    initialValues: patientInitValues,
    validationSchema: patientSchema,
    onSubmit: (values, action) => {
      console.log(values, "getValsFormData");
      try {
        handleFormSubmit(values);
        console.log(values, "checking submit values of createPatient");
      } catch (error) {
        console.error("Error creating patient:", error);
      }
      action.resetForm();

      navigate("/patients");
    },
  });
  const theme = useTheme();

  const handleMoreClick = () => {
    confirm({
      title: "You must save the patient in order to perform this action.",
      confirmationText: "Save",
    })
      .then(() => {
        formik.handleSubmit();
      })
      .catch(() => {});
  };

  // set default provider
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getData(`${path}/provider?page=1&pageSize=20`);
        console.log(response, "check response in default provider");
        if (
          response.result?.providers.length > 0 &&
          formik.values?.defaultProviderId === null
        ) {
          const defaultProvider = response?.result?.providers[0];
          console.log("defaultProvider", defaultProvider);
          formik.setValues((prevValues) => ({
            ...prevValues,
            defaultProviderId: defaultProvider.providerId,
            defaultProviderFirstName: `${defaultProvider.providerFirstName}`,
            defaultProviderLastName: `${
              defaultProvider.providerLastName
                ? defaultProvider.providerLastName
                : ""
            }`,
            defaultProviderSeqNo: defaultProvider.providerSequenceNo,
            practiceAddress: defaultProvider.practiceAddress,
            npiNo: defaultProvider.providerNPINo,
            practiceName: defaultProvider.practiceName,
            practiceSequenceNo: defaultProvider.practiceSequenceNo,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log(formik.values, "patVals");

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/patients");
  };
  return (
    <>
      <Box margin="20px">
        <MainHeading mainheading={"Create Patient"} />
        <Stack
          sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }}
          justifyContent="space-between"
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              order: { xs: 2, sm: 2, md: 1 },
              marginBottom: "10px",
            }}
          >
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Patient Info"
              value={0}
            />
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Insurance Information"
              value={1}
            />

            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Billing Info"
              value={2}
            />
            <Tab
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "10px 10px 0 0",
              }}
              className="tabsdesigning"
              label="Claim Defaults"
              value={3}
            />
          </Tabs>

          <Stack
            direction="row"
            alignContent="center"
            columnGap={2}
            sx={{
              order: { xs: 1, sm: 1, md: 4 },
              alignItems: "center",
              // margin: "15px",
            }}
          >
            {/* <CustomButton
              type="reset"
              color="error"
              padding={"7px"}
              isBlue={false}
              isCancel={true}
              sx={{
                marginRight: "15px",
              }}
              handleClick={() => {
                handleCancel(formik, "/patients", navigate);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="submit"
              isBlue={true}
              isSave={true}
              padding={"7px"}
              margin={"0 10px 0"}
              formId="patientForm"
              disabled={loading}
              sx={{
                marginRight: "30px",
              }}
            >
              {loading ? "Saving..." : "Save"}
            </CustomButton> */}

         <CustomButton  
         type="reset"
              color="error"
              padding={"7px"}
              isBlue={false}
              isCancel={true}
              sx={{
                marginRight: "15px",
              }} 
              handleClick={handleCancel}>
           Cancel
         </CustomButton>
         
<CustomButton  type="submit"
              isBlue={true}
              isSave={true}
              padding={"7px"}
              margin={"0 10px 0"}
              formId="patientForm"
              disabled={loading}
              sx={{
                marginRight: "30px",
              }}>
{loading ? "Saving..." : "Save"}
         </CustomButton>
            <Dropdown
              style={
                {
                  // color: theme.palette.btnCancelText.primary,
                  // backgroundColor: theme.palette.cancelBtn.primary,
                }
              }
              className="print"
            >
              <Dropdown.Toggle
                className="printDropDown"
                id="dropdown-basic"
                style={{ padding: "10px" }}
              >
                <PersonIcon />
                Eligibility
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                // onClick={handleSaveAndPreviewClick}
                >
                  New Eligibility Check
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              style={
                {
                  // color: theme.palette.btnCancelText.primary,
                  // backgroundColor: theme.palette.cancelBtn.primary,
                }
              }
              className="print"
            >
              <Dropdown.Toggle
                className="printDropDown"
                id="dropdown-basic"
                style={{ padding: "10px" }}
              >
                <MoreVertIcon />
                More
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleMoreClick}>
                  Create Professional Claim for Patient
                </Dropdown.Item>
                <Dropdown.Item onClick={handleMoreClick}>
                  Create Institutional Claim for Patient
                </Dropdown.Item>
                <Dropdown.Item onClick={handleMoreClick}>
                  Create Appointment for Patient
                </Dropdown.Item>
                <Dropdown.Item onClick={handleMoreClick}>
                  Post Payment for Patient
                </Dropdown.Item>
                <Dropdown.Item onClick={handleMoreClick}>
                  Create Payment Plan
                </Dropdown.Item>
                <Dropdown.Item>Open in Manage Account</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Stack>
        </Stack>

        <form
          id="patientForm"
          onSubmit={formik.handleSubmit}
          className={`formContainer ${!isFormVisible ? "hidden" : ""}`}
        >
          {tabValue === 0 && <PatientInfo formik={formik} />}
          {tabValue === 1 && <MainInsurance formik={formik} />}
          {tabValue === 2 && <BillingInfo formik={formik} />}
          {tabValue === 3 && <ClaimDefaults formik={formik} />}
        </form>
      </Box>

      <CustomDialog
 open={openCancelDialog}
 isWarning={true}
 handleClose={() => setOpenCancelDialog(false)}
 handleConfirm={handleConfirmCancel}
 handleCancel={() => setOpenCancelDialog(false)}
 confirmButtonTitle="OK"
 cancelButtonTitle="Cancel"
>
 <Typography component="h3">Are you sure you want to cancel?</Typography>
</CustomDialog>
    </>
  );
};

export default CreatePatient;
