// import React, { useState } from 'react';
// import { Grid, Box, Stack, Typography, useTheme, Radio, FormControl, FormControlLabel } from '@mui/material';
// import { feeSchedulesCols } from '../../../../utils/gridCols';
// import { getFeeScheduleAction } from '../../../../features/actions/feeSchedulesAction';
// import { useSelector } from 'react-redux';
// import CustomPreview from '../../../../layout/CustomPreview';
// import CheckBox from '../../../../components/CheckBox';

// const AnotherFeeSchedule = () => {
//   const anotherFeeScheduleCtList = [
//     { id: '1', ctOption: 'Do not adjust the prices of the new fee schedule' },
//     { id: '2', ctOption: 'Do not adjust the prices of the new fee schedule' },
//     { id: '3', ctOption: 'Adjust Prices Bto' }
//   ];
//   const theme = useTheme();
//   const { getFeeScheduleData, loading } = useSelector((state) => state.feeSchedule);
//   const [selectedOption, setSelectedOption] = useState('');

//   const handleRadioChange = (value) => {
//     setSelectedOption(value);
//   };

//   console.log(getFeeScheduleData, "feeScheduleData");

//   // rows
//   const rows =
//     getFeeScheduleData?.result && getFeeScheduleData.result?.length > 0
//       ? getFeeScheduleData.result?.map((el) => ({
//           id: el.feeSchedulesId,
//           feeSchedulesName: el.feeSchedulesName,
//           effectiveFrom: el.effectiveFrom,
//           effectiveTo: el.effectiveTo,
//           isActive: el.isActive,
//         }))
//       : [];

//   return (
//     <>
//       <Grid container spacing={2}>
//         <Grid item md={12}>
//           <CustomPreview
//             rows={rows}
//             columns={feeSchedulesCols}
//             handleGetAll={getFeeScheduleAction}
//             searchLabel="Search by First Name, Phone, or Sequence Number"
//           />
//         </Grid>

//         <Grid item md={12}>
//           <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px", margin: '15px' }}>
//             <Box
//               sx={{
//                 background: theme.palette.revenueStatement.primary,
//                 height: "33px",
//               }}
//             >
//               <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
//                 Adjust Prices
//               </Typography>
//             </Box>

//             <FormControl fullWidth sx={{ padding: "10px" }}>
//               <Stack flexDirection="column" alignItems="left" marginLeft="20px">
//                 {anotherFeeScheduleCtList.map((value) => (
//                   <Box key={value.id}>
//                     <FormControlLabel
//                       label={value.ctOption}
//                       control={
//                         <Radio
//                           value={value.id}
//                           checked={selectedOption === value.id}
//                           onChange={() => handleRadioChange(value.id)}
//                           name="radio-buttons"
//                         />
//                       }
//                     />
//                     {selectedOption === value.id && (
//                       <Box marginTop={2}>
//                         {value.id === '1' && (
//                           <Typography variant="body1">
//                             Field for option 1
//                           </Typography>
//                         )}
//                         {value.id === '2' && (
//                           <Typography variant="body1">
//                             Field for option 2
//                           </Typography>
//                         )}
//                       </Box>
//                     )}
//                   </Box>
//                 ))}
//               </Stack>
//             </FormControl>
//             <FormControl fullWidth>
//               <CheckBox
//                 label="Print this code on superbills"
//               />
//             </FormControl>
//           </Box>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default AnotherFeeSchedule;


import React, { useState } from 'react';
import { Grid, Box, Stack, Typography, useTheme, Radio, FormControl, FormControlLabel } from '@mui/material';
import { feeSchedulesCols } from '../../../../utils/gridCols';
import { getFeeScheduleAction } from '../../../../features/actions/feeSchedulesAction';
import { useSelector } from 'react-redux';
import CustomPreview from '../../../../layout/CustomPreview';
import CheckBox from '../../../../components/CheckBox';
import CustomField from '../../../../components/CustomField';

const AnotherFeeSchedule = () => {
  const anotherFeeScheduleCtList = [
    { id: '1', ctOption: 'Do not adjust the prices of the new fee schedule' },
    { id: '2', ctOption: 'Do not adjust the prices of the new fee schedule' },
    { id: '3', ctOption: 'Adjust Prices to' }
  ];
  const theme = useTheme();
  const { getFeeScheduleData, loading } = useSelector((state) => state.feeSchedule);
  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };

  console.log(getFeeScheduleData, "feeScheduleData");

  // rows
  const rows =
    getFeeScheduleData?.result && getFeeScheduleData.result?.length > 0
      ? getFeeScheduleData.result?.map((el) => ({
          id: el.feeSchedulesId,
          feeSchedulesName: el.feeSchedulesName,
          effectiveFrom: el.effectiveFrom,
          effectiveTo: el.effectiveTo,
          isActive: el.isActive,
        }))
      : [];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <CustomPreview
            rows={rows}
            columns={feeSchedulesCols}
            handleGetAll={getFeeScheduleAction}
            searchLabel="Search by First Name, Phone, or Sequence Number"
            isModal={true}
          />
        </Grid>

        <Grid item md={12}>
          <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px", margin: '15px' }}>
            <Box
              sx={{
                background: theme.palette.revenueStatement.primary,
                height: "33px",
              }}
            >
              <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
                Adjust Prices
              </Typography>
            </Box>

            <FormControl fullWidth sx={{ padding: "10px" }}>
              <Stack flexDirection="column" alignItems="left" marginLeft="20px">
                {anotherFeeScheduleCtList.map((value) => (
                  <Stack key={value.id} direction="row" alignItems="center" spacing={2}>
                    <FormControlLabel
                      label={value.ctOption}
                      control={
                        <Radio
                          value={value.id}
                          checked={selectedOption === value.id}
                          onChange={() => handleRadioChange(value.id)}
                          name="radio-buttons"
                        />
                      }
                    />
                    {value.id === '1' && (
                  <CustomField/>
                    )}
                    {value.id === '2' && (
                       <CustomField/>
                    )}
                     {value.id === '3' && (
                        <>
                       <CustomField/>
                       </>
                    )}
                  </Stack>
                ))}
              </Stack>
            </FormControl>
            <FormControl fullWidth>
              <CheckBox sx={{margin:'10px'}}
                label="Print this code on superbills"
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AnotherFeeSchedule;
