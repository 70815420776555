import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomPreview from "../../../layout/CustomPreview";
import { procedureCodesCols } from "../../../utils/gridCols";
import { useSelector } from "react-redux";
import {
  getProcedureCodeAction,
  getProcedureCodesBySearchTermAction,
} from "./../../../features/actions/procedureCodeAction";
import CustomModal from "./../../../components/CustomModal";
import SearchHcpcs from "./SearchHcpcs";
import SearchCpt from "./SearchCpt";

const ProcedureCodes = () => {
  const navigate = useNavigate();
  const [openHcpcMod, setOpenHcpcMod] = useState(false);
  const [openCptMod, setOpenCptMod] = useState(false);
  const { getProcedureCodes, loading } = useSelector(
    (state) => state.procedureCodes
  );

  const rows = getProcedureCodes
    ? getProcedureCodes.map((el) => ({
        id: el.procedureCodeId,
        units: el.units === null ? 1 : el.units,
        ...el,
      }))
    : [];

  return (
    <>
      <Box>
        <CustomPreview
          rows={rows}
          columns={procedureCodesCols}
          moduleHeading={'Procedure'}
          loading={loading}
          searchLabel="Search for CPT/HCPCS codes by code or description"
          handleCell={(params) =>
            navigate(`/procedure-codes/update/${params?.id}`)
          }
          handleGetAll={getProcedureCodeAction}
          handleBySearch={getProcedureCodesBySearchTermAction}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <CustomButton
              isBlue="true"
              isAdd="true"
              handleClick={() => navigate("/procedure-codes/new")}
            >
              Add Procedure
            </CustomButton>
            <CustomButton
              isBlue={false}
              handleClick={() => setOpenHcpcMod(true)}
            >
              Add From HCPCS List
            </CustomButton>
            <CustomButton
              isBlue={false}
              handleClick={() => setOpenCptMod(true)}
            >
              Add From CPT List
            </CustomButton>
          </Stack>
        </CustomPreview>
      </Box>

      {/* modals here */}
      <CustomModal open={openHcpcMod} handleClose={() => setOpenHcpcMod(false)}>
        <SearchHcpcs />
      </CustomModal>
      <CustomModal open={openCptMod} handleClose={() => setOpenCptMod(false)}>
        <SearchCpt />
      </CustomModal>
    </>
  );
};

export default ProcedureCodes;
