import { useSelector } from "react-redux";
import CustomPreview from "../../../layout/CustomPreview"
import { primaryInsPayerCol } from "../../../utils/gridCols";
import { getPayerAction } from "../../../features/actions/payerAction";
import { payerPolicyTypeInClaim } from "../../../utils/formikInitValues";
import { Box, Tab, Tabs } from "@mui/material";
import { Stack } from "react-bootstrap";
import { useEffect, useState } from "react";
import CustomDataGrid from "../../../components/CustomDataGrid";

const patientPayerCol = [
    {
        field: "payerSequenceNo",
        headerName: "Seq No",
        flex: 2,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
    },
    {
        field: "payerName",
        headerName: "Payer Name",
        flex: 2,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
    },
    {
        field: "groupId",
        headerName: "Group Id",
        flex: 2,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
    },
    {
        field: "memberId",
        headerName: "Member Id",
        flex: 2,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        headerClassName: "header-bg",
        cellClassName: "cell-content",
    }
]

const ClaimPayer = ({ formik, handlePayer, openInsuredParty }) => {
    const { getAllPayer } = useSelector((state) => state.payer);
    const payerInfoLoading = useSelector((state) => state.payerInfo.loading);
    const { primaryPayers, secondaryPayer, tertiaryPayer } = useSelector((state) => state.claim)
    const [tabValue, setTabValue] = useState(0)

    useEffect(() => {
        if (formik.values?.patientId === null) {
            setTabValue(1)
        }
    }, [])

    return (
        <Box margin={"15px"}>
            {formik.values?.patientId !== null && <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Tabs

                    value={tabValue}
                    onChange={(e, v) => setTabValue(v)}
                    sx={{
                        marginBottom: "10px",
                        borderRadius: "10px 10px 0 0",
                    }}
                >
                    <Tab
                        label="Patient Payer"
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        className="tabsdesigning"
                        value={0}
                    />
                    <Tab
                        label="All Payers"
                        style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            // borderBottom: payerInfoReq ? "2px solid red" : "none"
                        }}
                        className="tabsdesigning"
                        value={1}
                    />
                </Tabs>
            </Stack>}
            {console.log("getAllPayer", getAllPayer)}
            {tabValue === 1 &&
                // <CustomPreview
                //     rows={
                //         getAllPayer?.result && getAllPayer?.result?.payers?.length > 0
                //             ? getAllPayer.result.payers?.map((row) => ({
                //                 ...row,
                //                 id: row.payerId,
                //             }))
                //             : []
                //     }
                //     isModal={true}
                //     moduleHeading={"Payer Info"}
                //     columns={primaryInsPayerCol}
                //     handleGetAll={getPayerAction}
                //     loading={payerInfoLoading}
                //     searchLabel={"Search by Payer ID and Sequence #"}
                //     handleCell={(params) => {
                //         handlePayer(params, true)
                //     }}
                // />
                <h1>Please Select Patient First</h1>
            }
            {formik.values?.patientId !== null && tabValue === 0 && <>
                <CustomDataGrid
                    columns={patientPayerCol}
                    rows={(openInsuredParty.payerPriorityId === 1 ? primaryPayers : openInsuredParty.payerPriorityId === 2 ? secondaryPayer : tertiaryPayer).map((el, ind) => {
                        return (
                            {
                                ...el,
                                id: el?.payerId
                            }
                        )
                    })}
                    handleCellClick={handlePayer}
                />
            </>}
        </Box>
    )
}
export default ClaimPayer