import React, { useEffect, useState } from 'react';
import {Typography,Stack,Box,useTheme} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const NewPatient = ({ data }) => {
  console.log(data, "data in new patient");

  const totalNewPatient = data
    ? data.reduce((acc, item) => acc + item.newPatientCount, 0)
    : 0;
  const totalPatient = data
    ? data.reduce(
        (acc, item) => acc + item.newPatientCount + item.oldPatientCount,
        0
      )
    : 0;

  const percentage =
    totalNewPatient > 0
      ? ((totalPatient / totalNewPatient) * 100).toFixed(2)
      : 0;

  const theme = useTheme();
  return (
    <>
      <Card
        // className="newPatient"
        sx={{
      width:'100%',
          background: theme.palette.announcementCard.primary,
        }}
      >
        <CardContent>
          <Box>
    <Typography  className='newPtientHeading'   sx={{ textAlign: 'center',  marginBottom: { xs: '12px', md: '11px' } }}>This Month Patients</Typography>
    <Box className='totalPatient'>
    <Stack spacing={'5px'} direction='row' justifyContent='center'>

    <Typography
                  sx={{
                    color: theme.palette.globalText,
                    fontFamily: "Radio Canada",
                    fontSize: "2rem",
                    fontWeight: "700",
                  
                  }}
                >
                  {totalNewPatient}
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      color: "#00D115",
                      fontSize: "1.2rem",
              
                    }}
                  >
                    {percentage}%
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default NewPatient;


// import React, { useEffect, useState } from 'react';
// import {Typography,Stack,Box,useTheme} from '@mui/material';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';

// const NewPatient = ({ data }) => {
//   console.log(data, "data in new patient");

//   const totalNewPatient = data
//     ? data.reduce((acc, item) => acc + item.newPatientCount, 0)
//     : 0;
//   const totalPatient = data
//     ? data.reduce(
//         (acc, item) => acc + item.newPatientCount + item.oldPatientCount,
//         0
//       )
//     : 0;

//   const percentage =
//     totalNewPatient > 0
//       ? ((totalPatient / totalNewPatient) * 100).toFixed(2)
//       : 0;

//   const theme = useTheme();
//   return (
//     <Card sx={{ width: '50%', background: theme.palette.announcementCard.primary }}>
//       <CardContent>
//         <Box>
//           <Typography
//             className='newPatientHeading'
//             sx={{ textAlign: 'center', marginBottom: '16px' }}
//           >
//             This Month Patients
//           </Typography>
//           <Box className='totalPatient'>
//             <Stack spacing={'5px'} direction='row' justifyContent='center'>
//               <Typography sx={{ color: theme.palette.globalText, fontFamily: 'Radio Canada', fontSize: '2rem', fontWeight: '700' }}>
//                 {totalNewPatient}
//               </Typography>
//               <Box>
//                 <Typography sx={{ color: '#00D115', fontSize: '1.2rem' }}>
//                   {percentage}%
//                 </Typography>
//               </Box>
//             </Stack>
//           </Box>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

// export default NewPatient;
