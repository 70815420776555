import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getAllPosCodesActios = createAsyncThunk("getPos" , async()=>{
    try {
        const response = await getData(`${path}/posCode`);

    if (response) {
      return response;
    } 
    } catch (error) {
        console.log(error, ": error in fetching ppos codes");
        throw new Error(error);
    }
})