import { createSlice } from "@reduxjs/toolkit";
import { getFeeScheduleAction } from "../actions/feeSchedulesAction";

const initialState = {
  getFeeScheduleData: [],
  error: null,
  loading: false,
};
const feeScheduleSlice = createSlice({
  name: "feeSchedule",
  initialState: initialState,

  extraReducers: {
    [getFeeScheduleAction.pending]: (state) => {
      state.loading = true;
    },
    [getFeeScheduleAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getFeeScheduleData= action.payload;
    },
    [getFeeScheduleAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.log(state.error, "feeScheduleErr");
    },
   
  },
});

export default feeScheduleSlice.reducer;



