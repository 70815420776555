// import { Box, Grid, Stack, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import MultiButton from "./../../components/MultiButton";
// import path from "../../config/apiUrl";
// import { getData } from "../../config/axiosFunctions";
// import CustomButton from "../../components/CustomButton";
// import CustomModal from "../../components/CustomModal";
// import InsuredModalForm from "./InsuredModalForm";
// import CustomAccordion from "./../../components/CustomAccordion";
// import Item from "antd/es/list/Item";
// import LinkFamily from "./LinkFamily";
// import LinkFamilyOpt from "./LinkFamilyOpt";

// const MainInsurance = ({ formik }) => {
//   const [tabValue, setTabValue] = useState(0);
//   const [priorityTypeOpt, setPriorityTypeOpt] = useState([]);
//   const [openLinkFamilyModal, setOpenLinkFamilyModal] = useState(false);
//   const [genderOpts, setGenderOpts] = useState([]);
//   const [priorityId, setPriorityId] = useState(1);
//   const [openInsModal, setOpenInsModal] = useState(false);
//   const [openLinkFamilyOptModal, setOpenLinkFamilyOptModal] = useState(false);
//   const dataFetchUrl = {
//     priorityType: "/ct-priorityType",
//     genderType: "/ct-genderIdentity",
//   };

//   const fetchData = async (endPoint, setState) => {
//     try {
//       const response = await getData(`${path}${endPoint}`);
//       setState(response.result);
//     } catch (error) {
//       console.log("error while fetch data", error);
//     }
//   };
//   useEffect(() => {
//     fetchData(dataFetchUrl.priorityType, setPriorityTypeOpt);
//     fetchData(dataFetchUrl.genderType, setGenderOpts);
//   }, []);

//   // handle add insurance
//   const handleAddInsurance = (values) => {
//     let updatedPayerDto = formik.values.insuredPayerDto;
//     console.log(values, "checkAllValuesInsurance")
//     let insuredIndex = updatedPayerDto.findIndex(
//         (v) => v?.insuredFirstName === values?.insuredDetail?.insuredFirstName
//       );
//       if(insuredIndex === -1) {
//         formik.setValues((preVals) => ({
//           ...preVals,
//           insuredPayerDto: [
//             ...formik.values.insuredPayerDto,
//             values?.insuredDetail,
//           ],
//         }));
//       }else {
//         formik.setValues((prevVals) => {
//           const updatedPayerDto = [...prevVals.insuredPayerDto];
//           updatedPayerDto[insuredIndex] = {
//             ...updatedPayerDto[insuredIndex],
//             insurancePoliciesDtos: values.insuredDetail.insurancePoliciesDtos,
//           };
//           return { ...prevVals, insuredPayerDto: updatedPayerDto };
//         });
//       }
//     setOpenInsModal(false);
//   };

//   // linkfamily
//   const handleLinkFamily = (values) => {
//     setOpenLinkFamilyModal(false);
//     setOpenLinkFamilyOptModal(true);

//    console.log(values, "checkLinkFamilyParams")

//   };

//   const handleLinkRelation = (values) => {
//    console.log(values, "checkInsuredData")

//   };

//   return (
//     <>
//       <Box margin="10px">
//         <Stack direction="row" alignItems="center" gap={2} marginBottom="15px">
//           <MultiButton
//             title={"Add New"}
//             multiOpts={priorityTypeOpt?.map((opt) => ({
//               id: opt?.priorityTypeId,
//               value: opt?.priorityType,
//             }))}
//             onSelect={(value) => {
//               setPriorityId(value?.id);
//               setOpenInsModal(true);
//             }}
//           />

//           <CustomButton isBlue={false}  handleClick={() => setOpenLinkFamilyModal(true)}>Link Family</CustomButton>
//         </Stack>

//         {formik?.values?.insuredPayerDto?.length > 0 && (
//           <>
//             <CustomAccordion
//               heading={`Insured Parties (${formik.values.insuredPayerDto.length})`}
//             >
//               {formik.values.insuredPayerDto.map((item, index) => {
//                 return (
//                   <Box key={index}>
//                     <Stack
//                       direction="row"
//                       alignItems="center"
//                       justifyContent="space-between"
//                     >
//                       <Typography component="h3">
//                         {item?.insuredFirstName}, {item?.insuredLastName}
//                       </Typography>
//                       {/* <Stack direction="row" alignItems="center">
//                         <CustomButton>Edit</CustomButton>
//                         <CustomButton>Inactive</CustomButton>
//                       </Stack> */}
//                     </Stack>

//                     <Grid container spacing={2}>
//                       <Grid item md={4}>
//                         <Typography component="h5">
//                           DOB: {item?.insuredDateOfBirth}
//                         </Typography>
//                         <Typography component="h5">
//                           {" "}
//                           {item?.insuredGenderIdentityName}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={4}>
//                         <Typography component="h5">
//                           {item?.insuredAddress}, {item?.insuredCountryName}
//                         </Typography>

//                         <Typography component="h5">
//                           {item?.insuredCityName}, {item?.insuredStateName}{" "}
//                           {item?.insuredZipCode}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={4}>
//                         <Typography component="h5">
//                           Home: {item?.insuredHomePhone}
//                         </Typography>
//                         <Typography component="h5">
//                           Cell: {item?.insuredCellPhone}
//                         </Typography>
//                         <Typography component="h5">
//                           Work: {item?.insuredWorkPhone}
//                         </Typography>
//                         <Typography component="h5">
//                           Ext: {item?.insuredExt}
//                         </Typography>
//                       </Grid>
//                     </Grid>
//                   </Box>
//                 );
//               })}
//             </CustomAccordion>

//             {["Primary", "Secondary", "Tertiary"].map(
//               (priority, priorityInd) => {
//                 const priorId = priorityInd + 1;
//                 const filteredPolicies = formik.values.insuredPayerDto.flatMap(
//                   (item) =>
//                     item.insurancePoliciesDtos.filter(
//                       (policy) => policy.payerInfoPriorityId === priorId
//                     )
//                 );
//                 return (
//                   filteredPolicies.length > 0 && (
//                     <CustomAccordion
//                       key={priorId}
//                       heading={`${priority} Payers`}
//                     >
//                       {filteredPolicies.map((el, ind) => (
//                         <>
//                           <Grid container spacing={2}>
//                             <Grid item md={12}>
//                               <Stack
//                                 direction={"row"}
//                                 alignItems="center"
//                                 justifyContent="space-between"
//                               >
//                                 <Typography component="h3">
//                                   {el?.payerInfoPayerName} (#
//                                   {el?.payerInfoSequenceNumber})
//                                 </Typography>
//                               </Stack>
//                             </Grid>
//                             <Grid item md={6}>
//                               <Typography component="h5">Insured: {el?.insuredFirstName} {el?.insuredLastName}</Typography>
//                               <Typography component="h5">
//                                 Member ID: {el?.payerInfoMemberId}
//                               </Typography>
//                               <Typography component="h5">
//                                 Group ID: {el?.payerInfoGroupId}
//                               </Typography>
//                             </Grid>
//                             <Grid item md={6}>
//                               <Typography component="h5">
//                                 Copay: ${el?.payerInfoCopayAmount}
//                               </Typography>
//                               <Typography component="h5">
//                                 Co-Insurance: ${el?.payerInfoCoInsurancePercent}
//                               </Typography>
//                               <Typography component="h5">
//                                 Deductible: ${el?.payerInfoDeductibleAmount}
//                               </Typography>
//                               <Typography component="h5">
//                                 Out of Packet Max: $
//                                 {el?.payerInfoOutOfPocketMax}
//                               </Typography>
//                             </Grid>
//                           </Grid>
//                         </>
//                       ))}
//                     </CustomAccordion>
//                   )
//                 );
//               }
//             )}
//           </>
//         )}
//       </Box>

//       {/* modals here */}
//       <CustomModal
//         open={openInsModal}
//         handleClose={() => {
//           formik.setFieldValue("isPatientInsured", false);
//           formik.setFieldValue("isSamePatient", false);
//           setOpenInsModal(false);
//         }}
//         heading="Add Policy"
//       >
//         <InsuredModalForm
//           tabValue={tabValue}
//           setTabValue={setTabValue}
//           formik={formik}
//           handleAddInsurance={handleAddInsurance}
//           priorityId={priorityId}
//         />
//       </CustomModal>
//       <CustomModal
//       open={openLinkFamilyModal}
//       handleClose={() => setOpenLinkFamilyModal(false)}
//       heading={'Patient Search'}
//       height={'20px'}
//       width={'80%'}
//       >
//         <LinkFamily
//         handleCellClick={handleLinkFamily}
//         />
//       </CustomModal>

//       <CustomModal
//       open={openLinkFamilyOptModal}
//       handleClose={() => setOpenLinkFamilyOptModal(false)}
//       heading={'Search Link'}
//       height={'20px'}
//       >
//  <LinkFamilyOpt
// //  setOpenLinkOptModal={setOpenLinkFamilyOptModal}
// handleLinkRelation={handleLinkRelation}
// />
//       </CustomModal>
//     </>
//   );
// };

// export default MainInsurance;



import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import MultiButton from "../../components/MultiButton";
import path from "../../config/apiUrl";
import { getData } from "../../config/axiosFunctions";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import InsuredModalForm from "./InsuredModalForm";
import CustomAccordion from "../../components/CustomAccordion";
import LinkFamily from "./LinkFamily";
import LinkFamilyOpt from "./LinkFamilyOpt";

const MainInsurance = ({ formik }) => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [priorityTypeOpt, setPriorityTypeOpt] = useState([]);
  const [openLinkFamilyModal, setOpenLinkFamilyModal] = useState(false);
  const [genderOpts, setGenderOpts] = useState([]);
  const [priorityId, setPriorityId] = useState(1);
  const [openInsModal, setOpenInsModal] = useState(false);
  const [openLinkFamilyOptModal, setOpenLinkFamilyOptModal] = useState(false);
  const [insuredData, setInsuredData] = useState([]);
  const [linkFamilyBtn, setLinkFamilyBtn] = useState(true);

  const [editMode, setEditMode] = useState(false);
  const [insuredDataForEdit, setInsuredDataForEdit] = useState(null);
  const [indexForEdit, setIndexForEdit] = useState(null);


  const dataFetchUrl = {
    priorityType: "/ct-priorityType",
    genderType: "/ct-genderIdentity",
  };

  const fetchData = async (endPoint, setState) => {
    try {
      const response = await getData(`${path}${endPoint}`);
      setState(response.result);
    } catch (error) {
      console.log("error while fetch data", error);
    }
  };

  useEffect(() => {
    fetchData(dataFetchUrl.priorityType, setPriorityTypeOpt);
    fetchData(dataFetchUrl.genderType, setGenderOpts);
  }, []);

  const handleAddInsurance = (values) => {
    let updatedPayerDto = formik.values.insuredPayerDto;
    let insuredIndex = updatedPayerDto.findIndex(
      (v) => v?.insuredFirstName === values?.insuredDetail?.insuredFirstName
    );

    if (insuredIndex === -1) {
      formik.setValues((preVals) => ({
        ...preVals,
        insuredPayerDto: [
          ...formik.values.insuredPayerDto,
          values?.insuredDetail,
        ],
      }));
    } else {
      formik.setValues((prevVals) => {
        const updatedPayerDto = [...prevVals.insuredPayerDto];
        updatedPayerDto[insuredIndex] = {
          ...updatedPayerDto[insuredIndex],
          insurancePoliciesDtos: values.insuredDetail.insurancePoliciesDtos,
        };
        return { ...prevVals, insuredPayerDto: updatedPayerDto };
      });
    }
    setOpenInsModal(false);
  };

  // const handleAddInsurance = (insuranceData) => {
  //   console.log("insuranceDataCheck",insuranceData)

  //   // if (insuredDataForEdit !== null && insuredDataForEdit["insuredId"]) {
  //   if (insuranceData?.isLink === true) {
  //     setEditMode(false);
  //     setInsuredDataForEdit(null);
  //     formik.values.showInsuranceData = true;
  //     setOpenInsModal(false);
  //     // } else if (insuredDataForEdit !== null) {
  //   } else if (insuranceData?.isLink === false) {
  //     // debugger
  //     let updateData = [...formik.values.insuredPayerDto];
  //     let findDataIndex = formik.values.insuredPayerDto.findIndex(
    
  //       (val, ind) => ind === indexForEdit   
    
  //     );
  //     if (findDataIndex !== -1) {
  //       updateData[findDataIndex] = insuranceData;
  //       formik.setFieldValue("insuredPayerDto", updateData);
  //       setEditMode(false);
  //       setIndexForEdit(null);
  //       setInsuredDataForEdit(null);
  //     } else {
  //       formik.setFieldValue("insuredPayerDto", [
  //         ...formik.values.insuredPayerDto,
  //         insuranceData,
  //       ]);
  //       setEditMode(false);
  //       setIndexForEdit(null);
  //       setInsuredDataForEdit(null);
  //     }
  //   }
  //   setOpenInsModal(false);
  //   formik.values.showInsuranceData = true;
  // };
 
  const handleEditInsurance = (index) => {
    setIndexForEdit(index);
    const insDataToEdit = formik.values.insuredPayerDto[index];
    setInsuredDataForEdit(insDataToEdit);
    setEditMode(true);
    setOpenInsModal(true);
  };  

  const handleLinkFamily = async (values) => {
    try {
      const response = await getData(`${path}/patients/${values.accountNo}`);
      formik.setValues((preVals) => ({
        ...preVals,
        linkPatientAccountNo: response?.result?.accountNo,
        linkPatientId: response?.result?.patientId,
      }));
      console.log(response, "responseapi");
      const accountNoPatient = response?.result?.accountNo;
      console.log(accountNoPatient);
      // Set insured data
      setInsuredData(response?.result?.insuredPayerDto);
      setOpenLinkFamilyModal(false);
      setOpenLinkFamilyOptModal(true);
      setLinkFamilyBtn(false);
    } catch (error) {
      console.log("Error while fetching patient data", error);
    }
  };

  const handleLinkRelation = () => {
    if (insuredData) {
      formik.setFieldValue("insuredPayerDto", [
        ...formik.values.insuredPayerDto,
        ...insuredData.map((item) => ({ ...item, isLink: true })),
      ]);
    } else {
      alert("There is no patient insured data found!");
    }
    setOpenLinkFamilyOptModal(false);
    setOpenLinkFamilyModal(false);
  };
  return (
    <Box margin="10px">
      <Stack direction="row" alignItems="center" gap={2} marginBottom="15px">
        <MultiButton
          title={"Add New"}
          multiOpts={priorityTypeOpt?.map((opt) => ({
            id: opt?.priorityTypeId,
            value: opt?.priorityType,
          }))}
          onSelect={(value) => {
            setPriorityId(value?.id);
            setOpenInsModal(true);
          }}
        />
        {/* {linkFamilyBtn ? (
          <CustomButton
            isBlue={false}
            handleClick={() => setOpenLinkFamilyModal(true)}
          >
            Link Family
          </CustomButton>
        ) : (
          <CustomButton
            isBlue={false}
            // handleClick={() => setOpenLinkFamilyModal(true)}
          >
            {`Unlink From ${insuredData[0]?.insuredFirstName} ${insuredData[0]?.insuredLastName}`}
          </CustomButton>
        )} */}
      </Stack>

      {formik?.values?.insuredPayerDto?.length > 0 && (
        <>
          <CustomAccordion
            heading={`Insured Parties (${formik.values.insuredPayerDto.length})`}
          >
            {formik.values.insuredPayerDto.map((item, index) => (
              <Box
                key={index}
                sx={{ border: "1px solid #6e6b6b", borderRadius: "5px" , margin:'17px 0'}}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    background: theme.palette.revenueStatement.primary,
                    height: "33px",
                  }}
                >
                  <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
                    {item?.insuredFirstName}, {item?.insuredLastName}{" "}
                    {item?.isLink &&
                      `(${formik.values.linkFamilyRelation} - linked to #${formik.values.linkPatientAccountNo})`}
                  </Typography>

                  {/* {item?.isLink ? (
                    <CustomButton height={"20px"} padding={"12px"} isBlue={true} margin={'10px'}>
                      View
                    </CustomButton>
                  ) : (
                    <CustomButton height={"20px"} padding={"12px"} isBlue={true}  margin={'10px'}>
                      Edit
                    </CustomButton>
                  )} */}
                   <CustomButton
                          isBlue={true}
                          handleClick={() => {
                            setTabValue(0);
                            handleEditInsurance(index);
                          }}
                        >
                          {item?.isLink ? "View" : "Edit"}
                        </CustomButton>
                </Stack>

                <Grid container spacing={2}>
                  <Grid item md={4}>
                    <Typography component="h5">
                      DOB: {item?.insuredDateOfBirth}
                    </Typography>
                    <Typography component="h5">
                      {item?.insuredGenderIdentityName}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography component="h5">
                      {item?.insuredAddress}, {item?.insuredCountryName}
                    </Typography>
                    <Typography component="h5">
                      {item?.insuredCityName}, {item?.insuredStateName}{" "}
                      {item?.insuredZipCode}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography component="h5">
                      Home: {item?.insuredHomePhone}
                    </Typography>
                    <Typography component="h5">
                      Cell: {item?.insuredCellPhone}
                    </Typography>
                    <Typography component="h5">
                      Work: {item?.insuredWorkPhone}
                    </Typography>
                    <Typography component="h5">
                      Ext: {item?.insuredExt}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </CustomAccordion>

          {["Primary", "Secondary", "Tertiary"].map((priority, priorityInd) => {
            const priorId = priorityInd + 1;
            const filteredPolicies = formik.values.insuredPayerDto.flatMap(
              (item) =>
                item.insurancePoliciesDtos?.filter(
                  (policy) => policy.payerInfoPriorityId === priorId
                )
            );
            return (
              filteredPolicies.length > 0 && (
                <CustomAccordion key={priorId} heading={`${priority} Payers`}>
                  {filteredPolicies.map((el, ind) => (
                  <Box  sx={{ border: "1px solid #6e6b6b", borderRadius: "5px",margin:'17px 0' }}>
                    <Grid container spacing={2} key={ind}>
                      <Grid item md={12}>
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            background: theme.palette.revenueStatement.primary,
                            height: "33px",
                          }}
                        >
                          <Typography component="h3" >
                            {el?.payerInfoPayerName} (#
                            {el?.payerInfoSequenceNumber})
                          </Typography>
                        </Stack>
                      </Grid>
                    
                      <Grid item md={6}>
                        <Typography component="h5">
                          Insured: {el?.insuredFirstName} {el?.insuredLastName}
                        </Typography>
                        <Typography component="h5">
                          Member ID: {el?.payerInfoMemberId}
                        </Typography>
                        <Typography component="h5">
                          Group ID: {el?.payerInfoGroupId}
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Typography component="h5">
                          Copay: ${el?.payerInfoCopayAmount}
                        </Typography>
                        <Typography component="h5">
                          Co-Insurance: ${el?.payerInfoCoInsurancePercent}
                        </Typography>
                        <Typography component="h5">
                          Deductible: ${el?.payerInfoDeductibleAmount}
                        </Typography>
                        <Typography component="h5">
                          Out of Pocket Max: ${el?.payerInfoOutOfPocketMax}
                        </Typography>
                      </Grid>
                 
                    </Grid>
                    </Box>
                  ))}
                </CustomAccordion>
              )
            );
          })}
        </>
      )}

      <CustomModal
        open={openInsModal}
        handleClose={() => {
          formik.setFieldValue("isPatientInsured", false);
          formik.setFieldValue("isSamePatient", false);
          setOpenInsModal(false);
        }}
        heading="Add Policy"
      >
        <InsuredModalForm
          tabValue={tabValue}
          setTabValue={setTabValue}
          formik={formik}
          handleAddInsurance={handleAddInsurance}
          priorityId={priorityId}


          // editMode={editMode}
          // insuredDataForEdit={insuredDataForEdit}
        />
      </CustomModal>

      <CustomModal
        open={openLinkFamilyModal}
        handleClose={() => setOpenLinkFamilyModal(false)}
        heading={"Patient Search"}
        height={"20px"}
        width={"80%"}
      >
        <LinkFamily handleCellClick={handleLinkFamily} />
      </CustomModal>

      <CustomModal
        open={openLinkFamilyOptModal}
        // handleClose={() => setOpenLinkFamilyOptModal(false)}
        heading={"Search Link"}
        height={"20px"}
        isLoading={true}
      >
        {insuredData && (
          <LinkFamilyOpt
            formik={formik}
            insuredData={insuredData}
            handleLinkRelation={handleLinkRelation}
            handleClose={() => setOpenLinkFamilyOptModal(false)}
          />
        )}
      </CustomModal>
    </Box>
  );
};

export default MainInsurance;




