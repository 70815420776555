import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData } from "../../config/axiosFunctions";

export const getModifiers = createAsyncThunk("getModifiers", async (data) => {
  try {
    const response = await getData(`${path}/ct-modifierCode`);
    if (response) {
        console.log(response, "getModifiers response");
      return response?.result || [];
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error);
  }
});
