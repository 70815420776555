import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData, updateData } from "../../config/axiosFunctions";
import path from "../../config/apiUrl";

export const newRemittanceAction = createAsyncThunk(
  "createRemittance",
  async (data) => {
    try {
      const response = await postData(`${path}/remittanceCode`, data);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getRemittanceAction = createAsyncThunk(
  "getRemittance",
  async () => {
    try {
      const response = await getData(`${path}/remittanceCode`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

// get remarks remittance
export const getRemarkRemittanceAction = createAsyncThunk(
  "getRemarkRemittance",
  async () => {
    try {
      const response = await getData(`${path}/remittanceRemark`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);
// get adjustment remittance
export const getAdjustmentRemittanceAction = createAsyncThunk(
  "getAdjustmentRemittance",
  async () => {
    try {
      const response = await getData(`${path}/adjustmentRemark`);
      if (response) {
        return response;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

// search
export const getRemittanceBySearchTermAction = createAsyncThunk(
  "getRemittance",
  async (val) => {
    try {
      const response = await getData(
        `${path}/remittanceCode/SearchRemittanceCode?searchValue=${val}`
      );
      console.log(response, "action remittance by search term");
      if (response) {
        return response;
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const updateRemittanceAction = createAsyncThunk(
  "updateRemittance",
  async (data) => {
    try {
      const response = await updateData(
        `${path}/remittanceCode/${data.remittanceCodeId}`,
        data
      );
      console.log(response, "update remittance action response");
      return response;
    } catch (error) {
      throw error;
    }
  }
);
