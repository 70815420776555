import React from "react";
import { useState } from "react";
import MainHeading from "../../../components/MainHeading";
import { Box, FormControl,Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useFormik } from "formik";
import { diagnosisInitVal } from "../../../utils/formikInitValues";
import { newDiagnosisAction } from "../../../features/actions/diagnosisAction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomField from "../../../components/CustomField";
import CustomDatePicker from "../../../components/CustomDatePicker";
import DiagnosisForm from "./DiagnosisForm";
import { diagnosisSchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";


const CreateDiagnosis = () => {
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const navigate = useNavigate();
  // const handleCancel = () => {
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/diagnosis");
  //   }
  // };
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: diagnosisInitVal,
    validationSchema : diagnosisSchema,
    onSubmit: (values, action) => {
      console.log(values, "checkDiagnosisVals");
      dispatch(newDiagnosisAction(values));
      action.resetForm();
      navigate("/diagnosis");
    },
  });
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/diagnosis")
  };
  return (
    <>
     
     <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
     <MainHeading mainheading={"New Diagnosis"} />
      <form onSubmit={formik.handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >
          <CustomButton type="submit" isBlue={true} isSave={true}>
            Save
          </CustomButton>

          <CustomButton isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>
        </Box>
        <DiagnosisForm formik={formik} />
       
      </form>
      </Box>
      <CustomDialog
 open={openCancelDialog}
 isWarning={true}
 handleClose={() => setOpenCancelDialog(false)}
 handleConfirm={handleConfirmCancel}
 handleCancel={() => setOpenCancelDialog(false)}
 confirmButtonTitle="OK"
 cancelButtonTitle="Cancel"
>
 <Typography component="h3">Are you sure you want to cancel?</Typography>
</CustomDialog>
    </>
  );
};

export default CreateDiagnosis;
