// import React from "react";
// import FacilityInfo from "./FacilityInfo";
// import { useFormik } from "formik";
// import { useDispatch } from "react-redux";
// import { newFacilityAction } from "../../../features/actions/facilityAction";
// import { useNavigate } from "react-router-dom";
// import { facilityInitVals } from "../../../utils/formikInitValues";
// import CustomButton from "../../../components/CustomButton";
// import MainHeading from "../../../components/MainHeading";
// import { Box } from "@mui/material";
// import { facilitySchema } from "../../../schemas/patientSchema";

// const NewFacility = () => {
//   const dispatch = useDispatch();
//   const handleCancel = () => {
//     console.log("object");
//     const conform = window.confirm("Are you sure you want to cancel?");
//     if (conform) {
//       formik.resetForm();
//       navigate("/facility");
//     }
//   };
//   const navigate = useNavigate();
//   const formik = useFormik({
//     initialValues: facilityInitVals,
//     validationSchema: facilitySchema,
//     onSubmit: (values, action) => {
//       console.log(values, "checkFacilityVals");
//       dispatch(newFacilityAction(values));
//       action.resetForm();
//       navigate("/facility");
//     },
//   });
//   return (
//     <>
    
//       <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
//         <MainHeading mainheading={"New Facility"} />
//         <form onSubmit={formik.handleSubmit}>
//           <Box
//             display="grid"
//             gap="30px"
//             margin={"20px 0"}
//             sx={{
//               gridTemplateColumns: {
//                 xs: "repeat(1, minmax(0, 150px))",
//                 sm: "repeat(1, minmax(0, 150px))",
//                 md: "repeat(2, minmax(0, 150px))",
//               },
//             }}
//           >
//             <CustomButton type="submit" isBlue={true} isSave={true}>
//               Save
//             </CustomButton>
//             <CustomButton
//               isCancel={true}
//               handleClick={() => {
//                 let confirm = window.confirm(
//                   "Are you sure you want to cancel?"
//                 );
//                 if (confirm) {
//                   formik.resetForm();
//                   navigate("/facility");
//                 }
//               }}
//             >
//               Cancel
//             </CustomButton>
//           </Box>
//           <FacilityInfo formik={formik} />
//         </form>
//       </Box>
//     </>
//   );
// };

// export default NewFacility;



import React, { useState } from "react";
import FacilityInfo from "./FacilityInfo";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { newFacilityAction } from "../../../features/actions/facilityAction";
import { useNavigate } from "react-router-dom";
import { facilityInitVals } from "../../../utils/formikInitValues";
import CustomButton from "../../../components/CustomButton";
import MainHeading from "../../../components/MainHeading";
import { Box, Typography } from "@mui/material";
import { facilitySchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";

const NewFacility = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/facility");
  };

  const formik = useFormik({
    initialValues: facilityInitVals,
    validationSchema: facilitySchema,
    onSubmit: (values, action) => {
      console.log(values, "checkFacilityVals");
      dispatch(newFacilityAction(values));
      action.resetForm();
      navigate("/facility");
    },
  });

  return (
    <>
      <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
        <MainHeading mainheading={"New Facility"} />
        <form onSubmit={formik.handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 150px))",
                sm: "repeat(1, minmax(0, 150px))",
                md: "repeat(2, minmax(0, 150px))",
              },
            }}
          >
            <CustomButton type="submit" isBlue={true} isSave={true}>
              Save
            </CustomButton>
            <CustomButton isCancel={true} handleClick={handleCancel}>
              Cancel
            </CustomButton>
          </Box>
          <FacilityInfo formik={formik} />
        </form>
      </Box>

      <CustomDialog
        open={openCancelDialog}
        isWarning={true}
        handleClose={() => setOpenCancelDialog(false)}
        handleConfirm={handleConfirmCancel}
        handleCancel={() => setOpenCancelDialog(false)}
        confirmButtonTitle="OK"
        cancelButtonTitle="Cancel"
      >
        <Typography component="h3">Are you sure you want to cancel?</Typography>
      </CustomDialog>
    </>
  );
};

export default NewFacility;






