import { createSlice } from "@reduxjs/toolkit";
import {
  getProviderAction,
  getProviderByIdAction,
  newProviderAction,
  updateProviderAction,
} from "../actions/providerAction";
import { toast } from "react-toastify";

const initialState = {
  createProviderData: {},
  getProviders: [],
  providerById: {},
  loading: false,
  singleProviderLoading: false,
  error: null,
};
const providerSlice = createSlice({
  name: "provider",
  initialState: initialState,
  extraReducers: {
    [newProviderAction.pending]: (state) => {
      state.loading = true;
    },
    [newProviderAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createProviderData = action.payload;
      toast.success("Provider has been created Successfully!");
    },
    [newProviderAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Provider creation failed");
    },
    [getProviderAction.pending]: (state) => {
      state.loading = true;
    },
    [getProviderAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getProviders = action.payload;
    },
    [getProviderAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getProviderByIdAction.pending]: (state) => {
      state.singleProviderLoading = true;
    },
    [getProviderByIdAction.fulfilled]: (state, action) => {
      state.singleProviderLoading = false;
      state.providerById = action.payload;
    },
    [getProviderByIdAction.rejected]: (state, action) => {
      state.singleProviderLoading = false;
      state.error = action.payload;
    },
    [updateProviderAction.pending]: (state) => {
      state.loading = true;
    },
    [updateProviderAction.fulfilled]: (state, action) => {
      state.loading = false;
      toast.success("Provider Updated Successfully!");
    },
    [updateProviderAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Provider Updation Failed");
    },
  },
});

export default providerSlice.reducer;
