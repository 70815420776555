import React from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "antd";
const ProcedureTable = ({
  formik,
  claimChargesDto,
  setClaimChargesDto,
  handleEditProcedure,
}) => {
  const theme = useTheme();
  const rows = formik.values.claimChargesDto.map((el, index) => ({
    id: el.procedureCodeId || index,
    ...el,
  }));

  const columns = [
    {
      field: "fromDate",
      headerName: "From",
      width: 110,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "toDate",
      headerName: "To",
      width: 110,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },

    {
      field: "procedureCode",
      headerName: "Procedure",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "posCode",
      headerName: "POS",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "tosCode",
      headerName: "TOS",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "mod_Code1",
      headerName: "Mod 1",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "mod_Code2",
      headerName: "Mod 2",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "mod_Code3",
      headerName: "Mod 3",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "mod_Code4",
      headerName: "Mod 4",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "dxPointer",
      headerName: "DX Pointer",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "units",
      headerName: "Units",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "amountBilled",
      headerName: "Amount",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      renderCell: ({ row }) => (
        <Typography>{row?.unitPrice * row?.units}</Typography>
      ),
    },
    {
      field: "claimStatus",
      headerName: "Status",
      width: 250,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
   
    },
    {
      field: "",
      headerName: "Action",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteProcedure(params)}
            fontSize="medium"
          />
          <EditIcon
            style={{ cursor: "pointer" }}
            fontSize="medium"
            onClick={() => handleEditProcedure(params)}
          />
        </Box>
      ),
    },
  ];

  const insColumns = [
    {
      field: "fromDate",
      headerName: "Service Date",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },

    {
      field: "procedureCode",
      headerName: "HCPCS",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "mod_Code1",
      headerName: "Mod 1",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "mod_Code2",
      headerName: "Mod 2",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "mod_Code3",
      headerName: "Mod 3",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "mod_Code4",
      headerName: "Mod 4",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "revCodeId",
      headerName: "Rev Code",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "description",
      headerName: "Description",
      width: 350,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "units",
      headerName: "Units",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "amountBilled",
      headerName: "Amount",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "",
      headerName: "Action",
      width: 90,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteProcedure(params)}
            fontSize="medium"
          />
          <EditIcon
            style={{ cursor: "pointer" }}
            fontSize="medium"
            onClick={() => handleEditProcedure(params)}
          />
        </Box>
      ),
    },
  ];

  const handleDeleteProcedure = (e) => {
    let claimChargesDto = formik.values?.claimChargesDto;
    let updateClaimChargesDto = claimChargesDto?.filter(d => d?.procedureCodeId !== e?.row?.id)

    formik.setFieldValue("claimChargesDto", updateClaimChargesDto);
  };
  return (
    <DataGrid
      style={{
        borderRadius: "20px",
        boxShadow: "3px 4px 11px 0px rgba(0, 0, 0, 0.10)",
      }}
      rows={rows}
      columns={formik.values.claimTypeId === 2 ? insColumns : columns}
      sx={{
        "& .header-bg": {
          // backgroundColor: "lightgrey",
          background: theme.palette.dataGridHeading.primary,
          color: theme.palette.textMainHeading.primary,
          // color: 'black',
          fontSize: "14px",
        },
        "& .cell-content": {
          fontSize: "12.5px",
        },
      }}
      autoHeight
      components={{
        NoRowsOverlay: () => (
          <div style={{ width: "100%", textAlign: "center", padding: "16px" }}>
            {rows.length === 0 && "No Data Is Added"}
          </div>
        ),
      }}
    />
  );
};

export default ProcedureTable;
