// // import { Box, CircularProgress } from "@mui/material";
// import React from "react";
// import "./Custom-styling/Loader.css";
// import Loader from '../Loader.json'
// import Lottie from 'lottie-react';

// const Loading = () => {
//   return (
//     // <section class="dots-container">
//     //   <div class="dot"></div>
//     //   <div class="dot"></div>
//     //   <div class="dot"></div>
//     //   <div class="dot"></div>
//     //   <div class="dot"></div>
//     // </section>
//     <div className="loader-container">
// <Lottie className="loader" animationData={Loader}/>
//     </div>
//   );
// };

// export default Loading;



// import { Box, CircularProgress } from "@mui/material";
import React from "react";
import "./Custom-styling/Loader.css";
import Loader from '../Loader.json'
import Lottie from 'lottie-react';

const Loading = () => {
  return (
    <div className="center-container">
      <div className="loader-container">
        <Lottie className="loader" animationData={Loader}/>
      </div>
    </div>
  );
};

export default Loading;



