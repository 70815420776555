import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAdjustmentRemittanceAction,
  getRemarkRemittanceAction,
  getRemittanceAction,
  newRemittanceAction,
  updateRemittanceAction,
} from "../actions/remittanceAction";

const initialState = {
  createRemittanceData: {},
  getRemittance: [],
  remarkData: [],
  adjustmentData: [],
  loading: false,
  error: null,
};
const remittanceSlice = createSlice({
  name: "remittance",
  initialState: initialState,
  extraReducers: {
    [getRemittanceAction.pending]: (state) => {
      state.loading = true;
    },
    [getRemittanceAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getRemittance = action.payload.result;
    },
    [getRemittanceAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [newRemittanceAction.pending]: (state) => {
      state.loading = true;
    },
    [newRemittanceAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.createRemittanceData = action.payload;
      toast.success("Remittance has been created Successfully!");
    },
    [newRemittanceAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      toast.error("Remittance creation failed :(");
    },
    [updateRemittanceAction.pending]: (state) => {
      state.loading = true;
    },
    [updateRemittanceAction.fulfilled]: (state, action) => {
      state.loading = false;
      const findRemitIndex = state.getRemittance.result?.findIndex(
        (val) => val.remittanceCodeId === action.payload.remittanceCodeId
      );
      if (findRemitIndex !== -1) {
        state.getRemittance.result[findRemitIndex] = {
          ...state.getRemittance.result[findRemitIndex],
          ...action.payload,
        };
      }
      toast.success("Remittance Updated Successfully!");
    },

    
    [updateRemittanceAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      toast.error("Remittance updation failed :(");
    },
    [getRemarkRemittanceAction.pending]: (state) => {
      state.loading = true;
    },
    [getRemarkRemittanceAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.remarkData = action.payload.result;
    },
    [getRemarkRemittanceAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getAdjustmentRemittanceAction.pending]: (state) => {
      state.loading = true;
    },
    [getAdjustmentRemittanceAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.adjustmentData = action.payload.result;
    },
    [getAdjustmentRemittanceAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default remittanceSlice.reducer;
