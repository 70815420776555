import React from "react";
import { Box, Stack } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllUsers } from "../../../features/actions/userAction";
import CustomPreview from "./../../../layout/CustomPreview";
import { userCols } from "../../../utils/gridCols";
const AllUser = () => {
  const navigate = useNavigate();
  const { allUsers, loading } = useSelector((state) => state.users);

  const rows =
    allUsers && allUsers.result
      ? allUsers.result.map((val) => ({
          id: val?.user?.id,
          email: val?.user?.email,
          name: val?.user?.userName,
        }))
      : [];

  return (
    <Box>
      <CustomPreview
        rows={rows}
        columns={userCols}
        loading={loading}
        handleGetAll={getAllUsers}
        moduleHeading="All Users"
        searchLabel="Search by name, email"
        handleCell={(params) => {
          if (params?.id) {
            navigate(`/updateuser/${params?.id}`);
          }
        }}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <CustomButton
            isBlue={true}
            isAdd={true}
            handleClick={() => navigate("/registeruser")}
          >
            New User
          </CustomButton>
        </Stack>
      </CustomPreview>
    </Box>
  );
};

export default AllUser;
