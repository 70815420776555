import React, { useState } from "react";
import MainHeading from "../../../components/MainHeading";
import TextField from "@mui/material/TextField";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import { useEffect } from "react";
import { Formik, useFormik } from "formik";
import { paymentPlanValues } from "../../../utils/formikInitValues";
import CustomDataGrid from "../../../components/CustomDataGrid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpTwoToneIcon from "@mui/icons-material/ArrowDropUpTwoTone";

import {
  Box,
  Grid,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  useTheme,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import CheckBox from "../../../components/CheckBox";
import CustomButton from "../../../components/CustomButton";
import CustomField from "../../../components/CustomField";
import AddCharges from "../../patient/payment-plans/AddCharges";
import CustomModal from "../../../components/CustomModal";
import "../../patient/payment-plans/PaymentPlans.css";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import TextArea from "../../../components/TextArea";
import { newPaymentPlanCols } from "../../../utils/gridCols";

const NewPaymentPlans = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [hoveredStep, setHoveredStep] = React.useState(null);
  const [addCharges, setAddCharges] = useState(false);
  const [openAddCharges, setOpenAddCharges] = useState(false);
  const [paymentPlanInstallmentOpt, setPaymentPlanInstallmentOpt] = useState(
    []
  );

  const theme = useTheme();
  console.log(paymentPlanInstallmentOpt, "paymentPlanInstallmentOpt");
  const [dateOfServiceOtions, setDateOfServiceOptions] = useState([]);
  const [paymentPlanScheduleOptions, setPaymentPlanScheduleOptions] = useState(
    []
  );
  const [insTableShow, setInsTableShow] = useState(false);
  const [result, setResult] = useState("");
  // const [totalInstallment, setTotalInstallment] = useState(0);
  const [rows, setRows] = useState([]);
  const [value, setValue] = useState(0);

  const handleIncrement = () => {
    const newValue = formik.values.totalNumberOfPayments + 1;
    formik.setFieldValue("totalNumberOfPayments", newValue);
  };

  const handleDecrement = () => {
    const newValue = formik.values.totalNumberOfPayments - 1;
    formik.setFieldValue("totalNumberOfPayments", newValue);
  };

  const insTable = () => {
    setInsTableShow(!insTableShow);
  };

  const formik = useFormik({
    initialValues: paymentPlanValues,
    onSubmit: (values) => {
      console.log(values, "value of paymentplan");
    },
  });
  console.log(formik.values, "allFormikValues");

  const dataFetchUrls = {
    // extra
    dateOfService: `${path}/ct-dos`,
    paymentPlanSchedule: `${path}/ct-paymentPlanSchedule`,
    paymentPlanInstallments: `${path}/ct-paymentPlanInstallments`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.dateOfService, setDateOfServiceOptions);
    fetchDataOptions(
      dataFetchUrls.paymentPlanSchedule,
      setPaymentPlanScheduleOptions
    );
    fetchDataOptions(
      dataFetchUrls.paymentPlanInstallments,
      setPaymentPlanInstallmentOpt
    );
  }, []);

  const steps = [
    "Step 1: Select Amount And Add Charges",
    "Step 2: Generate Installments",
    "Step 3: Review And Finalize",
  ];

  const handleCharges = () => {
    setAddCharges(!addCharges);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // handleGenerateInstallment function
  const handleGenerateInstallment = () => {
    if (formik.values.eachInstallmentAmount > 0) {
      const installmentAmount = formik.values.eachInstallmentAmount;
      const selectedOptionId = formik.values.paymentDueEvery;
      const totalEachInstallment =
        formik.values.paymentPlanAmount / formik.values.eachInstallmentAmount;
      let frequency;
      if (selectedOptionId === 1) {
        frequency = "month";
      } else if (selectedOptionId === 2) {
        frequency = "week";
      } else if (selectedOptionId === 3) {
        frequency = "day";
      }
      const startDate = new Date();
      const newRows = [];
      let totalInstallment = 0;
      // for (let i = 1; i <= installmentAmount; i++)
      for (let i = 0; i < totalEachInstallment; i++) {
        const installmentDate = new Date(startDate);
        if (frequency === "month") {
          installmentDate.setMonth(startDate.getMonth() + i);
        } else if (frequency === "week") {
          installmentDate.setDate(startDate.getDate() + i * 7);
        } else if (frequency === "day") {
          installmentDate.setDate(startDate.getDate() + i);
        }
        totalInstallment += totalEachInstallment;
        newRows.push({
          id: i + 1,
          date: installmentDate.toLocaleDateString("en-US"),
          installment: installmentAmount.toFixed(2),
          description: `Installment ${i + 1} of ${installmentAmount}`,
        });
      }
      setRows(newRows);
      return totalInstallment;
    }

    // else if (formik.values.totalNumberOfPayments >= 1){
    //   console.log('totalNumberOfPayments')
    // }
    else if (formik.values.totalNumberOfPayments >= 1) {
      const totalPayments = formik.values.totalNumberOfPayments;
      const selectedOptionId = formik.values.paymentDueEvery;
      let frequency;
      if (selectedOptionId === 1) {
        frequency = "month";
      } else if (selectedOptionId === 2) {
        frequency = "week";
      } else if (selectedOptionId === 3) {
        frequency = "day";
      }
      const startDate = new Date();
      const newRows = [];
      let totalInstallment = 0;
      for (let i = 0; i < totalPayments; i++) {
        const installmentDate = new Date(startDate);
        if (frequency === "month") {
          installmentDate.setMonth(startDate.getMonth() + i);
        } else if (frequency === "week") {
          installmentDate.setDate(startDate.getDate() + i * 7);
        } else if (frequency === "day") {
          installmentDate.setDate(startDate.getDate() + i);
        }
        const totalEachInstallment =
          formik.values.paymentPlanAmount / formik.values.totalNumberOfPayments;
        totalInstallment += totalEachInstallment;
        newRows.push({
          id: i + 1,
          date: installmentDate.toLocaleDateString("en-US"),
          installment: totalEachInstallment.toFixed(2),
          description: `Installment ${i + 1} of ${totalPayments}`,
        });
      }
      setRows(newRows);
      return totalInstallment;
    } else if (formik.values.lastPaymentDate === null) {
      console.log("last payment");
    } else if (
      formik.values.generateInstallmentId !== 1 &&
      formik.values.generateInstallmentId !== 2 &&
      formik.values.generateInstallmentId !== 3
    ) {
      console.log("no value select");
    } else {
      console.log("Invalid input");
      return 0;
    }
  };

  const totalInstallment = rows.reduce(
    (acc, row) => acc + parseFloat(row.installment),
    0
  );

  const handleRadioChange = (item) => {
    console.log(item, "itemss");
    formik.setFieldValue(
      "generateInstallmentId",
      item?.paymentPlanInstallmentsId
    );
    formik.setFieldValue(
      "generateInstallmentName",
      item?.paymentPlanInstallmentsName
    );
    if (item?.paymentPlanInstallmentsId === 1) {
      formik.setFieldValue("totalNumberOfPayments", "");
      formik.setFieldValue("lastPaymentDate", "");
    } else if (item?.paymentPlanInstallmentsId === 2) {
      formik.setFieldValue("eachInstallmentAmount", "");
      formik.setFieldValue("lastPaymentDate", "");
    } else if (item?.paymentPlanInstallmentsId === 3) {
      formik.setFieldValue("eachInstallmentAmount", "");
      formik.setFieldValue("totalNumberOfPayments", "");
    } else {
      console.log("no value select");
    }
  };

  // formik.setFieldValue("lastPaymentDate", new Date());
  // const endDate = formik.values.lastPaymentDate;

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid
              md={12}
              // sx={{ margin: "5px 20px 0" }}
              sx={{ margin: "20px" }}
            >
              <CheckBox label="There are no existing charges to associate to this payment plan" />
            </Grid>
            <Grid md={12} sx={{ margin: "5px 20px 0" }}>
              <CheckBox
                label="There are one or more existing charges that this payment plans covers"
                handleChange={handleCharges}
              />
            </Grid>
            {addCharges ? (
              <Grid md={12} sx={{ margin: "5px 20px 0" }}>
                <CustomButton
                  isBlue={true}
                  isAdd={true}
                  handleClick={() => setOpenAddCharges(true)}
                >
                  Add Charges
                </CustomButton>
              </Grid>
            ) : null}
            <Grid md={4} margin={"10px 10px"}>
              <CustomField
                label={"Payment Plan Amount"}
                type="number"
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                value={formik.values.paymentPlanAmount}
                name="paymentPlanAmount"
                isDecimal={true}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <>
            <Grid
              container
              spacing={2}
              padding="18px"
              // onSubmit={panelFormik.handleSubmit}
            >
              <Grid item xs={12} sm={12} md={8}>
                <CustomDatePicker
                  dateLabel="First Payment Plan Date"
                  dateValue={formik.values.firstPayDate}
                  name="firstPayDate"
                  formik={formik}
                  handleDateChange={formik.handleChange}
                  handleDateBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} padding="18px">
              <Grid xs={12} sm={12} md={2}>
                <Typography margin={"19px 0 0 20px"} variant="h3">
                  Payment Due Every:
                </Typography>
              </Grid>

              <Grid md={2} sm={12} xs={12}>
                             <div
                                          style={{
                                            position: "relative",
                                            // width: "100px",
                                            // display: "inline-block",
                                            marginTop:"12px",
                                            marginLeft:'10px'
                                          }}
                                        >
                                          <CustomField
                                            type="number"
                                            value={
                                              formik.values.paymentDueEveryCount
                                            }
                                            name="eachInstallmentAmount"
                                            handleChange={formik.handleChange}
                                            handleBlur={formik.handleBlur}
                                         
                                          />
                                          <div
                                            style={{
                                              position: "absolute",
                                              right: "5px",
                                              top: "3px",
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              pointerEvents: "none",
                                            }}
                                          >
                                            <ArrowDropUpTwoToneIcon
                                              style={{
                                                pointerEvents: "auto",
                                                cursor: "pointer",
                                              }}
                                              handleClick={handleIncrement}
                                            />
                                            <ArrowDropDownIcon
                                              style={{
                                                pointerEvents: "auto",
                                                cursor: "pointer",
                                              }}
                                              handleClick={handleDecrement}
                                            />
                                          </div>
                                        </div></Grid>

              <Grid item xs={12} sm={12} md={2}>
                <CustomSelectBox2
                  showDefaultOpt={true}
                  value={formik.values.paymentDueEvery}
                  name="paymentDueEvery"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  formik={formik}
                  dropdownOptions={paymentPlanScheduleOptions?.map((opt) => ({
                    value: opt.paymentPlanScheduleName,
                    id: opt.paymentPlanScheduleId,
                  }))}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid xs={12} sm={12} md={12}>
                <Typography margin={"19px 0 0 35px"} variant="h3">
                  Generate Installments By:
                </Typography>
              </Grid>

              <Grid md={12} sm={12} xs={12}>
                <FormControl sx={{ fontSize: "1.5rem",margin:"19px 0 0 35px" }}>
                  <RadioGroup
                    defaultValue="outlined"
                    name="radio-buttons-group"
                    sx={{ fontSize: "20px !important" }}
                  >
                    {paymentPlanInstallmentOpt?.map((item) => {
                      console.log(item, "items");
                      console.log(item.paymentPlanInstallmentsId);
                      return (
                        <>
                          <FormControlLabel
                            key={item?.paymentPlanInstallmentsId}
                            value={item?.paymentPlanInstallmentsId}
                            control={
                              <Radio
                                key={item?.paymentPlanInstallmentsId}
                                variant="outlined"
                                checked={
                                  formik.values.generateInstallmentId ===
                                  item?.paymentPlanInstallmentsId
                                }
                                // onChange={() => {
                                //   formik.setFieldValue(
                                //     "generateInstallmentId",
                                //     item?.paymentPlanInstallmentsId
                                //   );
                                //   formik.setFieldValue(
                                //      "generateInstallmentName",
                                //     item?.paymentPlanInstallmentsName
                                //   );
                                // }}
                                onChange={() => handleRadioChange(item)}
                              />
                            }
                            label={
                              <>
                              
                                  <Typography
                                    fontSize={"1.3rem"}
                                    fontWeight="600"
                                  >
                                    {item?.paymentPlanInstallmentsName}
                                  </Typography>
                           
                                
                                  {formik.values.generateInstallmentId ===
                                  item.paymentPlanInstallmentsId ? (
                                    item.paymentPlanInstallmentsId === 1 ? (
                                      <CustomField
                                        type="number"
                                        label="Each Installment Amount"
                                        value={
                                          formik.values.eachInstallmentAmount
                                        }
                                        name="eachInstallmentAmount"
                                        handleChange={formik.handleChange}
                                        handleBlur={formik.handleBlur}
                                        isDecimal={true}
                                      />
                                    ) : item.paymentPlanInstallmentsId === 2 ? (
                                      <>
                                        <div
                                          style={{
                                            position: "relative",
                                            // width: "100px",
                                            // display: "inline-block",
                                        
                                          }}
                                        >
                                         <CustomField
                                            type="text"
                                            label="Total Number Of Payments"
                                            value={
                                              formik.values
                                                .totalNumberOfPayments
                                            }
                                            name="totalNumberOfPayments"
                                            handleChange={formik.handleChange}
                                            handleBlur={formik.handleBlur}
                                            style={{
                                              textAlign: "center",
                                              margin: "0 10px",
                                              width: "50px",
                                            }}
                                          />
                                          <div
                                            style={{
                                              position: "absolute",
                                              right: "5px",
                                              top: "20px",
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              pointerEvents: "none",
                                            }}
                                          >
                                            <ArrowDropUpTwoToneIcon
                                              style={{
                                                pointerEvents: "auto",
                                                cursor: "pointer",
                                              }}
                                             onClick={handleIncrement}
                                            />
                                            <ArrowDropDownIcon
                                              style={{
                                                pointerEvents: "auto",
                                                cursor: "pointer",
                                              }}
                                              onClick={handleDecrement}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : item.paymentPlanInstallmentsId === 3 ? (
                                      <CustomDatePicker 
                                        formik={formik}
                                        dateLabel="Plan End Date"
                                        name="lastPaymentDate"
                                        dateValue={
                                          formik.values.lastPaymentDate
                                        }
                                        handleDateChange={formik.handleChange}
                                        handleDateBlur={formik.handleBlur}
                                      />
                                    ) : (
                                      item.paymentPlanInstallmentsName
                                    )
                                  ) : (
                                    ""
                                  )}
                              
                              </>
                            }
                          />
                        </>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <CustomButton handleClick={handleGenerateInstallment}>
              Generate Installments
            </CustomButton>
            {/* <Typography>{result}</Typography> */}
            <Typography>Result:</Typography>
            <Typography variant="h6">
              Payment Plan Amount: {formik.values.paymentPlanAmount}
            </Typography>
            <CustomDataGrid columns={newPaymentPlanCols} rows={rows} />
            <Typography variant="h6">
              Total Installment: {totalInstallment.toFixed(2)}
            </Typography>
          </>
        );
      case 2:
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <CustomField label="Title" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} marginTop={2}>
                <TextArea
                  placeholder="Description"
                  minRows={4}
                  value={formik.values.description}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  name="description"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} marginTop={1}>
                <CustomField label="Practice" />
              </Grid>
              <Grid md={12} sm={12} xs={12} margin={"10px 0 0 18px"}>
                <Typography variant="h6">
                  Payment Plan Amount: {formik.values.paymentPlanAmount}
                </Typography>
              </Grid>

              <Grid md={12} sm={12} xs={12}>
                <CustomButton handleClick={insTable}>Installments</CustomButton>
                {insTableShow ? (
                  <CustomDataGrid columns={newPaymentPlanCols} rows={rows} />
                ) : (
                  ""
                )}
              </Grid>

              {/* <Grid
        item
        xs={12}
        sm={12}
        md={12}
      
      
      >
       <CustomDataGrid columns={newPaymentPlanCols} rows={rows} />
      </Grid> */}
            </Grid>
          </>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Box margin="20px">
      <MainHeading mainheading="New Payment Plans" />

      <Stepper
        activeStep={activeStep}
        className="custom-stepper .MuiStepIcon-root"
      >
        {steps.map((label, index) => (
          <Step
          title={label}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: "green",

                // circle color (COMPLETED)
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "grey.500",
                  fontSize: "3rem",
                  // Just text label (COMPLETED)
                },
              "& .MuiStepLabel-root .Mui-active": {
                fontSize: "1.5rem",
                // circle color (ACTIVE)
              },
              ".MuiStepLabel-labelContainer span": {
                fontSize: "1.2rem",
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "common.white",
                  // fontSize: '2.8rem'
                  // Just text label (ACTIVE)
                },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: "white",
                fontSize: "1rem",
                // circle's number (ACTIVE)
              },
            }}
            key={index}
          >
            <StepLabel
           
            >
              {/* {label} */}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {getStepContent(activeStep)}

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />

        <Button onClick={handleNext}>
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>

      {/* modal here  */}
      <CustomModal
        open={openAddCharges}
        heading="Add Charges"
        handleClose={() => setOpenAddCharges(false)}
      >
        <AddCharges />
      </CustomModal>
    </Box>
  );
};

export default NewPaymentPlans;
