import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const  getAllUsers = createAsyncThunk("getUsers" , async()=>{
    try {
        const response = await getData(`${path}/authenticate/users`);

    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
    } catch (error) {
        console.log(error, "action error");
        throw new Error(error);
    }
})

export const newUserAction = createAsyncThunk(
    "createUser",
    async (data) => {
      try {
        const response = await postData(`${path}/authenticate/register`, data);
        if (response) {
          return response;
        } else {
          throw new Error(response.error);
        }
      } catch (error) {
        console.log(error, "check Error");
        throw new Error(error);
      }
    }
  );
  
  export const updateUserAction = createAsyncThunk(
    "updateUser",
    async (data) => {
      try {
        const response = await updateData(
          `${path}/authenticate/register/${data.userId}`,
          data
        );
        console.log(response, "update user action response");
        return response;
      } catch (error) {
        throw error;
      }
    }
  );

  export const updateUserPasswordAction = createAsyncThunk(
    "updateUserPassword",
    async (data) => {
      try {
        const response = await updateData(
          `${path}/authenticate/changePassword/${data.userId}`,
          data
        );
        console.log(response, "update user password response");
        return response;
      } catch (error) {
        throw error;
      }
    }
  );