import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function ProtectedRoute() {
  const getTokenFromCookie = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith("token=")) {        
        return cookie.substring("token=".length);
      }
    }
    return null;
  };

  const token = getTokenFromCookie();

  return token ? <Outlet /> : <Navigate to="/" />;
}
