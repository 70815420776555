import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getPayerInfoAction } from "../actions/payerInfoAction";

const initialState = {
  payerInfoData: {},
  getAllPayerInfo: [],
  loading: false,
  error: null,
};
const payerInfoSlice = createSlice({
  name: "patient",
  initialState: initialState,
  extraReducers: {
    [getPayerInfoAction.pending]: (state) => {
      state.loading = true;
    },
    [getPayerInfoAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.getAllPayerInfo = action.payload.result;
    },
    [getPayerInfoAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default payerInfoSlice.reducer;
