import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { DataGrid } from "@mui/x-data-grid";
import { searchedEraCols } from "../../../utils/gridCols";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { useNavigate } from "react-router-dom";
import { getData } from "../../../config/axiosFunctions";
import path from "./../../../config/apiUrl";
import { useDispatch } from "react-redux";
import { setEraDetailDataAction } from "../../../features/slice/eraSlice";
import { toast } from "react-toastify";
const SearchedEra = ({ handleClose }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [demoEraData, setDemoEraData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rows =
    demoEraData && demoEraData.length > 0
      ? demoEraData?.map((row) => ({
          id: row?.id,
          checkDate: new Date(row?.checkDate).toLocaleString(),
          rptDate: new Date(row?.rptDate).toLocaleString(),
          ...row,
        }))
      : [];

  useEffect(() => {
    const handleFetchEraData = async () => {
      try {
        const response = await getData(`${path}/demo`);
        setDemoEraData(response);
      } catch (error) {
        console.log(error, ": error in fetching era data");
      }
    };
    handleFetchEraData();
  }, []);

  // handle select change
  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
    const lastSeletedRow = demoEraData?.find(
      (row) => row.id === newSelection[newSelection.length - 1]
    );
    setSelectedRow(lastSeletedRow);
  };
  // row click
  const handleRowClick = (params, event) => {
    const isCheckboxClick = event.target.tagName === "INPUT";
    if (!isCheckboxClick) {
      setSelectionModel([params.id]);
    }
  };

  // start review or apply view report
  const handleReview = (rowData) => {
    if (rowData) {
      dispatch(setEraDetailDataAction(rowData?.nestedPayments));
      navigate(`/payment/era/search/fileName`);
    } else {
      toast.error("Please select row");
    }
  };
  return (
    <Box>
      <Stack alignItems="center" gap={2} direction="row">
        <CustomButton isBlue={false} isCancel={true} handleClick={handleClose}>
          Close
        </CustomButton>
      </Stack>

      <Stack direction="row" alignItems="flex-start" gap={2}>
        <Box sx={{ width: "70%" }}>
          <DataGrid
            rows={rows}
            columns={searchedEraCols}
            sx={{
              "& .header-bg": {
                // background: theme.palette.dataGridHeading.primary,
                // color: theme.palette.textMainHeading.primary,
                fontSize: "16px",
              },
              "& .cell-content": {
                fontSize: "1.2rem",
              },
              "& .MuiTablePagination-displayedRows": {
                fontSize: "1.3rem",
              },
              "& .MuiTablePagination-actions": {
                "& svg": {
                  fontSize: "1.8rem",
                  //   color: theme.palette.textMainHeading.primary,
                  marginBottom: "8px",
                },
              },
            }}
            autoHeight
            // rowHeight={reducedCellHeight}
            checkboxSelection
            onRowClick={handleRowClick}
            onSelectionModelChange={handleSelectionModelChange}
            selectionModel={selectionModel}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSize={20}
            components={{
              NoRowsOverlay: () => (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    padding: "16px",
                  }}
                >
                  {rows.length === 0 && "No Data Is Added"}
                </div>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            width: "30%",
            border: ".5px solid grey",
            borderTopLeftRadius: "8px",
            maxHeight: "450px",
            overflowY: "scroll",
          }}
        >
          <Stack rowGap={2}>
            <Box padding="15px" sx={{ background: "lightBlue" }}>
              <Typography variant="h3" component="h3">
                File Name Here
              </Typography>
            </Box>

            <Box padding="15px">
              <Stack alignItems="center" gap={2} direction="row">
                <CustomButton
                  isBlue={true}
                  handleClick={() => handleReview(selectedRow)}
                >
                  Start Review
                </CustomButton>
                <CustomButton isBlue={false}>View EOB</CustomButton>
              </Stack>

              <FormControl fullWidth>
                <FormControlLabel
                  sx={{ marginTop: "12px" }}
                  control={
                    <Checkbox
                      sx={{
                        color: "black",
                      }}
                    />
                  }
                  label="Applied"
                />
              </FormControl>

              <FormControl fullWidth>
                <CustomDatePicker dateLabel="Check Date" />
              </FormControl>

              <Stack marginTop="15px" rowGap={2}>
                {selectedRow &&
                  selectedRow.checkPayments?.map((item) => (
                    <>
                      <Card>
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Check
                          </Typography>
                          <Typography variant="h5" component="div">
                            {item?.checkNumber}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5, fontSize: "1.4rem" }}
                            color="text.secondary"
                          >
                            ${item?.amount}
                          </Typography>
                          <Typography variant="body2" component="h4">
                            {item?.payment}
                          </Typography>
                        </CardContent>
                      </Card>
                    </>
                  ))}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default SearchedEra;
