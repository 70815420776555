// import { createContext ,useContext, useState } from "react";

// export const ThemeContext = createContext()

// export const ThemeContextProvider = ({children})=> {
// const [contextTheme , setContextTheme] =useState('light')
// const values= {contextTheme ,setContextTheme}
// return(

//     <ThemeContext.Provider value={values}>
//        {children}
//     </ThemeContext.Provider>
// )
// }
// export const useThemeContext=()=>{
//     const context = useContext(ThemeContext)
//     return context
// }



// import React, { createContext, useContext, useState } from "react";

// const ThemeContext = createContext();

// export const ThemeProvider = ({ children }) => {
//   const [theme, setTheme] = useState("light");

//   const toggleTheme = () => {
//     setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
//   };

//   return (
//     <ThemeContext.Provider value={{ theme, toggleTheme }}>
//       {children}
//     </ThemeContext.Provider>
//   );
// };

// export const useThemeContext = () => {
//   return useContext(ThemeContext);
// };



import React, { createContext, useContext, useState, useMemo } from "react";

const ColorModeContext = createContext();

export const useColorMode = () => {
  const context = useContext(ColorModeContext);
  if (!context) {
    throw new Error("useColorMode must be used within a ColorModeProvider");
  }
  return context;
};

export const ColorModeProvider = ({ children }) => {
  const [mode, setMode] = useState("light");

  const toggleColorMode = () => {
    setMode((prev) => (prev === "light" ? "dark" : "light"));
  };

  const value = useMemo(() => ({ mode, toggleColorMode }), [mode]);

  return (
    <ColorModeContext.Provider value={value}>
      {children}
    </ColorModeContext.Provider>
  );
};
