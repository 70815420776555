import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientAction,
  getPatientBySearchTermAction,
} from "../../features/actions/createPatientAction";
import CustomDataGrid from "../../components/CustomDataGrid";
import CustomPreview from "../../layout/CustomPreview";
import { patientCols } from "../../utils/gridCols";

const LinkFamily = ({ handleCellClick }) => {
  const { getAllPatients, loading } = useSelector((state) => state.patient);

  const rows =
    getAllPatients && getAllPatients?.result?.patients?.length > 0
      ? getAllPatients.result?.patients?.map((el) => ({
          id: el.accountNo,
          insuredName: el?.getInsuredParty?.insuredName,
          ...el,
        }))
      : [];

  return (
    <Box padding="18px">
      <CustomPreview
        isModal={true}
        rows={rows}
        columns={patientCols}
        paginationDetail={getAllPatients}
        searchLabel="Search by Account #, First Name, Last Name, Date of Birth, Gender, Account Type"
        loading={loading}
        handleCell={(params) => handleCellClick(params)}
        handleGetAll={getPatientAction}
        handleBySearch={getPatientBySearchTermAction}
      />
    </Box>
  );
};

export default LinkFamily;
