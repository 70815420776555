
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const options = {
const getOptions=(theme)=>({
  indexAxis: 'x',
  
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'left',
      labels: {
        color: theme.palette.patientsHeading.primary, 
      },
    },
    title: {
      display: true,
      text: 'Monthly Payments',
        color: theme.palette.patientsHeading.primary, 
  
    },
  },

  scales: {
        x: { 
          ticks: {
            color: theme.palette.patientsHeading.primary, 
           
          },
          grid: {
            color: theme.palette.chartGrid.primary, 
            borderColor: theme.palette.chartGrid.primary, 
          },
          // title: {
          //   display: true,
          //   color: theme.palette.patientsHeading.primary, 
          // }
        },
        y: { 
          ticks: {
            color: theme.palette.patientsHeading.primary, 
          },
          grid: {
            color: theme.palette.chartGrid.primary, 
            borderColor: theme.palette.chartGrid.primary, 
          },
          // title: {
          //   display: true,
          //   color: theme.palette.patientsHeading.primary, 
          // }
        }
      },
});

const BarChart = ({ data }) => {
  const theme = useTheme(); 
  const options = getOptions(theme);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Payments',
        data: [],
        // backgroundColor: '#2195F2',
        backgroundColor:'#7b8be7' 

      },
    ],
  });

  useEffect(() => {
    console.log("Data received in BarChart:", data);
    if (data && data.length > 0 && data[0].monthlyPaymentDto) {
      const { monthNames, totalPayments } = data[0].monthlyPaymentDto;
      setChartData({
        labels: monthNames,
        datasets: [
          {
            label: 'Payments',
            data: totalPayments,
            backgroundColor: '#2195F2',

          },
        ],
      });
    }
  }, [data]);
  
  return (
    <Card sx={{ width: '100%', 
    // height: '300px',
    height: { xs: '500px', sm: '500px', md: '500px',lg:'320px' },
     background: theme.palette.announcementCard.primary 
     }}>
      <CardContent>
        <Bar data={chartData} options={options} />
      </CardContent>
    </Card>
  );
};

export default BarChart;