
// import React, { useState, useEffect } from 'react';
// import { Radar } from 'react-chartjs-2';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import { Chart as ChartJS, RadialLinearScale, LineElement, Filler, Tooltip, Legend, PointElement } from 'chart.js';
// import { useTheme } from '@mui/material';

// ChartJS.register(
//   RadialLinearScale, LineElement, LineElement, Filler, Tooltip, Legend, PointElement
// );

// const getOptions=(theme) =>({
//   elements: {
//     line: {
//       borderWidth: 3,
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top',
//     },
//     title: {
//       display: true,
//       text: 'Monthly Payments Ratio',
//       color: theme.palette.patientsHeading.primary, 
//     },
//   },
//   scales: {
//     r: {
//       angleLines: {
//         display: true, 
//       },
//       ticks: {
//         color: theme.palette.patientsHeading.primary, // Use a theme color for tick labels
//         // Adjust these as necessary:
//       },
//       grid: {
//         color:theme.palette.patientsHeading.primary, 
//       },
//       pointLabels: {
//         color: theme.palette.patientsHeading.primary, 
//     },
//     angleLines: {
//       color: 'rgba(255, 255, 255, 0.2)', 
//     }
//   },
// }
// });

// const PendingPayments = ({data}) => {
//   const theme=useTheme();
//   const options=getOptions(theme);
//   const [chartData, setChartData] = useState({
//     labels: [],
//     datasets: [
//       {
//         label: 'Paid Payments',
//         data: [],
//         backgroundColor: 'rgba(135, 206, 250, 0.2)', 
//         borderColor: 'rgba(135, 206, 250, 1)',                 
//         borderWidth:1,
       
//       },
//       {
//         label: 'Pending Payments',
//         data: [],
//         backgroundColor: 'red',
//         backgroundColor:'rgba(255, 99, 132, 0.2)',
//         borderColor:'rgba(255, 40, 112, 1)',
//         borderWidth:1,
//       },
//     ],
//   });

//   useEffect(() => {
//     console.log("Data received in RadarChart:", data);
//     if (data && data.length > 0 && data[0].halfYearPaymentDto) {
//       const { monthNames, totalPayments, totalPendingPayments } = data[0].halfYearPaymentDto;
//       setChartData({
//         labels: monthNames,
//           datasets: [
//             {
//               label: 'Paid Payments',
//               data: totalPayments,
//               backgroundColor: 'rgba(135, 206, 250, 0.2)', 
//               borderColor: 'rgba(135, 206, 250, 1)',               
//               borderWidth:1,
//             },
//             {
//               label: 'Pending Payments',
//               data: totalPendingPayments.map(value => value === 0 ? 0.01 : value),
//               backgroundColor:'rgba(255, 99, 132, 0.2)',
//               borderColor:'rgba(255, 40, 112, 1)',
//               borderWidth:1,
//             },
//           ],
//       });
//     }
//   }, [data]);

//   return (
//     <>
//       <Card sx={{ maxWidth: 800, 
//       height: { xs: '500px', sm: '500px', md: '500px',lg:'430px' },
  
//         // maxHeight: 600,
//         background: theme.palette.announcementCard.primary }}>
//         <CardContent sx={{ maxWidth: 800, maxHeight: 600 }}>
//           <Radar data={chartData} options={options} />
//         </CardContent>
//       </Card>
//     </>
//   );
// };

// export default PendingPayments;












import React, { useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Chart as ChartJS, RadialLinearScale, LineElement, Filler, Tooltip, Legend, PointElement } from 'chart.js';
import { useTheme, useMediaQuery } from '@mui/material';

ChartJS.register(
  RadialLinearScale, LineElement, Filler, Tooltip, Legend, PointElement
);

const getOptions = (theme) => ({
  elements: {
    line: {
      borderWidth: 3,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Monthly Payments Ratio',
      color: theme.palette.patientsHeading.primary,
    },
  },
  scales: {
    r: {
      angleLines: {
        display: true,
      },
      ticks: {
        color: theme.palette.patientsHeading.primary,
      },
      grid: {
        color: theme.palette.patientsHeading.primary,
      },
      pointLabels: {
        color: theme.palette.patientsHeading.primary,
      },
      angleLines: {
        color: 'rgba(255, 255, 255, 0.2)',
      }
    },
  }
});

const PendingPayments = ({ data }) => {
  const theme = useTheme();
  const options = getOptions(theme);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Paid Payments',
        data: [],
        backgroundColor: 'rgba(135, 206, 250, 0.2)',
        borderColor: 'rgba(135, 206, 250, 1)',
        borderWidth: 1,
      },
      {
        label: 'Pending Payments',
        data: [],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 40, 112, 1)',
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    console.log("Data received in RadarChart:", data);
    if (data && data.length > 0 && data[0].halfYearPaymentDto) {
      const { monthNames, totalPayments, totalPendingPayments } = data[0].halfYearPaymentDto;
      setChartData({
        labels: monthNames,
        datasets: [
          {
            label: 'Paid Payments',
            data: totalPayments,
            backgroundColor: 'rgba(135, 206, 250, 0.2)',
            borderColor: 'rgba(135, 206, 250, 1)',
            borderWidth: 1,
          },
          {
            label: 'Pending Payments',
            data: totalPendingPayments.map(value => value === 0 ? 0.01 : value),
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 40, 112, 1)',
            borderWidth: 1,
          },
        ],
      });
    }
  }, [data]);

  return (
    <Card sx={{
      width: '100%',
      maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: 800 },
      height: { xs: '100%', sm: '100%', md: '100%', lg: '460px' },
      background: theme.palette.announcementCard.primary,
      margin: '0 auto'
    }}>
      <CardContent sx={{ width: '100%', height: '100%' }}>
        <Radar
          data={chartData}
          options={options}
        />
      </CardContent>
    </Card>
  );
};

export default PendingPayments;







