import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClaimAction } from "../../../features/actions/claimAction";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomDataGrid from "../../../components/CustomDataGrid";
import { useTheme } from "@mui/material";
import CustomField from "../../../components/CustomField";
import CustomButton from "../../../components/CustomButton";
import Loading from "../../../components/Loading";

const ClaimTable = ({ onCellClick, isUpdate, searchTerm }) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getClaims, loading } = useSelector((state) => state.claim);
  console.log(getClaims, "getAllClaims");
  const rows =
    getClaims && getClaims.result
      ? getClaims.result.map((el) => ({
          id: el.claimId,
          claimNumber: el.claimNumber,
          patientFirstName: el.patientFirstName,
          patientLastName: el.patientLastName,
          primaryPayerInsuranceName: el.primaryPayerInsuranceName,
          payerSequenceNo: el.payerSequenceNo,
          patientAccountNo: el.patientAccountNo,
          providerId: el.providerId,
          patientId: el.patientId,
          payerId: el.payerId,
          totalBilled: el.totalBilled,
          fromDate: el?.claimChargesDto[0]?.fromDate || "N/A",
          claimStatus: el?.claimChargesDto[0]?.claimStatus || "N/A",
          claimChargesDto: el?.claimChargesDto,
          totalCharges: el.totalCharges,
          totalBilled: el.totalBilled,
        }))
      : [];

  const columns = [
    {
      field: "claimNumber",
      headerName: "Claim ID",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "totalBilled",
      headerName: "Balance",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "totalCharges",
      headerName: "Total Charges",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "name",
      headerName: "Patient",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      editable: true,
      editField: "name", // Add this line
      valueGetter: (params) =>
        `${params.row.patientFirstName} ${params.row.patientLastName}`,
      cellClassName: "cell-content",
    },
    {
      field: "fromDate",
      headerName: "DOS",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
    {
      field: "claimStatus",
      headerName: "Status",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "header-bg",
      cellClassName: "cell-content",
    },
  ];

  useEffect(() => {
    try {
      if (!searchTerm) {
        dispatch(getClaimAction());
      }
    } catch (err) {
      console.log("err", err);
    }
  }, [searchTerm]);

  return (
    <Box>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "end",
          width: { md: "60%", sm: "95%" },
          margin: "10px",
        }}
      >
        <CustomField
          type="text"
          size="small"
          label="Search by Name, DOB, account#, member ID, claim ID or TCN Number"
          value={searchTerm}
          name="searchTerm"
          handleChange={(e) => setSearchTerm(e.target.value)}
        />
        <CustomButton
          type="button"
          handleClick={handleSearchChange}
          isBlue={true}
          padding="5px 30px"
          margin={"10px 10px 10px "}
          isSearch={true}
        >
          Search
        </CustomButton>
      </Box> */}
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <CustomDataGrid
            rows={rows}
            columns={columns}
            handleCellClick={
              isUpdate
                ? (params) => navigate(`/claims/update/${params.claimNumber}`)
                : (params) => onCellClick(params)
            }
            sx={{ background: "blue" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ClaimTable;
