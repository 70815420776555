import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eraDetailData: {},
};

const eraDetailSlice = createSlice({
  name: "eraDetail",
  initialState: initialState,
  reducers: {
    setEraDetailDataAction: (state, action) => {
      console.log(action, "checkAction");
      state.eraDetailData = action.payload;
    },
  },
});

export const { setEraDetailDataAction } = eraDetailSlice.actions;
export default eraDetailSlice.reducer;
