import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomField from "../../../components/CustomField";
import CustomSelectBox from "../../../components/CustomSelectBox";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { claimInitVal } from "../../../utils/formikInitValues";
import CustomAccordion from "../../../components/CustomAccordion";
import CheckBox from "../../../components/CheckBox";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
const types = [
  {
    id: 1,
    type: "One",
  },
  {
    id: 2,
    type: "Two",
  },
  {
    id: 3,
    type: "Three",
  },
];
let epsdT_DetailDto = [
  {
    epsdT_CertificationCodeId: 1,
    epsdT_CertificationCodeType: "No referral given",
  },
  {
    epsdT_CertificationCodeId: 2,
    epsdT_CertificationCodeType: "Patient refused referral",
  },
  {
    epsdT_CertificationCodeId: 3,
    epsdT_CertificationCodeType: "Patient is currently under treatment",
  },
  {
    epsdT_CertificationCodeId: 4,
    epsdT_CertificationCodeType: "Patient is reffered to another provider",
  },
];
const InsAdditionalinfo = ({ formik }) => {
  const [admissionHour, setAdmissionHour] = useState([]);
  const [admissionType, setAdmissionType] = useState([]);
  const [admissionSource, setAdmissionSource] = useState([]);
  const [disChargeHour, setDisChargeHour] = useState([]);
  const [patientStatus, setPatientStatus] = useState([]);
  const [delayReason, setDelayReason] = useState([]);
  const [releaseOfInfo, setReleaseOfInfo] = useState([]);
  const [assignmentOfBenefit, setAssignmentOfBenefit] = useState([]);
  const [providerAcceptance, setProviderAcceptance] = useState([]);
  const [documentationMethod, setDocumentationMethod] = useState([]);
  const [documentationType, setDocumentationType] = useState([]);
  const dataFetchUrls = {
    admissionHour: `${path}/ct-admissionHour`,
    admissionType: `${path}/ct-admissionType`,
    admissionSource: `${path}/ct-admissionSource`,
    disChargeHour: `${path}/ct-dischargeHour`,
    patientStatus: `${path}/ct-patientStatus`,
    delayReason: `${path}/ct-delayReason`,
    releaseOfInfo: `${path}/ct-releaseOfInfo`,
    assignmentOfBenefit: `${path}/ct-assignmentOfBenefit`,
    providerAcceptance: `${path}/ct-providerAcceptance`,
    documentationMethod: `${path}/ct-documentationMethod`,
    documentationType: `${path}/ct-documentationType`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.admissionHour, setAdmissionHour);
    fetchDataOptions(dataFetchUrls.admissionType, setAdmissionType);
    fetchDataOptions(dataFetchUrls.admissionSource, setAdmissionSource);
    fetchDataOptions(dataFetchUrls.disChargeHour, setDisChargeHour);
    fetchDataOptions(dataFetchUrls.patientStatus, setPatientStatus);
    fetchDataOptions(dataFetchUrls.delayReason, setDelayReason);
    fetchDataOptions(dataFetchUrls.documentationType, setDocumentationType);
    fetchDataOptions(dataFetchUrls.assignmentOfBenefit, setAssignmentOfBenefit);
    fetchDataOptions(dataFetchUrls.providerAcceptance, setProviderAcceptance);
    fetchDataOptions(dataFetchUrls.documentationMethod, setDocumentationMethod);
    fetchDataOptions(dataFetchUrls.releaseOfInfo, setReleaseOfInfo);
  }, []);

  const checkboxHandleChange = (value) => {
    console.log("value", value);
    let allVal = [...formik?.values?.EPSDT_DetailDto];
    let found = allVal?.findIndex(
      (v) => v?.epsdT_CertificationCodeId === value?.epsdT_CertificationCodeId
    );
    if (found === -1) {
      allVal.push(value);
    } else {
      allVal.splice(found, 1);
    }
    console.log("allVal", allVal);
    formik.setFieldValue("EPSDT_DetailDto", allVal);
    console.log("value", formik.values);
  };
  const theme = useTheme();
  const mode = theme.palette.mode; // Extract the mode from the theme
  const colors = theme.palette; // Extract the colors from the theme

  return (
    <>
      <Box margin="20px">
        <div>
          <Typography
            variant="h4"
            style={{
              color: "black",
              fontWeight: "600",
              color: theme.palette.textMainHeading.primary,
            }}
          >
            Show Additional Information about each field
          </Typography>
          {/* <FormControl
            sx={{
              margin: "20px 0",
            }}
          >
            <RadioGroup row name="row-radio-buttons-group">
              <FormControlLabel
                value={1}
                control={
                  <Radio
                    checked={formik.values.formType === "1"}
                    onChange={() => formik.setFieldValue("formType", "1")}
                  />
                }
                label={<Typography fontSize={'1.3rem'} fontWeight="600">
                None
              </Typography>}
              />
              <FormControlLabel
                value={2}
                control={
                  <Radio
                    checked={formik.values.formType === "2"}
                    onChange={() => formik.setFieldValue("formType", "2")}
                  />
                }
                label={<Typography fontSize={'1.3rem'} fontWeight="600">
                ANSI Location (For Electronic Claims)
               </Typography>}
              />
              <FormControlLabel
                value={3}
                control={
                  <Radio
                    checked={formik.values.formType === "3"}
                    onChange={() => formik.setFieldValue("formType", "3")}
                  />
                }
                label={<Typography fontSize={'1.3rem'} fontWeight="600">
                CMS 1500 (02-12) Box Numbers (For Printed Claims)
               </Typography>}
              />
            </RadioGroup>
          </FormControl> */}
        </div>
        {/* Claim Information */}
        <CustomAccordion defaultExpanded={true} heading={"Claim Information"}>
          <AccordionDetails>
            <Box
              display="grid"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 450px))",
                },
              }}
            >
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomDatePicker
                    formik={formik}
                    name="statementCoversFromDate"
                    dateValue={formik.values.statementCoversFromDate}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                    dateLabel="Statement Covers From Date"
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 DTP~435"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomDatePicker
                    formik={formik}
                    name="statementCoversToDate"
                    dateLabel="Statement Covers To Date"
                    dateValue={formik.values.statementCoversToDate}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 DTP~435"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>

              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomDatePicker
                    formik={formik}
                    dateLabel="Admission Date"
                    name="admissionDate"
                    dateValue={formik.values.admissionDate}
                    handleDateChange={formik.handleChange}
                    handleDateBlur={formik.handleBlur}
                  />
                </FormControl>

                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 DTP~435"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>

              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox
                    name="admissionHourId"
                    formik={formik}
                    value={formik.values.admissionHourId}
                    dropdownOptions={admissionHour?.map((opt) => ({
                      value: opt.admissionHour,
                      id: opt.admissionHourId,
                    }))}
                    label="Admission Hour"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-20"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>

              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox2
                    name="admissionTypeId"
                    value={formik.values.admissionTypeId}
                    formik={formik}
                    dropdownOptions={admissionType?.map((opt) => ({
                      value: opt.admissionType,
                      id: opt.admissionTypeId,
                    }))}
                    label="Admission Type"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-20"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
            </Box>
            <br />
            <br />
            {/* Admission Source */}
            <Box
              display="grid"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(1, minmax(0, 920px))",
                },
              }}
            >
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox2
                    name="admissionSourceId"
                    formik={formik}
                    value={formik.values.admissionSourceId}
                    dropdownOptions={admissionSource?.map((opt) => ({
                      value: opt.admissionSourceType,
                      id: opt.admissionSourceId,
                    }))}
                    label="Admission Source"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-20"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
            </Box>
            <br />
            <br /> <br />
            <br /> <br />
            {/* Discharge Hour */}
            <Box
              display="grid"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 450px))",
                },
              }}
            >
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox2
                    name="dischargeHourId"
                    formik={formik}
                    value={formik.values.dischargeHourId}
                    dropdownOptions={disChargeHour?.map((opt) => ({
                      value: opt.dischargeHour,
                      id: opt.dischargeHourId,
                    }))}
                    label="Discharge Hour"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-20"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
            </Box>
            <br />
            <br /> <br />
            <br /> <br />
            {/* patient status */}
            <Box
              display="grid"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(1, minmax(0, 920))",
                },
              }}
            >
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox2
                    name="patientStatusId"
                    formik={formik}
                    value={formik.values.patientStatusId}
                    dropdownOptions={patientStatus?.map((opt) => ({
                      value: opt.patientStatusName,
                      id: opt.patientStatusId,
                    }))}
                    label="Patient Status"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-20"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox2
                    name="delayReasonCodeId"
                    value={formik.values.delayReasonCodeId}
                    formik={formik}
                    dropdownOptions={delayReason?.map((opt) => ({
                      value: opt.delayReasonCodeName,
                      id: opt.delayReasonCodeId,
                    }))}
                    label="Delay Reason Code"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-20"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
            </Box>
            <br />
            <br /> <br />
            <br /> <br />
            {/* pps */}
            <Box
              display="grid"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(1, minmax(0, 450px))",
                },
              }}
            >
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomField
                    type="text"
                    label="PPS (Diagnosis Related Group)"
                    value={formik.values.pps}
                    name="pps"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>

                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "N/A*"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomField
                    type="number"
                    label="Patient Estimated Amount Due"
                    value={formik.values.patientDueAmount}
                    name="patientDueAmount"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>

                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "N/A*"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
            </Box>
          </AccordionDetails>
        </CustomAccordion>

        {/* patient condition */}
        <CustomAccordion defaultExpanded={false} heading={"Patient Condition"}>
          <AccordionDetails>
            <Typography variant="h4" fontWeight={"600"}>
              Is Patient Condition Related to:
            </Typography>
            <Stack direction="row" alignItems="center" marginTop="30px">
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ width: "100px", fontSize: "1.4rem", fontWeight: "600" }}
              >
                Employement
              </FormLabel>
              <RadioGroup
                sx={{
                  marginLeft: "20px",
                }}
                row
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      checked={formik.values.isEmployment}
                      onChange={() =>
                        formik.setFieldValue("isEmployment", true)
                      }
                      style={{ color: theme.palette.radioButtons.primary }}
                    />
                  }
                  label={
                    <Typography fontSize={"1.3rem"} fontWeight="600">
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      checked={!formik.values.isEmployment}
                      onChange={() =>
                        formik.setFieldValue("isEmployment", false)
                      }
                      style={{ color: theme.palette.radioButtons.primary }}
                    />
                  }
                  label={
                    <Typography fontSize={"1.3rem"} fontWeight="600">
                      No
                    </Typography>
                  }
                />
                {/* <Typography
                  fontSize={'1.3rem'}
                  component="p"
                  padding="10px"
                  textAlign="center"
                  bgcolor="lightgray"
                  height="100%"
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-11-1 (EM)"
                    : formik.values.formType === "3"
                    ? "BOX 10a"
                    : ""}
                </Typography> */}
              </RadioGroup>
            </Stack>
            <Stack direction="row" alignItems="center" marginTop="15px">
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ width: "100px", fontSize: "1.4rem", fontWeight: "600" }}
              >
                Auto Accident
              </FormLabel>
              <RadioGroup
                sx={{
                  marginLeft: "20px",
                  // width: "150px",
                }}
                row
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      checked={formik.values.isAutoAccident}
                      onChange={() =>
                        formik.setFieldValue("isAutoAccident", true)
                      }
                      style={{ color: theme.palette.radioButtons.primary }}
                    />
                  }
                  label={
                    <Typography fontSize={"1.3rem"} fontWeight="600">
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      checked={!formik.values.isAutoAccident}
                      onChange={() =>
                        formik.setFieldValue("isAutoAccident", false)
                      }
                      style={{ color: theme.palette.radioButtons.primary }}
                    />
                  }
                  label={
                    <Typography fontSize={"1.3rem"} fontWeight="600">
                      No
                    </Typography>
                  }
                />
                {/* <Typography
                  fontSize={'1.3rem'}
                  component="p"
                  padding="10px"
                  textAlign="center"
                  bgcolor="lightgray"
                  height="100%"
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-11-1 (AA)"
                    : formik.values.formType === "3"
                    ? "BOX 10b"
                    : ""}
                </Typography> */}
              </RadioGroup>
            </Stack>
            <Stack direction="row" alignItems="center" marginTop="15px">
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{ width: "100px", fontSize: "1.4rem", fontWeight: "600" }}
              >
                Other Accident
              </FormLabel>
              <RadioGroup
                sx={{
                  marginLeft: "20px",
                  // width: "150px",
                }}
                row
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      checked={formik.values.isOtherAccident}
                      onChange={() =>
                        formik.setFieldValue("isOtherAccident", true)
                      }
                      style={{ color: theme.palette.radioButtons.primary }}
                    />
                  }
                  label={
                    <Typography fontSize={"1.3rem"} fontWeight="600">
                      Yes
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      checked={!formik.values.isOtherAccident}
                      onChange={() =>
                        formik.setFieldValue("isOtherAccident", false)
                      }
                      style={{ color: theme.palette.radioButtons.primary }}
                    />
                  }
                  label={
                    <Typography fontSize={"1.3rem"} fontWeight="600">
                      No
                    </Typography>
                  }
                />
                {/* <Typography
                  fontSize={'1.3rem'}
                  component="p"
                  padding="10px"
                  textAlign="center"
                  bgcolor="lightgray"
                  height="100%"
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-11-1 (OA)"
                    : formik.values.formType === "3"
                    ? "BOX 10c"
                    : ""}
                </Typography> */}
              </RadioGroup>
            </Stack>
          </AccordionDetails>
        </CustomAccordion>
        {/* Assignment of benefits */}
        <CustomAccordion
          defaultExpanded={false}
          heading={"Assignment of Benefits"}
        >
          <AccordionDetails>
            <Box
              display="grid"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 450px))",
                },
              }}
            >
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox2
                    name="releaseOfInfoId"
                    formik={formik}
                    value={formik.values.releaseOfInfoId}
                    dropdownOptions={releaseOfInfo?.map((opt) => ({
                      value: opt.releaseOfInfoName,
                      id: opt.releaseOfInfoId,
                    }))}
                    label="Release Of Info"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-20"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox2
                    name="assignmentOfBenefitId"
                    formik={formik}
                    value={formik.values.assignmentOfBenefitId}
                    dropdownOptions={assignmentOfBenefit?.map((opt) => ({
                      value: opt.assignmentOfBenefit,
                      id: opt.assignmentOfBenefitId,
                    }))}
                    label="Assignment of Benefits"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-20"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>

              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox2
                    name="providerAcceptanceId"
                    value={formik.values.providerAcceptanceId}
                    dropdownOptions={providerAcceptance?.map((opt) => ({
                      value: opt.providerAcceptance,
                      id: opt.providerAcceptanceId,
                    }))}
                    formik={formik}
                    label="Provider Accept Assignment"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 CLM-20"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
            </Box>
          </AccordionDetails>
        </CustomAccordion>
        {/* EPSDT Certifications */}
        <CustomAccordion
          defaultExpanded={false}
          heading={"EPSDT Certifications"}
        >
          <AccordionDetails>
            <Typography variant="h4" fontWeight={"600"}>
              Select up to 3 if applicable
            </Typography>
            <Box
              display="grid"
              gap="10px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 300px))",
                },
                marginTop: "10px",
              }}
            >
              {/* <CheckBox /> */}
              {/* <FormGroup>
                <FormControlLabel
                  control={
                    <CheckBox
                      checked={selectedCheckboxes.includes(1)}
                      onChange={() => checkboxHandleChange(1)}
                    />
                  }
                  label={<Typography fontSize={'1.3rem'} fontWeight="600">
                  No referral given
                </Typography>}
                  
                />
              </FormGroup> */}
              {epsdT_DetailDto.map((v, i) => {
                return (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <CheckBox
                          CheckedValue={formik?.values?.EPSDT_DetailDto?.some(
                            (val) =>
                              val?.epsdT_CertificationCodeId ===
                              v?.epsdT_CertificationCodeId
                          )}
                          handleChange={() => checkboxHandleChange(v)}
                        />
                      }
                      label={
                        <Typography fontSize={"1.3rem"} fontWeight="600">
                          {v?.epsdT_CertificationCodeType}
                        </Typography>
                      }
                    />
                  </FormGroup>
                );
              })}
            </Box>
          </AccordionDetails>
        </CustomAccordion>

        {/* other reference information */}
        <CustomAccordion
          defaultExpanded={false}
          heading={"Other Reference Information"}
        >
          <AccordionDetails>
            <Box
              display="grid"
              gap="20px"
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 450px))",
                },
              }}
            >
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox2
                    name="documentationMethodId"
                    value={formik.values.documentationMethodId}
                    dropdownOptions={documentationMethod?.map((opt) => ({
                      value: opt.documentationMethodName,
                      id: opt.documentationMethodId,
                    }))}
                    formik={formik}
                    label="Documentation Method"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 PWK-2"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>

              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomSelectBox2
                    name="documentationTypeId"
                    value={formik.values.documentationTypeId}
                    dropdownOptions={documentationType?.map((opt) => ({
                      value: opt.documentationTypeName,
                      id: opt.documentationTypeId,
                    }))}
                    label="Documentation Type"
                    formik={formik}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 PWK-1"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
            </Box>

            <Box
              display="grid"
              gap="20px"
              sx={{
                marginTop: "20px",
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(1, minmax(0, 920px))",
                },
              }}
            >
              <Stack
                className="infoCodeContainer"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                }}
              >
                <FormControl fullWidth>
                  <CustomField
                    type="text"
                    label="Demonstration Project"
                    value={formik.values.demonstrationProject}
                    name="demonstrationProject"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                </FormControl>
                {/* <Typography
                  variant="h6"
                  component="span"
                  className="ft_content"
                  width={{ xs: "100%", sm: "30%", md: "38%" }}
                  display={formik.values.formType === "1" && "none"}
                >
                  {formik.values.formType === "2"
                    ? "2300 REF~P4"
                    : formik.values.formType === "3"
                    ? "BOX 11b"
                    : null}
                </Typography> */}
              </Stack>
            </Box>
          </AccordionDetails>
        </CustomAccordion>
      </Box>
    </>
  );
};

export default InsAdditionalinfo;
