import React from "react";
import MainHeading from "../../../components/MainHeading";
import { Box, Stack,Typography } from "@mui/material";
import ProcedureInfo from "./ProcedureInfo";
import { procedureInitValues } from "../../../utils/formikInitValues";
import { useFormik } from "formik";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { procedureSchema } from "../../../schemas/patientSchema";
import { useDispatch } from "react-redux";
import { newProcedureCodeAction } from "../../../features/actions/procedureCodeAction";
import {handleCancel} from "../../../utils/customFunctions"
import CustomDialog from "../../../components/CustomDialog";
import { useState } from "react";

const NewProcedure = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const formik = useFormik({
    initialValues: procedureInitValues,
    validationSchema: procedureSchema,
    onSubmit: (values, action) => {
      try {
        dispatch(newProcedureCodeAction([values]));
        navigate("/procedure-codes");
      } catch (err) {
        console.log(err, ": Error in creating new procedure code");
        throw err;
      }
    },
  });
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/procedure-codes");
  };
  return (
    <>
    <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
      <MainHeading mainheading={"Add New Procedure"} />
{/* 
      <Stack marginTop="15px" direction="row" alignItems="center" gap={2}>
        <CustomButton
          formId="procedureForm"
          isBlue={true}
          isSave={true}
          type="submit"
        >
          Save
        </CustomButton>
        <CustomButton isBlue={false} isCancel={true} handleClick={handleCancel}>
          Cancel
        </CustomButton>
      </Stack> */}
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 150px))",
                sm: "repeat(1, minmax(0, 150px))",
                md: "repeat(2, minmax(0, 150px))",
              },
            }}
          >
            {/* <CustomButton type="submit" isBlue={true} isSave={true}>
              Save
            </CustomButton> */}
             <CustomButton
          formId="procedureForm"
          isBlue={true}
          isSave={true}
          type="submit"
        >
          Save
        </CustomButton>
        {/* <CustomButton isBlue={false} isCancel={true} 
        handleClick={() => {
                handleCancel(
                  formik,
                   "/procedure-codes", navigate);
              }}>
          Cancel
        </CustomButton> */}
           <CustomButton   type="submit" isCancel={true} handleClick={handleCancel}>
         Cancel
       </CustomButton>
          </Box>


      <form id="procedureForm" onSubmit={formik.handleSubmit}>
        <ProcedureInfo formik={formik} />
      </form>
    </Box>
      <CustomDialog
      open={openCancelDialog}
      isWarning={true}
      handleClose={() => setOpenCancelDialog(false)}
      handleConfirm={handleConfirmCancel}
      handleCancel={() => setOpenCancelDialog(false)}
      confirmButtonTitle="OK"
      cancelButtonTitle="Cancel"
     >
      <Typography component="h3">Are you sure you want to cancel?</Typography>
     </CustomDialog>
   </>
  );
};

export default NewProcedure;
