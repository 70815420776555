import React from "react";
import { useEffect } from "react";
import { newPatientAction } from "../../../../features/actions/createPatientAction";
import { useDispatch, useSelector } from "react-redux";
import { getTaxonomyAction } from "../../../../features/actions/taxonomyAction";
import { useState } from "react";
import MajorCategory from "./MajorCategory";
import IntermediateCategory from "./IntermediateCategory";
import SubCategory from "./SubCategory";
import { Box, Button, Stack, Typography } from "@mui/material";
import SelectedCategory from "./SelectedCategory";
import CustomButton from "../../../../components/CustomButton";
import CustomSelectBox from "../../../../components/CustomSelectBox";
import { useTheme } from "@emotion/react";


const TaxonomyCategories = ({
  handleClose,
  setTaxonomyCode,
  setTaxonomyName,
  handleSelectTaxonomy,
}) => {
  const dispatch = useDispatch();
  const theme=useTheme()
  const { getTaxonomyData } = useSelector((state) => state.taxonomy);
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [selectedCode, setSelectedCode] = useState("");

  useEffect(() => {
    try {
      dispatch(getTaxonomyAction());
    } catch (error) {
      console.error(error, "taxonomy gettin in com error");
    }
  }, [dispatch]);

  const handleCloseModal = (itemCode) => {
    handleSelectTaxonomy(itemCode);
    handleClose();
  };
  return (
    <div>
      <Box
        sx={{
          position: "sticky",
          top: "0",
          zIndex: "111",
          background: "#fff",
          padding: "21px",
          borderBottom: "1px solid lightgrey",
          background:theme.palette.accordiansBodyBackground.primary
        }}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h2" component={"h2"} className='taxonomyHeading' sx={{color:theme.palette.fieldsText.primary,fontWeight:'700'}}>
            Taxonomy List
          </Typography>
          <Box textAlign={"right"}>
            <CustomButton
              isBlue={true}
              handleClick={() => handleCloseModal(selectedCode)}
            >
              Select
            </CustomButton>
          </Box>
        </Stack>
         <SelectedCategory 
          selectedCategory={selectedCategory}
          selectedCode={selectedCode}
        /> 
        {/* <CustomSelectBox/> */}
        
       
      </Box>

      {/* major component */}
      {getTaxonomyData.result?.map((majorCat, index) => {
        console.log(majorCat, "majorss55");
        return (
          <MajorCategory
            key={index}
            majorOption={majorCat}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            selectedCode={selectedCode}
            setSelectedCode={setSelectedCode}
            setTaxonomyName={setTaxonomyName}
          />
        );
      })}
    </div>
  );
};

export default TaxonomyCategories;
