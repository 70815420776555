import React from 'react'
import { Typography,Grid,useTheme } from '@mui/material';
import { useState } from 'react';
import CustomDataGrid from '../../../components/CustomDataGrid';
import { DataGrid } from '@mui/x-data-grid';
import Loading from '../../../components/Loading';
import DoneIcon from '@mui/icons-material/Done';
import CustomButton from '../../../components/CustomButton';
import path from "../../../config/apiUrl";
import { toast } from "react-toastify";
import { postData } from '../../../config/axiosFunctions';


const MasterTable = ({masterListData}) => {
  const theme=useTheme();
  const reducedCellHeight = 35;
    const [isLoading, setIsLoading]=useState(false);
    const [selectedMasterList, setSelectedMasterList] = useState(null);
console.log("master list" , selectedMasterList);
    const rows =
    masterListData && masterListData.length > 0
      ? masterListData.map((item) => ({
          id: item.revenueCodeId,
          ...item,
        }))
      : [];

      const columns = [
        {
            field: "isAdded",
            headerName: "Added",
            minWidth: 100,
            flex: 1,
            headerAlign: "center",
            align: "center",
            headerClassName: "header-bg",
            cellClassName: "cell-content",
            renderCell: (params) => (
                <span>{params.value ? <DoneIcon/> : ''}</span>
              ),
          },
          {
            field: "revenueCodesCategory",
            headerName: "Category",
            minWidth: 100,
            flex: 1,
            headerAlign: "center",
            align: "center",
            headerClassName: "header-bg",
            cellClassName: "cell-content",
          },
          {
            field: "revenueCode",
            headerName: "Code",
            minWidth: 100,
            flex: 2,
            headerAlign: "center",
            align: "center",
            headerClassName: "header-bg",
            cellClassName: "cell-content",
          },
        {
          field: "revenueCodeDescription",
          headerName: "Description",
          minWidth: 100,
          flex: 1,
          headerAlign: "center",
          align: "center",
          headerClassName: "header-bg",
          cellClassName: "cell-content",
        },
       
      ];

      const handleAddMasterList= async () => {
        // const selectedAllMasterList = selectedMasterList?.map((list) =>
        //   rows?.find((el) => el.id === list)
        const selectedAllMasterList = selectedMasterList?.map(listId =>
            rows.find(row => row.id === listId)
        );
          console.log(selectedAllMasterList, "selectedAllMaster")
        const masterListStructure = selectedAllMasterList.map((list) => ({
          // revenueCodeId: list?.revenueCodeId,
          revenueCode: list?.revenueCode,
          revenueCodeDescription: list?.revenueCodeDescription,
          revenueCodesCategory: list?.revenueCodesCategory,
          revenueCodePrice: list?.revenueCodePrice,
          statementDescription: list?.statementDescription,
          // isAdded: list?.isAdded,
          revenueCodeCategoryId: list?.revenueCodeCategoryId,
          isDuplicateService: list?.isDuplicateService,
      
        }));
        console.log(masterListStructure, "masterListStructure")
        try {
          const response = await postData(
            `${path}/revenueCode`,
            masterListStructure
          );
          if (response) {
            console.log(response, "check posting masterlist response");
            toast.success("Master List code added successfully");
           
            return response;
          }
        } catch (err) {
          console.log(err, ": Error inposting master list");
         
          toast.error(err.response.data.message);
        }
     
      }
  return (
    <>    
<Grid item md={12}>
            {isLoading? (
              <Loading />
            ) : (
            <DataGrid
            rows={rows}
            columns={columns}
            sx={{
              "& .header-bg": {
                background: theme.palette.dataGridHeading.primary,
                color: theme.palette.textMainHeading.primary,
                fontSize: "16px",
              },
              "& .cell-content": {
                fontSize: "1.2rem",
              },
              "& .MuiTablePagination-displayedRows": {
                fontSize: "1.3rem",
              },
              "& .MuiTablePagination-actions": {
                "& svg": {
                  fontSize: "1.8rem",
                  color: theme.palette.textMainHeading.primary,
                  marginBottom: "8px",
                },
              },
            }}
            autoHeight
            rowHeight={reducedCellHeight}
            checkboxSelection
            disableSelectionOnClick
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSize={20}
            onSelectionModelChange={(newMasterList) => setSelectedMasterList(newMasterList)}
            components={{
              NoRowsOverlay: () => (
                <div
                  style={{ width: "100%", textAlign: "center", padding: "16px" }}
                >
                  {masterListData.length === 0 && "No Data Is Added"}
                </div>
              ),
            }}
          />
            )}
          </Grid>

          <CustomButton isBlue={true} isAdd={true} handleClick={handleAddMasterList}>
          Add
        </CustomButton>
          </>
  )
}

export default MasterTable;
