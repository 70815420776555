import { useFormik } from "formik";
import React from "react";
import { useState } from "react";
import { chargePanelInitVal } from "../../../utils/formikInitValues";
import { Box, Stack,Typography } from "@mui/material";
import MainHeading from "../../../components/MainHeading";
import CustomButton from "../../../components/CustomButton";
import ChargePanelInfo from "./ChargePanelInfo";
import { useDispatch, useSelector } from "react-redux";
import { createNewChargePanelAction } from "../../../features/actions/chargePanelAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";

const NewChargePanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const {loading} = useSelector(state => state.chargePanel)
  const formik = useFormik({
    initialValues: chargePanelInitVal,
    onSubmit: (values, action) => {
      console.log(values, "charge panel values");
      try {
        dispatch(createNewChargePanelAction(values))
        action.resetForm()
        navigate("/charge-panel")
      }catch (err) {
        console.log(err, ": error in creating charge panel")
      }
    },
  });
  // const handleCancel = () => {
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/charge-panel");
  //   }
  // };
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/charge-panel")
  };
  return (
    <>
    <Box margin="20px" sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
      <MainHeading mainheading="New Charge Panel" />

      <Stack direction="row" alignItems="center" gap="10px">
        <CustomButton
          type="submit"
          formId="chargePanelInfo"
          isBlue={true}
          isSave={true}
        >
          {loading? "Saving..." : "Save"}
          {/* Save */}
        </CustomButton>
        {/* <CustomButton isBlue={false} isCancel={true} handleClick={handleCancel}>
          Cancel
        </CustomButton> */}
        <CustomButton isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>
      </Stack>

      <Box id="chargePanelInfo" component="form" onSubmit={formik.handleSubmit}>
        <ChargePanelInfo formik={formik} />
      </Box>
      <CustomDialog
 open={openCancelDialog}
 isWarning={true}
 handleClose={() => setOpenCancelDialog(false)}
 handleConfirm={handleConfirmCancel}
 handleCancel={() => setOpenCancelDialog(false)}
 confirmButtonTitle="OK"
 cancelButtonTitle="Cancel"
>
 <Typography component="h3">Are you sure you want to cancel?</Typography>
</CustomDialog>
    </Box>
    
    </>
  );
};

export default NewChargePanel;
