import React from 'react';
import { Grid,Box,Typography,Stack,useTheme,Radio,FormControl,FormControlLabel } from '@mui/material';
import { useState } from 'react';
import CheckBox from '../../../../components/CheckBox';
import CustomField from '../../../../components/CustomField';

const ChargesEntered = () => {
    const theme=useTheme();
    const anotherFeeScheduleCtList=[
        {id:'1',ctOption:'Do not adjust the prices of the new fee schedule '},
     {id:'2',ctOption:'Do not adjust the prices of the new fee schedule '},
     {id:'3',ctOption:'Adjust Prices to'}
    ]
    const [selectedOption, setSelectedOption] = useState('');

    const handleRadioChange = (value) => {
      setSelectedOption(value);
    };
  return (
    <>
           <Grid container spacing={2}>
           <Grid item md={12} sm={12} xs={12}>
            <Box alignItems="left" marginLeft="20px">
           <Typography variant='h4' fontWeight={'600'}>Set the prices based on the maximum amount entered in the last days</Typography>
           </Box>
        <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px" ,margin:'15px'}}>
       
            <Box
              sx={{
                background: theme.palette.revenueStatement.primary,
                height: "33px",
              }}
            >
              <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
            Adjust Prices
              </Typography>
            </Box>

            <FormControl fullWidth sx={{ padding: "10px" }}>
              <Stack flexDirection="column" alignItems="left" marginLeft="20px">
                {anotherFeeScheduleCtList.map((value) => (
                  <Stack key={value.id} direction="row" alignItems="center" spacing={2}>
                    <FormControlLabel
                      label={value.ctOption}
                      control={
                        <Radio
                          value={value.id}
                          checked={selectedOption === value.id}
                          onChange={() => handleRadioChange(value.id)}
                          name="radio-buttons"
                        />
                      }
                    />
                    {value.id === '1' && (
                  <CustomField/>
                    )}
                    {value.id === '2' && (
                       <CustomField/>
                    )}
                     {value.id === '3' && (
                        <>
                       <CustomField/>
                       </>
                    )}
                  </Stack>
                ))}
              </Stack>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ChargesEntered
