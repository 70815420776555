import React, { useState } from "react";
import { Grid, Box,useTheme,Typography } from "@mui/material";
import { useEffect } from "react";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { batchLabelPrintValues } from "../../../utils/formikInitValues";
import { useFormik } from "formik";
import FormControl from "@mui/material/FormControl";
import CustomField from "../../../components/CustomField";
import path from "../../../config/apiUrl";
import { getData } from "../../../config/axiosFunctions";
import { MultiSelect } from "react-multi-select-component";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomModal from "../../../components/CustomModal";


const LabelBatchPrintInfo = () => {
  // const accountType = [
  //   { id: 1, value: "Option 1" },
  //   { id: 2, value: "Option 2" },
  //   // add more options as needed
  // ];

  // const formik = useFormik({
  //   initialValues: {
  //     category: [], // Ensure this is an array for multi-select
  //   },
  //   onSubmit: (values) => {
  //     console.log(values);
  //   },
  // });
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState([]);
  console.log(selectedCategory, "selectedCategory");
  const [filterOpt, setFilterOpt] = useState([]);
  const [openPayerModal, setOpenPayerModal] = useState(false);
  const [accountTypeOptions, setAccountTypeOptions] = useState([]);
  const accountType = accountTypeOptions.map((item) => ({
    label: item.accountType,
    value: item.accountTypeId,
  }));
  const [endCtOption, setEndCtOption] = useState([]);
  const endCt = [{ endCtId: "1", endCtName: "Feldman Bruce (#123787)" }];
  const endCtMap = endCt.map((item) => ({
    label: item.endCtName,
    value: item.endCtId,
  }));

  const patientLastName = [{ id: "1", patientName: "Between" }];

  const formik = useFormik({
    initialValues: batchLabelPrintValues,
    onSubmit: (values) => {
      console.log(values, "value of batchlabelprint");
    },
  });
  console.log(formik.values, "allFormikValues");

  const dataFetchUrls = {
    accountType: `${path}/ct-accountType`,
    filters: `${path}/ct-arFilters`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataOptions(dataFetchUrls.accountType, setAccountTypeOptions);
    fetchDataOptions(dataFetchUrls.filters, setFilterOpt);
  }, []);
  return (
    <>

<Box sx={{background:theme.palette.accordiansBodyBackground.primary,padding:"15px",margin:"0 0 20px",border:"1px solid #D3D3D3",borderRadius:"4px"}}>    
      <Grid container spacing={1} padding={"8px"}>
        <Grid item md={4} sm={12} xs={12}>
          <FormControl fullWidth>
            <CustomSelectBox2
              value={formik.values.patientLastName}
              name="patientLastName"
              dropdownOptions={patientLastName?.map((opt) => ({
                value: opt.patientName,
                id: opt.id,
              }))}
              label="Patient Last Name"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </FormControl>
        </Grid>

        <Grid item md={4} sm={12} xs={12} margin={"18px 0 10px"}>
          <CustomField
            type="text"
            value={formik.values.patientLastName1}
            name="patientLastName1"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12} margin={"18px 0 "}>
          <CustomField
            type="text"
            value={formik.values.patientLastName2}
            name="patientLastName2"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <FormControl fullWidth>
            <MultiSelect
              options={accountType}
              value={selectedCategory}
              onChange={setSelectedCategory}
            />
          </FormControl>
           {/* <FormControl fullWidth>
        <CustomSelectBox2
          // value={formik.values.category}
          // handleChange={() => setSelectedCategory(formik.values.category)}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          label="Select Category"
          dropdownOptions={accountType}
          // name="category"
          formik={formik}
          isMulti={true} 
        />
      </FormControl> */}


  
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <CustomField
            type="text"
            label="Patient State"
            value={formik.values.patientState}
            name="patientState"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <FormControl fullWidth>
            <CustomSelectBox2
              value={formik.values.patientAge}
              name="patientAge"
              dropdownOptions={filterOpt?.map((opt) => ({
                value: opt.arFilterName,
                id: opt.arFilterId,
              }))}
              label="Patient Age"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </FormControl>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <FormControl fullWidth>
            <CustomSelectBox2
              value={formik.values.patientBirthMonth}
              name="patientBirthMonth"
              dropdownOptions={filterOpt?.map((opt) => ({
                value: opt.arFilterName,
                id: opt.arFilterId,
              }))}
              label="Patient Birth Month"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </FormControl>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <FormControl fullWidth>
            <CustomSelectBox2
              value={formik.values.totalBalance}
              name="totalBalance"
              dropdownOptions={patientLastName?.map((opt) => ({
                value: opt.patientName,
                id: opt.id,
              }))}
              label="Total Balance"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </FormControl>
        </Grid>
        <Grid item md={7} sm={12} xs={12}>
          <FormControl fullWidth>
            <CustomSelectBox2
              value={formik.values.patientBalance}
              name="patientBalance"
              dropdownOptions={filterOpt?.map((opt) => ({
                value: opt.arFilterName,
                id: opt.arFilterId,
              }))}
              label="Patient Balance"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </FormControl>
        </Grid>
        <Grid item md={7} sm={12} xs={12}>
          <FormControl fullWidth>
            <CustomSelectBox2
              value={formik.values.insuranceBalance}
              name="Insurance Balance"
              dropdownOptions={filterOpt?.map((opt) => ({
                value: opt.arFilterName,
                id: opt.arFilterId,
              }))}
              label="Insurance Balance"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </FormControl>
        </Grid>
        <Grid item md={7} sm={12} xs={12}>
          <FormControl fullWidth>
            <CustomSelectBox2
              value={formik.values.paperStatementsSent}
              name="paperStatementsSent"
              dropdownOptions={filterOpt?.map((opt) => ({
                value: opt.arFilterName,
                id: opt.arFilterId,
              }))}
              label="Paper Statements Sent"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </FormControl>
        </Grid>

        <Grid item md={7} sm={12} xs={12}>
          <FormControl fullWidth>
            <CustomSelectBox2
              value={formik.values.electronicStatementsSent}
              name="electronicStatementsSent"
              dropdownOptions={filterOpt?.map((opt) => ({
                value: opt.arFilterName,
                id: opt.arFilterId,
              }))}
              label="Electronic Statements Sent"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </FormControl>
        </Grid>

        <Grid item md={7} sm={12} xs={12}>
          <FormControl fullWidth>
            <CustomSelectBox2
              value={formik.values.totalStatementsSent}
              name="totalStatementsSent"
              dropdownOptions={filterOpt?.map((opt) => ({
                value: opt.arFilterName,
                id: opt.arFilterId,
              }))}
              label="Total Statements Sent"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
              showDefaultOpt={true}
            />
          </FormControl>
        </Grid>

        <Grid item md={12} sm={12} xs={12} padding={"7px 0 "}>
        <h5 style={{fontSize:'1.8rem',
  fontWeight:'bold',color:theme.palette.Labeltext.primary}}>Practice</h5>
        <MultiSelect
            options={endCtMap}
            value={endCtOption}
            onChange={setEndCtOption}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12} padding={"3px 0 "}>
        <h5 style={{fontSize:'1.8rem',
  fontWeight:'bold',color:theme.palette.Labeltext.primary}}>Provider</h5>
        <MultiSelect
            options={endCtMap}
            value={endCtOption}
            onChange={setEndCtOption}
          />
        </Grid>
        <Grid  item md={12} sm={12} xs={12} padding={"3px 0 "}>
        <h5 style={{fontSize:'1.8rem',
  fontWeight:'bold',color:theme.palette.Labeltext.primary}}>Office</h5>
        <MultiSelect
            options={endCtMap}
            value={endCtOption}
            onChange={setEndCtOption}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12} padding={"3px 0 "}>
        <CustomSearchField   
            label="Payer"
            fieldVal={formik.values.payerSearch}
            handleChange={formik.handleChange}
            name="payerSearch"
            handleModalOpen={() => setOpenPayerModal()}
            handleBlur={formik.handleBlur}
            isRequired={true}
            formik={formik}
            resetField={{
              payerSearch: "",
            }}
            />
        </Grid>

        <CustomModal
        open={openPayerModal}
        handleClose={() => setOpenPayerModal(false)}
        height={'20px'}
      >
       
      </CustomModal>
      </Grid>
      </Box>

    </>
  );
};

export default LabelBatchPrintInfo;
