export const patientInitValues = {
  firstName: "",
  lastName: "",
  referenceNo:"",
  mi: "",
  suffix: "",
  ssn: null,
  isActive: false,
  accountNo: null,
  email: "",
  drivingLicense: "",
  dateOfBirth: null,
  dateOfDeath: null,
  cellPhone: null,
  homePhone: null,
  workPhone: null,
  ext: null,
  address: "",
  zipCode: "",
  emergencyContactFirstName: "",
  emergencyContactLastName: "",
  emergencyContactAddress: "",
  emergencyContactZipCode: "",
  emergencyContactState: "",
  emergencyContactStateId: null,
  emergencyContactCity: "",
  emergencyContactCityId: null,
  // dropdowns
  genderIdentityStatusId: null,
  maritalStatusId: 1,
  raceStatusId: null,
  sexualOrientationId: null,
  employmentStatusId: 1,
  referralSourceId: null,
  relationShipToPatientId: 1,
  ethnicityId: null,
  studentStatusId: 1,
  accountTypeId: 1,
  cityId: null,
  countryId: null,
  stateId: null,
  residenceTypeId: 1,
  // link family
  linkPatientId: null,
  linkPatientAccountNo: null,
  linkFamilyRelationId: null,
  linkFamilyRelation: "",
  // isLink: false,
  // Insured Information Data
  isSamePatient: false,
  isPatientInsured: false,
  showInsuranceData: false,
  selectedPriorityType: 0,
  insuredPayerDto: [],
  isSendStatement: false,
  statementTypeId: 1,
  mailStatementId: 2,
  patientComment1: "",
  patientComment2: "",
  isFinalDemandNotice: false,
  collectionDate: null,
  collectionReason: "",
  billingRelationshipToPatientId: 5,
  guarantorFirstName: "",
  guarantorLastName: "",
  guarantorMI: "",
  guarantorDOB: null,
  guarantorSSN: "",
  guarantorAddress: "",
  guarantorCityId: null,
  guarantorCityName: "",
  guarantorStateName: "",
  guarantorStateId: null,
  guarantorZipCode: "",
  isInternationalAddress: false,
  guarantorHomePhone: "",
  guarantorCellPhone: "",
  guarantorWorkPhone: "",
  guarantorEmail: "",
  guarantorRemarks: "",
  // claim defaults
  defaultProviderId: null,
  defaultProviderName: "",
  defaultProviderFirstName: "",
  defaultProviderLastName: "",
  defaultProviderSeqNo: null,
  practiceAddress: "",
  npiNo: null,
  practiceName: "",
  practiceSequenceNo: null,
  defaultOrderingProviderId: null,
  defaultOrderingProviderName: "",
  defaultOrderingProviderSeqNo: null,
  defaultFacilityId: null,
  defaultFacilityName: "",
  defaultFacilitySeqNo: null,
  defaultRenderingProviderId: null,
  defaultRenderingProviderName: "",
  defaultRenderingProviderFirstName: "",
  defaultRenderingProviderLastName: "",
  defaultRenderingProviderSeqNo: null,
  assignmentOfBenefitId: 1,
  providerAcceptanceId: 1,
  isIncludeIllnessInfo: false,
  accidentDate: null,
  accidentState: "",
  accidentTypeId: 1,
  admissionDate: null,
  admissionHourId: null,
  lastMenstrualDate: null,
  initialTreatmentDate: null,
  otherClaimId: "",
  additionalClaimInfo: "",
  defaultICD_DiagnosisDto: [],
  defaultCPT_ProcedureDto: [],
  insuranceDataToEdit: null, // personal
};

export const insuranceInfoValues = {
  existingInsuredParty: false,
  insuredDetail: {
    insuredFirstName: "",
    insuredLastName: "",
    insuredDateOfBirth: "",
    insuredAddress: "",
    insuredSSN: null,
    insuredZipCode: "",
    insuredHomePhone: null,
    insuredCellPhone: null,
    insuredWorkPhone: null,
    insuredExt: null,
    insuredEmail: "",
    insuredCityId: null,
    insuredCityName: "",
    insuredStateId: null,
    insuredStateName: "",
    insuredCountryId: null,
    insuredCountryName: "",
    insuredRelationShipToPatientId: 0,
    insuredRelationShipToPatientName: "",
    insuredGenderIdentityId: null,
    insuredGenderIdentityName: "",
    insuredPriorityType: "",
    insuredPriorityTypeId: null,
    employeeId: null,
    employeeName: "",
    empAddress: "",
    empCityId: null,
    empCityName: null,
    empStateId: null,
    empStateName: "",
    empZipCode: "",
    empEmploymentStatusId: null,
    empEmploymentStatusName: "",
    patientAccountNo: null,
    isLink: false,
    isActive: false,
    insurancePoliciesDtos: []
  },
  payerDetailVals: {
    payerInfoId:null,
    payerInfoMemberId: "",
    payerInfoGroupId: "",
    payerInfoCopayAmount: 0,
    payerInfoCoInsurancePercent: 0,
    payerInfoDeductibleAmount: 0, 
    payerInfoOutOfPocketMax: 0,
    payerInfoEffectiveDate: null,
    payerInfoTerminationDate: null,
    payerInfoPriorityId: 1,
    payerInfoPriorityName: "",
    payerInfoPolicyId: 1,
    payerInfoPolicyType: "",
    payerId: null,
    payerInfoPayerName: "",
    patientPayerAccountNo: null,
    payerInfoSequenceNumber: null,
    defaultSetId: 1,
    defaultSetName: "",
    insuredFirstName: "",
    insuredLastName: ""
  },
};
// // insured payer formik values
// export const insPartyInitVals = {
//         insuredFirstName: "",
//         insuredLastName: "",
//         insuredDateOfBirth: "",
//         insuredAddress: "",
//         insuredSSN: "",
//         insuredZipCode: "",
//         insuredHomePhone: null,
//         insuredCellPhone: null,
//         insuredWorkPhone: null,
//         insuredExt: null,
//         insuredEmail: "",
//         insuredCityId: null,
//         insuredCityName: "",
//         insuredStateId: null,
//         insuredStateName: "",
//         insuredCountryId: null,
//         insuredCountryName: "",
//         insuredRelationShipToPatientId: null,
//         insuredRelationShipToPatientName: "",
//         insuredGenderIdentityId: null,
//         insuredGenderIdentityName: "",
//         insuredPriorityType: "",
//         insuredPriorityTypeId: null,
//         employeeId: null,
//         employeeName: "",
//         empAddress: "",
//         empCityId: null,
//         empCityName: null,
//         empStateId: null,
//         empStateName: "",
//         empZipCode: "",
//         empEmploymentStatusId: null,
//         empEmploymentStatusName: "",
//         patientAccountNo: null,
//         isLink: false
// }
// // primary payer dto
// export const insPayerDto =    {
//   payerInfoMemberId: null,
//   payerInfoGroupId: null,
//   payerInfoCopayAmount: null,
//   payerInfoCoInsurancePercent: null,
//   payerInfoDeductibleAmount: null,
//   payerInfoOutOfPocketMax: null,
//   payerInfoEffectiveDate: "",
//   payerInfoTerminationDate: "",
//   payerInfoPriorityId: null,
//   payerInfoPriorityName: "",
//   payerInfoPolicyId: null,
//   payerInfoPolicyType: "",
//   payerId: null,
//   payerInfoPayerName: "",
//   patientPayerAccountNo: null,
//   payerInfoSequenceNumber: null
// }

// second payer dto
// export const secondaryPayerDto =    {
//   payerInfoMemberId: null,
//   payerInfoGroupId: null,
//   payerInfoCopayAmount: null,
//   payerInfoCoInsurancePercent: null,
//   payerInfoDeductibleAmount: null,
//   payerInfoOutOfPocketMax: null,
//   payerInfoEffectiveDate: "",
//   payerInfoTerminationDate: "",
//   payerInfoPriorityId: null,
//   payerInfoPriorityName: "",
//   payerInfoPolicyId: null,
//   payerInfoPolicyType: "",
//   payerId: null,
//   payerInfoPayerName: "",
//   patientPayerAccountNo: null,
//   payerInfoSequenceNumber: null
// }
// primary payer dto
// export const tertiaryPayerDto =    {
//   payerInfoMemberId: null,
//   payerInfoGroupId: null,
//   payerInfoCopayAmount: null,
//   payerInfoCoInsurancePercent: null,
//   payerInfoDeductibleAmount: null,
//   payerInfoOutOfPocketMax: null,
//   payerInfoEffectiveDate: "",
//   payerInfoTerminationDate: "",
//   payerInfoPriorityId: null,
//   payerInfoPriorityName: "",
//   payerInfoPolicyId: null,
//   payerInfoPolicyType: "",
//   payerId: null,
//   payerInfoPayerName: "",
//   patientPayerAccountNo: null,
//   payerInfoSequenceNumber: null
// }

export const claimInitVal = {
  patientFirstName: "",
  patientLastName: "",
  patientAccountNo: null,
  EPSDT_DetailDto: [],
  icD_DiagnosisDetailDto: [],
  dxpointer: [],
  patientId: null,
  practiceId: null,
  billingProviderId: null,
  insuredPartyId: null,
  payerInfoId: null,
  certificationFieldDetailDto: [],
  renderingProviderId: null,
  supervisingProviderId: null,
  providerId: null,
  facilityId: null,
  billingProviderFirstName: "",
  billingProviderLastName: "",
  billingProviderSequence: null,
  renderingProviderFirstName: "",
  renderingProviderLastName: "",
  renderingProviderSequence: null,
  facilityName: "",
  facilitySequence: null,
  supervisingProviderName: "",
  practiceAddress: "",
  practiceSequenceNo: null,
  primaryPayerInsuranceName: "",
  payerSequenceNo: null,
  primaryPayerMemberId: null,
  primaryPayerGroupId: null,
  primaryPayerPolicyId: null,
  primaryPayerCopayDue: 0,
  frequencyTypeId: null,
  claimFrequencyId: 1,
  typeOfBill: null,
  referenceNumber: "",
  careTypeId: null,
  facilityTypeId: null,
  claimTypeId: 1,
  policyTypeDto: [],
  // tcn: "abc",
  // totalCharges: 200,
  // totalBilled: 200,

  // additioal info init vals
  formTypeId: 1,
  isEmployment: false,
  isAutoAccident: false,
  isOtherAccident: false,
  accidentDate: null,
  lastMenstrualDate: null,
  initialTreatmentDate: null,
  lastSeenDate: null,
  unableToWorkFromDate: null,
  unableToWorkToDate: null,
  isPatientHomebound: false,
  claimCodes: "",
  otherClaimId: null,
  claimInformation: "",
  claimNote: "",
  reasonCode: "",
  reasonDelayCodeId: 1,
  hospitalizedFromDate: null,
  hospitalizedToDate: null,
  labCharges: 0,
  specialProgramCodeId: null,
  patientSignatureId: 1,
  insuredSignatureId: 1,
  providerAcceptanceId: 1,
  documentationMethodId: 1,
  documentationTypeId: null,
  patientHeight: 0,
  patientWeight: 0,
  serviceAuthorizationId: null,
  demonstrationProject: "",
  mammographyCertification: "",
  investigationalDeviceExemption: "",
  ambulatoryPatientGroup: "",

  // ambulance info
  ambulanceClaimId: 2,
  transportReasonId: null,
  transportMiles: 0,
  ambPatientWeight: 0,
  roundTripReason: "",
  stretcherReason: "",
  pickupAddress: "",
  pickupCityId: null,
  pickupCity: "",
  pickupStateId: null,
  pickupState: "",
  pickupZipCode: "",
  dropOffName: "",
  dropOffAddress: "",
  dropOffCityId: null,
  dropOffCity: "",
  dropOffStateId: null,
  dropOffState: "",
  dropOffZipCode: "",
  certificationFieldType: "",

  // institutional
  // isProfessional: 1,
  statementCoversFromDate: null,
  statementCoversToDate: null,
  admissionDate: null,
  admissionHourId: null,
  admissionTypeId: null,
  admissionSourceId: null,
  dischargeHourId: null,
  patientStatusId: null,
  delayReasonCodeId: null,
  pps: "",
  patientDueAmount: 0,
  remarks: "",
  releaseOfInfoId: null,
  assignmentOfBenefitId: null,
  // // providerAcceptAssignment: null,
  // epsdT_1: 0,
  // epsdT_2: 0,
  // epsdT_3: 0,
  principalDiagnosisId: null,
  poaId: null,
  admittingDiagnosisId: null,
  injuryCauseId: null,
  patientVisitReasonId: null,
  otherDiagnosisId: null,
  principalProcedureId: null,
  principalProcedureDate: null,
  occurrenceSpanId: null,
  occurrenceSpanFromDate: null,
  occurrenceSpanToDate: null,
  occurrenceId: null,
  occurrenceDate: null,
  valueId: null,
  valueAmount: 0,
  conditionId: null,
  claimChargesDto: [],
  // intial value of INfoCOde DTO
  insClaimInfoDetailDto: [],
  descriptionFrom: null,
  claimSummaryDto: {
    formTypeId: 1,
    formType: "CMS 1500 02-12",
    totalAmount: 0,
    insPayments: 0,
    patPayment: 0,
    adjustments: 0,
    balance: 0,
    patientCredits: 0,
    lastStatement: '',
    patientFollowUpDate: null,
    patientRecallDate: null,
    dateOfService: null,
    dateEntered: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
    lastBilledDate: null,
    followUpDate: null,
    copayRemaining: 0,
    claimNumber: 0,
    claimId: 0,
    accountNo: null,
    patientId: null,
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    age: 0,
    gender: "",
  },
};


export const payerPolicyTypeInClaim = {
  payerId: null,
  copayAmount: 0,
  payerName: '',
  payerSequenceNo: null,
  priorityId: null,
  priorityName: '',
  memberId: null,
  groupId: null,
  policyTypeId: null,
  claimControlNumber: '',
  authorizationNumber: '',
}

export const paymentInitVal3 = {
  isClaim: true,
  paymentByTypeId: 1,
  payerSequenceNo: null,
  payerId: null,
  accountNo: null,
  paymentFrom: null,
  paymentFromName: "",
  paymentBy: "",
  payerName: null,
  paymentAmount: 0,
  checkDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  checkNumber: null,
  paymentTypeId: null,
  copayServiceDate: null,
  memo: null,
  eftNumber: null,
  paymentMethodId: 1,
  creditCardTypeId: null,
  providerId: null,
  providerName: null,
  sequenceNo: null,
  totalPaymentReceived: 0,
  applied: 0,
  currentClaim: null,
};

// practice
export const practiceInitVals = {
  practiceName: "",
  practiceNpiId: null,
  practiceNpi: null,
  taxonomySpecialty: "",
  organizationTypeId: null,
  organizationTypeName: "",
  sequenceNo: null,
  referenceNo: null,
  tcnPrefix: "",
  code: "",
  isPayToAddressSame: false,
  primaryAddress: "",
  primaryCity: "",
  primaryCityId: null,
  primaryState: "",
  primaryStateId: null,
  primaryZipCode: "",
  primaryPhone: null,
  primaryFax: "",
  payToAddress: "",
  payToCityId: null,
  payToCity: "",
  payToStateId: null,
  payToState: "",
  payToZipCode: "",
};
// faccility
export const facilityInitVals = {
  facilityName: "",
  facilityNpiId: null,
  facilityNpiNo: null,
  taxonomySpecialty: "",
  // sequenceNo: null,
  referenceNo: "",
  facilityAddress: "",
  facilityStateId: null,
  facilityState: "",
  facilityCityId: null,
  facilityCity: "",
  facilityZipCode: "",
  phoneNumber: null,
  faxNumber: null,
  email: "",
  taxId: null,
  cliaId: "",
  locationProviderId: "",
  siteId: "",
  blueCrossId: "",
  blueShieldId: "",
  medicareId: "",
  medicaidId: "",
  locatorCode: "",
  posCodeId: null,
  posCode: "",
};

export const diagnosisInitVal = {
  diagnosisCode: null,
  codeType: "ICD-10",
  diagnosisDescription: null,
  effectiveDate: null,
  terminationDate: null,
};

// provider
export const providerInitValues = {
  providerFirstName: "",
  providerLastName: "",
  providerMI: "",
  providerCredential: "",
  providerNPIId: null,
  providerNPINo: null,
  providerTaxonomySpecialtyCode: "",
  providerReferenceNo: null,
  providerCode: "",
  practiceName: "",
  billingProviderId: null,
  billingProviderFirstName: "",
  billingProviderLastName: "",
  billingProviderSeqNo: null,
  eligibilityProviderId: null,
  eligibilityProviderFirstName: "",
  eligibilityProviderLastName: "",
  eligibilityProviderSeqNo: null,
  practiceSequenceNo: null,
  practiceId: null,
  practiceAddress: "",
  id_NoId: 2,
  id_NoType: "",
  id_NoValue: null,
  billAsId: 1,
  billAsType: "",
  claimModeId: null,
  claimModeType: "",
  claimBillingId: null,
  claimBillingType: "",
  homePhone: null,
  cellPhone: null,
  faxNo: null,
  email: "",
  specialtyLicenseNo: "",
  stateLicenseNo: "",
  anesthesiaLicenseNo: "",
  upinNo: "",
  blueCrossNo: "",
  tricareNo: "",
  revCodeId: null,
  billingProvider: "",
  eligibilityProvider: "",
  ProviderType: "",
  revCode: "",
  providerTypeId: 1,
};

// register
export const register = {
  username: null,
  email: null,
  password: null,
  confirmPassword: null,
  roleName: null,
};

// remittance
export const remittanceValues = {
  remittanceCodeName: "",
  remittanceTypeId: null,
  remittanceType: "",
  remittanceInfoLevelId: null,
  remittanceInfoLevel: "",
  remittanceCodeDetailDtos: [],
  reportDescription: "",
  longDescription: "",
  isMemoline: false,
  isMemolineAmount: false,
};

// adjustment codes
export const adjustmentInitVals = {
  adjustmentCode: "",
  adjustmentCodeTypeId: null,
  adjustmentCodeType: "",
  description: "",
};

// revenue
export const revenueInitVals = {
  revenueCode: "",
  revenueCodePrice: null,
  isDuplicateService: false,
  revenueCodeDescription: "",
  statementDescription: "",
};

export const procedureInitValues = {
  procedureCodeId: 0,
  procedureCode: "",
  procedureCodeTypeId: null,
  procedureCodeType: null,
  procedureDepartment: "",
  procedureDescription: "",
  price: 0,
  units: 1,
  claimStatusId: null,
  claimStatus: null,
  revenueCodeId: null,
  revenueCode: null,
  posCodeId: null,
  posCode: null,
  cliA_No: null,
  tosCodeId: null,
  tosCode: null,
  narrativeNotes: null,
  drugPrice: 0,
  drugUnits: 1,
  drugUnitMeasureId: null,
  drugUnitMeasure: null,
  drugCode: null,
  drugUnitFormatId: null,
  drugUnitFormat: null,
  additionalDescription: null,
  globalPeriodId: null,
  globalPeriod: null,
  effectiveDates: null,
  terminationDates: null,
  isSuperBill: false,
  superBillCategory: null,
  superBillDescription: null,
  statementDescription: null,
  procedureSettingDetailDto: [],
  procedureModifierDetailDto: [],
};

//inventory Code
export const inventoryCode = {
  code: null,
  procedureCodeId: null,
  procedureCode: null,
  quantity: 0,
  codeDescription: null,
  billingDescription: null,
  alert: false,
  alertDescription: null,
  alertQuantity: 0,
};

export const payerIntialVal = {
  payerId: 0,
  payerName: "",
  planName: "",
  payerGroupNumber: "",
  payerClaimOfficeNumber: "",
  payerIDMediaGapNumber: "",
  payerOCNA: "",
  payerClaimOfficeAddress: "",
  payerAddress: "",
  payerZipCode: "",
  payerPhone: null,
  payerFax: "",
  payerEmail: "",
  payerWebsite: "",
  payerCityId: null,
  payerCity: "",
  payerStateId: null,
  payerState: "",
  payerTypeId: 1,
  payerType: "",
  payerSequenceNo: null,
  payerReferenceNo: "",
  networkStatusId: null,
  networkStatusName: "",
  defaultChargeStatusId: 1,
  defaultChargeStatusName: "",
  clearinghouseProcessingModeId: 1,
  clearinghouseProcessingModeName: "",
  useAlternatePractice: false,
  payerAlternatePracticeDto: {
    payerAlternatePracticeId: 0,
    payerId: 0,
    payerAlternatePracticeName: "",
    payerAlternatePracticeAddress: "",
    payerAlternatePracticeZipCode: "",
    payerAlternatePracticeCityId: null,
    payerAlternatePracticeStateId: null,
    payerAlternateTaxId: "",
    payerAlternatePracticeOverrideAddressId: 1,
    payerAlternatePracticeNpiId: null,
    payerAlternatePracticeTaxonomySpecialtyCode: "",
    alternatePracticeNpiNo: "",
    payerAlternatePracticeOverrideAddressName: "",
  },
};
// charge panel
export const chargePanelInitVal = {
  chargePanelTitle: "",
  chargePanelCode: "",
  claimTypeId: 1,
  claimType: "",
  chargePanelDescription: "",
  chargePanelCodeDtos: [],
};

// charge panel procedure
export const chargePanelProcedureValues = {
  procedureCodeId: null,
  procedureCode: "",
  posCodeId: null,
  posCode: "",
  tosCodeId: null,
  tosCode: "",
  modifierOptionId: 1,
  modifierOption: "",
  mod_1: null,
  mod_Code1: "",
  mod_2: null,
  mod_Code2: "",
  mod_3: null,
  mod_Code3: "",
  mod_4: null,
  mod_Code4: "",
  priceOptionId: 1,
  priceOption: "",
  unitPrice: 0,
  units: null,
  total: 0,
};

export const searchPayment = {
  searchTerm: "",
  paymentTypeId: 2,
  number: "",
  name: "",
  ctdosId: "",
  paymentId: [],
  fromDate: "",
  toDate: "",
};

export const paymentPlanValues = {
  paymentPlanAmount: 0,
  existingChargeId: 1,
  firstPayDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  paymentDueMonthId: 1,
  paymentDueEvery: 1,
  generateInstallmentId: 1,
  generateInstallmentName: "",
  eachInstallmentAmount: 0,
  totalNumberOfPayments: 1,
  lastPaymentDate: (() => {
    const currentDate = new Date();
    const nextMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );
    return `${nextMonthDate.getFullYear()}-${(nextMonthDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
  })(),
  description: "",
  detailtDto: [],
};

export const statementBatchPrintVal = {
  accountTypeIds: [],
  batchPrintTypeId: 1,
  statementAmountId: 3,
  statementAmountValue1: 0,
  statementAmountValue2: 0,
  electStatementId: 1,
  electStatementValue1: 0,
  electStatementValue2: 0,
  paperStatementId: 1,
  paperStatementValue1: 0,
  paperStatementValue2: 0,
  totalStatementId: 1,
  totalStatementValue1: 0,
  totalStatementValue2: 0,
  daysLastStatementId: 1,
  daysLastStatementValue1: 0,
  daysLastStatementValue2: 0,
  chargesIncludeId: 1,
  chargeStatusIds: [],
  separateStatementId: 0,
  isSeparateStatement: false,
  practiceSequenceNumbers: [],
  providerSequenceNumbers: [],
  showLastPayment: false,
  accountCredits: false,
};

export const batchLabelPrintValues = {
  patientLastName: 1,
  patientLastName1: "",
  patientLastName2: "",
  accountType: "",
  patientState: "",
  patientAge: "",
  patientBirthMonth: "",
  totalBalance: "",
  patientBalance: "",
  insuranceBalance: "",
  paperStatementsSent: "",
  electronicStatementsSent: "",
  totalStatementsSent: "",
  payerSearch: "",
};

export const icdProcedureInitVals = {
  // icdProcedureCodesId: 0,
  icdCode: "",
  icdCodeTypeId: 1,
  description: "",
  // icdCodeType:""
};

export const debitInitialValueManageAccount = {
  debitCodeId: null,
  amount: 0,
  receivedDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  memo: "",
  providerId: null,
  applied: 0,
  remaining: 0,
  providerName: "",
  codeType: "",
  practiceId: null,
  practiceAddress: "",
  patientAccountNo: null,
  patientId: null,
};
export const creditInitialValueManageAccount = {
  paymentByTypeId: 1,
  paymentByType: "",
  creditCodeId: null,
  codeType: "",
  amount: 0,
  applied: 0,
  remaining: 0,
  receivedDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  memo: "",
  providerName: "",
  practiceAddress: "",
  providerId: null,
  practiceId: 1,
  patientAccountNo: null,
  patientId: null,
};

// status control
export const statusControlInitVal = {
  showDeleted: false,
  enteredDateId: 0,
  enteredfromDate: null,
  enteredtoDate: null,
  dosId: 0,
  doSfromDate: null,
  doStoDate: null,
  submittedId: 0,
  submittedfromDate: null,
  submittedtoDate: null,
  reviewStatusID: 0,
  claimTypeId: 0,
  currentPayerSeqNum: 0,
  claimstatusid: 1,
  currentPayerTypeId: [],
  claimTypeId: [],
  patientAccountNo: 0,
  renderingProviderId: 0,
  facilityID: 0,
  procedureCode: null,
  trackingStatus: 0,
};

export const refundIntValManageAccount = {
  paymentByTypeId: 1,
  paymentByType: "",
  providerId: null,
  practiceId: null,
  refundAmount: null,
  practiceName: "",
  providerName: "",
  refundDate: `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`,
  checkNumber: "",
  memo: "PATIENT REFUND",
  patientAccountNo: null,
  patientId: null,
  amount: 0,
  applied: 0,
  remaining: 0,
};

export const claimTrackerIntVal = {
  filterSearchId: 1,
  ctDosId: 1,
  ctFromDate: null,
  ctToDate: null,
  statusDateId: null,
  sdFromDate: null,
  sdToDate: null,
  submissionTypeId: [],
  processingModeId: [],
  trackingStatusTypeId: [],
  trackingStatusSourceId: [],
  claimStatusId: [],
  claimTypeId: [],
  tcn: "",
  insuranceCheckNumber: "",
  claimNo: null,
  patientAccountNo: null,
  payer: [],
  payerTypeId: [],
  billingProvidersId: [],
  renderingProviderId: [],
  facilityId: [],
  claimHistory: false,
};

export const followUpManagementVal = {
  claimStatusId: [],
  followUpId: 0,
  followUpToDate: null,
  followUpFromDate: null,
  dateOfServiceId: 0,
  dateOfServiceToDate: null,
  dateOfServiceFromDate: null,
  payerId: [],
  practiceId: [],
  providerId: [],
  facilityId: [],
  payerTypeId: [],
  claimNumber: 0,
  patientAccountNumber: 0,
  billedInsId: 0,
  // filterByDaysOrDateId: 1,
  // noOfDays: 1,
  // claimTypeId: 1,
  // claimBalanceId: 1,
  filterByDaysOrDateId: 0,
  noOfDays: 0,
  claimTypeId: 0,
  claimBalanceId: 0,
  billedInsDateId: 0,
  billedInsFromDate: null,
  billedInsToDate: null,
  claimBalanceValue1: 0,
  claimBalanceValue2: 0,
};
export const claimBatchPrintIntVal = {
  claimFormId: 1,
  claimEnterdId: 1,
  claimEnterdFromDate: null,
  claimEnterdToDate: null,
  ctDosId: 1,
  ctFromDate: null,
  ctToDate: null,
  practiceSeqNums: [],
  providerSeqNums: [],
  facilitySeqNums: [],
  officeLocation: [],
  payerSeqNums: [],
  patientAccountNo: null,
  payerPriority: [],
  claimAmountId: null,
  claimAmountVal1: null,
  claimAmountVal2: null,
};
export const feeScheduleVal = {
  ctFeesScheduleId: 1,
};
