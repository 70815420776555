import { Box, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { hcpcsMasterCols } from "../../../utils/gridCols";
import CustomButton from "../../../components/CustomButton";
import { postData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import { toast } from "react-toastify";

const HcpcsMasterList = ({ hcMasterData, handleClose }) => {
  const [selectedCode, setSelectedCode] = useState(null);
  const rows =
    hcMasterData && hcMasterData.length > 0
      ? hcMasterData?.map((el) => ({
          id: el?.hcpcsCodeId,
          ...el,
        }))
      : [];

  const handleAddHcpcs = async () => {
    const selectedAllHcpcs = selectedCode?.map((code) => {
      const { hcpcsCodeId, ...rest } = rows?.find((row) => row.id === code);
      return rest;
    });
    const updatedHcpcsStructure = selectedAllHcpcs?.map((el) => ({
      procedureCode: el?.hcpcsCode,
      procedureSettingDetailDto: el?.hcpcsProcedureSettingDetailDto,
      procedureModifierDetailDto: el?.hcpcsProcedureModifierDetailDto,
      ...el,
    }));
    try {
      const response = await postData(
        `${path}/ct-procedureCodes`,
        updatedHcpcsStructure
      );
      if (response) {
        console.log(response, "check hcpcs response");
        toast.success("HCPCS Code Added Successfully!");
        // handleClose()
      }
    } catch (err) {
      console.log(err, "error in adding hcpcs code");
    }
  };

  return (
    <>
      <Box>
        <DataGrid
          rows={rows}
          columns={hcpcsMasterCols}
          sx={{
            "& .header-bg": {
              backgroundColor: "lightgrey",
              color: "black",
              fontSize: "14px",
            },
            "& .cell-content": {
              fontSize: "12.5px",
            },
          }}
          autoHeight
          checkboxSelection
          disableSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSize={20}
          onSelectionModelChange={(newCode) => setSelectedCode(newCode)}
          components={{
            NoRowsOverlay: () => (
              <div
                style={{ width: "100%", textAlign: "center", padding: "16px" }}
              >
                {hcMasterData.length === 0 && "No Data Is Added"}
              </div>
            ),
          }}
        />

        <Stack direction="row" alignItems="center" padding="10px">
          <CustomButton isBlue={true} isAdd={true} handleClick={handleAddHcpcs}>
            Add
          </CustomButton>
        </Stack>
      </Box>
    </>
  );
};

export default HcpcsMasterList;
