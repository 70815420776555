import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import CustomField from "../../../components/CustomField";
import CustomButton from "../../../components/CustomButton";
import { useFormik } from "formik";
import { getData, postData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomModal from "../../../components/CustomModal";
import CptMasterList from "./CptMasterList";

const SearchCpt = () => {
  const [cptRangeOpt, setCptRangeOpt] = useState([]);
  const [orderByOpt, setOrderByOpt] = useState([]);
  const [cptMasterData, setCptMasterData] = useState([]);
  const [openMasterListMod, setOpenMasterListMod] = useState(false);
  const formik = useFormik({
    initialValues: {
      searchValue: "",
      selectedRange: null,
      orderby: 1,
    },
    onSubmit: async (values) => {
      try {
        const response = await postData(
          `${path}/masterCPTCodes/FilterMasterCPTCode`,
          values
        );
        if (response) {
          setCptMasterData(response.result);
        }
        setOpenMasterListMod(true);
      } catch (err) {
        console.log(err, ": Error in posting hcpcs master list");
        throw err;
      }
    },
  });

  const dataFetchUrls = {
    cptRange: `${path}/ct-cptCodeSection`,
    ctMasterList: `${path}/ct-masterListOrderBy`,
  };

  const fetchDataOptions = async (url, setter) => {
    try {
      const response = await getData(url);
      setter(response.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions(dataFetchUrls.cptRange, setCptRangeOpt);
    fetchDataOptions(dataFetchUrls.ctMasterList, setOrderByOpt);
  }, []);
  return (
    <>
      <Grid
        container
        spacing={2}
        padding="15px"
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid item md={12}>
          <CustomSelectBox2
            label="Select Range to Display"
            formik={formik}
            value={formik.values.selectedRange}
            name="selectedRange"
            dropdownOptions={cptRangeOpt.map((opt) => ({
              value: opt.cptCodeSectionName,
              id: opt.cptCodeSectionId,
            }))}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={12}>
          <CustomField
            value={formik.values.searchValue}
            name="searchValue"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            label="Enter Code"
          />
        </Grid>
        <Grid item md={12}>
          <CustomSelectBox2
            showDefaultOpt={true}
            label="Order Results By"
            formik={formik}
            value={formik.values.orderby}
            name="orderby"
            dropdownOptions={orderByOpt.map((opt) => ({
              value: opt.masterListOrderByName,
              id: opt.masterListOrderById,
            }))}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item md={12}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <CustomButton type="submit" isBlue={true} isSearch={true}>
              Search
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>

      {/* modals here */}
      <CustomModal
        open={openMasterListMod}
        handleClose={() => setOpenMasterListMod(false)}
        height="50%"
        heading="Search CPT"
      >
        <CptMasterList
          cptMasterData={cptMasterData}
          handleClose={() => setOpenMasterListMod(false)}
        />
      </CustomModal>
    </>
  );
};

export default SearchCpt;
