import { createAsyncThunk } from "@reduxjs/toolkit";
import path from "../../config/apiUrl";
import { getData, postData, updateData } from "../../config/axiosFunctions";

export const getFeeScheduleAction = createAsyncThunk("getFeeSchedule", async (page) => {
  try {
    const response = await getData(`${path}/FeeSchedule/FeeSchedulesAll`);

    if (response) {
      return response;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    console.log(error, "action error");
    throw new Error(error);
  }
});




