import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomField from "../../../components/CustomField";
import CustomSelectBox2 from "../../../components/CustomSelectBox2";
import { getData } from "../../../config/axiosFunctions";
import path from "../../../config/apiUrl";
import CustomAccordion from "../../../components/CustomAccordion";
import CheckBox from "../../../components/CheckBox";
import CustomSearchField from "../../../components/CustomSearchField";
import CustomModal from "../../../components/CustomModal";
import TaxonomyCategories from "../practices/taxonomy/TaxonomyCategories";
import SearchNpi from "../practices/npi/SearchNpi";
import { payerIntialVal } from "../../../utils/formikInitValues";
import { useTheme } from "@emotion/react";

const networkStatus = [
  {
    networkStatusId: 1,
    networkStatusType: "In-network",
  },
  {
    networkStatusId: 2,
    networkStatusType: "Out-of-network",
  },
];
const defaultChargeStatus = [
  {
    chargeStatusId: 1,
    chargeStatusType: "Send To Payer Via Clearing house",
  },
  {
    chargeStatusId: 2,
    chargeStatusType: "User Print and Mail To Payer",
  },
  {
    chargeStatusId: 3,
    chargeStatusType: "Charges To Payer",
  },
  {
    chargeStatusId: 4,
    chargeStatusType: "Charges On Hold",
  },
  {
    chargeStatusId: 5,
    chargeStatusType: "Waiting for Review",
  },
  {
    chargeStatusId: 6,
    chargeStatusType: "Due Patient",
  },
];
const PayerForm = ({ formik }) => {
  const theme=useTheme()
  console.log("formik", formik);
  const [openTaxonomyListModal, setOpenTaxonomyListModal] = useState(false);
  const [searchNpiModal, setSearchNpiModal] = useState(false);
  const [payerTypeOpt, setPayerTypeOpt] = useState([]);
  const [clearingHouseProcessingModeOpt, setClearingHouseProcessingModeOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [stateOptforAlternate, setStateOptforAlternate] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const [cityOptforAlternate, setCityOptforAlternate] = useState([]);
  const DateSource = {
    payerType: "/ct-payerType",
    stateType: "/state",
    cityType: "/city",
    clearingHouseProcessingMode:"/ct-clearinghouseProcessingMode"
  };
  const fetchData = async (url, setValues) => {
    try {
      let response = await getData(`${path}${url}`);
      setValues(response.result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectTaxonomy = (taxonomyCode) => {
    formik.setFieldValue(
      "payerAlternatePracticeDto.payerAlternatePracticeTaxonomySpecialtyCode",
      taxonomyCode
    );
  };
  const handleSelectNpi = (npi) => {
    formik.setFieldValue(
      "payerAlternatePracticeDto.alternatePracticeNpiNo",
      npi?.npiNo
    );
    formik.setFieldValue(
      "payerAlternatePracticeDto.payerAlternatePracticeNpiId",
      npi?.id
    );
  };
  useEffect(() => {
    fetchData(DateSource.payerType, setPayerTypeOpt);
    fetchData(DateSource.stateType, setStateOpt);
    fetchData(DateSource.stateType, setStateOptforAlternate);
    fetchData(DateSource.clearingHouseProcessingMode, setClearingHouseProcessingModeOpt);
    if (formik.values?.payerStateId) {
      fetchData(`/city?stateId=${formik.values?.payerStateId}`, setCityOpt);
    }
    if (
      formik.values?.payerAlternatePracticeDto?.payerAlternatePracticeStateId
    ) {
      fetchData(
        `/city?stateId=${formik.values?.payerAlternatePracticeDto?.payerAlternatePracticeStateId}`,
        setCityOptforAlternate
      );
    }
  }, [
    formik.values?.payerStateId,
    formik.values?.payerAlternatePracticeDto?.payerAlternatePracticeStateId,
  ]);
  return (
    <Box>
      {/* Taxonomy Model */}
      <CustomModal
        open={openTaxonomyListModal}
        handleClose={() => setOpenTaxonomyListModal(false)}
        height={"20px"}
      >
        <TaxonomyCategories
          handleSelectTaxonomy={handleSelectTaxonomy}
          handleClose={() => setOpenTaxonomyListModal(false)}
        />
      </CustomModal>
      {/* npi modal */}
      <CustomModal
        open={searchNpiModal}
        handleClose={() => setSearchNpiModal(false)}
      >
        <SearchNpi
          //   setFieldValue={setFieldValue}
          handleSelectNpi={handleSelectNpi}
          handleClose={() => setSearchNpiModal(false)}
          setSearchNpiModal={setSearchNpiModal}
        />
      </CustomModal>
      <Box sx={{background:theme.palette.accordiansBodyBackground.primary,borderRadius:"10px",padding:"15px",margin:"0 0 20px"}}>
      <Box>
        <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          label="Name"
          isRequired={true}
          error={formik.errors}
          touched={formik.touched}
          name="payerName"
          type="text"
          value={formik.values?.payerName}
          
        />
      </Box>
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          label="Plan Name"
          name="planName"
          type="text"
          value={formik.values?.planName}
        />
        <CustomSelectBox2
          dropdownOptions={networkStatus.map((val) => {
            return {
              value: val.networkStatusType,
              id: val.networkStatusId,
            };
          })}
          label={"Network Status"}
          name={"networkStatusId"}
          value={formik.values?.networkStatusId}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          formik={formik}
        />
      </Box>
      <Box>
        <CustomSelectBox2
          dropdownOptions={payerTypeOpt.map((val) => {
            return {
              value: val.payerType,
              id: val.payerTypeId,
            };
          })}
          label={"Payer Type"}
          name={"payerTypeId"}
          value={formik.values?.payerTypeId}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          formik={formik}
          showDefaultOpt={true}
        />
      </Box>

      <Box margin={"15px 0"}>
        <CustomSelectBox2
          dropdownOptions={defaultChargeStatus.map((val) => {
            return {
              value: val.chargeStatusType,
              id: val.chargeStatusId,
            };
          })}
          label={"Default Charge Status"}
          name={"defaultChargeStatusId"}
          value={formik.values?.defaultChargeStatusId}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          formik={formik}
          showDefaultOpt={true}
        />
      </Box>
      <Box margin={"15px 0"}>
        <CustomSelectBox2
          dropdownOptions={clearingHouseProcessingModeOpt.map((val) => {
            return {
              value: val.clearinghouseProcessingModeName,
              id: val.clearinghouseProcessingModeId,
            };
          })}
          label={"Clearinghouse Processing Mode"}
          name={"clearinghouseProcessingModeId"}
          value={formik.values?.clearinghouseProcessingModeId}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          formik={formik}
          showDefaultOpt={true}
        />
      </Box>
      <Box
        display="grid"
        gap="30px"
        margin={"20px 0"}
        sx={{
          gridTemplateColumns: {
            xs: "repeat(1, minmax(0, 1fr))",
            sm: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
          },
        }}
      >
        <CustomField
          type="text"
          label="Sequence #"
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          error={formik.errors}
          touched={formik.touched}
          disable={true}
          name="payerSequenceNo"
          value={formik.values?.payerSequenceNo}
        />
        <CustomField
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          label="Reference #"
          name="payerReferenceNo"
          type="text"
          value={formik.values?.payerReferenceNo}
        />
      </Box>
      </Box>
      <CustomAccordion heading={"Contact Information"} defaultExpanded={true}>
        <Box>
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            isRequired={true}
            error={formik.errors}
            touched={formik.touched}
            label="Address"
            name="payerAddress"
            type="text"
            value={formik.values?.payerAddress}
          />
        </Box>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1 , minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(3, minmax(0, 1fr))",
            },
          }}
        >
          <CustomSelectBox2
            dropdownOptions={stateOpt.map((val) => {
              return {
                value: val.stateName,
                id: val.stateId,
              };
            })}
            label={"State"}
            name={"payerStateId"}
            value={formik.values?.payerStateId}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
            isRequired={true}
          />
          <CustomSelectBox2
            dropdownOptions={cityOpt.map((val) => {
              return {
                value: val.cityName,
                id: val.cityId,
              };
            })}
            label={"City"}
            name={"payerCityId"}
            value={formik.values?.payerCityId}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            formik={formik}
            isRequired={true}
          />
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label="Zip Code"
            isRequired={true}
            error={formik.errors}
            touched={formik.touched}
            name="payerZipCode"
            type="text"
            value={formik.values?.payerZipCode}
          />
        </Box>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(2, minmax(0, 1fr))",
            },
          }}
        >
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label="Phone"
            name="payerPhone"
            type="number"
            value={formik.values?.payerPhone}
          />
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label="Fax"
            name="payerFax"
            type="text"
            value={formik.values?.payerFax}
          />
        </Box>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(2, minmax(0, 1fr))",
            },
          }}
        >
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label="Email Address"
            name="payerEmail"
            type="text"
            value={formik.values?.payerEmail}
          />
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label="Website"
            name="payerWebsite"
            type="text"
            value={formik.values?.payerWebsite}
          />
        </Box>
      </CustomAccordion>
      <CustomAccordion heading={"ID Number"} defaultExpanded={true}>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(2, minmax(0, 1fr))",
            },
          }}
        >
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label="Group Number"
            name="payerGroupNumber"
            type="text"
            value={formik.values?.payerGroupNumber}
          />
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label="Claim Office #"
            name="payerClaimOfficeAddress"
            type="text"
            value={formik.values?.payerClaimOfficeAddress}
          />
        </Box>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(1, minmax(0, 1fr))",
              md: "repeat(2, minmax(0, 1fr))",
            },
          }}
        >
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label="Payer ID/Medigap#"
            name="payerIDMediaGapNumber"
            type="text"
            value={formik.values?.payerIDMediaGapNumber}
          />
          <CustomField
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            label="OCNA"
            name="payerOCNA"
            type="text"
            value={formik.values?.payerOCNA}
          />
        </Box>
        <Box>
          <CheckBox
            key={1}
            val={formik.values?.useAlternatePractice}
            handleChange={() => {
              if (formik.values.useAlternatePractice === false) {
                formik.setFieldValue(
                  "payerAlternatePracticeDto",
                  payerIntialVal.payerAlternatePracticeDto
                );
                formik.setFieldValue(
                  "useAlternatePractice",
                  !formik.values?.useAlternatePractice
                );
              } else {
                formik.setFieldValue(
                  "useAlternatePractice",
                  !formik.values?.useAlternatePractice
                );
              }
            }}
            label={
              " Use alternate practice information (override the practice info on claim) "
            }
            CheckedValue={formik.values?.useAlternatePractice}
          />
        </Box>
      </CustomAccordion>
      {formik.values?.useAlternatePractice && (
        <CustomAccordion
          heading={"Alternate Practice Operation"}
          defaultExpanded={true}
        >
          <Box>
            <Typography variant="h4">
              On electronic claims, the below address will override the
            </Typography>
            <Box
              display="grid"
              gap="30px"
              margin={"20px 0"}
              sx={{
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(1, minmax(0, 1fr))",
                  md: "repeat(2, minmax(0, 1fr))",
                },
              }}
            >
              <CheckBox
                key={1}
                val={
                  formik.values?.payerAlternatePracticeDto
                    ?.payerAlternatePracticeOverrideAddressId
                }
                handleChange={() => {
                  formik.setFieldValue(
                    "payerAlternatePracticeDto.payerAlternatePracticeOverrideAddressId",
                    1
                  );
                }}
                label={"Pay-To Address (Loop 2010AB)"}
                CheckedValue={
                  formik.values?.payerAlternatePracticeDto
                    ?.payerAlternatePracticeOverrideAddressId === 1
                }
              />
              <CheckBox
                key={1}
                val={
                  formik.values?.payerAlternatePracticeDto
                    ?.payerAlternatePracticeOverrideAddressId
                }
                handleChange={() => {
                  formik.setFieldValue(
                    "payerAlternatePracticeDto.payerAlternatePracticeOverrideAddressId",
                    2
                  );
                }}
                label={"Main Address (Loop 2010AA)"}
                CheckedValue={
                  formik.values?.payerAlternatePracticeDto
                    ?.payerAlternatePracticeOverrideAddressId === 2
                }
              />
            </Box>
          </Box>
          <Box>
            <CustomField
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              label="Name"
              name="payerAlternatePracticeDto.payerAlternatePracticeName"
              type="text"
              value={
                formik.values?.payerAlternatePracticeDto
                  ?.payerAlternatePracticeName
              }
            />
          </Box>
          <Box>
            <CustomField
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              label="Address"
              name="payerAlternatePracticeDto.payerAlternatePracticeAddress"
              type="text"
              value={
                formik.values?.payerAlternatePracticeDto
                  .payerAlternatePracticeAddress
              }
            />
          </Box>
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1 , minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(3, minmax(0, 1fr))",
              },
            }}
          >
            <CustomSelectBox2
              dropdownOptions={stateOptforAlternate.map((val) => {
                return {
                  value: val.stateName,
                  id: val.stateId,
                };
              })}
              label={"State"}
              name={"payerAlternatePracticeDto.payerAlternatePracticeStateId"}
              value={
                formik.values?.payerAlternatePracticeDto
                  ?.payerAlternatePracticeStateId
              }
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
            />
            <CustomSelectBox2
              dropdownOptions={cityOptforAlternate.map((val) => {
                return {
                  value: val.cityName,
                  id: val.cityId,
                };
              })}
              label={"City"}
              name={"payerAlternatePracticeDto.payerAlternatePracticeCityId"}
              value={
                formik.values?.payerAlternatePracticeDto
                  .payerAlternatePracticeCityId
              }
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              formik={formik}
            />
            <CustomField
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              label="Zip Code"
              name="payerAlternatePracticeDto.payerAlternatePracticeZipCode"
              type="text"
              value={
                formik.values?.payerAlternatePracticeDto
                  ?.payerAlternatePracticeZipCode
              }
            />
          </Box>
          <Box
            display="grid"
            gap="30px"
            margin={"20px 0"}
            sx={{
              gridTemplateColumns: {
                xs: "repeat(1 , minmax(0, 1fr))",
                sm: "repeat(1, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
              },
            }}
          >
            <CustomField
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              label="Tax ID"
              name="payerAlternatePracticeDto.payerAlternateTaxId"
              type="string"
              value={
                formik.values?.payerAlternatePracticeDto.payerAlternateTaxId
              }
            />
            <CustomSearchField
              label="Taxonomy Speciality"
              fieldVal={
                formik.values?.payerAlternatePracticeDto
                  ?.payerAlternatePracticeTaxonomySpecialtyCode
              }
              name="payerAlternatePracticeDto.payerAlternatePracticeTaxonomySpecialtyCode"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              handleModalOpen={() => setOpenTaxonomyListModal(true)}
            />
          </Box>
          <Box>
            <CustomSearchField
              fieldVal={
                formik.values?.payerAlternatePracticeDto.alternatePracticeNpiNo
              }
              name="payerAlternatePracticeDto.alternatePracticeNpiNo"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              handleModalOpen={() => setSearchNpiModal(true)}
              label="NPI"
            />
          </Box>
        </CustomAccordion>
      )}
    </Box>
  );
};

export default PayerForm;
