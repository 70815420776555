// import React from 'react';
// import { useState,useEffect } from 'react';
// import { Stack,Box,Typography,useTheme } from '@mui/material';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import AdminImage from '../../../assets/ellipse21.png';
// import WaveImage from '../../../assets/waveImg.png';

// const ProfileCard = () => {

//   const theme=useTheme();
//       const [isWaving, setIsWaving] = useState(false);
    
//       useEffect(() => {
//         const wavingInterval = setInterval(() => {
//           setIsWaving((prevIsWaving) => !prevIsWaving);
//         }, 1000); 
    
//         return () => {
//           clearInterval(wavingInterval);
//         };
//       }, []); 


      
//     // For Time and  Greeting
//   const [currentTime, setCurrentTime] = useState(new Date());
//   const [greeting, setGreeting] = useState('');

//   useEffect(() => {
//     const updateCurrentTime = () => {
//       setCurrentTime(new Date());
//     };

   
//     const interval = setInterval(updateCurrentTime, 1000);

//     return () => clearInterval(interval); 
//   }, []);

//   useEffect(() => {
//     const currentHour = currentTime.getHours();

//     if (currentHour >= 6 && currentHour < 12) {
//       setGreeting('Good Morning');
//     } else if (currentHour >= 12 && currentHour < 18) {
//       setGreeting('Good Afternoon');
//     } else {
//       setGreeting('Good Evening');
//     }
//   }, [currentTime]);
//   return (
//     <>
//      <Card className='adminProfile' sx={{ width: '100%' ,height: '100%',  background: theme.palette.profileCard.primary,}}>
//           <CardContent>
//           <Stack spacing={4} direction='row'>
//             <Box>
//             <img style={{width:'90px',marginTop:'10%'}}src={AdminImage} alt="adminImg"/>
//         </Box>
//         <Stack spacing={4} direction='column' >
//         <Box className='profileChild'>
//          <Typography  fontWeight={'600'} className='profchildText'>{greeting}</Typography>
//          <img   
//          style={{
//         marginLeft: '20px',
//         transition: 'transform 0.6s ease-in-out',
//         transform: isWaving ? 'rotate(30deg)' : 'rotate(0deg)',
//       }}
//       src={ WaveImage} alt="adminImg"/>
//         </Box>
//         {/* <Box sx={{background:'red'}}>
//         <Typography  variant='h4' fontWeight={'600'} className='profchildText2'>BRUCE L. FELDMAN, MD</Typography>
//         <Typography sx={{marginLeft:'-20px'}} variant='h4'  className='profchildText3'>ADMIN</Typography>
//         </Box> */}
//         <Box className="nameBox">
//                 <Typography variant="h4" fontWeight="600" className="profchildText2">
//                   BRUCE L. FELDMAN, MD
//                 </Typography>
//                 <Typography variant="h4" className="profchildText3">
//                   ADMIN
//                 </Typography>
//               </Box>
//         </Stack>
//             </Stack>  
//           </CardContent>
//         </Card>
//     </>
//   )
// }

// export default ProfileCard;




import React from 'react';
import { useState, useEffect } from 'react';
import { Stack, Box, Typography, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AdminImage from '../../../assets/ellipse21.png';
import WaveImage from '../../../assets/waveImg.png';

const ProfileCard = () => {
  const theme = useTheme();
  const [isWaving, setIsWaving] = useState(false);

  useEffect(() => {
    const wavingInterval = setInterval(() => {
      setIsWaving((prevIsWaving) => !prevIsWaving);
    }, 1000);

    return () => {
      clearInterval(wavingInterval);
    };
  }, []);

  // For Time and Greeting
  const [currentTime, setCurrentTime] = useState(new Date());
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const updateCurrentTime = () => {
      setCurrentTime(new Date());
    };

    const interval = setInterval(updateCurrentTime, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const currentHour = currentTime.getHours();

    if (currentHour >= 6 && currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
  }, [currentTime]);

  return (
    <>
      <Card className="adminProfile" sx={{ width: '100%', height: '100%', background: theme.palette.profileCard.primary }}>
        <CardContent>
          <Stack spacing={4} direction="row">
            <Box>
              <img style={{ width: '90px', marginTop: '10%' }} src={AdminImage} alt="adminImg" />
            </Box>
            <Stack spacing={4} direction="column" alignItems="flex-start"> {/* Ensure left alignment */}
              <Box className="profileChild">
                <Typography fontWeight="600" className="profchildText">
                  {greeting}
                </Typography>
                <img
                  style={{
                    marginLeft: '20px',
                    transition: 'transform 0.6s ease-in-out',
                    transform: isWaving ? 'rotate(30deg)' : 'rotate(0deg)',
                  }}
                  src={WaveImage}
                  alt="waveImg"
                />
              </Box>
              <Box className="nameBox">
                <Typography variant="h4" fontWeight="600" className="profchildText2">
                  BRUCE L. FELDMAN, MD
                </Typography>
                <Typography variant="h4" className="profchildText3">
                  ADMIN
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default ProfileCard;
