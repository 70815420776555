import React, { useEffect } from "react";
import FacilityInfo from "./FacilityInfo";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  getFacilityAction,
  newFacilityAction,
  updateFacilityAction,
} from "../../../features/actions/facilityAction";
import { useNavigate, useParams } from "react-router-dom";
import { facilityInitVals } from "../../../utils/formikInitValues";
import CustomButton from "../../../components/CustomButton";
import MainHeading from "../../../components/MainHeading";
import { Box,Typography } from "@mui/material";
import { facilitySchema } from "../../../schemas/patientSchema";
import CustomDialog from "../../../components/CustomDialog";


const UpdateFacility = () => {
  const dispatch = useDispatch();
  // const handleCancel = () => {
  //   console.log("object");
  //   const conform = window.confirm("Are you sure you want to cancel?");
  //   if (conform) {
  //     formik.resetForm();
  //     navigate("/facility");
  //   }
  // };


  const navigate = useNavigate();
  const { id } = useParams();
  const { getFacilityData, loading } = useSelector((state) => state.facility);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  console.log(getFacilityData, "getFacilityData");
  // const facilityDataToEdit = getFacilityData.result?.find(
  //   (item) => item.facilityId === Number(id)
  // );
  const facilityDataToEdit = getFacilityData.result?.facility?.find(
      (item) => item.facilityId === Number(id)
    );
  console.log(facilityDataToEdit, "facilityDataToEdit")
  const formik = useFormik({
    initialValues: facilityDataToEdit || facilityInitVals,
    validationSchema : facilitySchema,
    onSubmit: (values, action) => {
      console.log(values, "checkFacilityVals");
      dispatch(
        updateFacilityAction({
          facilityId: facilityDataToEdit?.facilityId,
          ...values,
        })
      );
      navigate("/facility");
    },
  });

  // useEffect(() => {
  //   try {
  //     dispatch(getFacilityAction());
  //   } catch (err) {
  //     console.log(err, "checkErr");
  //     throw err;
  //   }
  // }, []);

  useEffect(() => {
    try {
      dispatch(getFacilityAction());
    } catch (error) {
      throw error;
    }
  }, [dispatch]);

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    formik.resetForm();
    navigate("/facility")
  };
  return (
    <>
    <Box margin={"20px"} sx={{ width: { xs: "80%", sm: "70%", md: "60%" } }}>
    
        <MainHeading mainheading={"Update Facility"} />
      <form onSubmit={formik.handleSubmit}>
        <Box
          display="grid"
          gap="30px"
          margin={"20px 0"}
          sx={{
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 150px))",
              sm: "repeat(1, minmax(0, 150px))",
              md: "repeat(2, minmax(0, 150px))",
            },
          }}
        >
          <CustomButton type="submit" isBlue={true} isEdit={true}>
            Update
          </CustomButton>
          {/* <CustomButton
            
            type="submit"
            isCancel={true}
            handleClick={handleCancel}
          >
            Cancel
          </CustomButton> */}
          <CustomButton  isCancel={true} handleClick={handleCancel}>
           Cancel
         </CustomButton>
        </Box>
        <FacilityInfo formik={formik} />
      </form>
      </Box>
      <CustomDialog
 open={openCancelDialog}
 isWarning={true}
 handleClose={() => setOpenCancelDialog(false)}
 handleConfirm={handleConfirmCancel}
 handleCancel={() => setOpenCancelDialog(false)}
 confirmButtonTitle="OK"
 cancelButtonTitle="Cancel"
>
 <Typography component="h3">Are you sure you want to cancel?</Typography>
</CustomDialog>
    </>
  );
};

export default UpdateFacility;
