import React from 'react';
import { Box,Grid,Typography,Stack,Radio,FormControl,FormControlLabel,useTheme} from '@mui/material';
import CheckBox from '../../../../components/CheckBox';

const MedicareFeeSchedule = () => {
    const theme=useTheme()
    const medicareCarrierAndLocality=[
        {id:'1',ctOption:'Use Your Zip Code'},
     {id:'2',ctOption:'Enter The Carrier And Locality'},
    ]
    const pricingMethod=[
        {id:'1',ctOption:'Non Facility Pricing'},
     {id:'2',ctOption:'Facility Pricing'},
    ]
    const adjustPrices=[
        {id:'1',ctOption:'Do not adjust the prices of the new fee schedule '},
     {id:'2',ctOption:'Do not adjust the prices of the new fee schedule '},
     {id:'3',ctOption:'Adjust Prices to'}
    ]
  return (
    <>
    <Grid container spacing={2}>
       <Grid item md={12}>
       <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px" ,margin:'15px'}}>
            <Box
              sx={{
                background: theme.palette.revenueStatement.primary,
                height: "33px",
              }}
            >
              <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
              Medicare Carrier and Locality
              </Typography>
            </Box>

            <FormControl fullWidth sx={{ padding: "10px" }}>
            <Stack flexDirection="column" alignItems="left" marginLeft="20px">
            {medicareCarrierAndLocality.map((value) => (
            <FormControlLabel
              key={value.id}
              label={value.ctOption}
              control={
                <Radio
                //   value={value.id}
                //   checked={formik.values.ctFeesScheduleId === value.ctFeesScheduleId}
                //   onChange={() => handleRadioChange(value.ctFeesScheduleId)}
                  name="radio-buttons"
                />
              }
            />
          ))}
        </Stack>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
        <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px" ,margin:'15px'}}>
            <Box
              sx={{
                background: theme.palette.revenueStatement.primary,
                height: "33px",
              }}
            >
              <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
              Pricing Method
              </Typography>
            </Box>

            <FormControl fullWidth sx={{ padding: "10px" }}>
            <Stack flexDirection="column" alignItems="left" marginLeft="20px">
            {pricingMethod.map((value) => (
            <FormControlLabel
              key={value.id}
              label={value.ctOption}
              control={
                <Radio
                //   value={value.id}
                //   checked={formik.values.ctFeesScheduleId === value.ctFeesScheduleId}
                //   onChange={() => handleRadioChange(value.ctFeesScheduleId)}
                  name="radio-buttons"
                />
              }
            />
          ))}
        </Stack>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
        <Box sx={{ border: "1px solid #6e6b6b", borderRadius: "5px" ,margin:'15px'}}>
            <Box
              sx={{
                background: theme.palette.revenueStatement.primary,
                height: "33px",
              }}
            >
              <Typography variant="h5" fontWeight={"600"} padding={"3px"}>
            Adjust Prices
              </Typography>
            </Box>

            <FormControl fullWidth sx={{ padding: "10px" }}>
            <Stack flexDirection="column" alignItems="left" marginLeft="20px">
            {adjustPrices.map((value) => (
            <FormControlLabel
              key={value.id}
              label={value.ctOption}
              control={
                <Radio
                //   value={value.id}
                //   checked={formik.values.ctFeesScheduleId === value.ctFeesScheduleId}
                //   onChange={() => handleRadioChange(value.ctFeesScheduleId)}
                  name="radio-buttons"
                />
              }
            />
          ))}
        </Stack>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default MedicareFeeSchedule
