import React from "react";
import { Box, Typography,useTheme} from "@mui/material";
import { Stack } from "@mui/system";

const PreviewEdi = ({ data }) => {
  const theme=useTheme()
  const renderValue = (value) => {
    if (typeof value === "object" && value !== null) {
      return Object.values(value).map((nested, i) => (
        <Typography key={i} component="h4">
          {renderValue(nested)}
        </Typography>
      ));
    } else if (typeof value === "string") {
      return <Typography component="h4">{value}</Typography>;
    } else if (Array.isArray(value)) {
      return value.map((item, i) => (
        <Typography key={i} component="h4">
          {renderValue(item)}
        </Typography>
      ));
    } else {
      return null;
    }
  };

  return (
    <Box margin="14px" height="100%" border="1px solid grey">
      {Object.keys(data).map((key) => (
        <Stack
          key={key}
          direction="row"
          columnGap={2}
          margin={"10px 0"}
          height="100% !important"
        >
          <Typography
            component="h3"
            sx={{
              // background: "#c8e8fa",
              background: theme.palette.previewEdi.primary,

              minWidth: "12%",
              minHeight: "auto",
              textAlign: "center",
              padding: "10px",
              borderBottom: "1px solid grey",
            }}
          >
            {key.startsWith("_")
              ? key.substring(1).toUpperCase()
              : key.toUpperCase()}
          </Typography>
          <Stack
            direction="column"
            sx={{
              width: "70%",
              // height: "100%",
              minHeight: "auto",
              // backgroundColor: "#fff !important",
              background: theme.palette.previewEdiInfo.primary,
            }}
          >
            {renderValue(data[key])}
          </Stack>
        </Stack>
      ))}
    </Box>
  );
};

export default PreviewEdi;
