import { Box, Stack, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import path from "../../../config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { getProviderAction } from "../../../features/actions/providerAction";
import { getData } from "../../../config/axiosFunctions";
import { providerCols } from "../../../utils/gridCols";
import CustomField from "../../../components/CustomField";
import CustomButton from "../../../components/CustomButton";
import Loading from "../../../components/Loading";
import { useConfirm } from "material-ui-confirm";
import { getPracticeAction } from "../../../features/actions/practiceAction";
import { practiceCols } from './../../../utils/gridCols';

const MultiPractice = ({ formik, handleClose }) => {
    const theme = useTheme()
    const confirm = useConfirm()
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const {searchterm, setSearchTerm} = useState("")
  const [page, setPage] = useState(0);
  const { getPractices, loading } = useSelector((state) => state.practices);
  console.log(selectedRows, "selectedRows")
  const rows =
    getPractices?.result?.practice.length > 0
      ? getPractices?.result?.practice?.map((el) => ({
          id: el?.practiceId,
          ...el,
        }))
      : [];

  useEffect(() => {
    try {
      dispatch(getPracticeAction(page + 1));
    } catch (error) {
      console.log(error, "Fetching Practice Error");
    }
  }, []);

//   handlePageChange
const handlePageChange = (newPage) => {
    setPage(newPage)
}

// handle add rows

const handleAddRows = () => {
    const selectedProvData = selectedRows?.map((rowId) => rows?.find((el) => el.id === rowId))
    let setRowData = selectedProvData?.map(el => ({
        sequenceNumber: el?.sequenceNo,
        practiceName: el?.practiceName
    }))
   const alreadyExist = setRowData.some(({sequenceNumber}) => formik.values.practiceSequenceNumbers.some(el => el.sequenceNumber === sequenceNumber))
    if(alreadyExist) {
        confirm({
            title:"Already Exist!",
            confirmationText:"Ok",
            
        })
    }else{
        formik.setValues(preVals => ({
            ...preVals,
            practiceSequenceNumbers: [...preVals?.practiceSequenceNumbers, ...setRowData]
        }))
    }
    handleClose()
}
  return (
    <Box minHeight={"200px"} margin="15px">
        <Stack direction="row" alignItems="flex-end" marginBottom="15px">
           <CustomField  label="Search" value={searchterm} name='searchTerm' handleChange={(e) => setSearchTerm(e.target.value)} />
           <CustomButton
                type="button"
                // handleClick={handleSearchChange}
                isBlue={true}
                padding="5px 30px"
                margin={"0 10px 0"}
                isSearch={true}
              >
                Search
              </CustomButton>
        </Stack>
        {
            loading ? <Loading /> : 
            (
                <>
                <Box>
                <DataGrid
            columns={practiceCols}
            rows={rows}
            autoHeight
            page={page}
            rowHeight={35}
            pagination
            pageSize={getPractices?.result?.pageSize}
            checkboxSelection
            onSelectionModelChange={(newRow) => setSelectedRows(newRow)}
            disableSelectionOnClick
            onPageChange={handlePageChange}
            rowCount={getPractices?.result?.totalCount}
            sx={{
                "& .header-bg": {
                  background: theme.palette.dataGridHeading.primary,
                  color: theme.palette.textMainHeading.primary,
                  fontSize: "16px",
                },
                "& .cell-content": {
                  fontSize: "1.2rem",
                },
                "& .MuiTablePagination-displayedRows": {
                  fontSize: "1.3rem",
                },
                "& .MuiTablePagination-actions": {
                  "& svg": {
                    fontSize: "1.8rem",
                    color: theme.palette.textMainHeading.primary,
                    marginBottom: "8px",
                  },
                },
              }}    
              components={{
                NoRowsOverlay: () => (
                  <div
                    style={{ width: "100%", textAlign: "center", padding: "16px" }}
                  >
                    {rows?.length === 0 && "No Data Is Added"}
                  </div>
                ),
              }}
          />

          <CustomButton handleClick={handleAddRows} type="button" isAdd={true}>Add</CustomButton>
                </Box>
                </>
            ) 
        }
     
    </Box>
  );
};

export default MultiPractice;
